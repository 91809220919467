import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../img/logo/proxeet_logo.svg';
import logoWhite from '../img/logo/proxeet_logo_white.svg';
import Question from './question';
import { withGetScreen } from 'react-getscreen';
import { SocialButton } from './SocialButton';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import { Link } from 'react-router-dom';


class SiteMenu extends React.Component {


    constructor(props) {
        super(props);





        if (this.props.menuItemActive === "about") { this.state.aboutColor = '#FF7E33'; }
        else if (this.props.menuItemActive === "products") { this.state.productsColor = '#FF7E33'; }
        else if (this.props.menuItemActive === "howToBuy") { this.state.howToBuyColor = '#FF7E33'; }
        else if (this.props.menuItemActive === "support") { this.state.supportColor = '#FF7E33'; }
        else if (this.props.menuItemActive === "news") { this.state.newsColor = '#FF7E33'; }
        else if (this.props.menuItemActive === "contacts") { this.state.contactsColor = '#FF7E33'; }


    }

    state = {

        productsEnable: '',
        basicModal: false,
        aboutColor: '',
        productsColor: '',
        howToBuyColor: '',
        supportColor: '',
        newsColor: '',
        contactsColor: '',
        MobMenuState: false

    }

    contactsHandler = () => {

        this.props.callContacts();

    }




    menu = (eventKey) => {




        this.setState({ aboutColor: '' });
        this.setState({ productsColor: '' });
        this.setState({ howToBuyColor: '' });
        this.setState({ supportColor: '' });
        this.setState({ newsColor: '' });
        this.setState({ contactsColor: '' });


        this.props.menuItemSet(eventKey);




    }



    brand = () => {

        this.menu();
        this.props.call();


    }



    about = () => {

        this.props.callAbout();
        this.setState({ MobMenuState: !this.state.MobMenuState });

    }
    products = () => {

        this.props.callProducts();
        this.setState({ MobMenuState: !this.state.MobMenuState });

    }
    howToBuy = () => {

        this.props.callHowToBuy();
        this.setState({ MobMenuState: !this.state.MobMenuState });

    }
    support = () => {
        this.props.callSupport();
        this.setState({ MobMenuState: !this.state.MobMenuState });

    }
    news = () => {
        this.props.callNews();
        this.setState({ MobMenuState: !this.state.MobMenuState });

    }
    contacts = () => {

        this.contactsHandler();
        this.setState({ MobMenuState: !this.state.MobMenuState });

    }

    render() {




        if (this.props.isMobile()) return (

            <>

                {
                    this.props.menuColor === "blue" ?


                        <style type="text/css">
                            {`
                            
                            .modal { 
                                overflow-y: hidden;
                                position: relative;
                                margin: 0px auto;
                            }
    
                            .navbar-toggler-icon {
                                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
                            }

    
                        `}
                        </style>
                        :
                        <style type="text/css">
                            {`
                        
                        .modal { 
                            overflow-y: hidden;
                            position: relative;
                            margin: 0px auto;
                        }
                        
                       
                        .navbar-toggler-icon {
                            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23153F4E' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
                        }
                        
                    `}
                        </style>

                }


                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} mod={'QUESTIONS? WE ARE HERE TO ASSIST YOU'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>

                </Modal>


                <div style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#F8F9FA' }} className="container-fluid">


                    <Navbar style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#F8F9FA' }} collapseOnSelect expanded={this.state.MobMenuState} expand='sm'  >

                        <Navbar.Brand>
                            <Link to='/'>
                                <img
                                    onClick={this.props.call}
                                    className="mx-2 my-2 d-inline-block align-top"
                                    src={this.props.menuColor === "blue" ? logoWhite : logo}
                                    width="150"
                                    height="40"
                                    alt="logo"
                                />
                            </Link>
                        </Navbar.Brand>

                        <Navbar.Toggle
                            onClick={() => { this.setState({ MobMenuState: !this.state.MobMenuState }); }}
                            style={{ color: '#003052', boxShadow: 'none' }}
                            className="mx-3 my-2 border-0" aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse className='justify-content-center align-middle  text-center' id="basic-navbar-nav">
                            <Navbar.Offcanvas style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#FFFFFF' }} >

                                <div className='row justify-content-center align-middle  text-center my-4'>

                                    <Link className='col-6 text-start mx-4' to='/'>
                                        <img
                                            onClick={this.props.call}
                                            className="d-inline-block"
                                            src={this.props.menuColor === "blue" ? logoWhite : logo}
                                            width="168"
                                            height="42"
                                            alt="logo"
                                        />
                                    </Link>

                                    <Navbar.Toggle
                                        onClick={() => { this.setState({ MobMenuState: !this.state.MobMenuState }); }}
                                        style={{ color: '#003052', boxShadow: 'none' }}
                                        className="col-4" aria-controls="basic-navbar-nav" />

                                </div>

                                <Nav className="mx-4 text-center mt-4" >

                                    <Link className='my-2' onClick={this.about} to='about'> <span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>About us</span></Link>


                                    {this.props.productsStatus === 1 && <Link className='my-3' onClick={this.products} to='products' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>Products</span></Link>}
                                    <Link className='my-3' onClick={this.howToBuy} to='howToBuy' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>How to buy?</span></Link>
                                    <Link className='my-3' onClick={this.support} to='support' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>Support</span></Link>
                                    <Link className='my-3' onClick={this.news} to='news' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>News</span></Link>
                                    <Link className='my-3' onClick={this.contacts} to='contacts' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>Contacts</span></Link>

                                    <div className='col-12 my-4'>
                                        <button className="btn btn-proxeet-mob" onClick={() => this.setState({ basicModal: !this.state.basicModal })}>GET IN TOUCH</button>
                                    </div>


                                    <div className='col-12 my-4'>
                                        <SocialButton color={this.props.menuColor} />
                                    </div>

                                </Nav>
                            </Navbar.Offcanvas>
                        </Navbar.Collapse>


                    </Navbar >
                </div>

            </>

        );

        if (this.props.isTablet()) return (

            <>

                {
                    this.props.menuColor === "blue" ?


                        <style type="text/css">
                            {`
                            
                            .modal { 
                                overflow-y: hidden;
                                position: relative;
                                margin: 0px auto;
                            }
    
                            .navbar-toggler-icon {
                                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
                            }

    
                        `}
                        </style>
                        :
                        <style type="text/css">
                            {`
                        
                        .modal { 
                            overflow-y: hidden;
                            position: relative;
                            margin: 0px auto;
                        }
                        
                       
                        .navbar-toggler-icon {
                            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23153F4E' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
                        }
                        
                    `}
                        </style>

                }


                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} mod={'QUESTIONS? WE ARE HERE TO ASSIST YOU'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>

                </Modal>


                <div style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#F8F9FA' }} className="container-fluid">
                    <Navbar style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#F8F9FA' }} collapseOnSelect expanded={this.state.MobMenuState} expand='md'  >

                        <Navbar.Brand >
                            <Link to='/'>
                                <img
                                    onClick={this.props.call}
                                    className="mx-4 my-2 d-inline-block align-top"
                                    src={this.props.menuColor === "blue" ? logoWhite : logo}
                                    width="168"
                                    height="42"
                                    alt="logo"
                                />
                            </Link>
                        </Navbar.Brand>

                        <Navbar.Toggle
                            onClick={() => { this.setState({ MobMenuState: !this.state.MobMenuState }); }}
                            style={{ border: '0px', color: '#003052', boxShadow: 'none' }}
                            className="mx-3 my-2" aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse className='justify-content-center align-middle  text-center' id="basic-navbar-nav">
                            <Nav className="mx-4" >

                                <Link className='my-2' onClick={this.about} to='about'> <span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>About us</span></Link>


                                {this.props.productsStatus === 1 && <Link className='my-3' onClick={this.products} to='products' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>Products</span></Link>}
                                <Link className='my-3' onClick={this.howToBuy} to='howToBuy' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>How to buy?</span></Link>
                                <Link className='my-3' onClick={this.support} to='support' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>Support</span></Link>
                                <Link className='my-3' onClick={this.news} to='news' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>News</span></Link>
                                <Link className='my-3' onClick={this.contacts} to='contacts' ><span style={{ fontSize: '28px', color: this.props.menuColor === "blue" ? '#FFFFFF' : '#152F4E' }}>Contacts</span></Link>

                                <button className="d-block d-xl-none apply-btn btn btn-success"
                                    onClick={() => this.setState({ basicModal: !this.state.basicModal })}
                                    style={{ marginTop: '60%', height: '7vh', border: '0px', backgroundColor: '#FF7E33', borderRadius: '30px 30px 30px 30px' }} >
                                    <span style={{ fontSize: '22px' }} className='ttt'>GET IN TOUCH</span></button>
                                <div className='col-12 my-4'>
                                    <SocialButton color={this.props.menuColor} />
                                </div>

                            </Nav>
                        </Navbar.Collapse>
                        <button className="d-none d-xl-block mx-4 my-2 apply-btn btn btn-success"
                            onClick={() => this.setState({ basicModal: !this.state.basicModal })}
                            style={{ height: '54px', width: '243px', border: '0px', backgroundColor: '#FF7E33', borderRadius: '30px 30px 30px 30px' }} >
                            <span style={{ fontSize: '20px' }} className='ttt'>GET IN TOUCH</span></button>


                    </Navbar >
                </div>


            </>

        );

        return (

            <>


                <style type="text/css">
                    {`
                        
                        .modal { 
                            overflow-y: hidden;
                            
                            
                            
                        }
                        
                       
                        .navbar-toggler-icon {
                            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,48,82, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
                        }
                                                
                    `}
                </style>


                <Modal style={{ overflowY: 'auto' }}
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} mod={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>

                </Modal>

                {/*  --- mg ---  */}
                <div style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#F8F9FA' }} className="container-fluid d-none d-md-block d-xl-none">
                    <Navbar style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#F8F9FA' }} onSelect={this.menu} collapseOnSelect expand="md" >

                        <Navbar.Brand>
                            <Link to='/'>
                                <img
                                    onClick={this.brand}
                                    className="mx-2 my-2 d-inline-block align-top"
                                    src={this.props.menuColor === "blue" ? logoWhite : logo}
                                    width="168"
                                    height="42"
                                    alt="logo"
                                />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle
                            style={{ border: '0px', color: '#003052', boxShadow: 'none' }}
                            className="mx-2 my-2" aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse style={{ justifyContent: 'center' }} id="basic-navbar-nav">
                            <Nav className='' style={{ width: '100%', height: '1.8rem', }}>

                                <Link
                                    className='px-2'
                                    style={{ background: this.props.menuItemActive === 'about' && '#FF7E33', borderRadius: '12px' }}
                                    onClick={this.props.callAbout} eventKey="about"
                                    to="about">
                                    <span className='open-sans0' style={{ fontSize: '0.6rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === 'about' ? 'white' : '#152F4E' }}>About us</span>
                                </Link>

                                {this.props.productsStatus === 1 &&
                                    <Link
                                        className='px-2'
                                        style={{ background: this.props.menuItemActive === 'products' && '#FF7E33', borderRadius: '12px' }}
                                        onClick={this.props.callProducts} eventKey="products"
                                        to='products'>
                                        <span className='open-sans0' style={{ fontSize: '0.6rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "products" ? 'white' : '#152F4E' }}>Products</span>
                                    </Link>
                                }

                                <Link
                                    className='px-2'
                                    style={{ background: this.props.menuItemActive === 'howToBuy' && '#FF7E33', borderRadius: '12px' }}
                                    onClick={this.props.callHowToBuy} eventKey="howToBuy"
                                    to="howToBuy">
                                    <span className='open-sans0' style={{ fontSize: '0.6rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "howToBuy" ? 'white' : '#152F4E' }}>How to buy?</span>
                                </Link>

                                <Link
                                    className='px-2'
                                    style={{ background: this.props.menuItemActive === 'support' && '#FF7E33', borderRadius: '12px' }}
                                    onClick={this.props.callSupport} eventKey="support"
                                    to="support">
                                    <span className='open-sans0' style={{ fontSize: '0.6rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "support" ? 'white' : '#152F4E' }}>Support</span>
                                </Link>

                                <Link
                                    className='px-2'
                                    style={{ background: this.props.menuItemActive === 'news' && '#FF7E33', borderRadius: '12px' }}
                                    onClick={this.props.callNews} eventKey="news"
                                    to="news">
                                    <span className='open-sans0' style={{ fontSize: '0.6rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "news" ? 'white' : '#152F4E' }}>News</span>
                                </Link>

                                <Link

                                    className='px-2'
                                    style={{ background: this.props.menuItemActive === 'contacts' && '#FF7E33', borderRadius: '12px' }}
                                    onClick={this.contactsHandler} eventKey="contacts"
                                    to='contacts' >
                                    <span className='open-sans0' style={{ fontSize: '0.6rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "contacts" ? 'white' : '#152F4E' }}>Contacts</span>
                                </Link>



                            </Nav>
                        </Navbar.Collapse>
                        <button className="d-block mx-1 my-2 apply-btn btn btn-success br-30 border-0"
                            onClick={() => this.setState({ basicModal: !this.state.basicModal })}
                            style={{ height: '3rem', width: '13rem', backgroundColor: '#FF7E33' }} >
                            <span style={{ fontSize: '20px' }} className='ttt'>GET IN TOUCH</span>
                        </button>

                    </Navbar >

                </div>



                {/*  --- xl ---  */}
                <div style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#F8F9FA' }} className="container-fluid d-none d-xl-block">
                    <Navbar style={{ background: this.props.menuColor === "blue" ? '#152F4E' : '#F8F9FA' }} onSelect={this.menu} collapseOnSelect expand="md" >

                        <Navbar.Brand>
                            <Link to='/'>
                                <img onClick={this.brand} className="mx-4 my-2 d-inline-block align-top" src={this.props.menuColor === "blue" ? logoWhite : logo} width="168" height="42" alt="logo" />
                            </Link>
                        </Navbar.Brand>

                        <Navbar.Toggle
                            style={{ color: '#003052', boxShadow: 'none' }}
                            className="mx-3 my-2 border-0" aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse style={{ justifyContent: 'center', height: '100%' }} id="basic-navbar-nav">
                            <Nav className="">

                                <Link
                                    className='p-2'
                                    style={{ background: this.props.menuItemActive === 'about' && '#FF7E33', borderRadius: '20px' }}
                                    onClick={this.props.callAbout} eventKey="about"
                                    to="about">
                                    <span className='open-sans0 mx-3' style={{ fontSize: '0.9rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === 'about' ? 'white' : '#152F4E' }}>About us</span>
                                </Link>

                                {this.props.productsStatus === 1 &&
                                    <Link
                                        className='p-2'
                                        style={{ background: this.props.menuItemActive === 'products' && '#FF7E33', borderRadius: '20px' }}
                                        onClick={this.props.callProducts} eventKey="products"
                                        to='products'>
                                        <span className='open-sans0 mx-3' style={{ fontSize: '0.9rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "products" ? 'white' : '#152F4E' }}>Products</span>
                                    </Link>
                                }

                                <Link
                                    className='p-2'
                                    style={{ background: this.props.menuItemActive === 'howToBuy' && '#FF7E33', borderRadius: '20px' }}
                                    onClick={this.props.callHowToBuy} eventKey="howToBuy"
                                    to="howToBuy">
                                    <span className='open-sans0 mx-3' style={{ fontSize: '0.9rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "howToBuy" ? 'white' : '#152F4E' }}>How to buy?</span>
                                </Link>

                                <Link
                                    className='p-2'
                                    style={{ background: this.props.menuItemActive === 'support' && '#FF7E33', borderRadius: '20px' }}
                                    onClick={this.props.callSupport} eventKey="support"
                                    to="support">
                                    <span className='open-sans0 mx-3' style={{ fontSize: '0.9rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "support" ? 'white' : '#152F4E' }}>Support</span>
                                </Link>

                                <Link
                                    className='p-2'
                                    style={{ background: this.props.menuItemActive === 'news' && '#FF7E33', borderRadius: '20px' }}
                                    onClick={this.props.callNews} eventKey="news"
                                    to="news">
                                    <span className='open-sans0 mx-3' style={{ fontSize: '0.9rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "news" ? 'white' : '#152F4E' }}>News</span>
                                </Link>

                                <Link

                                    className='p-2'
                                    style={{ background: this.props.menuItemActive === 'contacts' && '#FF7E33', borderRadius: '20px' }}
                                    onClick={this.contactsHandler} eventKey="contacts"
                                    to='contacts' >
                                    <span className='open-sans0 mx-3' style={{ fontSize: '0.9rem', color: this.props.menuColor === "blue" || this.props.menuItemActive === "contacts" ? 'white' : '#152F4E' }}>Contacts</span>
                                </Link>

                                <button className="d-block d-xl-none apply-btn btn btn-success"
                                    onClick={() => this.setState({ basicModal: !this.state.basicModal })}
                                    style={{ border: '0px', backgroundColor: '#FF7E33', borderRadius: '30px 30px 30px 30px' }} >
                                    <span className='ttt'>GET IN TOUCH</span></button>

                            </Nav>
                        </Navbar.Collapse>
                        <button className="d-none d-xl-block mx-4 my-2 apply-btn btn btn-success"
                            onClick={() => this.setState({ basicModal: !this.state.basicModal })}
                            style={{ height: '54px', width: '243px', border: '0px', backgroundColor: '#FF7E33', borderRadius: '30px 30px 30px 30px' }} >
                            <span style={{ fontSize: '20px' }} className='ttt'>GET IN TOUCH</span></button>

                    </Navbar >
                </div>

            </>
        );
    }
}

export default withGetScreen(SiteMenu);
