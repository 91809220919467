import React from 'react';
//import Alert from 'react-bootstrap/Alert';
import logo from '../img/logo/proxeet_logo_white.svg';

//import { SendForm } from '../SubscribeToDB';
import { SocialButton } from './SocialButton';
import Subscribe from './subscribe';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withGetScreen } from 'react-getscreen';
import { Link } from 'react-router-dom';
import crab from '../img/chop-orange.svg';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import Offer from './offer';




class Footer extends React.Component {

    constructor(props) {
        super(props);

        ;

    }

    state = {

        // email: '',
        productsEnable: '',
        newsEnable: '',
        isSent: false,
        showToolTip: false,
        proxeetAddress: 'Proxeet Ltd, Neofytou Nikolaidi, 61, Paphos, Cyprus',
        copied: false,
        mal: '\u0456&#x202F;n&#x202F;f&#x202F;o&#x202F;&#64&#x202F;p&#x202F;r&#x202F;o&#x202F;x&#x202F;e&#x202F;e&#x202F;t&#46;<![if !IE]>&#x202F;c&#x202F;<![endif]>o&#x202F;m',
        e: 'info',
        ma: 'proxeet.com',

    }





    handler_email = ({ target: { value } }) => { this.setState({ email: value }) }

    handlerStatus = () => {


        this.setState({ isSent: true });
    }


    arrCp = () => {


        this.setState({ copied: true });


    }


    openModal = () => {


        this.setState({ basicModal: !this.state.basicModal });


    }




    render() {

        // const { email } = this.state;

        if (this.props.isMobile()) return (

            <>


                <ToastContainer className="p-3 position-fixed" position={'bottom-end'} style={{ zIndex: 1 }} >

                    <Toast show={this.state.copied} onClose={() => { this.setState({ copied: false }); }} delay={5000} autohide >
                        <Toast.Header>
                            <img src={crab} style={{ height: '20px', width: '20px' }} className="rounded me-2" alt="" />
                            <strong className="me-auto">Proxeet</strong>
                            <small></small>
                        </Toast.Header>
                        <Toast.Body>Proxeet address is copied to clipboard</Toast.Body>
                    </Toast>

                </ToastContainer>

                <Modal
                    className='position-absolute'
                    style={{ overflowY: 'scroll' }}
                    show={this.state.basicModal}
                    size="sm"
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />
                    </Modal.Header>
                    <Modal.Body>
                        <Offer />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>


                <div className="container-fluid" style={{ backgroundColor: '#003152' }}>

                    <div className="row mt-1">
                    </div>

                    <div className="row mt-3">

                        <div className="col-6  text-start">
                            <Link to='/'><img alt='' className="px-1" style={{ width: '160px', height: '40px' }} src={logo}></img></Link>
                        </div>

                        <div className="col-6  text-start">
                            <CopyToClipboard text={this.state.proxeetAddress}
                                onCopy={() => this.setState({ copied: true })}>

                                <p className='text-start px-1' style={{ fontSize: '14px', color: '#FFFFFF' }}>Proxeet Ltd, Neofytou Nikolaidi, 61, Paphos, Cyprus</p>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <div className='row mt-2'>
                        <div className="col-6  text-start">
                            <p className='text-start px-1' style={{ fontSize: '14px', color: '#FFFFFF' }} >©2023 Copyright</p>
                        </div>
                        <div className="col-6 my-0 text-start">
                            <a className='my-0' href={"tel:" + this.props.proxeetPhone}> <p className='my-0' style={{ fontSize: '14px', color: '#FFFFFF' }}>Contact: {this.props.proxeetPhone}</p></a>
                            <a className='my-0' href={'mailto:' + this.state.e + '@' + this.state.ma}><span className='my-0' style={{ fontSize: '14px', color: '#FFF' }} dangerouslySetInnerHTML={{ __html: this.state.mal }} /></a>
                        </div>
                    </div>
                    <div className="col-6  text-start">

                    </div>


                    <div className='row mx-2' >
                        <div className='col-12'>

                            <div className='row  text-start my-4 '>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} onClick={this.props.callProducts} to='products'>All products</Link>
                                </div>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="" onClick={this.props.callSupport} to='support' >Support</Link>
                                </div>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="" onClick={this.props.callAbout} to='about'>About us</Link>
                                </div>
                            </div>


                            <div className='row text-start my-4'>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} onClick={this.props.callProducts} to='/products/activeInjector'  >Active injector</Link>
                                </div>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="text-start" onClick={this.props.callFaq} to='support/faq' >FAQ</Link>
                                </div>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="col-md-6 text-md-center" onClick={this.props.callBuisnes} to='business' >Business</Link>
                                </div>
                            </div>
                            <div className='row text-start my-4 '>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} onClick={this.props.callProducts} to='/products/passiveInjector'  >Passive injector</Link>
                                </div>
                                <div className="col-4">
                                    {this.props.newsStatus === 1 && <> <Link style={{ fontSize: '14px', color: '#FFFFFF' }} onClick={this.props.callNews} to='news' >News</Link></>}
                                </div>
                                <div className="col-4">
                                    <button style={{ fontFamily: 'Helvetica', paddingLeft: '0', fontSize: '14px', backgroundColor: '#003152', color: '#FFFFFF', border: '0' }} className="col-12 text-start " onClick={this.openModal}>Terms and conditions</button>
                                </div>
                            </div>
                            <div className='row text-start my-4'>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="" onClick={this.props.callHowToBuy} to='howToBuy' >How to buy?</Link>
                                </div>
                                <div className='col-4'>
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="" onClick={this.props.callContacts} to='contacts' >Contact us</Link>
                                </div>

                            </div>


                        </div>
                    </div>















                    <div className="row text-center justify-content-center align-middle">
                        <span className=" col-12 proxeet-pc-header" style={{ marginTop: '10%', color: '#FF7E33' }}>FOLLOW US</span>
                    </div>

                    <div className="row text-center justify-content-center align-middle" >

                        <SocialButton color={'blue'} />
                    </div>

                    <div className="row justify-content-center mt-4">

                        <Subscribe isSent={this.state.isSent} full='false' statusEmail={this.handlerStatus} />

                    </div>

                    <div className="row">
                        <br />
                        <br />
                        <br />
                    </div>


                </div >




            </>

        );


        if (this.props.isTablet()) return (


            <>




                <Modal

                    className='position-absolute'
                    style={{ overflowY: 'scroll' }}
                    show={this.state.basicModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body>
                        <Offer />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

                <div className="d-col container-fluid py-4" style={{ backgroundColor: '#003152' }} >
                    <bitrixScript />

                    <div className="row">

                        <div className="col-6  text-start">
                            <Link to='/'><img alt='' className="mx-1 img-fluid" style={{ width: '160px', height: '40px' }} src={logo}></img></Link>
                        </div>

                        <div className="col-6  text-start">
                            <p className='text-start my-0 px-1' style={{ fontSize: '14px', color: '#FFFFFF' }}>Proxeet Ltd, Neofytou Nikolaidi, 61, Paphos, Cyprus</p>
                        </div>
                    </div>

                    <div className='row mt-2'>
                        <div className="col-6  text-start">
                            <p className='text-start px-1 my-3' style={{ fontSize: '14px', color: '#FFFFFF' }} >©2023 Copyright</p>
                        </div>
                        <div className="col-6 px-3 text-start">
                            <a className='my-0' href={"tel:" + this.props.proxeetPhone}> <p className='my-0' style={{ fontSize: '14px', color: '#FFFFFF' }}>Contact: {this.props.proxeetPhone}</p></a>
                            <a className='my-0' href={'mailto:' + this.state.e + '@' + this.state.ma}><span className='my-0' style={{ fontSize: '16px', color: '#FFF' }} dangerouslySetInnerHTML={{ __html: this.state.mal }} /></a>
                        </div>
                    </div>
                    <div className="col-6  text-start">

                    </div>


                    <div className='row mx-2' >
                        <div className='col-12'>

                            <div className='row  text-start my-4 '>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} onClick={this.props.callProducts} to='products'>All products</Link>
                                </div>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="" onClick={this.props.callSupport} to='support' >Support</Link>
                                </div>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="" onClick={this.props.callAbout} to='about'>About us</Link>
                                </div>
                            </div>


                            <div className='row text-start my-4'>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} onClick={this.props.callProducts} to='products/activeInjector' >Injector</Link>
                                </div>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="text-start" onClick={this.props.callFaq} to='support/faq' >FAQ</Link>
                                </div>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="col-md-6 text-md-center" onClick={this.props.callBuisnes} to='business' >Business</Link>
                                </div>
                            </div>
                            <div className='row text-start my-4 '>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} onClick={this.props.callProducts} to='products/tablet' >Tablet</Link>
                                </div>
                                <div className="col-4">
                                    {this.props.newsStatus === 1 && <> <Link style={{ fontSize: '14px', color: '#FFFFFF' }} onClick={this.props.callNews} to='news' >News</Link></>}
                                </div>
                                <div className='col-4'>
                                    <button style={{ fontFamily: 'Helvetica', fontSize: '14px', backgroundColor: '#003152', color: '#FFFFFF', border: '0' }} className="p-0 col-12 text-start" onClick={() => this.setState({ basicModal: !this.state.basicModal })}  >Terms and conditions</button>
                                </div>
                            </div>
                            <div className='row text-start my-4'>
                                <div className="col-4">
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="" onClick={this.props.callHowToBuy} to='howToBuy' >How to buy?</Link>
                                </div>
                                <div className='col-4'>
                                    <Link style={{ fontSize: '14px', color: '#FFFFFF' }} className="" onClick={this.props.callContacts} to='contacts' >Contact us</Link>
                                </div>



                            </div>

                        </div>
                    </div>















                    <div className="row text-center justify-content-center align-middle">
                        <span className=" col-12 proxeet-pc-header" style={{ marginTop: '10%', color: '#FF7E33' }}>FOLLOW US</span>
                    </div>

                    <div className="row text-center justify-content-center align-middle">

                        <SocialButton color={'blue'} />
                    </div>

                    <div className="row justify-content-center mt-4">

                        <Subscribe isSent={this.state.isSent} full='false' statusEmail={this.handlerStatus} />

                    </div>



                    <div className="row">
                        <br />
                    </div>
                </div >





            </>




        );

        return (
            <>



                <Modal style={{ overflowY: 'auto' }}
                    show={this.state.basicModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body >
                        <Offer />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

                <ToastContainer className="p-3 position-fixed" position={'bottom-end'} style={{ zIndex: 1 }} >

                    <Toast show={this.state.copied} onClose={() => { this.setState({ copied: false }); }} delay={5000} autohide >
                        <Toast.Header>
                            <img src={crab} style={{ height: '20px', width: '20px' }} className="rounded me-2" alt="" />
                            <strong className="me-auto">Proxeet</strong>
                            <small></small>
                        </Toast.Header>
                        <Toast.Body>Proxeet address is copied to clipboard</Toast.Body>
                    </Toast>

                </ToastContainer>

                <div className="container-fluid d-none d-md-block d-xl-none" style={{ backgroundColor: '#003152' }}>



                    <div className='row'>

                        <Link className='col-6' to='/'>
                            <img className='img-fluid text-center px-5 mx-3 my-4' alt='' onClick={this.props.goTop} src={logo}></img>
                        </Link>



                        <div className="col-6 text-center mt-4">
                            <div className="row" onClick={this.arrCp}  >
                                <CopyToClipboard text={this.state.proxeetAddress} onCopy={() => this.setState({ copied: true })}>
                                    <p className="m-0" style={{ fontSize: '16px', color: '#FFFFFF' }}>Office: Proxeet Ltd, Neofytou Nikolaidi, 61,<br /> Paphos, Cyprus</p>
                                </CopyToClipboard>
                                <a href={"tel:" + this.props.proxeetPhone}><p className='m-0' style={{ fontSize: '16px', color: '#FFFFFF' }}>Contact: {this.props.proxeetPhone}</p></a>
                                <a href={'mailto:' + this.state.e + '@' + this.state.ma}><span style={{ fontSize: '16px', color: '#FFF' }} dangerouslySetInnerHTML={{ __html: this.state.mal }} /></a>
                            </div>


                        </div>





                    </div>

                    <div className='row my-4'>

                        <div className='col-12  text-center'>

                            <div className="row justify-content-center">
                                <Link style={{ fontSize: '16px', color: '#FFFFFF' }} className="col-6 text-center mb-2" onClick={this.props.callAbout} to="/about">About us</Link>
                                <Link style={{ fontSize: '16px', color: '#FFFFFF' }} className="col-6 text-center mb-2" onClick={this.props.callSupport} to="/support">Support</Link>
                            </div>

                            <div className="row justify-content-center">
                                {this.props.productsStatus === 1 && <>
                                    <Link style={{ fontSize: '16px', color: '#FFFFFF' }} onClick={this.props.callProducts} className="col-6 text-center mb-2" to="/products">Products</Link></>}
                                <Link style={{ fontSize: '16px', color: '#FFFFFF' }} className="col-6 text-center mb-2" onClick={this.props.callFaq} to="/support/faq">FAQ</Link>
                            </div>

                            <div className="row justify-content-center">
                                <Link style={{ fontSize: '16px', color: '#FFFFFF' }} className="col-6 text-center mb-2" onClick={this.props.callHowToBuy} to="/howToBuy">How to buy?</Link>
                                <Link style={{ fontSize: '16px', color: '#FFFFFF' }} className="col-6 text-center mb-2" onClick={this.props.callBuisnes} to="/business">Business</Link>
                            </div>

                            <div className="row justify-content-center">
                                <Link style={{ fontSize: '16px', color: '#FFFFFF' }} className="col-6 text-center" onClick={this.props.callContacts} to="/contacts">Contact us</Link>
                                {this.props.newsStatus === 1 && <>
                                    <Link style={{ fontSize: '16px', color: '#FFFFFF' }} className="col-6 text-center mb-2" onClick={this.props.callNews} to="/news">News</Link></>}

                            </div>

                            <div className="row justify-content-center">
                                <button style={{ fontFamily: 'Helvetica', fontSize: '16px', backgroundColor: '#003152', color: '#FFFFFF', border: '0' }} className="col-6 text-center" onClick={() => this.setState({ basicModal: !this.state.basicModal })}  >Terms and conditions</button>
                                {<p style={{ fontSize: '16px', color: '#FFFFFF' }} className="col-6 text-center mb-2" onClick={() => { }} to=""></p>}
                            </div>

                        </div>

                    </div>


                    <div className='row justify-content-center'>

                        <div className='col-8'>
                            <Subscribe isSent={this.state.isSent} full='false' statusEmail={this.handlerStatus} />
                        </div>

                    </div>

                    <div className='row mt-3'>

                        <SocialButton color={'blue'} />

                    </div>

                    <div className='row'>

                        <div className="col-12 text-center">


                            <CopyToClipboard text={this.state.proxeetAddress}
                                onCopy={() => this.setState({ copied: true })}>

                                <p style={{ color: '#FFFFFF' }} onClick={this.arrCp}  >Proxeet Ltd, Neofytou Nikolaidi, 61, Paphos, Cyprus</p>

                            </CopyToClipboard>

                            <p style={{ color: '#FFFFFF' }} >©2023 Copyright</p><br></br>
                        </div>

                    </div>
                </div>




                <div className="container-fluid d-none d-xl-block">



                    <div style={{ marginTop: '5%', paddingTop: '50px', backgroundColor: '#003152' }} className="row ">





                        <div className="col-12 col-xl-3  text-start" style={{ marginTop: '0', marginLeft: '0px' }} >
                            <Link to='/'><img alt='' onClick={this.props.goTop} className="text-sm-center px-4" style={{ marginBottom: '10%' }} src={logo}></img><br /></Link>
                            <p className='text-start px-4' style={{ color: '#FFFFFF' }} >©2023 Copyright</p><br></br>
                            <CopyToClipboard text={this.state.proxeetAddress}
                                onCopy={() => this.setState({ copied: true })}>


                                <p className='text-start px-4' style={{ color: '#FFFFFF' }} onClick={this.arrCp}  >Proxeet Ltd, Neofytou Nikolaidi, 61, Paphos, Cyprus</p>
                            </CopyToClipboard>

                        </div>

                        <div className='d-none d-xl-block col-xl-3  text-center'>
                            <div className="row justify-content-center">
                                <Link style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} className="col-md-6 text-md-center mb-2" onClick={this.props.callAbout} to="/about">About us</Link>
                                <Link style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} className="col-md-6 text-md-center mb-2" onClick={this.props.callSupport} to="/support">Support</Link>
                            </div>
                            <div className="row justify-content-center">
                                {this.props.productsStatus === 1 && <><Link style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} onClick={this.props.callProducts} className="col-md-6 text-md-center  mb-2" to="/products">Products</Link></>}
                                <Link style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} className="col-md-6 text-md-center  mb-2" onClick={this.props.callFaq} to="/support/faq">FAQ</Link>
                            </div>
                            <div className="row justify-content-center">
                                <Link style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} className="col-md-6 text-md-center  mb-2" onClick={this.props.callHowToBuy} to="/howToBuy">How to buy?</Link>
                                <Link style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} className="col-md-6 text-md-center  mb-2" onClick={this.props.callBuisnes} to="/business">Business</Link>
                            </div>
                            <div className="row justify-content-center">
                                <Link style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} className="col-md-6 text-md-center" onClick={this.props.callContacts} to="/contacts">Contact us</Link>
                                {this.props.newsStatus === 1 && <> <Link style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} className="col-md-6 text-md-center  mb-2" onClick={this.props.callNews} to="/news">News</Link></>}

                            </div>

                            <div className="row justify-content-center">
                                <button style={{ fontFamily: 'Helvetica', textAlign: 'left', fontSize: '16px', backgroundColor: '#003152', color: '#FFFFFF', border: '0' }} className="col-md-6 text-md-center" onClick={() => this.setState({ basicModal: !this.state.basicModal })}  >Terms and conditions</button>
                                {<p style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }} className="col-md-6 text-md-center  mb-2" onClick={() => { }} to=""></p>}

                            </div>

                        </div>

                        <div className="col-12 col-xl-3 text-md-center">
                            <div className="row" onClick={this.arrCp}  >
                                <CopyToClipboard text={this.state.proxeetAddress}
                                    onCopy={() => this.setState({ copied: true })}>

                                    <p className="col-md-10 " style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }}     >Office:<br></br> Proxeet Ltd,<br></br> Neofytou Nikolaidi, 61,<br></br> Paphos, Cyprus</p>
                                </CopyToClipboard>
                            </div>
                            <div className="row">
                                <a href={"tel:" + this.props.proxeetPhone}><p className="col-md-10 " style={{ textAlign: 'left', fontSize: '16px', color: '#FFFFFF' }}    >Contact: {this.props.proxeetPhone}</p></a>

                                <a className='text-start' href={'mailto:' + this.state.e + '@' + this.state.ma}><span style={{ fontSize: '16px', color: '#FFF' }}>Email: </span>
                                    <span style={{ fontSize: '16px', color: '#FFF' }} dangerouslySetInnerHTML={{ __html: this.state.mal }} />
                                </a>
                            </div>
                        </div>




                        <Subscribe isSent={this.state.isSent} full='false' statusEmail={this.handlerStatus} />

                        <div className="row">
                            <SocialButton color={'blue'} />
                        </div>



                    </div>



                </div>


            </>
        );
    }
}



export default withGetScreen(Footer);