import React from 'react';
import axios from 'axios';
import { Chip } from './chip';


import { withGetScreen } from 'react-getscreen';

class WhoIsProxeet extends React.Component {

    constructor(props) {
        super(props);




        this.jsonGet();

    }




    state = {


        text: '',
        fullText: '',
        fullTextArr: [],


        fullTextShow: false,

    }


    jsonGet = async () => {


        let getWhoIsProxeet = new FormData();
        getWhoIsProxeet.append('table', 'whoIsProxeet');
        const components = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: getWhoIsProxeet });

        const data = await components.data;



        this.setState({ text: data[0].text });
        this.setState({ fullText: data[0].fullText });

    }

    fullTextShow = () => {

        this.setState({ fullTextShow: !this.state.fullTextShow });

    }

    render() {


        if (this.props.isMobile()) return (

            <>
                <div className="container-fluid" style={{ position: 'relative' }}>

                    {<Chip l={'-12'} t={'60'} w={'15'} rotate={'60'} />}



                    <div className="row justify-content-center align-middle  text-center">
                        <div className='col-12 p-1 my-1' style={{ height: '10vh' }}>
                            <span className='ttt2 ' style={{ fontSize: '28px' }} >WHO IS PRO</span>
                            <span className='chip' style={{ fontSize: '28px', color: '#FF7E33' }}>X </span>
                            <span className='ttt2 ' style={{ fontSize: '28px' }} >EET</span>
                        </div>
                    </div>



                    <div className="row justify-content-center align-middle  text-center" style={{ paddingTop: '5%' }}>
                        {this.state.fullTextShow === false ?
                            <div className='col-11 my-2'>
                                <p className='text-start' dangerouslySetInnerHTML={{ __html: this.state.text }} />

                                <button

                                    className="btn btn-proxeet-mob mt-2"
                                    onClick={this.fullTextShow}
                                >LEARN MORE

                                </button>

                            </div>

                            :
                            <>
                                <div className='col-11'>

                                    <div style={{ textIndent: '2rem' }} className='text-start mb-0' dangerouslySetInnerHTML={{ __html: this.state.fullText }} />

                                </div>

                                <div className='col-11'>
                                    <button

                                        className='btn btn-proxeet-mob my-3'
                                        onClick={this.fullTextShow}
                                    >HIDE TEXT

                                    </button>
                                </div>
                            </>

                        }
                    </div>

                    <div className="row justify-content-center align-middle  text-center mt-4">
                        {/*<img className='col-12' style={{ margin: '0', padding: '0' }} src={plug} ></img>*/}
                    </div>
                </div>
            </>
        );


        if (this.props.isTablet()) return (


            <>
                <div className="container-fluid">


                    <div className="row justify-content-center align-middle  text-center" style={{ paddingTop: '5%' }}>
                        <div className='col-12 p-3 my-3' style={{ height: '15vh' }}>

                            <span style={{ fontSize: '5vw' }} className='ttt2 '>WHO IS PRO</span>
                            <span className='chip' style={{ fontSize: '5vw', color: '#FF7E33' }}>X </span>
                            <span style={{ fontSize: '5vw' }} className='ttt2 '>EET</span>

                        </div>



                        <div className='col-12 my-3'>
                            {this.state.fullTextShow === false ?
                                <div className='col-11 my-2'>
                                    <p className='text-start' dangerouslySetInnerHTML={{ __html: this.state.text }} />

                                    <button
                                        style={{ width: '25vh', height: '7vh', backgroundColor: '#FF7E33' }}
                                        className="apply-btn btn btn-success mt-2 border-0 br-30"
                                        onClick={this.fullTextShow}
                                    >
                                        <span onClick={this.fullTextShow} className='ttt'>LEARN MORE</span>

                                    </button>

                                </div>

                                :
                                <>
                                    <div className='col-11'>

                                        <div style={{ textIndent: '2rem' }} className='text-start mb-0' dangerouslySetInnerHTML={{ __html: this.state.fullText }} />

                                    </div>

                                    <div className='col-11'>
                                        <button
                                            style={{ width: '25vh', height: '7vh', backgroundColor: '#FF7E33' }}
                                            className="apply-btn btn btn-success my-3 border-0 br-30"
                                            onClick={this.fullTextShow}
                                        >
                                            <span onClick={this.fullTextShow} className='ttt'>HIDE TEXT</span>

                                        </button>
                                    </div>
                                </>

                            }
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        {/*<img style={{ margin: '0', padding: '0' }} src={plug} ></img>*/}
                    </div>
                </div>



            </>



        );

        return (
            <>
                <div className="container-fluid mt-5">
                    <div className="row justify-content-start align-middle  text-md-center">
                        <div className={this.state.fullTextShow === false ? 'col-12 col-xl-5 p-3 my-3' : 'col-12 p-3 my-3'} style={this.state.fullTextShow === false ? { position: 'relative' } : {}} >


                            {/* <Chip l={'210'} t={'7'} w={'20'} rotate={'50'} />*/}

                            <span className='ttt2 ' style={{ fontSize: '48px' }}>WHO IS PRO</span>
                            <span className='chip' style={{ fontSize: '48px', color: '#FF7E33' }}>X </span>
                            <span className='ttt2 ' style={{ fontSize: '48px' }}>EET</span>

                        </div>


                        {this.state.fullTextShow === false &&
                            <div className='col-12 col-xl-7 my-3'>
                                <p className='text-start' dangerouslySetInnerHTML={{ __html: this.state.text }} />

                                <button
                                    style={{ width: '14rem', height: '4rem', fontSize: '1.3rem', backgroundColor: '#FF7E33' }}
                                    className="apply-btn btn btn-success border-0 br-30"
                                    onClick={this.fullTextShow}
                                >
                                    <span onClick={this.fullTextShow} className='ttt'>LEARN MORE</span>

                                </button>

                            </div>
                        }
                    </div>


                    <div className="row justify-content-center align-middle  text-md-center mb-4">



                        {this.state.fullTextShow === true && <>

                            <div className='col-8'>

                                <div style={{ textIndent: '2rem' }} className='text-start mb-0' dangerouslySetInnerHTML={{ __html: this.state.fullText }} />

                            </div>


                            <div className='col-8'>
                                <button
                                    style={{ width: '25vh', height: '7vh', backgroundColor: '#FF7E33' }}
                                    className="apply-btn btn btn-success my-3 border-0 br-30"
                                    onClick={this.fullTextShow}
                                >
                                    <span onClick={this.fullTextShow} className='ttt'>HIDE TEXT</span>

                                </button>
                            </div>
                        </>
                        }
                    </div >


                    <div className="row justify-content-center align-middle  text-md-center">
                        {/* <img style={{ margin: '0', padding: '0' }} src={plug} ></img>*/}
                    </div>
                </div >
            </>
        );
    }
}

export default withGetScreen(WhoIsProxeet);