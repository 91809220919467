import React from 'react';
//import axios from 'axios';
import l1 from '../img/mask group_l1.png';
import l2 from '../img/mask group_l2.png';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

import { withGetScreen } from 'react-getscreen';
import { Chip } from './chip';
import { Link } from 'react-router-dom';


class SupportBtn extends React.Component {

    constructor(props) {
        super(props);



    }

    state = {

        id: [],
        text: [],
        imgName: [],
        header: [],



        manualBtnText: 'Explore the documentation',
        faqBtnText: 'Answers to popular questions',

    }




    render() {


        if (this.props.isMobile()) return (

            <>


                <div className="container-fluid" style={{ position: 'relative' }}>


                    {<Chip l={'88'} t={'90'} w={'10'} rotate={'60'} />}




                    <div className="row justify-content-center mb-4" >


                        <Link to='/manuals'>
                            <Card onClick={this.props.callManualPage} className="col-11 m-3   text-center justify-content-center align-middle br-50" style={{ backgroundColor: '#95BBDB', border: '0' }}>
                                <Card.Body>
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-mob-header' style={{ margin: 'auto', color: '#FFFFFF' }} >MANUAL</span></Card.Title>
                                    <Card.Text   >
                                        <p style={{ margin: 'auto', color: '#FFFFFF' }}>{this.state.manualBtnText}</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>

                        <Link to='/support/support' onClick={this.props.callsupportSupportPage}>
                            <Card className="col-11 m-3 px-3 py-2  text-center justify-content-center align-middle br-50" style={{ backgroundColor: '#152F4E', border: '0' }}>

                                <Card.Body>
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-mob-header' style={{ margin: 'auto', color: '#FFFFFF' }} >Contact support team</span></Card.Title>

                                </Card.Body>

                            </Card>
                        </Link>

                        <Link to='/support/faq'>
                            <Card onClick={this.props.callFaq} className="col-11 m-3  text-center justify-content-center br-50" style={{ backgroundColor: '#00477A', border: '0', margin: 'auto' }}>
                                <Card.Body>
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-mob-header' style={{ margin: 'auto', color: '#FFFFFF' }} >FAQ</span></Card.Title>
                                    <Card.Text>
                                        <p style={{ margin: 'auto', color: '#FFFFFF' }}>{this.state.faqBtnText}</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>


                    </div>

                </div>




            </>
        );

        if (this.props.isTablet()) return (



            <>

                <div className="container-fluid d-block d-sm-none">




                    <div className="row justify-content-center mb-4" >

                        <Link to='/manuals'>
                            <Card onClick={this.props.callManualPage} className="col-11 m-3 btn   text-center justify-content-center align-middle" style={{ height: 'auto', backgroundColor: '#95BBDB', border: '0', borderRadius: '60px 60px 60px 60px' }}>
                                <Card.Body style={{ margin: '0 0', height: '18vw' }} >
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-mob-header' style={{ margin: 'auto', color: '#FFFFFF' }} >MANUAL</span></Card.Title>
                                    <Card.Text   >
                                        <p style={{ margin: '0 auto', color: '#FFFFFF' }}>{this.state.manualBtnText}</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>

                        <Link to='/support/support' onClick={this.props.callsupportSupportPage}>
                            <Card onClick={this.props.callsupportSupportPage} className="col-11 m-3 px-3   text-center justify-content-center align-middle" style={{ height: 'auto', backgroundColor: '#152F4E', border: '0', borderRadius: '60px 60px 60px 60px' }}>
                                <Card.Body style={{ margin: '0 0', height: '18vw' }} >
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-tab-header' style={{ lineHeight: '2rem', fontSize: '1.7rem', margin: '0 auto', color: '#FFFFFF' }} >Contact support team</span></Card.Title>

                                </Card.Body>
                            </Card>
                        </Link>


                        <Link to='/support/faq'>
                            <Card onClick={this.props.callFaq} className="col-11 m-3  text-center justify-content-center" style={{ height: 'auto', backgroundColor: '#00477A', border: '0', margin: 'auto', borderRadius: '60px 60px 60px 60px' }}>
                                <Card.Body style={{ margin: '0 0', height: '18vw' }} >
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-mob-header' style={{ margin: 'auto', color: '#FFFFFF' }} >FAQ</span></Card.Title>
                                    <Card.Text>
                                        <p style={{ margin: 'auto', color: '#FFFFFF' }}>{this.state.faqBtnText}</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>


                    </div>

                </div>


                <div className="container-fluid d-none d-sm-block">




                    <div className="row justify-content-center" >


                        <Link to='/manuals' className='col-3'>
                            <Card
                                onClick={this.props.callManualPage}
                                className="col-12 text-center m-1 px-1 py-2 btn border-0 br-30"
                                style={{ height: 'auto', backgroundColor: '#95BBDB' }}>

                                <Card.Body style={{ height: '10vw', color: '#FFFFFF' }} className='p-4 m-4'>
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-pc-header' style={{ fontSize: '3vw', color: '#FFFFFF' }} >MANUAL</span></Card.Title>
                                    <Card.Text>
                                        <p style={{ color: '#FFFFFF' }}>{this.state.manualBtnText}</p>
                                    </Card.Text>

                                </Card.Body>
                                <img alt='' style={{ height: '40%', position: 'absolute', right: '0%', top: '0', zIndex: '992' }} src={l2}></img>
                                <img alt='' style={{ height: '50%', position: 'absolute', left: '0%', bottom: '0', zIndex: '992' }} src={l1}></img>
                            </Card>
                        </Link>

                        <Link to='/support/support' className='col-3'>
                            <Card
                                onClick={this.props.callsupportSupportPage}
                                className="col-12 text-center m-1 px-1  py-2 btn br-30 border-0"
                                style={{ height: 'auto', backgroundColor: '#152F4E' }}>

                                <Card.Body className='p-4 m-4  justify-content-center align-middle' style={{ height: '10vw', margin: 'auto auto' }} >
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-pc-header' style={{ fontSize: '2.3vw', color: '#FFFFFF' }} >Contact support team</span></Card.Title>
                                </Card.Body>

                                <img alt='' style={{ height: '40%', position: 'absolute', right: '0%', top: '0', zIndex: '992' }} src={l2}></img>
                                <img alt='' style={{ height: '50%', position: 'absolute', left: '0%', bottom: '0', zIndex: '992' }} src={l1}></img>
                            </Card>
                        </Link>

                        <Link to='/support/faq' className='col-3'>
                            <Card
                                onClick={this.props.callFaq}
                                className="col-12 text-center m-1 px-1  py-2 btn border-0 br-30"
                                style={{ height: 'auto', backgroundColor: '#152F4E' }}>

                                <Card.Body className='p-4 m-4' style={{ height: '10vw' }}>
                                    <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-pc-header' style={{ fontSize: '3vw', color: '#FFFFFF' }} >FAQ</span></Card.Title>
                                    <Card.Text>
                                        <p style={{ color: '#FFFFFF' }}>{this.state.faqBtnText}</p>
                                    </Card.Text>

                                </Card.Body>
                                <img alt='' style={{ height: '40%', position: 'absolute', right: '0%', top: '0', zIndex: '992' }} src={l2}></img>
                                <img alt='' style={{ height: '50%', position: 'absolute', left: '0%', bottom: '0', zIndex: '992' }} src={l1}></img>
                            </Card>
                        </Link>


                    </div>

                </div>



            </>);


        return (
            <>

                <div className="container-fluid d-block my-5">




                    <div className="row justify-content-center mt-3" style={{ position: 'relative' }} >
                        <Chip l={'85'} t={'20'} w={'35'} rotate={'50'} />

                        <div className='col-11'>
                            <CardGroup>


                                <Card
                                    onClick={this.props.callManualPage}
                                    className="  justify-content-center align-middle text-center mx-4 btn br-30"
                                    style={{ height: 'auto', backgroundColor: '#95BBDB', border: '0' }}>
                                    <Link to='/manuals'>

                                        <div style={{ color: '#FFFFFF' }} className='position-relative d-inline-block p-4 m-4'>
                                            <Card.Title style={{ color: '#FFFFFF' }}>
                                                <span className='proxeet-pc-header' style={{ fontSize: '3vw', color: '#FFFFFF' }} >MANUAL</span>
                                            </Card.Title>
                                            <p style={{ color: '#FFFFFF' }}>{this.state.manualBtnText}</p>
                                        </div>

                                        <img alt='' style={{ height: '40%', position: 'absolute', right: '0%', top: '0', zIndex: '992' }} src={l2}></img>
                                        <img alt='' style={{ height: '50%', position: 'absolute', left: '0%', bottom: '0', zIndex: '992' }} src={l1}></img>
                                    </Link>
                                </Card>



                                <Card
                                    onClick={this.props.callManualPage}
                                    className="  justify-content-center align-middle text-center mx-4 btn br-30"
                                    style={{ height: 'auto', backgroundColor: '#95BBDB', border: '0' }}>
                                    <Link to='/support/support'>

                                        <div style={{ color: '#FFFFFF' }} className='position-relative d-inline-block p-4 m-4'>
                                            <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-pc-header' style={{ fontSize: '2.3vw', color: '#FFFFFF' }} >Contact support team</span></Card.Title>
                                        </div>

                                        <img alt='' style={{ height: '40%', position: 'absolute', right: '0%', top: '0', zIndex: '992' }} src={l2}></img>
                                        <img alt='' style={{ height: '50%', position: 'absolute', left: '0%', bottom: '0', zIndex: '992' }} src={l1}></img>

                                    </Link>
                                </Card>






                                <Card
                                    onClick={this.props.callFaq}
                                    className=" justify-content-center align-middle text-center mx-4 btn br-30"
                                    style={{ height: 'auto', backgroundColor: '#152F4E', border: '0' }}>

                                    <Link to='/support/faq'>

                                        <div style={{ color: '#FFFFFF' }} className='position-relative d-inline-block p-4 m-4'>
                                            <Card.Title style={{ color: '#FFFFFF' }}><span className='proxeet-pc-header' style={{ fontSize: '3vw', color: '#FFFFFF' }} >FAQ</span></Card.Title>
                                            <Card.Text>
                                                <p style={{ color: '#FFFFFF' }}>{this.state.faqBtnText}</p>
                                            </Card.Text>
                                        </div>

                                        <img alt='' style={{ height: '40%', position: 'absolute', right: '0%', top: '0', zIndex: '992' }} src={l2}></img>
                                        <img alt='' style={{ height: '50%', position: 'absolute', left: '0%', bottom: '0', zIndex: '992' }} src={l1}></img>

                                    </Link>

                                </Card>


                            </CardGroup>
                        </div>

                    </div>

                </div>





            </>
        );
    }
}


export default withGetScreen(SupportBtn);