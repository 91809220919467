import React from 'react';
import { Chip } from './chip';
import inj from '../img/IMG_3.png';
import devDescription1 from '../img/devDescr1.svg';
import devDescription2 from '../img/devDescr2.svg';
import devDescription3 from '../img/devDescr3.svg';


import { withGetScreen } from 'react-getscreen';

class DeviceDescription extends React.Component {

    constructor(props) {
        super(props);



    }



    state = {

        header: 'The Game-Changing Benefits of Portable Passive PoE Injector',
        text: 'Powering your network with convenience and flexibility',
        description: [
            `<span style=" font-weight: bold " > Load detector.</span> IBlock is equipped with a load detector that intelligently detects the presence or absence of power consumption, as well as identifies potential overload or short circuit conditions on the consumer side. With a convenient status display on the device indicator, you can easily monitor the power status and ensure optimal power delivery to your connected devices. Experience peace of mind knowing that our injector keeps a vigilant eye on power consumption, providing you with a reliable and efficient power solution.`
            , `<span style=" font-weight: bold " > Auto-off timer.</span> A built-in auto-off timer protects against battery discharge in the event of accidental pressing the button.`
            , `<span style=" font-weight: bold " > On-off button.</span> By simply pressing the on/off button, you can initiate the reset process, which helps in troubleshooting and resolving any potential issues with your connected devices. Whether you're dealing with network disruptions, connectivity problems, or performance issues, the on/off button provides a straightforward solution. Having this ability saves you time and effort. Instead of going through complex procedures or accessing the device's interface, you can conveniently reset the device with a simple press of a button.`
        ]

    }



    render() {



        if (this.props.isMobile()) return (

            <>
                <div className='container-fluid position-relative mt-4' >

                    <Chip l={'5'} t={'30'} w={'7'} rotate={'35'} />


                    <Chip l={'-10'} t={'45'} w={'15'} rotate={'70'} />

                    <Chip l={'80'} t={'90'} w={'15'} rotate={'80'} />

                    <div className="row justify-content-center align-middle  text-center">
                        <span className='my-4 proxeet-mob-header' dangerouslySetInnerHTML={{ __html: this.state.header }} />
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <p className='mb-4' dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>


                    <div className="row justify-content-center align-middle  text-center mt-4">
                        <div className="col-8">
                            <img className='img-fluid' src={inj} alt="" />
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12 mt-3">
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription1} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[0] }} />
                            </div>
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription2} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[1] }} />
                            </div>
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription3} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[2] }} />
                            </div>



                        </div>

                    </div >

                </div >


            </>
        );

        if (this.props.isTablet()) return (


            <>

                <div className="container-fluid d-block d-sm-none">

                    <div className="row justify-content-center align-middle  text-center">
                        <span className='my-4 proxeet-pc-header' dangerouslySetInnerHTML={{ __html: this.state.header }} />
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <p className='my-4' dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>


                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12">
                            <img className='img-fluid' src={inj} alt="" />
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12 my-3">
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription1} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[0] }} />
                            </div>
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription2} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[1] }} />
                            </div>
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription3} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[2] }} />
                            </div>



                        </div>

                    </div >

                </div >




            </>

        );


        return (
            <>
                <div className="container-fluid">

                    <div className="row justify-content-center align-middle  text-md-center">
                        <span style={{ paddingTop: '10%' }} className='col-9 proxeet-pc-header' dangerouslySetInnerHTML={{ __html: this.state.header }} />
                    </div>

                    <div className="row justify-content-center align-middle  text-md-center">
                        <p style={{ paddingTop: '5%', paddingBottom: '5%' }} className='col-9' dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>

                    <div className="row justify-content-center align-middle  text-md-center">
                        <div className='col-md-6 col-xl-4' >
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription1} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[0] }} />
                            </div>
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription2} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[1] }} />
                            </div>
                            <div className="row justify-content-center align-middle  text-md-center">
                                <img alt='' className="col-4" style={{ width: '80px', height: '70px', paddingRight: '2%' }} src={devDescription3} /><p style={{ paddingTop: '2%', fontSize: '14px' }} className="col-8 text-start" dangerouslySetInnerHTML={{ __html: this.state.description[2] }} />
                            </div>



                        </div>
                        <div className="col-md-5 col-xl-4">
                            <img src={inj} className='img-fluid' alt="" />
                        </div>
                    </div >

                </div >
            </>
        );
    }
}


export default withGetScreen(DeviceDescription);