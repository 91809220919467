import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {withGetScreen} from 'react-getscreen';
import {Share} from '../share';

class newsPage extends React.Component {

    constructor(props) {
        super(props);

        this.props.callNews();
        this.jsonGet();

    }


    state = {

        NewsList: {},
    }

    jsonGet = async () => {

        let newsData = new FormData();
        newsData.append('table', 'news');
        newsData.append('id', this.props.newsNum);

        const news = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_FULL_API,
            data: newsData
        });

        const data = await news.data;

        this.setState({NewsList: data});


    }

    render() {


        if (this.props.isMobile()) return (

            <>
                {Object.keys(this.state.NewsList).length !== 0 &&
                    <>
                        <div className="container-fluid">
                            <div className='row'>
                                <Breadcrumb className='row mx-2 p-2'>
                                    <Breadcrumb.Item className='col-4 text-center'><Link onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/'}><p style={{fontWeight: 'bold'}}>Proxeet</p></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item className='col-4 text-center'><Link onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/news'}><p style={{fontWeight: 'bold'}}>news</p></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item className='col-12' active><p
                                        dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullHeader}}/>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <div className="row justify-content-center align-middle  text-center">
                                <div className='col-10 my-4'>
                                    <div className="row justify-content-center align-middle  text-center">
                                        <span className='col-12 proxeet-pc-header'
                                              style={{lineHeight: '2rem', fontSize: '1.4rem'}}
                                              dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullHeader}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-center">
                                        <h2 className='col-12' style={{fontSize: '16px', color: '#95BBDB'}}
                                            dangerouslySetInnerHTML={{__html: this.state.NewsList[0].newsData}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <img alt='' className='rounded-5'
                                             src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.NewsList[0].imgName}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <div className='col-12'
                                             style={{paddingTop: '5%', fontSize: '16px', color: '#152F4E'}}
                                             dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullText}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-center my-3">
                                        {<Share url={this.props.url}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        );

        if (this.props.isTablet()) return (

            <>
                {Object.keys(this.state.NewsList).length !== 0 &&
                    <>
                        <div className="container-fluid">
                            <div className='row'>
                                <Breadcrumb className='row mx-2 p-2'>
                                    <Breadcrumb.Item className='col-4 text-center'><Link onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/'}><p style={{fontWeight: 'bold'}}>Proxeet</p></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item className='col-4 text-center'><Link onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/news'}><p style={{fontWeight: 'bold'}}>news</p></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item className='col-12' active><p
                                        dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullHeader}}/>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <div className="row justify-content-center align-middle  text-center">
                                <div className='col-10 my-4'>
                                    <div className="row justify-content-center align-middle  text-center">
                                        <span className='col-12 proxeet-tab-header' style={{fontSize: '24px'}}
                                              dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullHeader}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-center">
                                        <h2 className='col-12' style={{fontSize: '16px', color: '#95BBDB'}}
                                            dangerouslySetInnerHTML={{__html: this.state.NewsList[0].newsData}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <img alt='' style={{borderRadius: '5%'}}
                                             src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.NewsList[0].imgName}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <div className='col-12 '
                                             style={{paddingTop: '5%', fontSize: '16px', color: '#152F4E'}}
                                             dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullText}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-center my-3">
                                        {<Share url={this.props.url}/>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                }


            </>
        );

        return (
            <>

                {Object.keys(this.state.NewsList).length !== 0 &&
                    <>
                        <div className="container-fluid d-none d-md-block d-xl-none">
                            <div className='row'>
                                <Breadcrumb className='row mx-2 p-2'>
                                    <Breadcrumb.Item className='col-2 text-center'><Link onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/'}><p style={{fontWeight: 'bold'}}>Proxeet</p></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item className='col-2 text-center'><Link onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/news'}><p style={{fontWeight: 'bold'}}>news</p></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item className='col-8' active><p
                                        dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullHeader}}/>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <div className="row justify-content-center align-middle  text-center">
                                <div className='col-10 my-4'>
                                    <div className="row justify-content-center align-middle  text-start">
                                        <span className='col-12 proxeet-pc-header' style={{fontSize: '24px'}}
                                              dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullHeader}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <h2 className='col-12' style={{fontSize: '16px', color: '#95BBDB'}}
                                            dangerouslySetInnerHTML={{__html: this.state.NewsList[0].newsData}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <img alt='' style={{borderRadius: '5%'}}
                                             src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.NewsList[0].imgName}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <div className='col-12 '
                                             style={{paddingTop: '5%', fontSize: '16px', color: '#152F4E'}}
                                             dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullText}}/>
                                    </div>

                                    <div className="row justify-content-left align-middle  text-start mt-4">

                                        {<Share url={this.props.url}/>}
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="container-fluid d-none d-xl-block">
                            <div className='row'>
                                <Breadcrumb className='row mx-2 p-2'>
                                    <Breadcrumb.Item className='col-1 text-center'><Link onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/'}><p style={{fontWeight: 'bold'}}>Proxeet</p></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item className='col-1 text-center'><Link onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/news'}><p style={{fontWeight: 'bold'}}>news</p></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item className='col-10' active><p
                                        dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullHeader}}/>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                            <div className="row justify-content-center align-middle  text-center">
                                <div className='col-10 my-4'>
                                    <div className="row justify-content-center align-middle  text-start">
                                        <span className='col-12 proxeet-pc-header' style={{fontSize: '1.4rem'}}
                                              dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullHeader}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <h2 className='col-12' style={{fontSize: '16px', color: '#95BBDB'}}
                                            dangerouslySetInnerHTML={{__html: this.state.NewsList[0].newsData}}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <img alt='' style={{borderRadius: '5%'}}
                                             src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.NewsList[0].imgName}/>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-start">
                                        <div className='col-12 '
                                             style={{paddingTop: '5%', fontSize: '16px', color: '#152F4E'}}
                                             dangerouslySetInnerHTML={{__html: this.state.NewsList[0].fullText}}/>
                                    </div>

                                    <div className="row justify-content-left align-middle  text-start mt-4">

                                        {<Share url={this.props.url}/>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default withGetScreen(newsPage);