import React from 'react';
import Alert from 'react-bootstrap/Alert';
import qs from 'qs';
import axios from 'axios';
import { withGetScreen } from 'react-getscreen';
import { Chip } from './chip';
import { emailFirstTest } from '../emailValidator';

class Subscribe extends React.Component {

    constructor(props) {
        super(props);



    }



    state = {

        email: '',
        emailValid: true,
        emailValidatorResponse: false,
        isSent: 0


    }

    emailTest = async () => {





        let email = this.state.email;

        if (this.state.email === '') {

            this.setState({ emailValid: false });
            return false;
        }


        let data = qs.stringify({

            'email': email
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_TEST_EMAIL_URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };






        if (emailFirstTest(email)) {


            axios(config)
                .then((response) => {
                    if (JSON.stringify(response.data).includes('email valid')) {

                        this.setState({ emailValid: true });
                        this.setState({ emailValidatorResponse: true });

                        axios.post(process.env.REACT_APP_DB_QUESTION, { email: this.state.email })
                            .then((response) => {

                                console.log(response);
                                this.setState({ isSent: 1 });


                            })
                            .catch((error) => {

                                console.log(error)
                                this.setState({ isSent: 2 });


                            });



                        return true;
                    }
                    else {

                        this.setState({ emailValid: false });
                        this.setState({ emailValidatorResponse: false });
                        return false;
                    }
                })
                .catch((error) => {

                    console.log(error)
                    this.setState({ isSent: 2 });
                    return false;
                });



        }
        else {

            this.setState({ emailValid: false });
            return false;
        }




    }


    handler_email = ({ target: { value } }) => {

        this.setState({ emailValid: true });
        this.setState({ email: value });
    }

    handlerButton = async () => {

        let data = qs.stringify({

            'email': this.state.email
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_PROXEET_FORM_LOG,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };


        axios(config)
            .then((response) => {

                console.log(response);

            })
            .catch((error) => {

                console.log(error);

            });





        await this.emailTest();


    }




    render() {


        if (this.props.isMobile()) return (

            <>

                <div className="col-12 text-center justify-content-center  align-middle " style={{ marginTop: '25%', marginBottom: '20%', position: 'relative' }} >

                    <Chip l={'-18'} t={'-13'} w={'15'} rotate={'45'} />

                    <Chip l={'10'} t={'-30'} w={'9'} rotate={'5'} />

                    <Chip l={'35'} t={'-30'} w={'5'} rotate={'55'} />


                    <Chip l={'80'} t={'55'} w={'20'} rotate={'30'} />

                    <Chip l={'60'} t={'100'} w={'10'} rotate={'-15'} />

                    <div className="row  text-center justify-content-center  align-middle">
                        <span className="col-12 proxeet-pc-header"
                              style={{
                                  fontSize: '28px',
                                  textAlign: 'middle',
                                  color: this.props.full === 'false' ? '#FFFFFF' : '#00477A' }}
                        >SUBSCRIBE TO OUR
                        </span>
                    </div>
                    <div className="row  text-center justify-content-center  align-middle">
                        <span className="col-12 proxeet-pc-header"
                              style={{
                                fontSize: '28px',
                                textAlign: 'middle',
                                color: this.props.full === 'false' ? '#FFFFFF' : '#00477A'
                            }}>NEWS AND UPDATES</span>
                    </div>
                    {
                        this.state.isSent === 1 &&

                        <>
                            <div className="row  text-md-center justify-content-center  align-middle">
                                <Alert className="col-8 col-sm-8 col-md-8" key='success' variant='success'>
                                    E-mail sent successfully !
                                </Alert>
                            </div>
                        </>
                    }
                    {
                        this.state.isSent === 2 &&

                        <>
                            <div className="row  text-md-center justify-content-center  align-middle">
                                <Alert className="col-8 col-sm-8 col-md-8" key='danger' variant='danger'>
                                    E-mail sent error, please try later !
                                </Alert>
                            </div>
                        </>
                    }
                    {this.state.isSent === 0 &&

                        <>
                            <div className="row  text-center justify-content-center  align-middle">
                                <input value={this.state.email} onChange={this.handler_email} placeholder='Email' className="col-10 mt-3 mb-2" style={{ height: '60px', borderRadius: '5px', border: '0px', backgroundColor: '#F1F1F1' }} name="email" type="text" size="40" /><br></br>
                            </div>
                            <div className="row  text-center justify-content-center  align-middle mt-4">
                                <button className="btn btn-proxeet-mob" onClick={this.handlerButton} >subscribe</button>
                            </div>
                        </>
                    }
                </div >


            </>
        );

        if (this.props.isTablet()) return (


            <>





                {
                    this.props.full === 'false' ?


                        <>
                            <div className="col-12 col-xl-3 text-center justify-content-center  align-middle">
                                <div className="row  text-center justify-content-center  align-middle">
                                    <span className="col-12 mt-4 proxeet-pc-header"
                                          style={{
                                              fontSize: '15px',
                                              lineHeight: '20px',
                                              fontWeight: '200',
                                              textAlign: 'middle',
                                              color: '#FFFFFF' }}
                                    >SUBSCRIBE TO OUR
                                    </span>
                                </div>

                                <div className="row  text-center justify-content-center  align-middle mb-3">
                                    <span className="col-12 proxeet-pc-header"
                                          style={{
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                              fontWeight: '200',
                                              textAlign: 'middle',
                                              color: '#FFFFFF' }}
                                    >NEWS AND UPDATES
                                    </span>
                                </div>

                                {
                                    this.state.isSent === 1 &&

                                    <>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            <Alert className="col-8 col-sm-8 col-md-8" key='success' variant='success'>
                                                E-mail sent successfully !
                                            </Alert>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.isSent === 2 &&

                                    <>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            <Alert className="col-8 col-sm-8 col-md-8" key='danger' variant='danger'>
                                                E-mail sent error, please try later !
                                            </Alert>
                                        </div>
                                    </>
                                }
                                {this.state.isSent === 0 &&
                                    <>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            <input
                                                value={this.state.email}
                                                onChange={this.handler_email}
                                                placeholder='Email'
                                                className="col-10"
                                                style={{
                                                    borderRadius: '5px',
                                                    border: '0px',
                                                    backgroundColor: '#F1F1F1'
                                            }}
                                                name="email"
                                                type="text"
                                                size="40"
                                            />
                                        </div>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            <button
                                                className="border-0 br-30 btn btn-success mt-3"
                                                onClick={this.handlerButton}
                                                style={{
                                                    width: '18vh',
                                                    height: '5vh',
                                                    backgroundColor: '#FF7E33'
                                            }} >
                                                <span className='ttt'>
                                                    subscribe
                                                </span>
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                        :
                        <>
                            <div className="col-12 text-center justify-content-center  align-middle pt-4 pb-4">
                                <div className="row  text-center justify-content-center  align-middle">
                                    <span className="col-12 mt-4 proxeet-pc-header"
                                          style={{
                                              fontSize: '48px',
                                              lineHeight: '48px',
                                              fontWeight: '200',
                                              textAlign: 'middle',
                                              color: '#00477A'
                                    }}
                                    >SUBSCRIBE TO OUR
                                    </span>
                                </div>
                                <div className="row  text-center justify-content-center  align-middle">
                                    <span className="col-12 proxeet-pc-header"
                                          style={{
                                              fontSize: '48px',
                                              lineHeight: '48px',
                                              fontWeight: '200',
                                              textAlign: 'middle',
                                              color: '#00477A' }}
                                    >NEWS AND UPDATES
                                    </span>
                                </div>

                                {
                                    this.state.isSent === 1 &&

                                    <>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            <Alert className="col-8 col-sm-8 col-md-8" key='success' variant='success'>
                                                E-mail sent successfully !
                                            </Alert>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.isSent === 2 &&

                                    <>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            <Alert className="col-8 col-sm-8 col-md-8" key='danger' variant='danger'>
                                                E-mail sent error, please try later !
                                            </Alert>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.isSent === 0 &&
                                    <>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            {/*<input className="col-md-8 " placeholder='Email address' value={this.state.email} onChange={this.handler_email} style={{ borderRadius: '3px', border: '0px' }} name="subsrube_email" type="text" size="40"></input>*/}
                                            <input value={this.state.email} onChange={this.handler_email} placeholder='Email' className="col-8 col-sm-10 col-md-8" style={{ height: '60px', borderRadius: '5px', border: '0px', backgroundColor: '#F1F1F1' }} name="email" type="text" size="40" /><br></br>
                                        </div>
                                        <div className="row  text-center justify-content-center  align-middle">
                                            <button className="apply-btn btn btn-success mt-4 br-30 border-0" onClick={this.handlerButton} style={{ width: '25vh', height: '7vh', backgroundColor: '#FF7E33' }} ><span className='ttt'>subscribe</span></button>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                }








            </>
        );

        return (

            <>






                {
                    this.props.full === 'false' ?


                        <>
                            <div className="col-12 col-xl-3 text-center justify-content-center  align-middle" style={{ position: 'relative', padding: '0', margin: '0' }} >


                                <div className="row  text-center justify-content-center  align-middle">
                                    <span className="col-12 mt-4 proxeet-pc-header" style={{ fontSize: '24px', lineHeight: '20px', fontWeight: '200', textAlign: 'middle', color: '#FFFFFF' }}>SUBSCRIBE TO OUR</span>
                                </div>

                                <div className="row  text-center justify-content-center  align-middle">
                                    <span className="col-12 proxeet-pc-header mt-2" style={{ fontSize: '24px', lineHeight: '20px', fontWeight: '200', textAlign: 'middle', color: '#FFFFFF' }}>NEWS AND UPDATES</span>
                                </div>

                                {
                                    this.state.isSent === 1 &&

                                    <>
                                        <div className="row mt-2 text-md-center justify-content-center  align-middle">
                                            <Alert className="col-8 col-sm-8 col-md-8" key='success' variant='success'>
                                                E-mail sent successfully !
                                            </Alert>
                                        </div>
                                    </>

                                }
                                {
                                    this.state.isSent === 2 &&

                                    <>
                                        <div className="row mt-2 text-md-center justify-content-center  align-middle">
                                            <Alert className="col-8 col-sm-8 col-md-8" key='danger' variant='danger'>
                                                E-mail sent error, please try later !
                                            </Alert>
                                        </div>
                                    </>

                                }
                                {
                                    this.state.isSent === 0 &&
                                    <>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            {/*<input className="col-md-8 " placeholder='Email address' value={this.state.email} onChange={this.handler_email} style={{ borderRadius: '3px', border: '0px' }} name="subsrube_email" type="text" size="40"></input>*/}
                                            <input value={this.state.email} onChange={this.handler_email} placeholder='Email' className="col-10 my-2"
                                                style={{ borderRadius: '5px', border: `${this.state.emailValid ? '0' : '2px solid red'}`, backgroundColor: '#F1F1F1' }} name="email" type="text" size="40"
                                            />

                                            {!this.state.emailValid &&
                                                <p className='mt-3' style={{ color: 'red' }}> Email address incorrect please enter correct email...</p>
                                            }



                                        </div>
                                        <div className="row  text-md-center justify-content-center  align-middle">
                                            <button className=" btn btn-success mt-2 br-30" onClick={this.handlerButton} style={{ width: '14rem', height: '4rem', fontSize: '1.3rem', backgroundColor: '#FF7E33', border: '0' }} ><span className='ttt'>subscribe</span></button>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                        :
                        <>
                            <div className="col-12 text-center justify-content-center  align-middle pt-4 pb-4" style={{ position: 'relative', padding: '0', margin: '0' }} >

                                <Chip l={'15'} t={'0'} w={'15'} rotate={'-15'} />

                                <Chip l={'-5'} t={'40'} w={'35'} rotate={'45'} />


                                <Chip l={'70'} t={'80'} w={'10'} rotate={'-15'} />

                                <Chip l={'75'} t={'-20'} w={'15'} rotate={'0'} />
                                <Chip l={'90'} t={'40'} w={'15'} rotate={'320'} />


                                <div className="row  text-center justify-content-center  align-middle">
                                    <span className="col-12 mt-4 proxeet-pc-header" style={{ fontSize: '48px', lineHeight: '48px', fontWeight: '200', textAlign: 'middle' }}>SUBSCRIBE TO OUR</span>
                                </div>
                                <div className="row  text-center justify-content-center  align-middle">
                                    <span className="col-12 proxeet-pc-header" style={{ fontSize: '48px', lineHeight: '48px', fontWeight: '200', textAlign: 'middle' }}>NEWS AND UPDATES </span>
                                </div>

                                {
                                    this.state.isSent === 1 &&

                                    <>
                                        <div className="row mt-2 text-md-center justify-content-center  align-middle">
                                            <Alert className="col-8 col-sm-8 col-md-8" key='success' variant='success'>
                                                E-mail sent successfully !
                                            </Alert>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.isSent === 2 &&

                                    <>
                                        <div className="row mt-2 text-md-center justify-content-center  align-middle">
                                            <Alert className="col-8 col-sm-8 col-md-8" key='danger' variant='danger'>
                                                E-mail sent error, please try later !
                                            </Alert>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.isSent === 0 &&
                                    <>
                                        <div className="row mt-4 text-md-center justify-content-center  align-middle">

                                            <input value={this.state.email} onChange={this.handler_email} placeholder='Email' className="col-10 col-sm-10 col-md-8 my-3"
                                                style={{ height: '60px', borderRadius: '5px', border: `${this.state.emailValid ? '0' : '2px solid red'}`, backgroundColor: '#F1F1F1' }}
                                                name="email" type="text" size="40"
                                            />


                                            {!this.state.emailValid &&
                                                <p className='mt-3' style={{ color: 'red' }}> Email address incorrect please enter correct email...</p>
                                            }
                                        </div>
                                        <div className="row mt-4 text-center justify-content-center  align-middle">
                                            <button className="apply-btn btn btn-success mt-4 br-30 border-0" onClick={this.handlerButton} style={{ width: '14rem', height: '4rem', fontSize: '1.3rem', backgroundColor: '#FF7E33' }} ><span className='ttt'>subscribe</span></button>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                }





            </>
        );
    }
}



export default withGetScreen(Subscribe);