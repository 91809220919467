import React from 'react';
import axios from 'axios';
import Slider from "react-slick";
import Card from 'react-bootstrap/Card';
import {BlueNextArrow, BluePrevArrow} from './arrow';
import {withGetScreen} from 'react-getscreen';

class Distributors extends React.Component {

    constructor(props) {
        super(props);


        this.jsonGet();

    }

    state = {

        distributors: {},
        header: 'our distributors',

    }


    jsonGet = async () => {


        let partnersData = new FormData();
        partnersData.append('table', 'distributors');

        const partners = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_FULL_API,
            data: partnersData
        });

        const partnersResp = await partners.data;


        this.setState({distributors: partnersResp});


    }


    render() {


        const settingsMob = {


            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <BlueNextArrow/>,
            prevArrow: <BluePrevArrow/>,
        };


        if (this.props.isMobile()) return (

            <>

                <div className="container-fluid">
                    <div className="row justify-content-center align-middle  text-center my-2">
                        <span className='proxeet-mob-header' style={{position: 'relative'}}>{this.state.header}</span>
                    </div>

                    <div className="row justify-content-center align-middle align-items-center text-center">
                        <div className="col-10  text-center">
                            {Object.keys(this.state.distributors).length !== 0 &&


                                <>

                                    <Slider className='row'  {...settingsMob}>
                                        {
                                            Object.keys(this.state.distributors).map((someId) => (
                                                <div key={someId} className="col-3">
                                                    <Card className="m-1"
                                                          style={{border: 'none'}}
                                                          onClick={
                                                              () => {
                                                                  window.location.href = this.state.distributors[someId].url
                                                              }
                                                          }
                                                    >
                                                        <Card.Title
                                                            className="justify-content-center align-middle align-items-center text-center br-0"
                                                            style={{backgroundColor: '#FFFFFF'}}>
                                                            <img

                                                                className="d-inline-block "
                                                                src={process.env.REACT_APP_DISTRIBUTORS_IMG_ON_SERVER + this.state.distributors[someId].imgName}
                                                                width={'130px'}
                                                                height={'50px'}
                                                                alt="logo"
                                                            />

                                                        </Card.Title>
                                                        <Card.Body
                                                            className="justify-content-center align-middle align-items-center text-center">
                                                            <Card.Title className="text-center">
                                                                <span
                                                                    style={{fontSize: '12px', color: '#152F4E'}}
                                                                    className="text-center font-docker">
                                                                    {this.state.distributors[someId].name}

                                                                </span>
                                                                <p style={{fontSize: '12px', color: '#152F4E'}}
                                                                   className="text-center font-docker">{this.state.distributors[someId].country}</p>

                                                            </Card.Title>
                                                        </Card.Body>
                                                    </Card>


                                                </div>
                                            ))
                                        }
                                    </Slider>


                                    <span style={{fontSize: '1.2rem', color: '#00477a'}}
                                          className='text-center fst-italic my-4 mb-4'>
                                Become our distributor in your country or city
                                        <br/><br/>
                                </span>

                                </>
                            }
                        </div>
                    </div>

                </div>
            </>


        );


        if (this.props.isTablet()) return (


            <>
            <div className="container-fluid">
                    <div className="row justify-content-center text-center">
                        <span className="col-10 text-center proxeet-pc-header my-3"
                              style={{position: 'relative'}}>{this.state.header}</span>
                    </div>
                    {Object.keys(this.state.distributors).length !== 0 &&


                        <>
                            <div className="row justify-content-center text-center">

                                {

                                    Object.keys(this.state.distributors).map((someId) => (
                                        <div
                                            key={someId.toString()}
                                            className="col-4 col-sm-3 mx-4 text-center"
                                            style={{cursor: 'pointer'}}
                                            onClick={
                                                () => {
                                                    window.location.href = this.state.distributors[someId].url
                                                }
                                            }
                                        >
                                            <img alt='' style={{width: '150px', height: '50px'}}
                                                 src={process.env.REACT_APP_DISTRIBUTORS_IMG_ON_SERVER + this.state.distributors[someId].imgName}/>

                                            <p className='my-3 font-docker'>{this.state.distributors[someId].name}</p>
                                            <p className='font-docker text-uppercase'>{this.state.distributors[someId].country}</p>
                                        </div>
                                    ))

                                }
                                <span style={{fontSize: '1.8rem', color: '#00477a'}}
                                      className='text-center fst-italic my-4 mb-4'>
                                Become our distributor in your country or city
                                </span>

                            </div>
                        </>
                    }
                </div>
            </>


        );

        return (
            <>
                <div className="container-fluid my-3">
                    <div className="row justify-content-center text-md-center">
                        <span
                            className="col-10 col-sm-10 col-md-6 text-md-center proxeet-pc-header my-3"
                            style={{position: 'relative'}}>{this.state.header}</span>
                    </div>
                    {Object.keys(this.state.distributors).length !== 0 &&


                        <>
                            <div className="row justify-content-center text-md-center" style={{border: '0px solid red'}}>

                                {

                                    Object.keys(this.state.distributors).map((someId) => (
                                        <div
                                            key={someId.toString()}
                                            className="col-10 col-sm-10 col-md-3 text-md-center"
                                            style={{cursor: 'pointer'}}
                                            onClick={
                                                () => {
                                                    window.location.href = this.state.distributors[someId].url
                                                }
                                            }
                                        >
                                            <img
                                                className='my-3'
                                                alt={this.state.distributors[someId].name}
                                                style={{width: '150px', height: '50px'}}
                                                src={process.env.REACT_APP_DISTRIBUTORS_IMG_ON_SERVER + this.state.distributors[someId].imgName}/>

                                            <p className='font-docker text-uppercase'>{this.state.distributors[someId].name}</p>
                                            <p className='font-docker text-uppercase'>{this.state.distributors[someId].country}</p>
                                        </div>
                                    ))

                                }
                                <span style={{fontSize: '1.8rem', color: '#00477a'}} className='text-center fst-italic my-4 mb-4'>
                                Become our distributor in your country or city
                                </span>


                            </div>
                        </>
                    }
                </div>
            </>
        );
    }
}


export default withGetScreen(Distributors);
