import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import sldN from '../img/staticSliderSmall.png';
import sldSmallNew from '../img/sldSmallNew.png';
import Form from 'react-bootstrap/Form';
import { withGetScreen } from 'react-getscreen';

class StaticSlider extends React.Component {


    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    state = {


    }


    handleSubmit(event) {
        
        event.preventDefault();
    }
    
 


    searchHandler = (e) => {

        this.props.manualSearchStringSet(e.target.value);


    }


    render() {

        const carouselStyle = {

            marginLeft: '0',
            paddingLeft: '10%',

            top: '17%',
            left: '5%'

        };



        if (this.props.isMobile()) return (

            <>
                <Carousel controls={false} indicators={false} interval={null}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={sldSmallNew}
                            alt=''
                            style={{ borderRadius: '0 0 50px 50px' }}
                        />
                        <Carousel.Caption className="row text-center" style={carouselStyle}>
                            <span className='col-12 proxeet-pc-header' style={{ fontSize: '20px', color: '#FFFFFF' }}>{this.props.header}</span>
                            <p className='col-12' style={{ fontSize: '12px', color: '#FFFFFF' }} dangerouslySetInnerHTML={{ __html: this.props.text }} />
                            {
                                this.props.btnName !== '' &&
                                <button className="apply-btn btn btn-success ttt border-0 br-30" style={{ width: '70vw', height: '9vh', backgroundColor: '#FF7E33', textSize: '22px'}} onClick={this.ProductCardActive} ><span style={{ fontSize: '22px' }} className='ttt'>{this.props.btnName}</span></button>

                            }

                            {
                                this.props.search === true &&
                                <>
                                    <div className="row justify-content-center align-middle pt-3 text-md-center">
                                        <div className='col-12 '>
                                            <Form   onSubmit={this.handleSubmit}  className="d-flex">


                                                <Form.Control

                                                    style={{ borderRadius: '10px 0px 0px 10px' }}
                                                    
                                                    placeholder="Type your questions here..."

                                                    
                                                    onChange={this.searchHandler}
                                                />
                                                <span className="input-group-text" style={{ marginLeft: '-5px', backgroundColor: '#FF7E33', border: '1px solid #FF7E33', borderRadius: '0 10px 10px 0' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"
                                                        className="bi bi-search" viewBox="0 0 16 16">
                                                        <path
                                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                    </svg>
                                                </span>

                                            </Form>
                                        </div>

                                    </div>

                                </>

                            }
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>

            </>
        );

        if (this.props.isTablet()) return (


            <>
                <Carousel controls={false} indicators={false} interval={null}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={sldSmallNew}
                            alt=''
                            style={{ height: '55vw', borderRadius: '0 0 50px 50px' }}
                        />
                        <Carousel.Caption className="row justify-content-center align-middle  text-center" style={carouselStyle}>
                            <span className='col-12 proxeet-pc-header' style={{ fontSize: '20px', color: '#FFFFFF' }}>{this.props.header}</span>
                            <p className='col-12' style={{ fontSize: '12px', color: '#FFFFFF' }} dangerouslySetInnerHTML={{ __html: this.props.text }} />
                            {
                                this.props.btnName !== '' &&
                                <button className="apply-btn btn btn-success ttt border-0 br-30" style={{ margin: 'auto 0', width: '36vw', height: '6vh', backgroundColor: '#FF7E33', textSize: '22px'}} onClick={this.ProductCardActive} ><span style={{ fontSize: '3vw' }} className='ttt'>{this.props.btnName}</span></button>

                            }

                            {
                                this.props.search === true &&
                                <>
                                    <div className="row justify-content-center align-middle pt-3 text-md-center">
                                        <div className='col-12 '>
                                            <Form   onSubmit={this.handleSubmit}  className="d-flex">


                                                <Form.Control

                                                    style={{ borderRadius: '10px 0px 0px 10px' }}
                                                    type="search"
                                                    placeholder="Type your questions here..."

                                                    aria-label="Search"
                                                    onChange={this.searchHandler}
                                                />
                                                <span className="input-group-text" style={{ marginLeft: '-5px', backgroundColor: '#FF7E33', border: '1px solid #FF7E33', borderRadius: '0 10px 10px 0' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"
                                                        className="bi bi-search" viewBox="0 0 16 16">
                                                        <path
                                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                    </svg>
                                                </span>

                                            </Form>
                                        </div>

                                    </div>

                                </>

                            }
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>




            </>

        );

        return (
            <>
            <Carousel controls={false} indicators={false} interval={null}>
                    <Carousel.Item>
                        <img
                            className="d-none d-md-block d-xl-none  w-100"
                            src={sldN} alt=''
                            style={{ borderRadius: '0 0 100px 100px' }}
                        />
                        <Carousel.Caption className="text-crnter" style={carouselStyle}>
                            <span className='py-1 proxeet-pc-header' style={{ color: '#FFFFFF' }}>{this.props.header}</span>
                            <p className='py-1' style={{ color: '#FFFFFF' }} dangerouslySetInnerHTML={{ __html: this.props.text }} />
                            {
                                this.props.btnName !== '' &&
                                <button
                                    style={{
                                            backgroundColor: '#FF7E33',
                                            textSize: '22px'
                                        }}
                                    className="br-30 apply-btn btn btn-success col-md-4 py-2 ttt"
                                    onClick={this.ProductCardActive}>
                                    <span style={{ fontSize: '22px' }}
                                          className='ttt'>
                                        {this.props.btnName}
                                    </span>
                                </button>

                            }

                            {
                                this.props.search === true &&
                                <>
                                    <div className="row justify-content-center align-middle pt-1 text-center">
                                        <div className='col-6 '>
                                            <Form   onSubmit={this.handleSubmit}  className="d-flex">


                                                <Form.Control

                                                    style={{ borderRadius: '10px 0px 0px 10px' }}
                                                    type="search"
                                                    placeholder="Type your questions here..."

                                                    aria-label="Search"
                                                    onChange={this.searchHandler}
                                                />
                                                <span className="input-group-text" style={{ marginLeft: '-5px', backgroundColor: '#FF7E33', border: '1px solid #FF7E33', borderRadius: '0 10px 10px 0' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"
                                                        className="bi bi-search" viewBox="0 0 16 16">
                                                        <path
                                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                    </svg>
                                                </span>

                                            </Form>
                                        </div>

                                    </div>

                                </>

                            }
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>



                <Carousel controls={false} indicators={false} interval={null}>
                    <Carousel.Item>
                        <img
                            className="d-none d-xl-block w-100"
                            src={sldN} alt=''
                            style={{ borderRadius: '0 0 100px 100px' }}
                        />
                        <Carousel.Caption className="text-crnter" style={carouselStyle}>
                            <span className='py-3 proxeet-pc-header' style={{ color: '#FFFFFF' }}>{this.props.header}</span>
                            <p className='py-2' style={{ color: '#FFFFFF' }} dangerouslySetInnerHTML={{ __html: this.props.text }} />
                            {
                                this.props.btnName !== '' &&
                                <button style={{ backgroundColor: '#FF7E33', textSize: '22px' }} className="apply-btn btn btn-success col-md-4 py-2 ttt border-0 br-30" onClick={this.ProductCardActive} ><span style={{ fontSize: '22px' }} className='ttt'>{this.props.btnName}</span></button>

                            }

                            {
                                this.props.search === true &&
                                <>
                                    <div className="row justify-content-center align-middle pt-3 text-md-center">
                                        <div className='col-6 '>
                                            <Form   onSubmit={this.handleSubmit}  className="d-flex">


                                                <Form.Control

                                                    style={{ borderRadius: '10px 0px 0px 10px' }}
                                                    type="search"
                                                    placeholder="Type your questions here..."

                                                    aria-label="Search"
                                                    onChange={this.searchHandler}
                                                />
                                                <span className="input-group-text" style={{ marginLeft: '-5px', backgroundColor: '#FF7E33', border: '1px solid #FF7E33', borderRadius: '0 10px 10px 0' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"
                                                        className="bi bi-search" viewBox="0 0 16 16">
                                                        <path
                                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                    </svg>
                                                </span>

                                            </Form>
                                        </div>

                                    </div>

                                </>

                            }
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>

            </>
        );
    }
}


export default withGetScreen(StaticSlider);
