let country = {

    ab: "+7840",
    au: "+61",
    at: "+43",
    az: "+994",
    al: "+355",
    dz: "+213",
    ao: "+244",
    ad: "+376",
    ag: "+1268",
    ar: "+54",
    am: "+374",
    af: "+93",
    bs: "+1242",
    bd: "+880",
    bb: "+1246",
    bh: "+973",
    bz: "+501",
    by: "+375",
    be: "+32",
    bj: "+229",
    bg: "+359",
    bo: "+591",
    ba: "+387",
    bw: "+267",
    br: "+55",
    bn: "+673",
    bf: "+226",
    bi: "+257",
    bt: "+975",
    vu: "+678",
    va: "+379",
    gb: "+44",
    hu: "+36",
    ve: "+58",
    tl: "+670",
    vn: "+84",
    ga: "+241",
    ht: "+509",
    gy: "+592",
    gm: "+220",
    gh: "+233",
    gt: "+502",
    gn: "+224",
    gw: "+245",
    de: "+49",
    hn: "+504",
    ps: "+970",
    gd: "+1473",
    gr: "+30",
    ge: "+995",
    dk: "+45",
    dj: "+253",
    dm: "+1767",
    do: "+1809",
    cd: "+243",
    eg: "+20",
    zm: "+260",
    zw: "+263",
    il: "+972",
    in: "+91",
    id: "+62",
    jo: "+962",
    iq: "+964",
    ir: "+98",
    ie: "+353",
    is: "+354",
    es: "+34",
    it: "+39",
    ye: "+967",
    cv: "+238",
    kz: "+7",
    kh: "+855",
    cm: "+237",
    ca: "+1",
    qa: "+974",
    ke: "+254",
    cy: "+357",
    kg: "+996",
    ki: "+686",
    cn: "+86",
    kp: "+850",
    co: "+57",
    km: "+269",
    cr: "+506",
    ci: "+225",
    cu: "+53",
    kw: "+965",
    la: "+856",
    lv: "+371",
    ls: "+266",
    lr: "+231",
    lb: "+961",
    ly: "+218",
    lt: "+370",
    li: "+423",
    lu: "+352",
    mu: "+230",
    mr: "+222",
    mg: "+261",
    mw: "+265",
    my: "+60",
    ml: "+223",
    mv: "+960",
    mt: "+356",
    ma: "+212",
    mh: "+692",
    mx: "+52",
    mz: "+258",
    md: "+373",
    mc: "+377",
    mn: "+976",
    mm: "+95",
    na: "+264",
    nr: "+674",
    np: "+977",
    ne: "+227",
    ng: "+234",
    nl: "+31",
    ni: "+505",
    nz: "+64",
    no: "+47",
    ae: "+971",
    om: "+968",
    pk: "+92",
    pw: "+680",
    pa: "+507",
    pg: "+675",
    py: "+595",
    pe: "+51",
    pl: "+48",
    pt: "+351",
    cg: "+242",
    kr: "+82",
    ru: "+7",
    rw: "+250",
    ro: "+40",
    sv: "+503",
    ws: "+685",
    sm: "+378",
    st: "+239",
    sa: "+966",
    mk: "+389",
    sc: "+248",
    sn: "+221",
    vc: "+1784",
    kn: "+1869",
    lc: "+1758",
    rs: "+381",
    sg: "+65",
    sy: "+963",
    sk: "+421",
    si: "+386",
    sb: "+677",
    so: "+252",
    sd: "+249",
    sr: "+597",
    us: "+1",
    sl: "+232",
    tj: "+992",
    th: "+66",
    tz: "+255",
    tg: "+228",
    to: "+676",
    tt: "+1868",
    tv: "+688",
    tn: "+216",
    tm: "+993",
    tr: "+90",
    ug: "+256",
    uz: "+998",
    ua: "+380",
    uy: "+598",
    fm: "+691",
    fj: "+679",
    ph: "+63",
    fi: "+358",
    fr: "+33",
    hr: "+385",
    cf: "+236",
    td: "+235",
    me: "+382",
    cz: "+420",
    cl: "+56",
    ch: "+41",
    se: "+46",
    lk: "+94",
    ec: "+593",
    gq: "+240",
    er: "+291",
    sz: "+268",
    ee: "+372",
    et: "+251",
    za: "+27",
    os: "+7",
    ss: "+211",
    jm: "+1876",
    jp: "+81",



};



export default country;