import React from 'react';
import Alert from 'react-bootstrap/Alert';
import {Image} from 'react-bootstrap';
import axios from 'axios';
import {withGetScreen} from 'react-getscreen';
import country from '../country';
import countryCode from '../countryCode';
import {Chip} from './chip';
import qs from 'qs';
import {emailFirstTest} from '../emailValidator';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";


class Question extends React.Component {

    constructor(props) {
        super(props);


        this.jsonGet();

    }

    state = {

        user_name: '',
        email: '',
        phone: '',
        isSent: 0,
        sendErr: 'Network error please try again later',
        userPermission: false,
        flag: 'cy',
        countryCode: '',
        phoneCode: '',
        emailValid: true,
        phoneValid: true,
        nameValid: true,
        emailValidatorResponse: false,
        selectCountry: true,

    }

    jsonGet = async () => {

        const api_call = await axios.get(process.env.REACT_APP_GET_COUNTRYCODE);
        const data = await api_call.data;


        this.setState({flag: data.countryCode.toLowerCase()});
        this.setState({phoneCode: country[data.countryCode.toLowerCase()]});
        this.setState({phone: country[data.countryCode.toLowerCase()]});


    }

    handler_name = ({target: {value}}) => {


        this.setState({user_name: value}, () => {
            this.nameCheck(this.state.user_name)
        });


    }
    handler_email = ({target: {value}}) => {

        this.setState({email: value});
        this.setState({emailValid: true});

        //this.emailTest(value);

    }
    handler_phone = ({target: {value}}) => {


        this.setState({phone: value})
        this.setState({phoneValid: true});

    }

    enableHandler = () => {

        this.setState({userPermission: !this.state.userPermission}, () => {

            console.log("разрешение: " + this.state.userPermission);

            this.logger();

        });


    }
    logger = () => {

        let userPermission = "";

        if (this.state.userPermission === true) {

            userPermission = "Разрешение на обработку персональных данных выдано";
        } else {

            userPermission = "галочка НЕ проставлена";
        }


        let data = qs.stringify({

            'email': this.state.email,
            'name': this.state.user_name,
            'phone': this.state.phone,
            'user_permission': userPermission

        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_PROXEET_FORM_LOG,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };


        axios(config)
            .then((response) => {

                console.log(response);

            })
            .catch((error) => {

                console.log(error);

            });


    }


    handlerButton = () => {

        if (this.nameCheck() === true) {

            if (this.phoneCheck() === true) {

                this.emailTest(this.state.email).then(() => {

                    let comment = '';

                    if (this.props.selectedProduct !== undefined) {


                        comment = this.props.selectedProduct;

                    }

                    let formData =
                        {
                            name: this.state.user_name,
                            email: this.state.email,
                            phone: this.state.phone,
                            comment: comment,
                        }


                    if (this.nameCheck(this.state.user_name) && this.phoneCheck(this.state.phone)) {

                        const API_URL = process.env.REACT_APP_DB_QUESTION;


                        axios.post(API_URL, formData)
                            .then(() => {

                                this.setState({isSent: 1});


                            })
                            .catch(() => {

                                this.setState({isSent: 2});

                            });


                    } else {

                        this.setState({isSent: 2});
                    }


                });
            }
        }

    }


    emailTest = async () => {

        this.logger();


        let email = this.state.email;

        if (this.state.email === '') {

            this.setState({emailValid: false});
        }

        let data = qs.stringify({

            'email': email
        });

        const config = {
            method: 'post',
            url: process.env.REACT_APP_TEST_EMAIL_URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };


        if (emailFirstTest(email)) {


            axios(config)
                .then((response) => {
                    if (JSON.stringify(response.data).includes('email valid')) {

                        this.setState({emailValid: true});
                        this.setState({emailValidatorResponse: true});
                        return true;
                    } else {

                        this.setState({emailValid: false});
                        this.setState({emailValidatorResponse: false});
                        return false;
                    }
                })
                .catch(() => {

                    this.setState({isSent: 2});
                    return false;
                });


        } else {

            this.setState({emailValid: false});
            return false;
        }


    }


    phoneCheck = () => {

        this.logger();

        const regex = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;

        if (this.state.phone.match(regex)) {

            this.setState({phoneValid: true});
            return true;

        } else {

            this.setState({phoneValid: false});
            return false;

        }


    }

    nameCheck = () => {


        if (this.state.user_name === '') {

            this.setState({nameValid: false});
            return false;

        } else {

            this.setState({nameValid: true});
            return true;

        }


    }


    flagHandler = (e) => {


        let value = e.target.getAttribute("data-index") + '-';
        this.setState({phone: value});

        if (e.target.getAttribute("data-index") === '+357') {
            this.setState({flag: 'cy'});
        } else if (e.target.getAttribute("data-index") === '+33') {
            this.setState({flag: 'fr'});
        } else if (e.target.getAttribute("data-index") === '+49') {
            this.setState({flag: 'de'});
        } else if (e.target.getAttribute("data-index") === '+976') {
            this.setState({flag: 'mn'});
        } else if (e.target.getAttribute("data-index") === '+90') {
            this.setState({flag: 'tr'});
        } else if (e.target.getAttribute("data-index") === '+44') {
            this.setState({flag: 'gb'});
        } else if (e.target.getAttribute("data-index") === '+380') {
            this.setState({flag: 'ua'});
        } else if (e.target.getAttribute("data-index") === '+7') {
            this.setState({flag: 'ru'});
        }


    }


    inputStyle = {

        height: '60px',
        borderRadius: '10px',
        border: '0px',
        backgroundColor: '#F1F1F1'
    }


    setPhoneCode = (e) => {


        let value = '+' + e.target.getAttribute("data-index") + '-';


        this.setState({phone: value});

        let flag = e.target.getAttribute("data-index-flag");

        this.setState({flag: flag});

        this.setSelectCountry();

        this.setState({phoneValid: true});

    }

    setPhoneCode2 = (value, flag) => {

        this.setState({phone: '+' + value});

        this.setState({flag: flag});

        this.setSelectCountry();

        this.setState({phoneValid: true});


    }


    setSelectCountry = () => {

        this.setState({selectCountry: !this.state.selectCountry});

    }


    render() {

        const {user_name, email, phone} = this.state;

        const UserMenu = (
            <Image
                src={process.env.REACT_APP_FLAG_DIR_IMG_ON_SERVER + this.state.flag.toLocaleLowerCase() + '.svg'}
                alt="Flag image"
                className=''
                style={{padding: '0', margin: '0', height: '40px'}}
            />
        )


        return (


            <>

                <div className="container-fluid" style={{position: 'relative'}}>


                    {
                        this.props.noChip !== true &&
                        <>
                            <Chip l={'0'} t={'12'} w={'10'} rotate={'-15'}/>
                            <Chip l={'5'} t={'35'} w={'15'} rotate={'0'}/>
                            <Chip l={'-10'} t={'60'} w={'40'} rotate={'40'}/>

                            <Chip l={'85'} t={'-10'} w={'55'} rotate={'45'}/>
                            <Chip l={'80'} t={'20'} w={'20'} rotate={'0'}/>
                            <Chip l={'82'} t={'50'} w={'15'} rotate={'15'}/>

                        </>
                    }
                    {this.state.isSent === 0 && <>

                        <div className="row justify-content-center align-middle  text-center">

                            <span className="col-12 col-lg-10 proxeet-pc-header">

                                <span style={{fontSize: '30px'}}>{this.props.mod}</span>

                            </span>

                        </div>

                        <div className="row mb-4 justify-content-center align-middle text-md-center">
                            <p className="col-12 col-lg-10 my-4">{this.props.subHeader}</p>
                        </div>

                    </>

                    }

                    {this.state.isSent !== 0 ?

                        <>
                            {this.state.isSent === 1 ?

                                <div className="row justify-content-center align-middle  text-md-center">
                                    <Alert className={this.props.modal === false ? "col-7" : "col-9"} key='success'
                                           variant='success'>
                                        {/*Question sent successfully !*/}
                                        {this.props.sendedText}
                                    </Alert>
                                </div>
                                :

                                <div className="row justify-content-center align-middle  text-md-center">
                                    <Alert className={this.props.modal === false ? "col-7" : "col-9"} key='danger'
                                           variant='danger'>
                                        {/*Question sent successfully !*/}
                                        {this.state.sendErr}
                                    </Alert>
                                </div>
                            }
                        </>

                        : <>


                            <div className="row justify-content-center align-middle  text-md-center">
                                <input
                                    value={user_name}
                                    onChange={this.handler_name}
                                    onBlur={this.nameCheck}
                                    placeholder='Name'
                                    className={this.props.modal === false ? "col-11 col-xl-7" : "col-xl-11"}
                                    style={{
                                        height: '60px',
                                        border: `${this.state.nameValid ? '0' : '2px solid red'}`,
                                        borderRadius: '10px',
                                        backgroundColor: '#F1F1F1'
                                    }}
                                    name="name"
                                    type="text"
                                    size="40"
                                />
                                {

                                    !this.state.nameValid &&
                                    <p className='mt-3' style={{color: 'red'}}> Field name is obligatory please enter
                                        you name...</p>

                                }
                            </div>

                            <div className="row justify-content-center align-middle  text-center my-4">
                                <input
                                    value={email}
                                    onChange={this.handler_email}
                                    onBlur={this.emailTest}
                                    placeholder='Email'
                                    className={this.props.modal === false ? "col-11 col-xl-7" : "col-xl-11"}
                                    style={{
                                        height: '60px',
                                        borderRadius: '10px',
                                        border: `${this.state.emailValid ? '0' : '2px solid red'}`,
                                        backgroundColor: '#F1F1F1'
                                    }} name="email" type="email" size="40"/>

                                {!this.state.emailValid &&
                                    <p className='mt-3' style={{color: 'red'}}>
                                        Email address incorrect please enter correct email...
                                    </p>
                                }

                            </div>

                            <div className="row justify-content-center align-middle text-start">

                                {
                                    this.state.selectCountry === true ?
                                        <>
                                            <div
                                                onClick={this.setSelectCountry}
                                                className={ this.props.modal === false ? "col-3 col-sm-2 col-xl-1 d-flex" :  "col-3 col-sm-2 col-xl-2 d-flex"   }
                                                style={{
                                                    height: '60px',
                                                    borderRadius: '10px 0 0 10px',
                                                    borderTop: `${this.state.phoneValid ? '0' : '2px solid red'}`,
                                                    borderLeft: `${this.state.phoneValid ? '0' : '2px solid red'}`,
                                                    borderBottom: `${this.state.phoneValid ? '0' : '2px solid red'}`,
                                                    borderRight: '0px solid red',
                                                    backgroundColor: '#F1F1F1'
                                                }}
                                            >

                                                <img
                                                    onClick={this.setSelectCountry}
                                                    className='img-fluid align-self-center'
                                                    style={{height: '40px'}}
                                                    alt={''}
                                                    src={process.env.REACT_APP_FLAG_DIR_IMG_ON_SERVER + this.state.flag.toLowerCase() + '.svg'}
                                                />

                                            </div>


                                            <input
                                                value={phone}
                                                onBlur={this.phoneCheck}
                                                onChange={this.handler_phone}
                                                placeholder='Phone number'
                                                className={this.props.modal === false ? "col-8 col-sm-9 col-xl-6 " : "col-9 col-sm-10 col-xl-9"}
                                                style={{
                                                    height: '60px',
                                                    borderRadius: '0px 10px 10px 0',
                                                    borderTop: `${this.state.phoneValid ? '0' : '2px solid red'}`,
                                                    borderRight: `${this.state.phoneValid ? '0' : '2px solid red'}`,
                                                    borderBottom: `${this.state.phoneValid ? '0' : '2px solid red'}`,
                                                    borderLeft: '0px solid blue',
                                                    backgroundColor: '#F1F1F1'
                                                }}
                                                name="phone"
                                                type="text"
                                                size="40"

                                            />

                                            {!this.state.phoneValid &&
                                                <p className='mt-3 text-center' style={{color: 'red'}}>
                                                    Phone number is incorrect please enter correct phone...
                                                </p>
                                            }
                                        </>

                                        :

                                        <Autocomplete
                                            id="country-select-demo"
                                            className={this.props.modal === false ? "col-11 col-sm-11 col-xl-7 " : "col-12 col-md-7 col-xl-11"}
                                            style={{background: '#F1F1F1', border: '0', borderRadius: '10px'}}
                                            options={countryCode}

                                            open
                                            getOptionLabel={(option) => option.country}

                                            onChange={(event) => {
                                                if (event.target.getAttribute("data-index") != null && event.target.getAttribute("data-index-flag") != null) {

                                                    this.setPhoneCode2(event.target.getAttribute("data-index"), event.target.getAttribute("data-index-flag"))

                                                }
                                            }}


                                            renderOption={(props, option) => (
                                                <Box

                                                    data-index-flag={option.iso}
                                                    data-index={option.code}
                                                    className=''
                                                    style={{
                                                        marginBottom: '6px',
                                                        borderRadius: '10px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 'bold',
                                                        color: '#152F4E',
                                                        fontFamily: 'Open Sans',
                                                        background: '#F1F1F1'
                                                    }}

                                                    component="div" {...props}>

                                                    <img
                                                        onClick={this.setPhoneCode} data-index-flag={option.iso}
                                                        data-index={option.code}
                                                        loading="lazy"
                                                        style={{width: '50px', height: '40px'}}
                                                        className='mx-2 img-fluid'
                                                        src={process.env.REACT_APP_FLAG_DIR_IMG_ON_SERVER + option.iso.toLowerCase() + '.svg'}
                                                        alt=""
                                                    />
                                                    <span onClick={this.setPhoneCode} data-index-flag={option.iso}
                                                          data-index={option.code}>
                                                        {option.country} +{option.code}</span>

                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}

                                                    sx={{border: 'none', "& fieldset": {border: 'none'},}}

                                                    variant="standard"
                                                    autoFocus
                                                    inputProps={{

                                                        style: {


                                                            height: '60px',
                                                            background: '#F1F1F1',
                                                            color: '#152F4E'


                                                        },

                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                />
                                            )}
                                        />

                                }


                            </div>


                            <div className="row justify-content-center align-middle  text-center mt-5 my-3">
                                <button
                                    onClick={this.handlerButton}
                                    disabled={!this.state.userPermission || !this.state.nameValid || !this.state.emailValid || !this.state.phoneValid}
                                    className="btn btn-proxeet">

                                    SEND

                                </button>
                            </div>


                            <div className="row justify-content-center align-middle pt-3">
                                <div className="col-11">
                                    <div className="row justify-content-center align-middle pt-2">
                                        <input style={{height: '18px', fontSize: '18px'}}
                                               className='col-1 text-end my-3' type="checkbox"
                                               onChange={this.enableHandler} checked={this.state.userPermission}/>
                                        <p className={this.props.modal === false ? "col-7 col-xl-4 text-start" : 'col-7 text-center'}
                                           style={{fontSize: '14px', color: '#B5B5B5'}}> By leaving a request, you agree
                                            to the privacy<br/> policy and the offer agreement.</p>
                                    </div>
                                </div>
                            </div>


                        </>

                    }
                </div>
            </>
        )
            ;
    }
}


export default withGetScreen(Question);