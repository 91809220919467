import React from 'react';
import {withGetScreen} from 'react-getscreen';
import {Chip} from './chip';
import proxeet from '../img/proxeet_.svg';
import support from '../img/support.svg';
import mail from '../img/mail_.svg';
import notFound from '../img/404.png';
import {Link} from 'react-router-dom';


import crab from '../img/chop-orange.svg';

class NoMatch extends React.Component {

    constructor(props) {
        super(props);


        this.animationViever();
    }

    state = {

        hiddenPage: true,
        anim: true,

    }


    animationViever = () => {

        setTimeout(() => {
            this.setState({hiddenPage: false});
        }, 3000);

    }


    render() {


        if (this.props.isMobile()) return (

            <>

                {this.state.hiddenPage === true ?

                    <div className='row  justify-content-center  text-center'>
                        <img
                            className='rot'
                            style={{marginTop: '15%', marginBottom: '15%', width: '150px', height: '150px'}}
                            src={crab}
                            alt={''}
                        />
                    </div>
                    :

                    <>
                        <div className='row text-center'>
                            <span className='ttt my-4 proxeet-pc-header'>SORRY!</span>
                            <p>Page is not available - but PROXEET is!</p>

                        </div>
                        <div className='row text-center'>

                            <div className="rounded-circle" style={{}}>
                                <img style={{}} src={notFound} className='img-fluid' alt=''></img>
                            </div>


                        </div>
                        <div className='row text-center'>
                            <span className='proxeet-pc-header'> ALL LIFE IS AN ETERNAL SEARCH</span>
                        </div>


                        <div className='row justify-content-center my-3 px-4' style={{position: 'relative'}}>

                            <Chip l={'5'} t={'-300'} w={'35'} rotate={'35'}/>


                            <Chip l={'0'} t={'-500'} w={'20'} rotate={'55'}/>

                            <Chip l={'87'} t={'-550'} w={'50'} rotate={'30'}/>

                            <div className='col-4'><Link to='/products'>
                                <div className="row text-center justify-content-center">


                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={proxeet} className='img-fluid'
                                             alt=''></img>
                                    </div>

                                    <div>
                                        <p className='my-4'>Products</p>
                                    </div>
                                </div>
                            </Link>
                            </div>

                            <div className='col-4'><Link to='/support'>
                                <div className="row text-center justify-content-center">


                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={support} className='img-fluid'
                                             alt=''></img>
                                    </div>


                                    <div>
                                        <p className='my-4'>Support </p>
                                    </div>

                                </div>
                            </Link>
                            </div>
                            <div className='col-4 '><Link to='/contacts'>

                                <div className="row text-center justify-content-center">

                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={mail} className='img-fluid'
                                             alt=''></img>
                                    </div>

                                    <div>
                                        <p className='my-4'>Contact us</p>
                                    </div>

                                </div>
                            </Link>
                            </div>
                        </div>
                        <div className="row text-center justify-content-center  align-middle">

                            <Link to='/'>
                                <button

                                    style={{width: '60vw', height: 'auto', backgroundColor: '#FF7E33'}}

                                    className="apply-btn btn btn-success my-4 col-4 border-0 br-30">
                                    <span className='ttt  '>Go to our<br/> Home page</span>

                                </button>
                            </Link>
                        </div>

                    </>
                }
            </>
        );


        if (this.props.isTablet()) return (

            <>

                {this.state.hiddenPage === true ?

                    <div className='row  justify-content-center  text-center'>
                        <img className='rot'
                             style={{marginTop: '15%', marginBottom: '15%', width: '150px', height: '150px'}}
                             src={crab}
                             alt={''}
                        />
                    </div>
                    :

                    <>
                        <div className='row text-center'>
                            <span className='ttt my-4 proxeet-pc-header'>SORRY!</span>
                            <p>Page is not available - but PROXEET is!</p>

                        </div>
                        <div className='row text-center'>

                            <div className="rounded-circle" style={{}}>
                                <img style={{}} src={notFound} className='img-fluid' alt=''></img>
                            </div>


                        </div>
                        <div className='row text-center'>
                            <span className='proxeet-pc-header'> ALL LIFE IS AN ETERNAL SEARCH</span>
                        </div>


                        <div className='row justify-content-center my-3 px-4' style={{position: 'relative'}}>

                            <Chip l={'5'} t={'-300'} w={'35'} rotate={'35'}/>


                            <Chip l={'0'} t={'-500'} w={'20'} rotate={'55'}/>

                            <Chip l={'87'} t={'-550'} w={'50'} rotate={'30'}/>

                            <div className='col-4'><Link to='/products'>
                                <div className="row text-center justify-content-center">


                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={proxeet} className='img-fluid'
                                             alt=''></img>
                                    </div>

                                    <div>
                                        <p className='my-4'>Products</p>
                                    </div>
                                </div>
                            </Link>
                            </div>

                            <div className='col-4'><Link to='/support'>
                                <div className="row text-center justify-content-center">


                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={support} className='img-fluid'
                                             alt=''></img>
                                    </div>


                                    <div>
                                        <p className='my-4'>Support </p>
                                    </div>

                                </div>
                            </Link>
                            </div>
                            <div className='col-4 '><Link to='/contacts'>

                                <div className="row text-center justify-content-center">

                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={mail} className='img-fluid'
                                             alt=''></img>
                                    </div>

                                    <div>
                                        <p className='my-4'>Contact us</p>
                                    </div>

                                </div>
                            </Link>
                            </div>
                        </div>
                        <div className="row text-center justify-content-center align-middle">

                            <Link to='/'>
                                <button

                                    style={{width: '60vw', height: 'auto', backgroundColor: '#FF7E33'}}

                                    className="apply-btn btn btn-success my-4 col-4 border-0 br-30">
                                    <span className='ttt  '>Go to our<br/> Home page</span>

                                </button>
                            </Link>
                        </div>

                    </>


                }
            </>

        );

        return (
            <>

                {this.state.hiddenPage === true ?

                    <div className='row  justify-content-center  text-center'>
                        <img className='rot'
                             style={{marginTop: '15%', marginBottom: '15%', width: '150px', height: '150px'}}
                             src={crab}
                             alt={''}
                        />
                    </div>
                    :
                    <>

                        <div className='row text-center'>
                            <span className='ttt my-4 proxeet-pc-header'>SORRY!</span>
                            <p>Page is not available - but PROXEET is!</p>

                        </div>
                        <div className='row text-center'>

                            <div className="rounded-circle" style={{}}>
                                <img style={{}} src={notFound} className='img-fluid' alt=''></img>
                            </div>


                        </div>
                        <div className='row text-center'>
                            <span className='proxeet-pc-header'> ALL LIFE IS AN ETERNAL SEARCH</span>
                        </div>


                        <div className='row justify-content-center my-3' style={{position: 'relative'}}>

                            <Chip l={'5'} t={'-300'} w={'35'} rotate={'35'}/>


                            <Chip l={'0'} t={'-500'} w={'20'} rotate={'55'}/>

                            <Chip l={'87'} t={'-550'} w={'50'} rotate={'30'}/>

                            <div className='col-2'><Link to='/products'>
                                <div className="row text-center justify-content-center">


                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={proxeet} className='img-fluid'
                                             alt=''></img>
                                    </div>

                                    <div>
                                        <p className='my-4'>Products</p>
                                    </div>
                                </div>
                            </Link>
                            </div>

                            <div className='col-2'><Link to='/support'>
                                <div className="row text-center justify-content-center">


                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={support} className='img-fluid'
                                             alt=''></img>
                                    </div>


                                    <div>
                                        <p className='my-4'>Support </p>
                                    </div>

                                </div>
                            </Link>
                            </div>
                            <div className='col-2 '><Link to='/contacts'>

                                <div className="row text-center justify-content-center">

                                    <div className="rounded-circle"
                                         style={{height: '57px', width: '57px', background: '#152F4E'}}>
                                        <img style={{height: '57px', width: '57px'}} src={mail} className='img-fluid'
                                             alt=''></img>
                                    </div>

                                    <div>
                                        <p className='my-4'>Contact us</p>
                                    </div>

                                </div>
                            </Link>
                            </div>
                        </div>
                        <div className="row text-center justify-content-center align-middle">

                            <Link to='/'>
                                <button

                                    style={{width: 'auto', height: '7vh', backgroundColor: '#FF7E33'}}

                                    className="apply-btn btn btn-success col-4 br-30 border-0">
                                    <span className='ttt '>Go to our Home page</span>

                                </button>
                            </Link>
                        </div>

                    </>
                }

            </>
        );
    }
}


export default withGetScreen(NoMatch);