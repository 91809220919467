import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { Chip } from './chip';



import { Link } from 'react-router-dom';
import { withGetScreen } from 'react-getscreen';


import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';

import Question from './question';

class TopBlock extends React.Component {


    constructor(props) {
        super(props);

        this.jsonGet();

    }

    state = {

        slider: {},
        basicModal: false,

        iconColor: '%23FF7E33',
        indicatorColor: '#FF7E33',


    }



    jsonGet = async () => {


        let sliderData = new FormData();
        sliderData.append('table', 'slider');



        const slider = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: sliderData });

        const sliderResp = await slider.data;


        this.setState({ slider: sliderResp });


    }

    ProductCardActive = () => {

        this.props.callProductCard(1);

    }




    render() {

        const carouselStyle = {

            marginLeft: '0',
            paddingLeft: '10%',

            top: '17%',
            left: '5%'

        };
        const carouselStyleMob = {

            margin: '0',
            marginLeft: '10%',
            top: '15%',
            left: '0'

        };

        const carouselStyleTablet = {

            marginLeft: '0',
            paddingLeft: '10%',

            top: '4vw',
            left: '8vw'

        };


        if (this.props.isMobile()) return (

            <>
                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} mod={'SUBMIT YOUR APPLICATION'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>

                </Modal>
                {Object.keys(this.state.slider).length !== 0 &&


                    <>
                        <div className="container-fluid" style={{ position: 'relative' }} >
                            <div className="row justify-content-center align-middle  text-md-center">
                                <div className='col-12' style={{ maxWidth: '100%', margin: '0', padding: '0', position: 'relative' }} >
                                    <Carousel controls={false} indicators={false}  >
                                        {
                                            Object.keys(this.state.slider).map((someId) => (
                                                <Carousel.Item interval={Number(this.state.slider[someId].speed) + 3000} >
                                                    <img
                                                        className="d-block w-100" alt=''
                                                        src={process.env.REACT_APP_SLIDER_IMG_ON_SERVER + this.state.slider[someId].imgMobName}
                                                    />
                                                    <Carousel.Caption
                                                        className="row text-start justify-content-center align-middle"
                                                        style={carouselStyleMob}
                                                    >
                                                        <span
                                                            className='text-nowrap'
                                                            style={{ fontFamily: 'DOCKER', lineHeight: '2rem', fontSize: '1.4rem', color: this.state.slider[someId].textColor }}
                                                            dangerouslySetInnerHTML={{ __html: this.state.slider[someId].mobileHeader }}
                                                        />
                                                        <p
                                                            style={{ color: this.state.slider[someId].textColor }}
                                                            dangerouslySetInnerHTML={{ __html: this.state.slider[someId].text }}
                                                        />

                                                        <button
                                                            className="btn btn-proxeet-mob"
                                                            onClick={() => { this.setState({ basicModal: !this.state.basicModal }) }}
                                                        >
                                                            LEARN MORE

                                                        </button>

                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            ))
                                        }

                                    </Carousel>

                                    {<Chip l={'65'} t={'80'} w={'40'} rotate={'40'} />}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        );


        if (this.props.isTablet()) return (

            <>
                <style type="text/css">
                    {`

                        .carousel-control-next-icon {
                            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${this.state.iconColor}'  viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
                        }

                        .carousel-control-prev-icon {
                            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${this.state.iconColor}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
                        }

                        .carousel-indicators button{
                            margin-bottom: 15% !important;
                            background-color: ${this.state.indicatorColor} !important;
                          }

                    `}
                </style>
                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />
                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} mod={'SUBMIT YOUR APPLICATION'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>
                </Modal>


                {Object.keys(this.state.slider).length !== 0 &&


                    <>
                        <div className="container-fluid d-block d-sm-none">
                            <Carousel onSlide={this.setIcon} controls={false} indicators={false}  >
                                {

                                    Object.keys(this.state.slider).map((someId) => (
                                        <Carousel.Item interval={Number(this.state.slider[someId].speed) + 3000} >
                                            <img
                                                className="d-block w-100" alt=''
                                                src={process.env.REACT_APP_SLIDER_IMG_ON_SERVER + this.state.slider[someId].imgMobName}
                                            />
                                            <Carousel.Caption
                                                className="row text-start justify-content-center align-middle"
                                                style={carouselStyleMob}
                                            >
                                                <span
                                                    className='text-nowrap'
                                                    style={{ fontFamily: 'DOCKER', lineHeight: '2rem', fontSize: '1.4rem', color: this.state.slider[someId].textColor }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.slider[someId].mobileHeader }}
                                                />
                                                <p
                                                    style={{ color: this.state.slider[someId].textColor }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.slider[someId].text }}
                                                />

                                                <button
                                                    className="btn btn-proxeet-mob"
                                                    onClick={() => { this.setState({ basicModal: !this.state.basicModal }) }}
                                                >
                                                    LEARN MORE

                                                </button>

                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    ))

                                }


                            </Carousel>


                        </div>


                        <div className="container-fluid d-none d-sm-block">
                            <div className="row justify-content-center align-middle  text-md-center">
                                <div className='col-12' style={{ position: 'relative', padding: '0', margin: '0' }} >
                                    <Carousel onSlide={this.setIcon} controls={false} indicators={false}  >
                                        {

                                            Object.keys(this.state.slider).map((someId) => (
                                                <Carousel.Item key={someId.toString()} interval={Number(this.state.slider[someId].speed) + 3000} >
                                                    <img
                                                        className="d-block w-100" alt=''
                                                        src={process.env.REACT_APP_SLIDER_IMG_ON_SERVER + this.state.slider[someId].imgName}
                                                    />
                                                    <Carousel.Caption className="text-start" style={carouselStyleTablet}>
                                                        <div className='row'>
                                                            <span className='col-8 proxeet-header' style={{ fontSize: '3vw', color: this.state.slider[someId].textColor }} dangerouslySetInnerHTML={{ __html: this.state.slider[someId].header }} />
                                                        </div>
                                                        <div className='row'>
                                                            <p className='col-8' style={{ fontSize: '2vw', color: this.state.slider[someId].textColor }} dangerouslySetInnerHTML={{ __html: this.state.slider[someId].text }} />
                                                        </div>

                                                        <button
                                                            className="btn col-md-4 br-30"
                                                            onClick={() => { this.setState({ basicModal: !this.state.basicModal }) }}
                                                            style={{ width: '12rem', height: '3rem', color: '#FFF', background: '#FF7E33' }}
                                                        >
                                                            <span style={{ fontSize: '1rem' }} className='ttt'>{this.state.slider[someId].btnName}</span>
                                                        </button>

                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            ))

                                        }


                                    </Carousel>
                                    <Chip l={'75'} t={'60'} w={'60'} rotate={'40'} />
                                </div>
                            </div>
                        </div>
                    </>
                }


            </>




        );

        return (
            <>
                <style type="text/css">
                    {`
                        
                        .carousel-control-next-icon {
                            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${this.state.iconColor}'  viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
                            margin-bottom: 35% !important;
                        }
                        
                        .carousel-control-prev-icon {
                            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='${this.state.iconColor}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
                            margin-bottom: 35% !important;
                        }
                       
                        .carousel-indicators button{
                            margin-bottom: 15% !important;
                            background-color: ${this.state.indicatorColor} !important;
                          }
                        
                    `}
                </style>

                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />
                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} mod={'SUBMIT YOUR APPLICATION'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>
                </Modal>

                <div className='container-fluid d-md-block d-xl-none '>

                    <div className="row justify-content-center align-middle  text-md-center">
                        <div className='col-12' style={{ position: 'relative', padding: '0', margin: '0' }} >

                            {Object.keys(this.state.slider).length !== 0 &&


                                <>

                                    <Carousel onSlide={this.setIcon} controls={true} indicators={true}  >
                                        {

                                            Object.keys(this.state.slider).map((someId) => (
                                                <Carousel.Item key={someId.toString()} interval={Number(this.state.slider[someId].speed) + 3000} >
                                                    <img
                                                        className="d-block w-100" alt=''
                                                        src={process.env.REACT_APP_SLIDER_IMG_ON_SERVER + this.state.slider[someId].imgName}
                                                    />
                                                    <Carousel.Caption className="text-start" style={carouselStyle}>
                                                        <span className='proxeet-pc-header' style={{ fontSize: '1.4rem', color: this.state.slider[someId].textColor }} dangerouslySetInnerHTML={{ __html: this.state.slider[someId].header }} />
                                                        <p className='text-wrap col-6' style={{ color: this.state.slider[someId].textColor }} dangerouslySetInnerHTML={{ __html: this.state.slider[someId].text }} />
                                                        {Number(someId) === 1 ?

                                                            <Link to={this.state.slider[someId].btnFnc}>
                                                                <button style={{ width: '300px', height: '60px', backgroundColor: '#FF7E33' }} className="apply-btn btn btn-success col-md-4 border-0 br-30" onClick={this.ProductCardActive} >
                                                                    <span style={{ fontSize: '22px' }} className='ttt'>{this.state.slider[someId].btnName}</span>
                                                                </button>
                                                            </Link>


                                                            :

                                                            <button style={{ width: '12rem', height: '3rem', backgroundColor: '#FF7E33' }} className="apply-btn btn btn-success col-md-4 border-0 br-30" onClick={() => { this.setState({ basicModal: !this.state.basicModal }) }} >
                                                                <span style={{ fontSize: '1rem' }} className='ttt'>{this.state.slider[someId].btnName}</span>
                                                            </button>


                                                        }
                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            ))

                                        }


                                    </Carousel>
                                    <Chip l={'75'} t={'60'} w={'60'} rotate={'40'} />
                                </>
                            }
                        </div>
                    </div>

                </div>

                <div className="container-fluid d-none d-xl-block">
                    <div className="row justify-content-center align-middle  text-md-center">
                        <div className='col-12' style={{ position: 'relative', padding: '0', margin: '0' }} >

                            {Object.keys(this.state.slider).length !== 0 &&


                                <>
                                    <Carousel onSlide={this.setIcon} controls={true} indicators={true}  >
                                        {

                                            Object.keys(this.state.slider).map((someId) => (
                                                <Carousel.Item key={someId.toString()} interval={Number(this.state.slider[someId].speed) + 3000} >
                                                    <img
                                                        className="d-block w-100" alt=''
                                                        src={process.env.REACT_APP_SLIDER_IMG_ON_SERVER + this.state.slider[someId].imgName}
                                                    />
                                                    <Carousel.Caption className="text-start" style={carouselStyle}>
                                                        <span className='proxeet-pc-header' style={{ color: this.state.slider[someId].textColor }} dangerouslySetInnerHTML={{ __html: this.state.slider[someId].header }} />
                                                        <p style={{ color: this.state.slider[someId].textColor }} dangerouslySetInnerHTML={{ __html: this.state.slider[someId].text }} />
                                                        { Number(someId) === 1 ?

                                                            <Link to={this.state.slider[someId].btnFnc}>
                                                                <button style={{ width: '300px', height: '60px', backgroundColor: '#FF7E33' }} className="apply-btn btn btn-success col-md-4 border-0 br-30" onClick={this.ProductCardActive} >
                                                                    <span style={{ fontSize: '22px' }} className='ttt'>{this.state.slider[someId].btnName}</span>
                                                                </button>
                                                            </Link>

                                                            :

                                                            <button style={{ width: '300px', height: '60px', backgroundColor: '#FF7E33' }} className="apply-btn btn btn-success col-md-4 border-0 br-30" onClick={() => { this.setState({ basicModal: !this.state.basicModal }) }} >
                                                                <span style={{ fontSize: '22px' }} className='ttt'>{this.state.slider[someId].btnName}</span></button>
                                                        }
                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            ))

                                        }


                                    </Carousel>
                                    <Chip l={'75'} t={'60'} w={'60'} rotate={'40'} />

                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


export default withGetScreen(TopBlock);
