import React from "react";
import appImg1 from "../img/appImg1.svg";
import appImg2 from "../img/appImg2.svg";
import appImg3 from "../img/appImg3.svg";
import appImg4 from "../img/appImg4.svg";
import appImg5 from "../img/appImg5.svg";
import wifi from "../img/19.png";
import helm from "../img/20.png";
import tool from "../img/21.png";
import arrow_1 from "../img/Vector_1.png";
import arrow_2 from "../img/Vector_2.png";
import { Chip } from "./chip";
import { withGetScreen } from "react-getscreen";


class ProductApp extends React.Component {
  constructor(props) {
    super(props);


  }

  state = {
    header: "Where to apply?",
    item1: "Internet service<br /> providers (ISPs)",
    item2: "Event management<br /> companies",
    item3: "Network maintenance<br /> companies",
    item4: "Security systems ",
    item5: "System integrators",
  };

  render() {
    if (this.props.isMobile())
      return (
        <>
          <div className="container-fluid position-relative mt-5" >
            {<Chip l={"72"} t={"-4"} w={"20"} rotate={"50"} />}

            <div className="row justify-content-center align-middle  text-center">
              <span className="proxeet-mob-header" dangerouslySetInnerHTML={{ __html: this.state.header }} />
            </div>

            <div className="row justify-content-center">
              <div className="col-10 text-start mb-4">
                <div className="row justify-content-center align-middle align-items-center">
                  <div className="col-3 text-start my-4">
                    <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg1} alt='' className="img-fluid p-2"></img>
                  </div>
                  <div className="col-9 text-start my-4">
                    <span style={{ fontSize: "3vw" }} className="col-3 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item1 }} />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-12 text-start my-4">
                    <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_2} alt='' className="img-fluid"></img>
                  </div>
                </div>

                <div className="row justify-content-center align-middle align-items-center">
                  <div className="col-3 text-start my-4">
                    <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg2} alt='' className="img-fluid p-2"></img>
                  </div>
                  <div className="col-9 text-start ">
                    <span style={{ fontSize: "3vw" }} className="col-3 ttt tb " dangerouslySetInnerHTML={{ __html: this.state.item2 }} />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-12 text-start my-4">
                    <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_1} alt='' className="img-fluid"></img>
                  </div>
                </div>

                <div className="row justify-content-center align-middle align-items-center">
                  <div className="col-3 text-start my-4">
                    <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg3} alt='' className="img-fluid p-2"></img>
                  </div>
                  <div className="col-9 text-start ">
                    <span style={{ fontSize: "3vw" }} className="col-3 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item3 }} />
                  </div>
                  {this.props.extended === "active" &&

                    <>
                      <div className="col-12 text-start my-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_2} alt='' className="img-fluid" ></img>
                      </div>
                      <div className="col-3 text-start my-4">
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg4} alt='' className="img-fluid p-2"></img>
                      </div>
                      <div className="col-9 text-start my-4">
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item4 }} />
                      </div>

                      <div className="col-12 text-start my-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_1} alt='' className="img-fluid"></img>
                      </div>

                      <div className="col-3 text-start my-4">
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg5} alt='' className="img-fluid p-2"></img>
                      </div>
                      <div className="col-9 text-start my-4">
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item5 }} />
                      </div>

                    </>

                  }


                </div>
              </div>
            </div>
            {
              this.props.extended === "full" &&

              <>
                <div className="row justify-content-center">
                  <div className="col-10 text-start my-4">
                    <div className="row justify-content-center align-middle align-items-center">
                      <div className="col-12 text-start mb-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_2} alt='' className="img-fluid" ></img>
                      </div>
                      <div className="col-3 text-start my-4">
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={wifi} alt='' className="img-fluid"></img>
                      </div>
                      <div className="col-9 text-start my-4">
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb">
                          {" "}
                          Endless Customization
                        </span>
                      </div>
                    </div>

                    <div className="row justify-content-center align-middle align-items-center">
                      <div className="col-12 text-start my-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_1} alt='' className="img-fluid"></img>
                      </div>
                    </div>

                    <div className="row justify-content-center align-middle align-items-center">
                      <div className="col-3 text-start my-4">
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={helm} alt='' className="img-fluid" ></img>
                      </div>
                      <div className="col-9 text-start ">
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb ">
                          Low MOQ and CapEx
                        </span>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 text-start my-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_2} alt='' className="img-fluid"></img>
                      </div>
                    </div>

                    <div className="row justify-content-center align-middle align-items-center">
                      <div className="col-3 text-start my-4" style={{ border: "0px solid red" }}>
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={tool} alt='' className="img-fluid"></img>
                      </div>
                      <div className="col-9 text-start my-4" style={{ border: "0px solid red" }}>
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb">
                          Empower your Support team{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </>
      );

    if (this.props.isTablet())
      return (
        <>
          <div className="container-fluid py-4" >
            {<Chip l={"72"} t={"-4"} w={"20"} rotate={"50"} />}

            <div className="row justify-content-center align-middle  text-center">
              <span className="proxeet-pc-header" style={{ marginTop: "10%", marginBottom: "5%", position: "relative", }} dangerouslySetInnerHTML={{ __html: this.state.header }} />
            </div>

            <div className="row justify-content-center">
              <div className="col-10 text-start my-4">
                <div className="row justify-content-center align-middle align-items-center">
                  <div className="col-3 text-start my-4">
                    <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg1} alt='' className="img-fluid p-2"></img>
                  </div>
                  <div className="col-9 text-start my-4">
                    <span style={{ fontSize: "3vw" }} className="col-3 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item1 }} />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-12 text-start my-4">
                    <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_2} alt='' className="img-fluid"></img>
                  </div>
                </div>

                <div className="row justify-content-center align-middle align-items-center">
                  <div className="col-3 text-start my-4">
                    <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg2} alt='' className="img-fluid p-2"></img>
                  </div>
                  <div className="col-9 text-start ">
                    <span style={{ fontSize: "3vw" }} className="col-3 ttt tb " dangerouslySetInnerHTML={{ __html: this.state.item2 }} />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-12 text-start my-4">
                    <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_1} alt='' className="img-fluid"></img>
                  </div>
                </div>

                <div className="row justify-content-center align-middle align-items-center">
                  <div className="col-3 text-start my-4">
                    <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg3} alt='' className="img-fluid p-2"></img>
                  </div>
                  <div className="col-9 text-start ">
                    <span style={{ fontSize: "3vw" }} className="col-3 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item3 }} />
                  </div>
                  {this.props.extended === "active" &&

                    <>
                      <div className="col-12 text-start my-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_2} alt='' className="img-fluid" ></img>
                      </div>
                      <div className="col-3 text-start my-4">
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg4} alt='' className="img-fluid p-2"></img>
                      </div>
                      <div className="col-9 text-start my-4">
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item4 }} />
                      </div>

                      <div className="col-12 text-start my-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_1} alt='' className="img-fluid"></img>
                      </div>

                      <div className="col-3 text-start my-4">
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={appImg5} alt='' className="img-fluid p-2"></img>
                      </div>
                      <div className="col-9 text-start my-4">
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item5 }} />
                      </div>

                    </>

                  }


                </div>
              </div>
            </div>
            {
              this.props.extended === "full" &&

              <>
                <div className="row justify-content-center">
                  <div className="col-10 text-start my-4">
                    <div className="row justify-content-center align-middle align-items-center">
                      <div className="col-12 text-start mb-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_2} alt='' className="img-fluid" ></img>
                      </div>
                      <div className="col-3 text-start my-4">
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={wifi} alt='' className="img-fluid"></img>
                      </div>
                      <div className="col-9 text-start my-4">
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb">
                          {" "}
                          Endless Customization
                        </span>
                      </div>
                    </div>

                    <div className="row justify-content-center align-middle align-items-center">
                      <div className="col-12 text-start my-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_1} alt='' className="img-fluid"></img>
                      </div>
                    </div>

                    <div className="row justify-content-center align-middle align-items-center">
                      <div className="col-3 text-start my-4">
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={helm} alt='' className="img-fluid" ></img>
                      </div>
                      <div className="col-9 text-start ">
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb ">
                          Low MOQ and CapEx
                        </span>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 text-start my-4">
                        <img style={{ width: "50px", height: "20px", backgroundColor: "#FFFFFF", transform: "rotate(90deg)" }} src={arrow_2} alt='' className="img-fluid"></img>
                      </div>
                    </div>

                    <div className="row justify-content-center align-middle align-items-center">
                      <div className="col-3 text-start my-4" style={{ border: "0px solid red" }}>
                        <img style={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#FF7E33" }} src={tool} alt='' className="img-fluid"></img>
                      </div>
                      <div className="col-9 text-start my-4" style={{ border: "0px solid red" }}>
                        <span style={{ fontSize: "3vw" }} className="col-3 ttt tb">
                          Empower your Support team{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>

        </>
      );

    return (
      <>
        <div className="container-fluid py-4 mt-4 d-none d-md-block d-xl-none" style={{ position: "relative" }}>

          {this.props.extended ? (
            <></>
          ) : (
            <Chip l={"90"} t={"0"} w={"35"} rotate={"55"} />
          )}
          <div className="row justify-content-center align-middle  text-center">
            <span className="proxeet-pc-header my-4">{this.state.header}</span>
          </div>

          <>
            <div className="row justify-content-center">
              <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                <img style={{ backgroundColor: "#FF7E33" }} src={appImg1} alt='' className="img-fluid  br-50-percent p-3"></img>
              </div>

              <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_2} alt='' className="img-fluid mt-3" ></img>
              </div>

              <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                <img style={{ backgroundColor: "#FF7E33", }} src={appImg2} alt='' className="img-fluid br-50-percent p-3" ></img>
              </div>

              <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_1} alt='' className="img-fluid  mt-3"></img>
              </div>

              <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                <img style={{ backgroundColor: "#FF7E33" }} src={appImg3} alt='' className="img-fluid br-50-percent p-3"></img>
              </div>

              {this.props.extended === "active" && (
                <>
                  <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                    <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_2} alt='' className="img-fluid mt-3"></img>
                  </div>

                  <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                    <img style={{ backgroundColor: "#FF7E33" }} src={appImg4} alt='' className="img-fluid br-50-percent p-3"></img>
                  </div>

                  <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                    <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_1} alt='' className="img-fluid mt-3"></img>
                  </div>

                  <div className="col-1 text-center" style={{ width: '5.5rem' }}>
                    <img style={{ backgroundColor: "#FF7E33" }} src={appImg5} alt='' className="img-fluid br-50-percent p-3"></img>
                  </div>
                </>
              )}
            </div>

            <div className="row justify-content-center text-center pt-3">
              <span className="col-2 mx-4 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item1 }} />

              <span className="col-2 mx-4 ttt tb " dangerouslySetInnerHTML={{ __html: this.state.item2 }} />

              <span className="col-2 mx-4 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item3 }} />
              {this.props.extended === "active" && (
                <>
                  <span className="col-2 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item4 }} />
                  <span className="col-2 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item5 }} />
                </>
              )}
            </div>
          </>

          {this.props.extended === "full" && (
            <>
              <div
                className="row justify-content-center my-3"
                style={{ position: "relative" }}
              >
                <Chip l={"9"} t={"-400"} w={"25"} rotate={"35"} />
                <Chip l={"0"} t={"0"} w={"20"} rotate={"55"} />
                <Chip l={"90"} t={"0"} w={"35"} rotate={"20"} />

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33" }} src={wifi} alt='' className="img-fluid br-50-percent"></img>
                </div>

                <div className="col-md-2 text-center">
                  <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_2} alt='' className="img-fluid"></img>
                </div>

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33" }} src={helm} alt='' className="img-fluid br-50-percent"></img>
                </div>

                <div className="col-md-2 text-center">
                  <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_1} alt='' className="img-fluid"></img>
                </div>

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33" }} src={tool} alt='' className="img-fluid br-50-percent"></img>
                </div>
              </div>

              <div className="row justify-content-center text-center my-3 pt-3">
                <span className="col-3 ttt tb">
                  Full Connectivity
                  <br /> Suite
                </span>
                <span className="col-3 ttt tb ">
                  Create Happier <br />
                  Customers
                </span>
                <span className="col-3 ttt tb">
                  {" "}
                  Top Your <br />
                  Competition
                </span>
              </div>

              <div className="row justify-content-center my-3">
                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33" }} src={wifi} alt='' className="img-fluid br-50-percent"></img>
                </div>

                <div className="col-md-2 text-center">
                  <img style={{ backgroundColor: "#FFFFFF", transform: "rotate(180deg)" }} src={arrow_2} alt='' className="img-fluid"></img>
                </div>

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33", }} src={helm} alt='' className="img-fluid br-50-percent"></img>
                </div>

                <div className="col-md-2 text-center">
                  <img style={{ backgroundColor: "#FFFFFF", transform: "rotate(180deg)", }} src={arrow_1} alt='' className="img-fluid" ></img>
                </div>

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33", }} src={tool} alt='' className="img-fluid br-50-percent"></img>
                </div>
              </div>

              <div className="row justify-content-center text-center my-3 pt-3">
                <span className="col-3 ttt tb">{" "}Endless <br />Customization</span>
                <span className="col-3 ttt tb ">Low MOQ <br />and CapEx</span>
                <span className="col-3 ttt tb">Empower your<br /> Support team{" "}
                </span>
              </div>
            </>
          )}
        </div>



        <div className="container-fluid py-4 mt-4 d-none d-xl-block" style={{ position: "relative" }}>

          {this.props.extended ? (
            <></>
          ) : (
            <Chip l={"90"} t={"0"} w={"35"} rotate={"55"} />
          )}
          <div className="row justify-content-center align-middle  text-center mb-4">
            <span className="proxeet-pc-header" style={{ position: "relative", }} >{this.state.header}</span>
          </div>

          <>
            <div className="row justify-content-center">
              <div className="col-1 text-center">
                <img style={{ backgroundColor: "#FF7E33" }} src={appImg1} alt='' className="img-fluid br-50-percent p-3"></img>
              </div>

              <div className="col-1 text-center">
                <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_2} alt='' className="img-fluid mt-3" ></img>
              </div>

              <div className="col-1 text-center">
                <img style={{ backgroundColor: "#FF7E33", }} src={appImg2} alt='' className="img-fluid br-50-percent p-3" ></img>
              </div>

              <div className="col-1 text-center">
                <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_1} alt='' className="img-fluid mt-3"></img>
              </div>

              <div className="col-1 text-center">
                <img style={{ backgroundColor: "#FF7E33" }} src={appImg3} alt='' className="img-fluid br-50-percent p-3"></img>
              </div>

              {this.props.extended === "active" && (
                <>
                  <div className="col-1 text-center">
                    <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_2} alt='' className="img-fluid mt-3"></img>
                  </div>

                  <div className="col-1 text-center">
                    <img style={{ backgroundColor: "#FF7E33" }} src={appImg4} alt='' className="img-fluid br-50-percent p-3"></img>
                  </div>

                  <div className="col-1 text-center">
                    <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_1} alt='' className="img-fluid mt-3"></img>
                  </div>

                  <div className="col-1 text-center">
                    <img style={{ backgroundColor: "#FF7E33" }} src={appImg5} alt='' className="img-fluid  br-50-percent p-3"></img>
                  </div>
                </>
              )}
            </div>

            <div className="row justify-content-center text-center pt-3">
              <span className="col-2 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item1 }} />
              <span className="col-2 ttt tb " dangerouslySetInnerHTML={{ __html: this.state.item2 }} />
              <span className="col-2 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item3 }} />
              {this.props.extended === "active" && (
                <>
                  <span className="col-2 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item4 }} />
                  <span className="col-2 ttt tb" dangerouslySetInnerHTML={{ __html: this.state.item5 }} />
                </>
              )}
            </div>
          </>

          {this.props.extended === "full" && (
            <>
              <div
                className="row justify-content-center my-3"
                style={{ position: "relative" }}
              >
                <Chip l={"9"} t={"-400"} w={"25"} rotate={"35"} />
                <Chip l={"0"} t={"0"} w={"20"} rotate={"55"} />
                <Chip l={"90"} t={"0"} w={"35"} rotate={"20"} />

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33" }} src={wifi} alt='' className="img-fluid br-50-percent"></img>
                </div>

                <div className="col-md-2 text-center">
                  <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_2} alt='' className="img-fluid"></img>
                </div>

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33" }} src={helm} alt='' className="img-fluid br-50-percent"></img>
                </div>

                <div className="col-md-2 text-center">
                  <img style={{ backgroundColor: "#FFFFFF" }} src={arrow_1} alt='' className="img-fluid"></img>
                </div>

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33" }} src={tool} alt='' className="img-fluid br-50-percent"></img>
                </div>
              </div>

              <div className="row justify-content-center text-center my-3 pt-3">
                <span className="col-3 ttt tb">
                  Full Connectivity
                  <br /> Suite
                </span>
                <span className="col-3 ttt tb ">
                  Create Happier <br />
                  Customers
                </span>
                <span className="col-3 ttt tb">
                  {" "}
                  Top Your <br />
                  Competition
                </span>
              </div>

              <div className="row justify-content-center my-3">
                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33" }} src={wifi} alt='' className="img-fluid br-50-percent"></img>
                </div>

                <div className="col-md-2 text-center">
                  <img style={{ backgroundColor: "#FFFFFF", transform: "rotate(180deg)" }} src={arrow_2} alt='' className="img-fluid"></img>
                </div>

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33", }} src={helm} alt='' className="img-fluid br-50-percent"></img>
                </div>

                <div className="col-md-2 text-center">
                  <img style={{ backgroundColor: "#FFFFFF", transform: "rotate(180deg)", }} src={arrow_1} alt='' className="img-fluid" ></img>
                </div>

                <div className="col-md-1 text-center">
                  <img style={{ backgroundColor: "#FF7E33", }} src={tool} alt='' className="img-fluid br-50-percent"></img>
                </div>
              </div>

              <div className="row justify-content-center text-center my-3 pt-3">
                <span className="col-3 ttt tb">{" "}Endless <br />Customization</span>
                <span className="col-3 ttt tb ">Low MOQ <br />and CapEx</span>
                <span className="col-3 ttt tb">Empower your<br /> Support team{" "}
                </span>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default withGetScreen(ProductApp);
