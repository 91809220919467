import React from 'react';
import Form from 'react-bootstrap/Form';

export class ProductSearch extends React.Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

    }


    state = {

        searchString: '',

    }


    handleSubmit(event) {
        
       event.preventDefault();
      }
   


    searchHandler = (e) => {

        this.setState({ searchString: e.target.value });

       this.props.setProductList(e.target.value);

    }


    render() {

        return (
            <>
                <div className='row justify-content-center align-middle text-center'>
                   
                  
                    <div className='col-10 col-lg-5'>
                        <Form  onSubmit={this.handleSubmit} className="d-flex">


                            <Form.Control

                                style={{ borderRadius: '10px 0px 0px 10px' }}
                                type="text" 
                                placeholder="Search by Model Name"
                                
                                
                                onChange={this.searchHandler}
                            />
                            <span className="input-group-text" style={{ marginLeft: '-5px', backgroundColor: '#FF7E33', border: '1px solid #FF7E33', borderRadius: '0 10px 10px 0' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"
                                    className="bi bi-search" viewBox="0 0 16 16">
                                    <path
                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>

                        </Form>

                    </div>

                </div>
            </>
        );
    }
}