import React from 'react';
import Card from 'react-bootstrap/Card';

export class SearchRender extends React.Component {

    constructor(props) {
        super(props);



    }

    state = {

    }

    render() {

        return (
            <>
                {

                    Object.keys(this.props.DataSet).length !== 0 &&

                    <div style={{ marginTop: '0%', marginBottom: '10%' }} className="container-fluid">
                        <div className="row justify-content-center align-middle  text-center">
                            <span className='proxeet-pc-header' style={{ marginTop: '3%', marginBottom: '5%', position: 'relative' }} >Search result</span>
                        </div>

                        <div className="row justify-content-center align-middle  text-center">
                            <div className="col-10">
                                <div className="row justify-content-center align-middle  text-center">

                                    {
                                        Object.keys(this.props.DataSet).map((someId) => (
                                            <Card key={someId.toString()} className="col-3 m-4 border border-1" style={{ borderRadius: '30px 30px 30px 30px' }}>
                                                <Card.Img variant="top" src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.props.DataSet[someId].imgName} style={{ borderRadius: '30px 30px 0 0' }} />
                                                <Card.Body>
                                                    <Card.Title style={{ color: '#152F4E' }} >{this.props.DataSet[someId].header}</Card.Title>
                                                </Card.Body>
                                                <Card.Footer style={{ borderRadius: '0 0 30px 30px' }}>
                                                    <small className="text-muted">23 MARCH 2023</small>
                                                </Card.Footer>
                                            </Card>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }

            </>
        );
    }
}