import React from 'react';
import axios from 'axios';
import l1 from '../img/mask group_l1.png';
import l2 from '../img/mask group_l2.png';
import Card from 'react-bootstrap/Card';
import Question from './question';
import {withGetScreen} from 'react-getscreen';
import Slider from "react-slick";
import {SampleNextArrow, SamplePrevArrow} from './arrow';
import {Link} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import CardGroup from 'react-bootstrap/CardGroup';


class About extends React.Component {

    constructor(props) {
        super(props);

        this.jsonGet();

    }

    state = {

        news: {},
        basicModal: false,
        mainHeader: 'Proxeet: portable devices and software for optimizing PoE networks',
        header: "what's new at proxeet?",
        btnText: 'learn more'

    }

    jsonGet = async () => {


        let newsData = new FormData();
        newsData.append('table', 'news');
        newsData.append('rows', 'header,text,id');
        newsData.append('idLimit', '2');

        const news = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_FULL_API,
            data: newsData
        });

        const data = await news.data;

        this.setState({news: data});


    }


    render() {

        const settings = {


            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow/>,
            prevArrow: <SamplePrevArrow/>,
        };


        if (this.props.isMobile()) return (

            <>

                <div className="container-fluid mt-4">


                    <div className="row justify-content-center text-center my-3">
                        <h1 className='col-11'>{this.state.mainHeader}</h1>
                    </div>


                    <div className="row justify-content-center text-center">
                        <span className="col-12 text-center proxeet-mob-header">{this.state.header}</span>
                    </div>


                    <div className="row justify-content-center">
                        <div className="col-10  text-center">
                            {Object.keys(this.state.news).length !== 0 &&
                                <Slider className='row'  {...settings}>


                                    <div>
                                        <Card className="text-center border-0 m-4 px-5 px-bg-ll-blue br-30 py-2">
                                            <Card.Body>
                                                <Card.Title className=''>
                                                    <p className='proxeet-pc-header px-white'
                                                       style={{
                                                           lineHeight: '18px',
                                                           fontSize: '14px',

                                                       }}
                                                       dangerouslySetInnerHTML={{__html: this.state.news[0].header}}/>
                                                </Card.Title>
                                                <Card.Text>
                                                    <p
                                                        className={'px-white'}
                                                        style={{fontSize: '10px', lineHeight: '12px'}}
                                                        dangerouslySetInnerHTML={{__html: this.state.news[0].text}}/>
                                                </Card.Text>
                                                <Link to={'news/' + (this.state.news[0].id)}>
                                                    <button

                                                        style={{fontSize: '12px'}}
                                                        className="btn btn-px-mob border-0 br-30">
                                                        <span
                                                            className='ttt'>
                                                            {this.state.btnText}
                                                        </span>
                                                    </button>
                                                </Link>
                                            </Card.Body>
                                            <img
                                                alt='proxeet'
                                                className={'proxeet-crab-on-card'}
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    right: '0%',
                                                    top: '0',
                                                }}
                                                src={l2}/>

                                            <img
                                                alt='proxeet'
                                                className={'proxeet-crab-on-card'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    left: '0%',
                                                    bottom: '0',
                                                }}
                                                src={l1}
                                            />
                                        </Card>
                                    </div>
                                    <div>
                                        <Card className="text-center m-4 px-5 py-2 px-bg-blue border-0 br-30">
                                            <Card.Body>
                                                <Card.Title><p className='proxeet-pc-header px-white' style={{
                                                    lineHeight: '18px',
                                                    fontSize: '14px',

                                                }} dangerouslySetInnerHTML={{__html: this.state.news[1].header}}/>
                                                </Card.Title>
                                                <Card.Text>
                                                    <p style={{fontSize: '10px', lineHeight: '12px', color: '#FFFFFF'}}
                                                       dangerouslySetInnerHTML={{__html: this.state.news[1].text}}/>
                                                </Card.Text>
                                                <Link to={'news/' + (this.state.news[1].id)}>
                                                    <button
                                                        className="btn btn-px-mob border-0 br-30">
                                                        <span
                                                            className='ttt'
                                                        >{this.state.btnText}
                                                        </span>
                                                    </button>
                                                </Link>
                                            </Card.Body>
                                            <img
                                                alt=''
                                                className={'proxeet-crab-on-card'}
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    right: '0%',
                                                    top: '0',

                                                }}
                                                src={l2}
                                            />
                                            <img
                                                alt='proxeet'
                                                className={'proxeet-crab-on-card'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    left: '0%',
                                                    bottom: '0',

                                                }}
                                                src={l1}/>
                                        </Card>
                                    </div>

                                </Slider>
                            }
                        </div>
                    </div>
                </div>

            </>

        );


        if (this.props.isTablet()) return (


            <>

                <div className="container-fluid">


                    <div className="row justify-content-center text-center my-3">
                        <h1 className='col-9'>{this.state.mainHeader}</h1>
                    </div>


                    <div className="row justify-content-center text-center">
                        <span
                            className="col-12 text-center py-4 proxeet-pc-header"
                        >{this.state.header}</span>
                    </div>


                    <div className="row justify-content-center">
                        <div className="col-10  text-center">
                            {Object.keys(this.state.news).length !== 0 &&
                                <Slider className='row'  {...settings}>


                                    <div>
                                        <Card
                                            className="text-center border-0 m-4 px-bg-ll-blue border-0 px-5 py-2 br-30">
                                            <Card.Body>
                                                <Card.Title className=''>
                                                    <span className='ttt px-white'
                                                          style={{fontSize: '14px'}}
                                                          dangerouslySetInnerHTML={{__html: this.state.news[0].header}}
                                                    />
                                                </Card.Title>
                                                <Card.Text>
                                                    <p
                                                        className={'px-white'}
                                                        style={{fontSize: '10px', lineHeight: '12px'}}
                                                        dangerouslySetInnerHTML={{__html: this.state.news[0].text}}
                                                    />
                                                </Card.Text>
                                                <Link to={'news/' + (this.state.news[0].id)}>
                                                    <button
                                                        className="btn btn-px-mob br-30 border-0">
                                                        <span
                                                            className='ttt'>
                                                            {this.state.btnText}
                                                        </span>
                                                    </button>
                                                </Link>
                                            </Card.Body>
                                            <img
                                                alt='proxeet'
                                                className={'proxeet-crab-on-card'}
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    right: '0%',
                                                    top: '0'
                                                }}
                                                src={l2}
                                            />
                                            <img alt='proxeet'
                                                 className={'proxeet-crab-on-card'}
                                                 style={{
                                                     width: '50px',
                                                     height: '50px',
                                                     left: '0%',
                                                     bottom: '0',
                                                 }}
                                                 src={l1}
                                            />
                                        </Card>
                                    </div>
                                    <div>
                                        <Card className="text-center m-4 px-5 py-2 br-30"
                                              style={{backgroundColor: '#152F4E', border: '0'}}>
                                            <Card.Body>
                                                <Card.Title>
                                                    <span
                                                        className='ttt px-white'
                                                        style={{fontSize: '14px'}}
                                                        dangerouslySetInnerHTML={{__html: this.state.news[1].header}}
                                                    />
                                                </Card.Title>
                                                <Card.Text>
                                                    <p
                                                        className={'px-white'}
                                                        style={{fontSize: '10px', lineHeight: '12px'}}
                                                        dangerouslySetInnerHTML={{__html: this.state.news[1].text}}/>
                                                </Card.Text>
                                                <Link to={'news/' + (this.state.news[1].id)}>
                                                    <button
                                                        className="btn btn-px-mob br-30 border-0">
                                                        <span
                                                            className='ttt'>
                                                            {this.state.btnText}
                                                        </span>
                                                    </button>
                                                </Link>
                                            </Card.Body>
                                            <img
                                                alt='proxeet'
                                                className={'proxeet-crab-on-card'}
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    right: '0%',
                                                    top: '0',
                                                }}
                                                src={l2}
                                            />
                                            <img
                                                className={'proxeet-crab-on-card'}
                                                alt={'proxeet'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    left: '0%',
                                                    bottom: '0',
                                                }}
                                                src={l1}
                                            />
                                        </Card>
                                    </div>

                                </Slider>
                            }
                        </div>
                    </div>
                </div>


                <div className="container-fluid d-none d-md-block">


                    <div className="row justify-content-center text-center">
                        <span className="col-12 text-center my-4 proxeet-pc-header">{this.state.header}</span>
                    </div>


                </div>


            </>

        );

        return (
            <>


                <div className="container-fluid mt-5 d-none d-md-block d-xl-none">


                    <div className="row justify-content-center text-center">
                        <span className="col-12 text-center proxeet-pc-header">{this.state.header}</span>
                    </div>


                    <div className="row justify-content-center mt-4">
                        {Object.keys(this.state.news).length !== 0 &&
                            <CardGroup>
                                <Card className="mx-3 px-1 py-2 proxeet-bg-ll-blue border-0 br-30 mt-3"
                                      style={{width: '16rem'}}>
                                    <Card.Body className='row  justify-content-center text-center proxeet-white'>
                                        <Card.Title className='my-4 proxeet-white'>
                                            <span className='ttt proxeet-white'
                                                  style={{fontSize: '1rem'}}
                                                  dangerouslySetInnerHTML={{__html: this.state.news[0].header}}/>
                                        </Card.Title>
                                        <Card.Text>
                                            <p className={'proxeet-white'} style={{fontSize: '0.9rem'}}
                                               dangerouslySetInnerHTML={{__html: this.state.news[0].text}}/>
                                        </Card.Text>

                                        <Link className='row justify-content-center text-center'
                                              to={'news/' + (this.state.news[0].id)}>
                                            <button onClick={() => {
                                            }}
                                                    className="col-6  border-0 btn btn-proxeet-h-3">
                                                <span className='ttt'>Learn more</span>
                                            </button>
                                        </Link>


                                    </Card.Body>
                                    <img className={'proxeet-crab-on-card'} alt='proxeet'
                                         style={{width: '3rem', right: '0%', top: '0'}} src={l2}/>
                                    <img className={'proxeet-crab-on-card'} alt='proxeet'
                                         style={{width: '5rem', left: '0%', bottom: '0'}} src={l1}/>
                                </Card>


                                <Card className="mx-3 px-1  py-2 br-30 mt-3"
                                      style={{width: '16rem', backgroundColor: '#152F4E', border: '0'}}>
                                    <Card.Body className='row  justify-content-center text-center'>
                                        <Card.Title className='my-4 col-12 proxeet-white'>
                                            <span className='text-center ttt'
                                                  style={{fontSize: '1rem'}}
                                                  dangerouslySetInnerHTML={{__html: this.state.news[1].header}}/>
                                        </Card.Title>
                                        <Card.Text className='col-11  proxeet-white'>
                                            <p
                                                className='text-center px-white'
                                                style={{fontSize: '0.9rem'}}
                                                dangerouslySetInnerHTML={{__html: this.state.news[1].text}}/>
                                        </Card.Text>

                                        <Link className='row justify-content-center text-center'
                                              to={'news/' + (this.state.news[1].id)}>
                                            <button
                                                onClick={() => {
                                                }}

                                                className="col-6 border-0 btn btn-proxeet-h-3  px-bg-orange px-white br-30">
                                                <span className='ttt'>Learn more</span>
                                            </button>
                                        </Link>

                                    </Card.Body>
                                    <img className={'proxeet-crab-on-card'} alt='proxeet'
                                         style={{width: '3rem', right: '0%', top: '0'}} src={l2}></img>
                                    <img className={'proxeet-crab-on-card'} alt='proxeet'
                                         style={{width: '5rem', left: '0%', bottom: '0'}} src={l1}></img>
                                </Card>
                            </CardGroup>
                        }
                    </div>


                </div>

                <div className="container-fluid mt-5 d-none  d-xl-block">


                    <div className="row justify-content-center text-center my-3">
                        <h1 className='col-9'>{this.state.mainHeader}</h1>
                    </div>


                    <div className="row justify-content-center text-center">
                        <span className="col-12 text-center proxeet-pc-header">{this.state.header}</span>
                    </div>


                    <div className="row justify-content-center mt-4">


                        {Object.keys(this.state.news).length !== 0 &&
                            <>
                                <Card className="text-center px-bg-ll-blue border-0 mx-5 px-5 py-2 mt-5 br-30"
                                      style={{width: '35rem'}}>
                                    <Card.Body className={'px-white'}>
                                        <Card.Title className='my-4 px-white'><span
                                            className='proxeet-pc-header px-white' style={{fontSize: '2rem'}}
                                            dangerouslySetInnerHTML={{__html: this.state.news[0].header}}/></Card.Title>
                                        <Card.Text>
                                            <p
                                                className={'px-white'}
                                                dangerouslySetInnerHTML={{__html: this.state.news[0].text}}/>
                                        </Card.Text>
                                        <Link to={'news/' + (this.state.news[0].id)}>
                                            <button
                                                onClick={() => {
                                                }}
                                                className="br-30 btn-proxeet px-bg-ll-blue btn">
                                                <span className='ttt'>{this.state.btnText}</span>
                                            </button>
                                        </Link>
                                    </Card.Body>
                                    <img
                                        className={'proxeet-crab-on-card'}
                                        alt='proxeet'
                                        style={{right: '0%', top: '0'}}
                                        src={l2}
                                    />
                                    <img
                                        className={'proxeet-crab-on-card'}
                                        alt='proxeet'
                                        style={{left: '0%', bottom: '0'}}
                                        src={l1}
                                    />
                                </Card>


                                <Card className="text-center mx-5 px-5 border-0 px-bg-blue py-2 mt-5 br-30"
                                      style={{width: '35rem'}}>
                                    <Card.Body>
                                        <Card.Title className='my-4 px-white'><span
                                            className='proxeet-pc-header px-white' style={{fontSize: '2rem'}}
                                            dangerouslySetInnerHTML={{__html: this.state.news[1].header}}/></Card.Title>
                                        <Card.Text>
                                            <p
                                                className={'px-white'}

                                                dangerouslySetInnerHTML={{__html: this.state.news[1].text}}/>
                                        </Card.Text>
                                        <Link to={'news/' + (this.state.news[1].id)}>
                                            <button onClick={() => {
                                            }}

                                                    className="br-30 btn-proxeet btn">
                                                <span className='ttt'>{this.state.btnText}</span>
                                            </button>
                                        </Link>
                                    </Card.Body>
                                    <img
                                        alt='proxeet'
                                        className={'proxeet-crab-on-card'}
                                        style={{right: '0%', top: '0'}}
                                        src={l2}
                                    />
                                    <img
                                        alt='proxeet'
                                        className={'proxeet-crab-on-card'}
                                        style={{left: '0%', bottom: '0'}}
                                        src={l1}
                                    />
                                </Card>
                            </>
                        }


                    </div>

                </div>


            </>
        );


    }
}

export default withGetScreen(About);