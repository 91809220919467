import React from 'react';
import f_blue from '../img/f.svg';
import y_blue from '../img/y.svg';
import t_blue from '../img/socialIcons/x_blue.svg';
import i_blue from '../img/i.svg';
import f from '../img/socialIcons/fb.svg';
import y from '../img/socialIcons/ytube.svg';
import t from '../img/socialIcons/t.svg';
import i from '../img/socialIcons/ig.svg';
import linkedin from '../img/socialIcons/linkedin.svg';
import linkedin_blue from '../img/socialIcons/linkedin_blue.svg';

import tg from '../img/socialIcons/tg.svg';
import tg_blue from '../img/socialIcons/tg_blue.svg';

export class SocialButton extends React.Component {

    constructor(props) {
        super(props);



    }

    state = {

    }

    render() {

        return (
            <>
                <div className="row text-center justify-content-center align-middle">
                    { this.props.header === true && <span  className="col-6 proxeet-pc-header" style={{ marginTop: '10%', color: '#FF7E33' }}>FOLLOW US</span>}
                </div>

                <div className="row justify-content-center mb-4">

                    <div className="col-12 text-center" style={{marginRight: '10px'}}>
                        <a href='https://twitter.com/Proxeet_Cy'>
                            <img alt='' className='mx-2' src={this.props.color !== "blue" ? t_blue : t}
                                 style={{width: '', height: '50px',}}/>
                        </a>
                        <a href='https://www.youtube.com/@Proxeet'>
                            <img alt='' className='mx-2' src={this.props.color !== "blue" ? y_blue : y}
                                 style={{width: '', height: '50px'}}/>
                        </a>
                        <a href='https://www.facebook.com/proxeet/'>
                            <img alt='' className='mx-2' src={this.props.color !== "blue" ? f_blue : f}
                                 style={{width: '', height: '50px'}}/>
                        </a>
                        <a href='https://www.instagram.com/proxeet/'>
                            <img alt='' className='mx-2' src={this.props.color !== "blue" ? i_blue : i}
                                 style={{width: '', height: '50px'}}/>
                        </a>
                        <a href='https://www.linkedin.com/company/proxeet/'>
                            <img alt='' className={this.props.color !== "blue" ? 'mx-2' : 'p-2 mx-2 '} src={this.props.color !== "blue" ? linkedin_blue : linkedin}
                                 style={{width: '', height: '50px'}}/>
                        </a>
                        <a href='https://t.me/proxeetcy'>
                            <img alt='' className={this.props.color !== "blue" ? 'mx-2' : 'p-2 mx-2 '}  src={this.props.color !== "blue" ? tg_blue : tg}
                                 style={{width: '', height: '50px'}}/>
                        </a>
                    </div>

                </div>

            </>
        );
    }
}