import React from 'react';
import {withGetScreen} from 'react-getscreen';
import axios from 'axios';
import {Chip} from '../chip';
import {Link} from 'react-router-dom';


class MoreNews extends React.Component {

    constructor(props) {
        super(props);

        this.jsonGet();


    }


    state = {

        news: {}

    }


    jsonGet = async () => {

        let newsData = new FormData();
        newsData.append('table', 'news');
        newsData.append('rows', 'id,imgName,header,date,die,themes');


        const news = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_FULL_API,
            data: newsData
        });

        const newsResp = await news.data;

        this.setState({news: newsResp});


    }


    setNewsListThemes = (e) => {

        this.props.newsSearchStringThemesSet(e.target.getAttribute("data-index"));

    }


    render() {


        if (this.props.isMobile()) return (

            <>
                <div className="container-fluid mt-4">
                    <div className="row justify-content-center align-middle  text-center">
                        <span className='proxeet-mob-header'>MORE FROM NEWS</span>
                    </div>


                    {
                        Object.keys(this.state.news).reverse().slice(0, 4).map((someId) => (

                            <>

                                <div className="row justify-content-center align-middle  text-center my-4">
                                    <div className='col-12'>
                                        <div className='my-4'>
                                            <div onClick={this.props.callSingleNewPage} className="row">
                                                <div
                                                    className='col-4 d-flex align-items-center  justify-content-center'>
                                                    <div className=' position-relative d-inline-block'>
                                                        <img alt=''
                                                             src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                                             className="img-fluid br-10"/>
                                                    </div>
                                                </div>
                                                <div className="col-7" style={{border: '0px solid red'}}>
                                                    {this.state.news[someId].themes.includes(';') ?

                                                        <div className='ttt text-start'
                                                             style={{border: '0px solid blue', color: '#FF7E33'}}>
                                                            {
                                                                this.state.news[someId].themes.split(';').map((someId) => (

                                                                    <>
                                                                        <p
                                                                            style={{
                                                                                lineHeight: '0.1rem',
                                                                                color: '#FF7E33'
                                                                            }}
                                                                            onClick={this.setNewsListThemes}
                                                                            data-index={someId}
                                                                            className="btn text-start ttt"
                                                                            dangerouslySetInnerHTML={{__html: someId}}/>
                                                                        <span>&nbsp;</span>
                                                                    </>

                                                                ))
                                                            }

                                                        </div>

                                                        :
                                                        <div className='ttt text-start'
                                                             style={{border: '0px solid green', color: '#FF7E33'}}>
                                                            <p style={{color: '#FF7E33'}}
                                                               onClick={this.setNewsListThemes}
                                                               data-index={this.state.news[someId].themes}
                                                               className="px-0 btn text-start ttt"
                                                               dangerouslySetInnerHTML={{__html: this.state.news[someId].themes}}/>
                                                        </div>
                                                    }
                                                    <Link className='col-5 ' onClick={() => {
                                                        window.scrollTo(0, 0);
                                                        window.reload();
                                                    }} to={'/news/' + (this.state.news[someId].id)}>
                                                        <p className='text-start proxeet-mob-header'
                                                           style={{
                                                               lineHeight: '14px',
                                                               fontSize: '12px',
                                                               // border: '1px solid blue'
                                                           }}
                                                           dangerouslySetInnerHTML={{__html: this.state.news[someId].header}}/>
                                                    </Link>
                                                    <h5 className='text-start m-0 p-0'
                                                        style={{fontSize: '10px', color: '#95BBDB'}}
                                                        dangerouslySetInnerHTML={{__html: this.state.news[someId].newsData}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ))


                    }


                    <div className="row justify-content-center align-middle  mb-4 text-center">
                        {/*this.props.newsCount !== 'all' && <button

                            style={{ width: '25vh', height: '7vh', marginTop: '5%', marginBottom: '0%', backgroundColor: '#FF7E33'}}
                            onClick={this.props.callNews}
                            className="apply-btn btn btn-success col-2 border-0 br-30">
                            <span className='ttt'>ALL NEWS</span>

                </button>*/}
                    </div>


                </div>
            </>
        );

        if (this.props.isTablet()) return (

            <>
                <div style={{marginTop: '0%', marginBottom: '10%'}} className="container-fluid">
                    <div className="row justify-content-center align-middle  text-center">
                        <span className='proxeet-tab-header' style={{position: 'relative'}}>MORE FROM NEWS</span>
                    </div>


                    {
                        Object.keys(this.state.news).reverse().slice(0, 4).map((someId) => (

                            <>

                                <div className="row justify-content-center align-middle  text-center my-4">
                                    <div className='col-12'>
                                        <div className='my-4'>
                                            <div className="row">
                                                <div className='col-3'>
                                                    <img alt=''
                                                         src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                                         style={{borderRadius: '10px 10px 10px 10px'}}
                                                         className="img-fluid my-3"/>
                                                </div>
                                                <div className="col-9 mt-2">
                                                    {this.state.news[someId].themes.includes(';') ?

                                                        <h5 className='ttt text-start' style={{color: '#FF7E33'}}>
                                                            {
                                                                this.state.news[someId].themes.split(';').map((someId) => (

                                                                    <>
                                                                        <span
                                                                            style={{color: '#FF7E33'}}
                                                                            onClick={this.setNewsListThemes}
                                                                            data-index={someId}
                                                                            className="px-0 btn text-start ttt"
                                                                            dangerouslySetInnerHTML={{__html: someId}}/>
                                                                        <span>&nbsp;</span>
                                                                    </>

                                                                ))
                                                            }

                                                        </h5>

                                                        :
                                                        <h5 className='ttt text-start' style={{color: '#FF7E33'}}><span
                                                            style={{color: '#FF7E33'}} onClick={this.setNewsListThemes}
                                                            data-index={this.state.news[someId].themes}
                                                            className="px-0 btn text-start ttt"
                                                            dangerouslySetInnerHTML={{__html: this.state.news[someId].themes}}/>
                                                        </h5>
                                                    }
                                                    <div>
                                                        <Link className='' onClick={() => {
                                                            window.scrollTo(0, 0);
                                                            window.reload();
                                                        }} to={'/news/' + (this.state.news[someId].id)}>
                                                            <p className='text-start proxeet-tab-header'
                                                               style={{fontSize: '12px'}}
                                                               onClick={this.props.callSingleNewPage}
                                                               dangerouslySetInnerHTML={{__html: this.state.news[someId].header}}/>
                                                        </Link>
                                                    </div>
                                                    <h5 className='text-start'
                                                        style={{fontSize: '10px', color: '#95BBDB'}}
                                                        dangerouslySetInnerHTML={{__html: this.state.news[someId].newsData}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ))


                    }


                    <div className="row justify-content-center align-middle  text-center">
                        {/*this.props.newsCount !== 'all' && <button

                            style={{ width: '25vh', height: '7vh', marginTop: '5%', marginBottom: '0%', backgroundColor: '#FF7E33' }}
                            onClick={this.props.callNews}
                            className="apply-btn btn btn-success col-2 border-0 br-30">
                            <span className='ttt'>ALL NEWS</span>

                </button>*/}
                    </div>


                </div>


            </>
        );


        return (
            <>

                <div className="container-fluid mt-4 d-block">
                    <div className="row justify-content-center align-middle  text-center"
                         style={{position: 'relative', padding: '0', margin: '0'}}>

                        <Chip l={'-5'} t={'240'} w={'25'} rotate={'-15'}/>

                        <Chip l={'90'} t={'140'} w={'35'} rotate={'70'}/>
                        <span className='col-10 text-center proxeet-pc-header'
                        >MORE FROM NEWS</span>
                    </div>

                    <div className="row justify-content-center align-middle  text-md-center">


                        {
                            Object.keys(this.state.news).reverse().slice(0, 4).map((someId) => (

                                <div className='col-5 d-flex'>
                                    <div
                                        className='row my-3 d-flex justify-content-center align-self-middle  text-center'>
                                        <div className='col-4  d-flex  align-items-center  justify-content-center'>
                                            <img onClick={this.props.callSingleNewPage} alt=''
                                                 src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                                 className="br-10 img-fluid"/>
                                        </div>

                                        <div className='col-7  d-flex align-items-center  justify-content-left'>
                                            <div
                                                className='row my-3  justify-content-center align-middle  text-center '>
                                                <div className=''>
                                                    {this.state.news[someId].themes.includes(';') ?

                                                        <h5 className='ttt text-start proxeet-orange'>
                                                            {
                                                                this.state.news[someId].themes.split(';').map((someId) => (

                                                                    <>
                                                                        <span
                                                                            onClick={this.setNewsListThemes}
                                                                            data-index={someId}
                                                                            className="px-0 btn text-start ttt proxeet-orange"
                                                                            dangerouslySetInnerHTML={{__html: someId}}/>
                                                                        <span>&nbsp;</span>
                                                                    </>

                                                                ))
                                                            }

                                                        </h5>

                                                        :
                                                        <h5 className='ttt text-start proxeet-orange'>
                                                            <span
                                                                onClick={this.setNewsListThemes}
                                                                data-index={this.state.news[someId].themes}
                                                                className="px-0 btn text-start ttt proxeet-orange "
                                                                dangerouslySetInnerHTML={{__html: this.state.news[someId].themes}}/>
                                                        </h5>
                                                    }
                                                    <Link
                                                        className=''
                                                         onClick={() => {
                                                             window.scrollTo(0, 0);
                                                             window.reload();
                                                         }}

                                                        to={'/news/' + (Number(someId) + 1)}>
                                                        <p
                                                            className='text-start f-docker'
                                                            style={{
                                                                lineHeight: '20px',
                                                                fontSize: '16px',
                                                                // border: '2px solid red'
                                                            }}
                                                            dangerouslySetInnerHTML={{__html: this.state.news[someId].header.replace('</br>', '')}}/>
                                                    </Link>
                                                    <h5 className='text-start' style={{color: '#95BBDB'}}
                                                        dangerouslySetInnerHTML={{__html: this.state.news[someId].date}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))


                        }


                    </div>


                    <div className="row justify-content-center align-middle  text-center mt-3">
                        {/*this.props.newsCount !== 'all' && <button

                            style={{ width: '14rem', height: '4rem', border: '0' ,backgroundColor: '#FF7E33', borderRadius: '30px 30px 30px 30px' }}
                            onClick={this.props.callNews}
                            className="apply-btn btn btn-success col-2 ">
                            <span className='ttt'>ALL NEWS</span>

                    </button>*/}
                    </div>

                </div>

            </>
        );
    }
}


export default withGetScreen(MoreNews);