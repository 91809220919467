import React from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import axios from 'axios';
import {SampleNextArrow, SamplePrevArrow} from '../arrow';
import {withGetScreen} from 'react-getscreen';
import {Chip} from '../chip';


class LastNews extends React.Component {

    constructor(props) {
        super(props);

        this.props.callNews();
        this.jsonGet();

    }


    state = {

        news: {},


    }


    jsonGet = async () => {

        let newsData = new FormData();
        newsData.append('table', 'news');
        newsData.append('rows', 'id,imgName,header,date,die');


        const news = await axios.request({
            method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: newsData
        });

        const newsResp = await news.data;

        this.setState({news: newsResp});

    }


    render() {


        const settingsMob = {


            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow/>,
            prevArrow: <SamplePrevArrow/>,
        };


        if (this.props.isMobile()) return (

            <>
                {Object.keys(this.state.news).length !== 0 && <>
                    <div className="container-fluid mt-4">
                        <div className="row justify-content-center align-middle  text-center">
                            <span className='proxeet-mob-header'>LATEST NEWS</span>
                        </div>


                        <div className="row justify-content-center align-middle  text-center position-relative">

                            {<Chip l={'75'} t={'65'} w={'25'} rotate={'30'}/>}

                            <div className="col-12">

                                {Object.keys(this.state.news).slice(-1).reverse().map((someId) => (<>
                                    <Link className='col-12 my-4' onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/news/' + (Number(someId) + 1)}>
                                        <Card className="text-white m-4  rounded-4">
                                            <Card.Img
                                                src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[(Number(someId))].imgName}
                                                alt="Card image" className="rounded-4"/>
                                            <Card.ImgOverlay>
                                                {this.state.news[(Number(someId))].die === 'off' ?
                                                    <></>
                                                    :
                                                    <>
                                                        {this.state.news[(Number(someId))].die !== 'none' ?
                                                            <>
                                                                <Card.Title style={{
                                                                    background: this.state.news[(Number(someId))].die,
                                                                    position: 'absolute',
                                                                    top: '60%'
                                                                }} className="text-start rounded-">
                                                                        <span
                                                                            className='text-start proxeet-pc-header px-3 py-2'
                                                                            style={{
                                                                                color: "#FFFFFF",
                                                                                fontSize: '0.6rem',
                                                                                border: '1px solid red'
                                                                            }}
                                                                            dangerouslySetInnerHTML={{__html: this.state.news[(Number(someId))].header}}/>
                                                                </Card.Title>

                                                                <Card.Text
                                                                    style={{position: 'absolute', top: '75%'}}
                                                                    className="text-white text-start ttt my-4 px-2">
                                                                    <h5 className='text-start br-15 py-1 px-2'
                                                                        style={{
                                                                            background: this.state.news[(Number(someId))].die,
                                                                            fontSize: '12px',
                                                                            color: '#FFFFFF'
                                                                        }}
                                                                        dangerouslySetInnerHTML={{__html: this.state.news[(Number(someId))].date}}/>
                                                                </Card.Text>
                                                            </>
                                                            :
                                                            <>
                                                                <Card.Title
                                                                    style={{position: 'absolute', top: '65%'}}
                                                                    className="text-white text-start fs-2 ttt px-2"><span
                                                                    className='text-start proxeet-pc-header'
                                                                    style={{color: "#FFFFFF", fontSize: '16px'}}
                                                                    dangerouslySetInnerHTML={{__html: this.state.news[(Number(someId))].header}}/></Card.Title>

                                                                <Card.Text
                                                                    style={{position: 'absolute', top: '75%'}}
                                                                    className="text-white text-start ttt my-4 px-2">
                                                                    <h5 className='text-start'
                                                                        style={{fontSize: '12px', color: '#FFFFFF'}}
                                                                        dangerouslySetInnerHTML={{__html: this.state.news[(Number(someId))].data}}/>
                                                                </Card.Text>
                                                            </>


                                                        }
                                                    </>
                                                }
                                            </Card.ImgOverlay>
                                        </Card>
                                    </Link>
                                </>))}
                            </div>
                        </div>

                        <div className="row justify-content-center align-middle align-items-center text-center">
                            <div className="col-10  text-center">
                                <Slider className='row'  {...settingsMob}>

                                    {Object.keys(this.state.news).reverse().slice(1).map((someId) => (

                                        <>
                                            <div className="col-12">
                                                <Link
                                                    className='col-4'
                                                    onClick={() => {
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    to={'/news/' + (Number(someId) + 1)}>
                                                    <Card className="m-4 border border-1 br-30 shadow">
                                                        <Card.Img variant="top"
                                                                  src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[Number(someId)].imgName}
                                                                  style={{borderRadius: '30px 30px 0 0'}}/>
                                                        <Card.Body className='mx-4'>
                                                            <Card.Title style={{color: '#152F4E'}}><p
                                                                className='text-start mb-2 proxeet-mob-header'
                                                                style={{color: "#152F4E", fontSize: '16px'}}
                                                                dangerouslySetInnerHTML={{__html: this.state.news[Number(someId)].header}}/>
                                                            </Card.Title>
                                                            <small className="text-muted"><h5
                                                                className='text-start my-2'
                                                                style={{color: '#95BBDB'}}
                                                                dangerouslySetInnerHTML={{__html: this.state.news[Number(someId)].date}}/>
                                                            </small>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            </div>
                                        </>

                                    ))

                                    }

                                </Slider>
                            </div>
                        </div>
                    </div>

                </>}
            </>

        );

        if (this.props.isTablet()) return (

            <>
                {Object.keys(this.state.news).length !== 0 && <>
                    <div style={{marginTop: '0%', marginBottom: '10%'}} className="container-fluid">
                        <div className="row justify-content-center align-middle  text-center">
                                <span className='proxeet-tab-header' style={{
                                    marginTop: '10%', marginBottom: '5%', position: 'relative'
                                }}>LATEST NEWS</span>
                        </div>


                        <div className="row justify-content-center align-middle  text-center"
                             style={{position: 'relative'}}>

                            {<Chip l={'75'} t={'65'} w={'25'} rotate={'30'}/>}

                            <div className="col-12">

                                {Object.keys(this.state.news).slice(-1).reverse().map((someId) => (<>
                                    <Link className='col-12 my-4' onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} to={'/news/' + (Number(someId) + 1)}>
                                        <Card className="text-white m-4 rounded-5">
                                            <Card.Img
                                                src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[Number(someId)].imgName}
                                                alt="Card image" className=" rounded-5"/>
                                            <Card.ImgOverlay>
                                                <>
                                                    {this.state.news[someId].die === 'off' ?
                                                        <></>
                                                        :
                                                        <>
                                                            {this.state.news[someId].die !== 'none' ? <>
                                                                <Card.Title style={{
                                                                    background: this.state.news[someId].die,
                                                                    position: 'absolute',
                                                                    top: '60%'
                                                                }} className="text-start br-15 p-0">
                                                                        <span
                                                                            className='text-start proxeet-pc-header p-0'
                                                                            style={{
                                                                                color: "#FFFFFF", fontSize: '0.6rem'
                                                                            }}
                                                                            dangerouslySetInnerHTML={{__html: this.state.news[someId].header}}
                                                                        />
                                                                </Card.Title>

                                                                <Card.Text
                                                                    style={{position: 'absolute', top: '75%'}}
                                                                    className="text-white text-start ttt my-4 px-2">
                                                                    <h5 className='text-start br-15 p-1' style={{
                                                                        background: this.state.news[someId].die,
                                                                        fontSize: '12px',
                                                                        color: '#FFFFFF'
                                                                    }}
                                                                        dangerouslySetInnerHTML={{__html: this.state.news[someId].date}}/>
                                                                </Card.Text>
                                                            </> : <>
                                                                <Card.Title
                                                                    style={{position: 'absolute', top: '65%'}}
                                                                    className="text-white text-start fs-2 ttt px-2"><span
                                                                    className='text-start proxeet-pc-header'
                                                                    style={{color: "#FFFFFF", fontSize: '16px'}}
                                                                    dangerouslySetInnerHTML={{__html: this.state.news[someId].header}}/></Card.Title>

                                                                <Card.Text
                                                                    style={{position: 'absolute', top: '75%'}}
                                                                    className="text-white text-start ttt my-4 px-2">
                                                                    <h5 className='text-start'
                                                                        style={{fontSize: '12px', color: '#FFFFFF'}}
                                                                        dangerouslySetInnerHTML={{__html: this.state.news[someId].date}}/>
                                                                </Card.Text>
                                                            </>


                                                            }

                                                        </>
                                                    }
                                                </>
                                            </Card.ImgOverlay>
                                        </Card>
                                    </Link>
                                </>))}


                            </div>
                        </div>


                        <div className="row justify-content-center align-middle align-items-center text-center">
                            <div className="col-10  text-center">
                                <Slider className='row'  {...settingsMob}>
                                    {Object.keys(this.state.news).reverse().slice(1).map((someId) => (

                                        <>
                                            <div className="col-12">
                                                <Link className='col-4' onClick={() => {
                                                    window.scrollTo(0, 0);
                                                }} to={'/news/' + (Number(someId) + 1)}>
                                                    <Card className="m-4 border border-1 shadow"
                                                          style={{borderRadius: '30px 30px 30px 30px'}}>
                                                        <Card.Img variant="top"
                                                                  src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                                                  style={{borderRadius: '30px 30px 0 0'}}/>
                                                        <Card.Body className='mx-4'>
                                                            <Card.Title style={{color: '#152F4E'}}><span
                                                                className='text-start mb-2 proxeet-tab-header'
                                                                style={{
                                                                    color: "#152F4E",
                                                                    lineHeight: '20px',
                                                                    fontSize: '16px'
                                                                }}
                                                                dangerouslySetInnerHTML={{__html: this.state.news[someId].header}}/></Card.Title>
                                                            <small className="text-muted"><h5
                                                                className='text-start my-2'
                                                                style={{color: '#95BBDB'}}
                                                                dangerouslySetInnerHTML={{__html: this.state.news[someId].date}}/>
                                                            </small>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            </div>
                                        </>

                                    ))

                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </>}

            </>


        );

        return (<>
            {Object.keys(this.state.news).length !== 0 && <>
                <div className="container-fluid mt-5 d-none d-md-block d-xl-none">
                    <div className="row justify-content-center align-middle  text-start">
                                <span className='col-9 proxeet-pc-header'
                                      style={{position: 'relative'}}>LAST NEWS</span>
                    </div>

                    <div className='row container-fluid'>
                        <Chip l={'80'} t={'85'} w={'60'} rotate={'40'}/>
                    </div>


                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-10">
                            {Object.keys(this.state.news).slice(-1).reverse().map((someId) => (<>
                                <Link className='col-12 my-4' onClick={() => {
                                    window.scrollTo(0, 0);
                                }} to={'/news/' + (Number(someId) + 1)}>
                                    <Card className=" text-white m-4 rounded-5 shadow">
                                        <Card.Img
                                            src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[(Number(someId))].imgName}
                                            alt="Card image" className=" rounded-5"/>
                                        <Card.ImgOverlay>
                                            <Card.Title style={{position: 'absolute', top: '70%'}}
                                                        className="text-white text-start fs-2 ttt px-4">


                                                {this.state.news[someId].die === 'off' ? <></> : <>
                                                    {this.state.news[someId].die !== 'none' ?
                                                        <>
                                                            <div className='br-15 px-2'
                                                                 style={{
                                                                     background: this.state.news[someId].die
                                                                 }}>
                                                                        <span
                                                                            className=' mx-2 text-start proxeet-pc-header'
                                                                            style={{
                                                                                color: "#FFFFFF",
                                                                                fontSize: '1.8rem',
                                                                                border: '0px solid red'
                                                                            }}
                                                                            dangerouslySetInnerHTML={{__html: this.state.news[someId].header}}/>
                                                            </div>
                                                            <Card.Text
                                                                style={{position: 'absolute', top: '88%'}}
                                                                className="text-white text-start ttt my-4 px-4">
                                                                <h5
                                                                    className='text-start br-15 px-3 p-2'
                                                                    style={{
                                                                        background: this.state.news[someId].die,
                                                                        color: '#FFFFFF'
                                                                    }}
                                                                    dangerouslySetInnerHTML={{__html: this.state.news[someId].date}}/>
                                                            </Card.Text>
                                                        </>

                                                        :
                                                        <>
                                                            <div style={{
                                                                background: this.state.news[someId].die,
                                                                borderRadius: '15px'
                                                            }}>
                                                                        <span className='text-start proxeet-pc-header'
                                                                              style={{
                                                                                  color: "#FFFFFF", fontSize: '2rem'
                                                                              }}
                                                                              dangerouslySetInnerHTML={{__html: this.state.news[someId].header}}/>
                                                            </div>
                                                            <Card.Text style={{position: 'absolute', top: '85%'}}
                                                                       className="text-white text-start ttt my-4 px-4">
                                                                <h5 className='text-start'
                                                                    style={{color: '#FFFFFF'}}
                                                                    dangerouslySetInnerHTML={{__html: this.state.news[someId].date}}/>
                                                            </Card.Text>
                                                        </>

                                                    }
                                                </>

                                                }


                                            </Card.Title>


                                            <Card.Footer>

                                            </Card.Footer>
                                        </Card.ImgOverlay>
                                    </Card>
                                </Link>
                            </>))}
                        </div>
                    </div>


                    {<>
                        <div className="row justify-content-center align-middle  text-md-center">
                            <div className="col-10">
                                <CardGroup>
                                    {Object.keys(this.state.news).reverse().slice(0, 2).map((someId) => (

                                        <>
                                            <Link className='col-6 ' onClick={() => {
                                                window.scrollTo(0, 0);
                                            }} style={{ height: '40%' }} to={'/news/' + (Number(someId))}>
                                                <Card className="m-4 border border-1 shadow br-30" style={{ }}>
                                                    <div style={{ height: '28vh', overflow: 'hidden', borderRadius: '30px 30px 0 0' }}>
                                                        <Card.Img className='' variant="top"
                                                              src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[Number(someId) - 1].imgName}
                                                              style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                                                    </div>
                                                    <Card.Body className='mx-4'>
                                                        <Card.Title style={{
                                                            height: '4rem', color: '#152F4E'
                                                        }}><span className='text-center mb-4' style={{
                                                            fontFamily: 'DOCKER', color: "#152F4E", fontSize: '16px'
                                                        }}
                                                                 dangerouslySetInnerHTML={{__html: this.state.news[Number(someId) - 1].header}}/></Card.Title>
                                                        <small className="text-muted"><h5
                                                            className='text-start' style={{
                                                            fontSize: '0.8rem', color: '#95BBDB'
                                                        }}
                                                            dangerouslySetInnerHTML={{__html: this.state.news[Number(someId) - 1].date}}/>
                                                        </small>
                                                    </Card.Body>

                                                </Card>
                                            </Link>
                                        </>))}
                                </CardGroup>
                            </div>
                        </div>


                        <div className="row justify-content-center align-middle  text-md-center">


                            <div className="col-10 " style={{position: 'relative', padding: '0' }}>
                                <Chip l={'-15'} t={'30'} w={'25'} rotate={'40'}/>

                                <Chip l={'-8'} t={'100'} w={'15'} rotate={'60'}/>

                                <CardGroup>

                                    {Object.keys(this.state.news).reverse().slice(0, 3).map((someId) => (

                                        <>
                                            <Link className='col-4' onClick={() => {
                                                window.scrollTo(0, 0);
                                            }} to={'/news/' + (Number(someId) - 2)}>
                                                <Card className="m-4 border border-1 shadow br-30">
                                                    <div style={{ height: '18vh', overflow: 'hidden', borderRadius: '30px 30px 0 0' }}>
                                                        <Card.Img variant="top"
                                                              src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[Number(someId) - 3].imgName}
                                                              style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                                                    </div>
                                                    <Card.Body className='mx-1'
                                                               style={{height: '5rem'}}>
                                                        <Card.Title style={{color: '#152F4E'}}>
                                                                            <span className='text-start' style={{
                                                                                fontFamily: 'DOCKER',
                                                                                color: "#152F4E",
                                                                                fontSize: '0.8rem'
                                                                            }}
                                                                                  dangerouslySetInnerHTML={{__html: this.state.news[Number(someId) - 3].header}}/>
                                                        </Card.Title>

                                                    </Card.Body>
                                                    <Card.Footer style={{
                                                        background: '#FFF',
                                                        border: '0',
                                                        borderRadius: '0 0 30px 30px'
                                                    }}>
                                                        <small className="text-muted"><h5
                                                            className=' text-start' style={{
                                                            fontSize: '0.8rem', color: '#95BBDB'
                                                        }}
                                                            dangerouslySetInnerHTML={{__html: this.state.news[Number(someId) - 3].date}}/>
                                                        </small>
                                                    </Card.Footer>
                                                </Card>
                                            </Link>
                                        </>))}

                                </CardGroup>
                            </div>
                        </div>

                    </>}

                </div>
                <div className="container-fluid mt-5 d-none d-xl-block">
                    <div className="row justify-content-center align-middle  text-start">
                                <span className='col-9 proxeet-pc-header'
                                      style={{position: 'relative'}}>LAST NEWS</span>
                    </div>

                    <div className='row container-fluid'>
                        <Chip l={'80'} t={'85'} w={'60'} rotate={'40'}/>
                    </div>


                    <div className="row justify-content-center align-middle  text-center" >
                        <div className="col-10" >
                            {Object.keys(this.state.news).slice(-1).reverse().map((someId) => (<>
                                <Link className='col-12 my-4' onClick={() => {
                                    window.scrollTo(0, 0);
                                }} to={'/news/' + (Number(someId) + 1)}>
                                    <Card className=" text-white m-4 rounded-5 shadow">
                                        <Card.Img
                                            src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[Number(someId)].imgName}
                                            alt="Card image" className=" rounded-5"/>
                                        <Card.ImgOverlay>
                                            <Card.Title style={{position: 'absolute', bottom: '10%'}}
                                                        className="text-white text-start fs-2 ttt px-4">
                                                {this.state.news[Number(someId)].die == 'off' ? <></> : <>
                                                    {this.state.news[Number(someId)].die !== 'none' ? <>
                                                        <div className='p-2' style={{
                                                            background: this.state.news[Number(someId)].die,
                                                            borderRadius: '15px'
                                                        }}>
                                                                                                <span
                                                                                                    className='mx-3 text-start proxeet-pc-header'
                                                                                                    style={{
                                                                                                        color: "#FFFFFF",
                                                                                                        fontSize: '36px'
                                                                                                    }}
                                                                                                    dangerouslySetInnerHTML={{__html: this.state.news[Number(someId)].header}}/>
                                                        </div>
                                                        <Card.Text
                                                            style={{
                                                                position: 'absolute', bottom: '1%'
                                                            }}
                                                            className="text-white text-start ttt my-3 px-4"
                                                        >
                                                            <h5
                                                                className='text-start px-3 p-2 br-15'
                                                                style={{
                                                                    background: this.state.news[Number(someId)].die,
                                                                    color: '#FFFFFF'
                                                                }}
                                                                dangerouslySetInnerHTML={{__html: this.state.news[Number(someId)].date}}
                                                            />
                                                        </Card.Text>
                                                    </> : <>
                                                        <div className='p-2' style={{
                                                            background: this.state.news[Number(someId)].die,
                                                            borderRadius: '15px'
                                                        }}>
                                                                                            <span
                                                                                                className='mx-3 text-start proxeet-pc-header'
                                                                                                style={{
                                                                                                    color: "#FFFFFF",
                                                                                                    fontSize: '36px'
                                                                                                }}
                                                                                                dangerouslySetInnerHTML={{__html: this.state.news[Number(someId)].header}}/>
                                                        </div>
                                                        <Card.Text style={{
                                                            position: 'absolute', bottom: '1%'
                                                        }}
                                                                   className="text-white text-start ttt my-4 px-4">
                                                            <h5 className='text-start'
                                                                style={{color: '#FFFFFF'}}
                                                                dangerouslySetInnerHTML={{__html: this.state.news[Number(someId)].date}}/>
                                                        </Card.Text>

                                                    </>

                                                    }
                                                </>}


                                            </Card.Title>


                                        </Card.ImgOverlay>

                                    </Card>
                                </Link>
                            </>))}
                        </div>
                    </div>


                    <div className="row justify-content-center align-middle  text-md-center">
                        <div className="col-10">
                        <CardGroup>
                        {
                            Object.keys(this.state.news).reverse().slice(0, 2).map((someId) => (
                                <Link className='col-6' onClick={() => {
                                    window.scrollTo(0, 0);
                                }} to={'/news/' + (Number(someId))}>
                                    <Card className="m-4 border border-1 shadow br-30 d-flex flex-column">
                                        <div style={{ height: '35vh', overflow: 'hidden', borderRadius: '30px 30px 0 0' }}>
                                            <Card.Img className='img-fluid' variant="top"
                                                    src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[Number(someId) - 1].imgName}
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                        </div>
                                        <Card.Body className='mx-4'>
                                            <Card.Title style={{ color: '#152F4E', height: '10vh', display: 'flex', alignItems: 'center' }}>
                                                <p className='text-start f-docker' style={{
                                                    color: "#152F4E", lineHeight: '', fontSize: '16px'
                                                }}
                                                dangerouslySetInnerHTML={{ __html: this.state.news[Number(someId) - 1].header }} />
                                            </Card.Title>
                                            <small className="text-muted">
                                                <h5 className='text-start' style={{ color: '#95BBDB' }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.news[Number(someId) - 1].date }} />
                                            </small>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            ))
                        }
                    </CardGroup>

                        </div>
                    </div>


                    <div className="row justify-content-center align-middle  text-md-center">


                        <div className="col-10 " style={{position: 'relative', padding: '0'}}>
                            <Chip l={'-15'} t={'30'} w={'25'} rotate={'40'}/>

                            <Chip l={'-8'} t={'100'} w={'15'} rotate={'60'}/>

                            <CardGroup>

                                {Object.keys(this.state.news).reverse().slice(0, 3).map((someId) => (

                                    <>
                                        <Link className='col-4' onClick={() => {
                                            window.scrollTo(0, 0);
                                        }} to={'/news/' + (Number(someId) - 2)}>
                                            <Card className="m-4 border border-1 shadow br-30 d-flex flex-column">
                                                <div style={{ height: '30vh', overflow: 'hidden', borderRadius: '30px 30px 0 0' }}>
                                                    <Card.Img variant="top"
                                                          src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[Number(someId) - 3].imgName}
                                                          style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                </div>

                                                <Card.Body className='mx-4'>
                                                    <Card.Title
                                                        style={{color: '#152F4E', height: '10vh'}}><span
                                                        className='text-start proxeet-pc-header' style={{
                                                        color: "#152F4E", lineHeight: '8px', fontSize: '13px'
                                                    }}
                                                        dangerouslySetInnerHTML={{__html: this.state.news[Number(someId) - 3].header}}/></Card.Title>
                                                    <small className="text-muted"><h5 className='text-start'
                                                                                      style={{color: '#95BBDB'}}
                                                                                      dangerouslySetInnerHTML={{__html: this.state.news[Number(someId) - 3].date}}/>
                                                    </small>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </>))}

                            </CardGroup>
                        </div>
                    </div>


                </div>
            </>}
        </>);
    }
}


export default withGetScreen(LastNews);
