

import arrowR from '../img/arrowR.svg';

import DarkArrow from '../img/DarkArrow.svg';


/* ----------blue-----------------*/

export function BlueNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#95BBDB" , width: '30px', height: '30px', borderRadius: '50%', right: '-30px' }}
      onClick={onClick}
    ><img alt='' style={{ marginTop: '5px', top: '5px', height: '20px', width: '15px' }} src={arrowR} /></div>
  );
}

export function BluePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#95BBDB", width: '30px', height: '30px', borderRadius: '50%', left: '-30px' }}
      onClick={onClick}
    ><img alt='' style={{ marginTop: '5px', top: '5px', height: '20px', width: '15px' , transform: 'rotate(180deg)'  }} src={arrowR} /></div>
  );
}

/*--------------------------*/


/* ----------#152F4E--------------*/

export function DarkBlueNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#FFFFFF" , width: '30px', height: '30px', borderRadius: '50%', right: '-30px' }}
      onClick={onClick}
    ><img alt='' style={{ marginTop: '5px', top: '5px', height: '20px', width: '15px' , transform: 'rotate(180deg)'  }} src={DarkArrow} /></div>
  );
}

export function DarkBluePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#FFFFFF", width: '30px', height: '30px', borderRadius: '50%', left: '-30px' }}
      onClick={onClick}
    ><img alt='' style={{ marginTop: '5px', top: '5px', height: '20px', width: '15px'}} src={DarkArrow} /></div>
  );
}

/*--------------------------*/



export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#FF7E33" , width: '30px', height: '30px', borderRadius: '50%', right: '-30px' }}
      onClick={onClick}
    ><img alt='' style={{ marginTop: '5px', top: '5px', height: '20px', width: '15px' }} src={arrowR} /></div>
  );
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#FF7E33", width: '30px', height: '30px', borderRadius: '50%', left: '-30px' }}
      onClick={onClick}
    ><img alt='' style={{ marginTop: '5px', top: '5px', height: '20px', width: '15px', transform: 'rotate(180deg)' }} src={arrowR} /></div>
  );
}


export function BigNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#FF7E33", width: '70px', height: '70px', borderRadius: '50%', right: '-70px' }}
      onClick={onClick}
    ><img alt='' style={{ marginTop: '10px', marginLeft: '5px', height: '50px', width: '20px' }} src={arrowR} /></div>
  );
}

export function BigPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#FF7E33", width: '70px', height: '70px', borderRadius: '50%', left: '-70px' }}
      onClick={onClick}
    ><img alt='' style={{ marginTop: '10px', marginLeft: '0px', height: '50px', width: '20px', transform: 'rotate(180deg)' }} src={arrowR} /></div>
  );
}
