import React from 'react';
import { Chip } from './chip';
import { withGetScreen } from 'react-getscreen';

class Support extends React.Component {

    constructor(props) {
        super(props);


        this.props.callSupport();

        this.jsonGet();

    }




    state = {



        subHeader: `<p className='my-3'>We’re here to help. Get up to date information about your Proxeet products including datasheets, manuals, video tutorials and much more</p> `,
        mainContent: `
                        <ol className='' style={{ color: '#154F3E' }} >
                        <li><p class='text-start'>You can get started from choosing a product name in a MANUAL section and finding information you need</p></li>
                        <li><p class='text-start'>If you have purchased your device from one of our distributors, please contact them first</p></li>
                        <li><p class='text-start'>Check the FAQ section. Maybe the answer for your question is already there</p></li>
                        <li><p class='text-start'>If you want to contact a support team, make sure that your problem is not described in the Manual or FAQ section</p> </li>
                        <li><p class='text-start'>If not, visit the Contact support team section and choose the most convenient way to contact us </p></li>
                        </ol>
                    `,


    }


    jsonGet = async () => {





    }

    textHendler = (e) => {

        let index = e.target.getAttribute("data-index");


        this.setState({ curientText: this.state.text[index - 1] });


    }

    render() {


        if (this.props.isMobile()) return (

            <>
                <div className='container-fluid position-relative mt-4' >


                    {<Chip l={'80'} t={'80'} w={'15'} rotate={'50'} />}



                    <div className="row justify-content-center align-middle pt-3 text-center">


                        <div className='col-12  my-1'>

                            <span className='ttt ' style={{ fontSize: '28px', color: '#152F4E' }} >PRO</span>
                            <span className='chip' style={{ fontSize: '28px', color: '#FF7E33' }}>X </span>
                            <span className='ttt ' style={{ fontSize: '28px', color: '#152F4E' }} >EET PRODUCT SUPPORT SERVICE</span>

                        </div>

                        <div className='col-12 mt-4'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.subHeader }} />
                        </div>



                        <div className='col-10 my-3'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.mainContent }} />
                        </div>
                    </div>
                </div>





            </>

        );


        if (this.props.isTablet()) return (

            <>
                <div className="container-fluid">


                    <div className="row justify-content-center align-middle pt-3 text-center">


                        <div className='col-12 p-1 my-1' style={{ height: '10vh' }}>

                            <span className='ttt2 ' style={{ fontSize: '4vw' }} >PRO</span>
                            <span className='chip' style={{ fontSize: '4vw', color: '#FF7E33' }}>X </span>
                            <span className='ttt2 ' style={{ fontSize: '4vw' }} >EET PRODUCT SUPPORT SERVICE</span>

                        </div>

                        <div className='col-12 mt-4'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.subHeader }} />
                        </div>

                        <div className='col-10 my-3'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.mainContent }} />
                        </div>
                    </div>


                </div>





            </>

        );

        return (
            <>
                <div className="container-fluid my-4">


                    <div className="row justify-content-center align-middle pt-3 text-md-center">

                        <div className='col-12 p-1' style={{ height: '10vh' }}>

                            <span className='ttt2 ' style={{ fontSize: '3vw' }} >PRO</span>
                            <span className='chip' style={{ fontSize: '3vw', color: '#FF7E33' }}>X </span>
                            <span className='ttt2 ' style={{ fontSize: '3vw' }} >EET PRODUCT SUPPORT SERVICE</span>

                        </div>

                        <div className='col-12 mt-4'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.subHeader }} />
                        </div>

                        <div className='col-10 mt-4'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.mainContent }} />
                        </div>
                    </div>


                </div>
            </>
        );
    }
}


export default withGetScreen(Support);