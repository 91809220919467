import React from 'react';
import logo from '../img/crab.png';

export class Chip extends React.Component {

    constructor(props) {
        super(props);



    }


    render() {


        return (



            <img

                className='d-block d-md-none d-xl-block'
                alt=''
                style={{
                    position: 'absolute',
                    left: this.props.l + '%',
                    top: this.props.t + '%',
                    bottom: '0',
                    zIndex: '-120',
                    width: Number(this.props.w) + 'vh',
                    transform: 'rotate(' + Number(this.props.rotate) + 'deg)',

                }} src={logo}></img>






        );
    }
}