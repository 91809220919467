import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from 'axios';
import { SearchRender } from '../search/searchRender';
import { withGetScreen } from 'react-getscreen';

class NewsRoom extends React.Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.jsonGet();


    }



    state = {

        id: [],
        item: [],
        SearchList: {},
        SearchStr: '',
        SearchResult: {},
        toggleBtnDown: '↓',
        toggleBtnUp: '↑',
        toggleBtnVal: false,


        newsRoom: {},


    }

    jsonGet = async () => {

        let newsRoomData = new FormData();
        newsRoomData.append('table', 'newsRoom');



        const newsRoom = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: newsRoomData });

        const newsRoomResp = await newsRoom.data;

        this.setState({ newsRoom: newsRoomResp });


    }




    handleSubmit(event) {

        event.preventDefault();
    }







    setNewsListThemes = (e) => {

        this.props.newsSearchStringThemesSet(e.target.getAttribute("data-index"));

    }

    searchHendler = (e) => {



        this.props.newsSearchStringSet(e.target.value);


    }



    styles = {
        myStyleClassName: {
            padding: '16px 0px 16px 0px',
            '& a': {
                textDecoration: 'none',
                color: '#0000ee',
            },
            '& a:hover': {
                textDecoration: 'underline',
            },
        },
        myButtonClass: {
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    };


    render() {


        if (this.props.isMobile()) return (

            <>

                <style type="text/css">
                    {`
                                
                                .custitem { 
                                    
                                    color: #152F4E;
                                }
                                
                                .custitem:hover { 
                                
                                    text-decoration: underline; 
                                    color: #152F4E;
                                }
        
                                
        
                                
                            `}
                </style>
                {Object.keys(this.state.newsRoom).length !== 0 &&


                    <>
                        <Navbar bg="light" expand="lg">
                            <Container fluid>
                                <Navbar.Brand className='ttt' style={{ color: '#00477A' }} >NEWSROOM</Navbar.Brand>
                                <Navbar.Toggle aria-controls="navbarScroll" />
                                <Navbar.Collapse id="navbarScroll">
                                    <Nav
                                        className="me-auto my-2 my-lg-0"
                                        style={{ maxHeight: '100px' }}
                                        navbarScroll
                                    >

                                        {
                                            Object.keys(this.state.newsRoom).map((someId) => (


                                                <p key={someId.toString() - 1} onClick={this.setNewsListThemes} style={{ color: '#00477A' }} data-index={this.state.newsRoom[someId].id} className='col-4 btn my-3' >{this.state.newsRoom[someId].item}</p>


                                            ))
                                        }

                                    </Nav>
                                    <Form onSubmit={this.handleSubmit} className="d-flex">
                                        <span className="input-group-text bg-white " style={{ borderRight: 'none', borderRadius: '30px 0 0 30px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                className="bi bi-search" viewBox="0 0 16 16">
                                                <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                            </svg>
                                        </span>
                                        <Form.Control

                                            style={{ borderLeft: 'none', borderRadius: '0 30px 30px 0' }}
                                            type="search"
                                            placeholder="Search in Newsroom"
                                            className="me-2 "
                                            aria-label="Search"
                                            onChange={this.searchHendler}
                                        />

                                    </Form>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </>
                }
                <SearchRender DataSet={this.state.SearchResult} />

            </>
        );

        if (this.props.isTablet()) return (


            <>

                <style type="text/css">
                    {`
                                
                                .custitem { 
                                    
                                    color: #152F4E;
                                }
                                
                                .custitem:hover { 
                                
                                    text-decoration: underline; 
                                    color: #152F4E;
                                }
        
                                
        
                                
                            `}
                </style>
                {Object.keys(this.state.newsRoom).length !== 0 &&


                    <>
                        <Navbar bg="light" expand="lg" className='mb-4'>
                            <Container fluid>
                                <Navbar.Brand className='ttt' style={{ color: '#00477A' }} >NEWSROOM</Navbar.Brand>

                                <Navbar.Toggle aria-controls="navbarScroll" className='border-0'>
                                    <button onClick={() => { this.setState({ togleBtnVal: !this.state.toggleBtnVal }) }} className='tb' style={{ background: '#F8F9FA', border: '0' }}>Popular Topics <span className='' style={{ fontSize: '30px' }}>{this.state.toggleBtnVal ? this.state.toggleBtnUp : this.state.toggleBtnDown}</span></button>

                                </Navbar.Toggle>
                                <Navbar.Collapse id="navbarScroll">
                                    <Nav
                                        className="me-auto my-2 my-lg-0"
                                        style={{ maxHeight: '100px' }}
                                        navbarScroll
                                    >

                                        {
                                            Object.keys(this.state.newsRoom).map((someId) => (

                                                <p key={someId.toString() - 1} onClick={this.setNewsListThemes} style={{ color: '#00477A' }} data-index={this.state.newsRoom[someId].id} className='col-4 btn my-3' >
                                                    {this.state.newsRoom[someId].item}
                                                </p>


                                            ))
                                        }

                                    </Nav>
                                    <Form onSubmit={this.handleSubmit} className="d-flex">
                                        <span className="input-group-text bg-white " style={{ borderRight: 'none', borderRadius: '30px 0 0 30px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                className="bi bi-search" viewBox="0 0 16 16">
                                                <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                            </svg>
                                        </span>
                                        <Form.Control

                                            style={{ borderLeft: 'none', borderRadius: '0 30px 30px 0' }}
                                            type="search"
                                            placeholder="Search in Newsroom"
                                            className="me-2 "
                                            aria-label="Search"
                                            onChange={this.searchHendler}
                                        />

                                    </Form>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </>
                }

                <SearchRender DataSet={this.state.SearchResult} />




            </>




        );


        return (
            <>

                <style type="text/css">
                    {`
                        
                        .custitem { 
                            
                            color: #152F4E;
                        }
                        
                        .custitem:hover { 
                        
                            text-decoration: underline; 
                            color: #152F4E;
                        }

                        

                        
                    `}
                </style>
                {Object.keys(this.state.newsRoom).length !== 0 &&


                    <>

                        <Navbar bg="light" expand="false">
                            <Container fluid>
                                <Navbar.Brand className='ttt' style={{ color: '#00477A' }} href="/news">NEWSROOM</Navbar.Brand>
                                <Form onSubmit={this.handleSubmit} className="d-flex">
                                    <span className="input-group-text bg-white " style={{ borderRight: 'none', borderRadius: '30px 0 0 30px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            className="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </span>
                                    <Form.Control

                                        style={{ borderLeft: 'none', borderRadius: '0 30px 30px 0' }}
                                        type="search"
                                        placeholder="Search in Newsroom"
                                        className="me-2 "
                                        aria-label="Search"
                                        onChange={this.searchHendler}
                                    />

                                </Form>
                                <Navbar.Toggle className='border-0'>
                                    <button onClick={() => { this.setState({ togleBtnVal: !this.state.toggleBtnVal }) }} className='tb' style={{ background: '#F8F9FA', border: '0' }}>Popular Topics <span className='' style={{ fontSize: '30px' }}>{this.state.toggleBtnVal ? this.state.toggleBtnUp : this.state.toggleBtnDown}</span></button>

                                </Navbar.Toggle>
                                <Navbar.Collapse>
                                    <Nav
                                        className=" my-2"
                                        style={{ overflowX: 'hidden' }}
                                        navbarScroll
                                    >
                                        <div className='row  justify-content-start align-middle  text-center my-4'>
                                            {
                                                Object.keys(this.state.newsRoom).map((someId) => (

                                                    <button
                                                        key={someId.toString() - 1}
                                                        onClick={this.setNewsListThemes}
                                                        style={{ color: '#00477A' }}
                                                        data-index={this.state.newsRoom[someId].item}
                                                        className='col-4 btn my-3' >

                                                        {this.state.newsRoom[someId].item}

                                                    </button>


                                                ))
                                            }
                                        </div>

                                    </Nav>

                                </Navbar.Collapse>
                            </Container>
                        </Navbar>

                    </>
                }

                <SearchRender DataSet={this.state.SearchResult} />
            </>
        );
    }
}
export default withGetScreen(NewsRoom);