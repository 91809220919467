import React from 'react';
import axios from 'axios';
import pdf from '../img/pdf.png';
import elips from '../img/ellipse.svg';
import gear from '../img/orangeGear.svg';
import 'react-medium-image-zoom/dist/styles.css';
import arrowR from '../img/arrowR.svg';
import Accordion from 'react-bootstrap/Accordion';
import {Chip} from './chip';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import {SampleNextArrow, SamplePrevArrow} from './arrow';
import Question from './question';
import Slider from "react-slick";
import {withGetScreen} from 'react-getscreen';
import {Button} from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip';

import buyEbay from "../img/marketplaces/buyEbay.png";

class ProductsCard extends React.Component {

    constructor(props) {
        super(props);

        this.props.callProducts();
        this.jsonGet();

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

    }


    state = {

        productCard: {},
        modalImg: false,
        basicModal: false,
        bigImg: 1,


    }


    jsonGet = async () => {

        const formData = new FormData();

        formData.append('id', this.props.productID);
        formData.append('table', 'ProductCard');


        const api_call = await axios.post(process.env.REACT_APP_PROXEET_FULL_API, formData);

        const data = await api_call.data;

        let img_arr = [];
        for (let i = 1; i <= data[0].imgCount; i++) {

            img_arr.push(i);
        }

        data[0].imgCount = img_arr;


        this.setState({productCard: data});


    }


    modalChange = () => {


        this.setState({modalImg: !this.state.modalImg});


    }


    buyClick = () => {

        this.setState({basicModal: !this.state.basicModal})

        this.clickCounter("buy");

    }

    buyOnClick = (e) => {

        this.setState({basicModal: !this.state.basicModal});

        let index = e.target.getAttribute("data-index");

        this.setState({selectedProduct: index});


    }


    clickCounter = (e) => {


        let index;

        if (e === 'buy') {

            index = 'buy';
        } else {
            index = e.target.getAttribute("data-index");
        }


        let prodName;

        if (this.state.ProductName.toLowerCase().includes('passive')) {
            prodName = 'passive';
        } else if (this.state.ProductName.toLowerCase().includes('active')) {

            prodName = 'active';

        }


        let data = new FormData();
        data.append('product', prodName);
        data.append('action', index);


        axios.post(process.env.REACT_APP_CLICK_COUNTER, data);


    }


    imgSet = (e) => {

        let index = e.target.getAttribute("data-index");
        this.setState({bigImg: index});
    }


    next() {

        let max = this.state.productCard[0].imgCount.length;

        let curientImg = this.state.bigImg;

        if (curientImg === max) {

            curientImg = 1;
        } else {

            curientImg++;

        }


        this.setState({bigImg: curientImg});

        this.slider.slickNext();


    }

    previous() {


        let max = this.state.productCard[0].imgCount.length;


        let curientImg = this.state.bigImg;


        if (curientImg === 1) {

            curientImg = max;
        } else {

            curientImg--;

        }


        this.setState({bigImg: curientImg});

        this.slider.slickPrev();

    }


    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Injector is compatible with this battery
        </Tooltip>
    );


    render() {


        const settingsMob = {


            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow/>,
            prevArrow: <SamplePrevArrow/>,
        };

        const settingsPC = {


            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,

        };


        if (this.props.isMobile()) return (

            <>
                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{border: '0'}}>
                        <CloseButton onClick={() => this.setState({basicModal: !this.state.basicModal})}/>

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} modal={false}
                                  mod={'Leave your details and our Sales team will contact you within 1 working day'}
                                  sendedText={'Thank you! Your message sent successfully!'}/>
                    </Modal.Body>

                </Modal>

                {Object.keys(this.state.productCard).length !== 0 &&


                    <>

                        <div className='container-fluid' style={{marginBottom: '10%', position: 'relative'}}>

                            <Chip l={'-15'} t={'95'} w={'20'} rotate={'15'}/>


                            <div className="row justify-content-center align-middle align-items-center text-center">
                                <div className="col-10  text-center border-0">
                                    <Slider className='row'  {...settingsMob}>
                                        {

                                            this.state.productCard[0].imgCount.map((Item) => (
                                                <div className='col-10 mt-4'>
                                                    <img alt='' className='img-fluid' onClick={this.imgSet}
                                                         data-index={Item}
                                                         src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/md/' + Item + '.png'}/>
                                                </div>
                                            ))
                                        }
                                    </Slider>


                                    <div className="row justify-content-center align-middle  text-center">
                                        <div className='col-12 my-3 border-0'><span className='proxeet-pc-header'
                                                                                    style={{fontSize: '22px'}}>{this.state.productCard[0].ProductName}</span>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-md-center">
                                        <div className='col-6 my-2'>
                                            <a href={process.env.REACT_APP_PDF_DIR + this.state.productCard[0].pdfLink + '.pdf'}
                                               target="_blank" rel="noreferrer"><img alt='' className='img-fluid'
                                                                                     style={{width: '30px'}} src={pdf}/>
                                                <br/>
                                                <span style={{fontSize: '10px', color: '#00477a'}} className='ttt'>Download Datasheet</span>
                                            </a>
                                        </div>
                                        <div className='col-6 my-2'>
                                            <a href={process.env.REACT_APP_MANUAL_DIR + this.state.productCard[0].manualLink + '.pdf'}
                                               target="_blank" rel="noreferrer">
                                                <img alt='' className='img-fluid' style={{width: '30px'}} src={pdf}/>
                                                <br/>
                                                <span style={{fontSize: '10px', color: '#00477a'}} className='ttt'>Download Manual</span>
                                            </a>
                                        </div>
                                    </div>


                                    <div className="row justify-content-left">
                                        <div className='col-12 mx-3' style={{background: '#F8F8F8'}}>
                                            <ul className="list-group text-start border-0">
                                                {

                                                    this.state.productCard[0].spec.split(";").map((CharacterItem) => (

                                                        <li className="list-group-item" style={{
                                                            fontSize: '0.9rem',
                                                            border: '0',
                                                            background: '#F8F8F8'
                                                        }}>
                                                            <img alt='' style={{paddingRight: '2%'}}
                                                                 src={elips}/>{CharacterItem}
                                                        </li>

                                                    ))
                                                }
                                            </ul>
                                        </div>

                                    </div>

                                    <div className="row justify-content-center align-middle  text-center mt-4">
                                        <span className='proxeet-pc-header'
                                              style={{fontSize: '22px'}}>STOCK LEVEL: <span
                                            style={{color: '#95BBDB'}}>{this.state.productCard[0].stock}</span></span>
                                    </div>
                                    <div className="row justify-content-center align-middle  text-center my-3">
                                        <span className='proxeet-pc-header' style={{fontSize: '22px'}}>PRICE:
                                            <span style={{color: '#FF7E33'}}>€{this.state.productCard[0].price}*</span>
                                            <span style={{color: '#7b7979', fontSize: '0.6rem'}}>price without fees and taxes</span>
                                        </span>
                                    </div>
                                    <div className="row justify-content-start align-middle  text-start">
                                        <div className="col-12 mb-3">
                                            <Accordion bsPrefix='x'>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <button className="btn br-30"
                                                                style={{width: '18rem', border: '2px solid #152F4E'}}>
                                                            <img alt='' src={gear} width="30"/> <span
                                                            className='open-sans'>Battery compatibility </span><span
                                                            className='open-sans ' style={{fontSize: '0.8rem'}}>▼</span>
                                                        </button>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className="list-group text-start my-3"
                                                            style={{border: '0'}}>
                                                            {
                                                                this.state.productCard[0].ShortCharacter.split(";").map((CharacterItem, index) => (

                                                                    <li key={index} className="list-group-item" style={{
                                                                        fontSize: '0.9rem',
                                                                        border: '0',
                                                                        background: '#F8F8F8'
                                                                    }}>
                                                                        <img alt='' style={{paddingRight: '2%'}}
                                                                             src={elips}/>{CharacterItem}
                                                                        <button className=" mx-2" style={{
                                                                            color: "#FFFFFF",
                                                                            border: "0",
                                                                            borderRadius: "20px",
                                                                            background: this.state.productCard[0].dieColor.split(";")[index]
                                                                        }}>
                                                                            <span className="mx-2"
                                                                                  style={{}}>{this.state.productCard[0].die.split(";")[index]}</span>
                                                                        </button>
                                                                    </li>


                                                                ))
                                                            }
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                            </Accordion>

                                        </div>
                                    </div>


                                    <div className="row justify-content-center">
                                        <div className="col-12 my-3">
                                            <button data-index="buy" onClick={this.buyClick}
                                                    className="btn btn-proxeet-mob-w17">Buy
                                            </button>
                                        </div>
                                    </div>



                                    <div className="row justify-content-center">
                                        {this.state.productCard[0].amazonLink !== '' ?
                                            <div className="col-12 mt-3">
                                                <a href={this.state.productCard[0].amazonLink}>
                                                    <Button onClick={this.clickCounter} data-index="amazon"
                                                            className="btn-amazon btn-amazon-com"/>
                                                </a>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>

                                    <div className="row justify-content-center">
                                        {this.state.productCard[0].alibabaLink !== '' ?
                                            <div className="col-12 mt-3">
                                                <a href={this.state.productCard[0].alibabaLink}>
                                                    <Button onClick={this.clickCounter} data-index="amazon"
                                                            className="btn-amazon btn-alibaba"/>
                                                </a>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    <div className="row justify-content-center">
                                        {this.state.productCard[0].ebayLink !== '' ?
                                            <div className="col-12 mt-3">
                                                <a href={this.state.productCard[0].ebayLink}>
                                                    <Button onClick={this.clickCounter}
                                                            data-index="amazon"
                                                            className="btn-amazon btn-ebay"/>
                                                </a>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>


                                </div>

                            </div>


                        </div>


                    </>

                }
                {Object.keys(this.state.productCard).length !== 0 &&
                    <>
                        {this.state.youTubeLink !== null &&
                            <div className="row justify-content-center align-middle  text-center">
                                <div className='col-12 my-4'
                                     dangerouslySetInnerHTML={{__html: `${this.state.productCard[0].youTubeLink}`}}/>


                            </div>

                        }
                    </>
                }
            </>

        );


        if (this.props.isTablet()) return (


            <>
                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{border: '0'}}>
                        <CloseButton onClick={() => this.setState({basicModal: !this.state.basicModal})}/>

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} modal={false}
                                  mod={'Leave your details and our Sales team will contact you within 1 working day'}
                                  sendedText={'Thank you! Your message sent successfully!'}/>
                    </Modal.Body>

                </Modal>


                {Object.keys(this.state.productCard).length !== 0 &&


                    <>
                        <div className='container-fluid' style={{marginBottom: '10%', position: 'relative'}}>

                            <Chip l={'-15'} t={'95'} w={'20'} rotate={'15'}/>


                            <div className="row justify-content-center align-middle align-items-center text-center">
                                <div className="col-10  text-center">
                                    <Slider className='row'  {...settingsMob}>
                                        {

                                            this.state.productCard[0].imgCount.map((Item) => (
                                                <div className='col-10 my-4'>
                                                    <img alt='' className='img-fluid' style={{}} onClick={this.imgSet}
                                                         data-index={Item}
                                                         src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/md/' + Item + '.png'}/>
                                                </div>
                                            ))
                                        }
                                    </Slider>


                                    <div className="row justify-content-center align-middle  text-center">
                                        <div className='col-12 my-3'><span className='proxeet-pc-header'
                                                                           style={{fontSize: '22px'}}>{this.state.productCard[0].ProductName}</span>
                                        </div>
                                    </div>
                                    <div className="row justify-content-left">
                                        <div className='col-12 mx-3' style={{background: '#F8F8F8'}}>
                                            <ul className="list-group text-start" style={{border: '0'}}>
                                                {

                                                    this.state.productCard[0].spec.split(";").map((CharacterItem) => (

                                                        <li className="list-group-item"
                                                            style={{border: '0', background: '#F8F8F8'}}>
                                                            <img alt='' style={{paddingRight: '2%'}}
                                                                 src={elips}/>{CharacterItem}
                                                        </li>

                                                    ))

                                                }
                                            </ul>
                                        </div>

                                    </div>

                                    <div className="row justify-content-center align-middle  text-center my-3">
                                        <span className='proxeet-pc-header' style={{fontSize: '22px', padding: '2%'}}>STOCK LEVEL: <span
                                            style={{color: '#95BBDB'}}>{this.state.productCard[0].stock}</span></span>
                                    </div>
                                    <div className="row justify-content-center align-middle  text-center my-3">
                                        <span className='proxeet-pc-header'
                                              style={{fontSize: '22px', paddingBottom: '2%'}}>PRICE:
                                            <span style={{color: '#FF7E33'}}>€{this.state.productCard[0].price}*</span>
                                            <span style={{color: '#7b7979', fontSize: '0.8rem'}}>price without fees and taxes</span>
                                        </span>
                                    </div>
                                    <div className="row justify-content-center align-middle  text-center">

                                        <Accordion bsPrefix='x'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>

                                                    <button className="btn col-12 br-30"
                                                            style={{border: '2px solid #152F4E'}}>
                                                        <img alt='' src={gear} width="30"/> <span className='open-sans'>Battery compatibility </span><span
                                                        className='open-sans ' style={{fontSize: '0.8rem'}}>▼</span>
                                                    </button>

                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ul className="list-group text-start my-3" style={{border: '0'}}>
                                                        {


                                                            this.state.productCard[0].ShortCharacter.split(";").map((CharacterItem, index) => (

                                                                <li key={index} className="list-group-item"
                                                                    style={{border: '0', background: '#F8F8F8'}}>
                                                                    <img alt='' style={{paddingRight: '2%'}}
                                                                         src={elips}/>{CharacterItem}
                                                                    <button className=" mx-2" style={{
                                                                        color: "#FFFFFF",
                                                                        border: "0",
                                                                        borderRadius: "20px",
                                                                        background: this.state.productCard[0].dieColor.split(";")[index]
                                                                    }}>
                                                                        <span className="mx-2"
                                                                              style={{}}>{this.state.productCard[0].die.split(";")[index]}</span>
                                                                    </button>
                                                                </li>


                                                            ))

                                                        }
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                        <div className="row justify-content-center align-middle  text-center">
                                            {/*<div className="col-12">
                                    <a href={this.state.amazonLink}><button className="btn" type="button" style={{ width: '300px', height: '60px', border: '0px', color: 'white', backgroundColor: '#152F4E', borderRadius: '30px 30px 30px 30px' }}><span style={{ fontSize: '22px' }} className='ttt'>BY ON</span>
                                        <img className='mx-2 mt-2' src={amazon} height="34px" />
                                    </button></a>
                                                </div>*/}
                                        </div>


                                        <div className="row justify-content-center align-middle  text-center">
                                            <div className="col-12 mt-3">
                                                <button data-index="buy" onClick={this.buyClick}
                                                        className="btn btn-proxeet-w19-h4" type="button">
                                                    <span style={{fontSize: '20px'}} className='ttt'>Buy</span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row justify-content-center">
                                            {this.state.productCard[0].amazonLink !== '' ?
                                                <div className="col-12 mt-3">
                                                    <a href={this.state.productCard[0].amazonLink}>
                                                        <Button onClick={this.clickCounter} data-index="amazon"
                                                                className="btn-amazon btn-amazon-com"/>
                                                    </a>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>

                                        <div className="row justify-content-center">
                                            {this.state.productCard[0].alibabaLink !== '' ?
                                                <div className="col-12 mt-3">
                                                    <a href={this.state.productCard[0].alibabaLink}>
                                                        <Button onClick={this.clickCounter} data-index="amazon"
                                                                className="btn-amazon btn-alibaba"/>
                                                    </a>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <div className="row justify-content-center">
                                            {this.state.productCard[0].ebayLink !== '' ?
                                                <div className="col-12 mt-3">
                                                    <a href={this.state.productCard[0].ebayLink}>
                                                        <Button onClick={this.clickCounter}
                                                                data-index="amazon"
                                                                className="btn-amazon btn-ebay"/>
                                                    </a>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>


                                    </div>


                                </div>


                                <div className="row justify-content-center align-middle  text-center " style={{}}>

                                    {/*<div className="col-12">
                                <div className="input-group" style={{ margin: 'auto', width: '32vh', height: '7vh', borderRadius: '30px 30px 30px 30px' }}  >
                                    <button className="btn btn-outline-secondary" data-index={'-'} onClick={this.shopCardFieldHeandler} type="button" style={{ borderRadius: '30px 0 0 30px' }}>-</button>
                                    <input type="number" className="form-control" onChange={this.shopCardFieldChangeHeandler} value={this.state.shopCardField} placeholder="0" aria-label="Example text with two button addons" />
                                    <button className="btn btn-outline-secondary" data-index={'+'} onClick={this.shopCardFieldHeandler} type="button" style={{ borderRadius: '0 30px 30px 0' }}>+</button>
                                </div>
                        </div>*/}


                                </div>
                            </div>
                        </div>
                    </>
                }
                {Object.keys(this.state.productCard).length !== 0 &&
                    <>
                        {this.state.youTubeLink !== null &&
                            <div className="row justify-content-center align-middle  text-center">
                                <div className='col-12 my-4'
                                     dangerouslySetInnerHTML={{__html: `${this.state.productCard[0].youTubeLink}`}}/>


                            </div>

                        }
                    </>
                }

            </>
        );

        return (
            <>

                {this.state.modalImg === true &&


                    <Modal
                        show={this.state.modalImg}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header className='border-0'>
                            <CloseButton onClick={this.modalChange}/>

                        </Modal.Header>
                        <Modal.Body>
                            <img alt='' className='img-fluid' onClick={this.modalChange}
                                 src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/lg/' + this.state.bigImg + '.png'}/>
                        </Modal.Body>

                    </Modal>


                }

                <Modal style={{overflowY: 'auto'}}
                       show={this.state.basicModal}
                       size="lg"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Header className='border-0'>
                        <CloseButton onClick={() => this.setState({basicModal: !this.state.basicModal})}/>

                    </Modal.Header>
                    <Modal.Body>
                        <Question selectedProduct={this.state.selectedProduct} noChip={true}
                                  mod={'Leave your details and our Sales team will contact you within 1 working day'}
                                  sendedText={'Thank you! Your message sent successfully!'}/>
                    </Modal.Body>
                </Modal>


                {Object.keys(this.state.productCard).length !== 0 &&


                <>
                <div className="container-fluid" style={{paddingTop: '5%'}}>
                    <div className="row justify-content-center align-middle  text-center">
                        <div className='col-6 border-0' style={{paddingTop: '5%', paddingBottom: '1%'}}>
                            <div className="row justify-content-center align-middle  text-md-center">
                                <div className='col-8 d-flex  justify-content-center '
                                     style={{aspectRatio: '1/1'}}>

                                    <div className='align-self-center'>
                                        <img
                                            className='img-fluid'
                                            alt=''
                                            onClick={this.modalChange}
                                            src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/md/' + this.state.bigImg + '.png'}
                                        />
                                    </div>


                                    {

                                        this.state.productCard[0].imgCount.map((Item) => (
                                            <img
                                                key={Item}
                                                alt=''
                                                className='d-none'
                                                onClick={this.imgSet}
                                                data-index={Item}
                                                src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/md/' + Item + '.png'}
                                            />

                                        ))
                                    }
                                </div>
                            </div>
                            <div className='col-10 justify-content-center align-middle text-center'
                                 style={{paddingLeft: '15%', paddingBottom: '1%', border: '0'}}>
                                <div className="row justify-content-center align-middle  text-center">
                                    <div className='col-2'>
                                        <button
                                            className="button border-0 rounded-circle "
                                            style={{
                                                marginTop: '70%',
                                                background: '#FF7E33',
                                                width: '40px',
                                                height: '40px'
                                            }}
                                            onClick={this.previous}
                                        >

                                            <img
                                                onClick={this.previous}
                                                alt=''
                                                style={{
                                                    height: '30px',
                                                    width: '30px',
                                                    transform: 'rotate(180deg)'
                                                }}
                                                src={arrowR}
                                            />
                                        </button>

                                    </div>
                                    <Slider className='col-8' ref={c => (this.slider = c)} {...settingsPC}>
                                        {

                                            Object.keys(this.state.productCard[0].imgCount).map((Item) => (
                                                <div className='col-10 my-4'>
                                                    <img
                                                        key={Item}
                                                        alt=''
                                                        className='img-fluid'
                                                        onClick={this.imgSet}
                                                        data-index={Item}
                                                        src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/sm/' + (Number(Item) + 1) + '.png'}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                    <div className='col-2'>

                                        <button className="button border-0 rounded-circle" style={{
                                            marginTop: '70%',
                                            background: '#FF7E33',
                                            width: '40px',
                                            height: '40px'
                                        }} onClick={this.next}>
                                            <img alt='' style={{height: '30px', width: '30px'}} src={arrowR}/>
                                        </button>
                                    </div>

                                    {/*

                                        this.state.imgCount.map((Item) => (
                                            <div className='col-2 m-2' style={{ width: '80px' }}>
                                                <img className='img-fluid' onClick={this.imgSet} data-index={Item} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.imgDir + '/' + 'sm' + '/' + Item + '.png'} />
                                            </div>
                                        ))
                                        */}
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="row justify-content-center align-middle  text-md-center">
                                <div className='col-6'><span className='proxeet-pc-header'
                                                             style={{fontSize: '22px', paddingTop: '5%'}}
                                                             dangerouslySetInnerHTML={{__html: this.state.productCard[0].ProductName}}/>
                                </div>
                                <div className='col-3'>
                                    <a href={process.env.REACT_APP_PDF_DIR + this.state.productCard[0].pdfLink + '.pdf'}
                                       target="_blank" rel="noreferrer"><img alt='' style={{paddingTop: '3%'}}
                                                                             src={pdf}></img><br/><span
                                        style={{fontSize: '10px', color: '#00477a'}}
                                        className='ttt'>Download Datasheet</span></a>
                                </div>
                                <div className='col-3'>
                                    <a href={process.env.REACT_APP_MANUAL_DIR + this.state.productCard[0].manualLink + '.pdf'}
                                       target="_blank" rel="noreferrer"><img alt='' style={{paddingTop: '3%'}}
                                                                             src={pdf}></img><br/><span
                                        style={{fontSize: '10px', color: '#00477a'}}
                                        className='ttt'>Download Manual</span></a>
                                </div>
                            </div>
                            <div className="row justify-content-left">
                                <div className='col-11' style={{background: '#F8F8F8'}}>
                                    <ul className="list-group text-start border-0">
                                        {


                                            this.state.productCard[0].spec.split(";").map((CharacterItem) => (

                                                <li className="list-group-item"
                                                    style={{border: '0', background: '#F8F8F8'}}><img alt=''
                                                                                                      style={{paddingRight: '2%'}}
                                                                                                      src={elips}/>{CharacterItem}
                                                </li>


                                            ))

                                        }
                                    </ul>
                                </div>

                            </div>
                            <div className="row justify-content-center align-middle  text-start">
                                        <span className='proxeet-pc-header' style={{fontSize: '22px', padding: '2%'}}>STOCK LEVEL: <span
                                            style={{color: '#95BBDB'}}>{this.state.productCard[0].stock}</span></span>
                            </div>
                            <div className="row justify-content-center align-middle  text-start">
                                        <span className='proxeet-pc-header'
                                              style={{fontSize: '22px', paddingBottom: '2%'}}>
                                            PRICE:
                                            <span style={{color: '#FF7E33'}}>€{this.state.productCard[0].price}*</span>
                                            <span style={{color: '#7b7979', fontSize: '0.8rem'}}>price without fees and taxes</span>
                                        </span>
                            </div>
                            <div className="row justify-content-start align-middle  text-start">
                                {/*<div className="col-12 my-3">
                                    <button className="btn" style={{ width: '300px', border: '2px solid #152F4E', borderBottom: '0', borderRadius: '30px 30px 0 0' }}>
                                        <img alt='' src={gear} width="30" /> <span className='open-sans'>Battery compatibility</span>
                                    </button>

                                    <div className="" style={{ borderRadius: '0 0 30px 30px', border: '2px solid #154F4E', background: '#F8F8F8' }}>

                                        {/*

                                            this.state.ShortCharacter.map((CharacterItem, index) => (
                                                <div className='row justify-content-start align-middle text-center my-3'>
                                                    <div className='col-1 ' style={{ border: '0px solid red' }}>
                                                        <img className='img-fluid' alt='' style={{ width: '10px', height: '15px' }} src={elips} />
                                                    </div>

                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={this.renderTooltip}
                                                    >
                                                        <span className='col-3 mx-0' style={{ fontSize: '0.8rem', border: '0px solid red' }}>{CharacterItem}</span>
                                                    </OverlayTrigger>


                                                    <button className="col-2 mx-2" style={{ color: "#FFFFFF", border: "0", borderRadius: "20px", background: this.state.dieColor[index] }} >
                                                        <span className="mx-2" style={{ fontFamily: 'DOCKER' }} >{this.state.die[index]}</span>
                                                    </button>
                                                    <Button data-index={CharacterItem} onClick={this.buyOnClick} style={{ aspectRatio: '7/1.5', backgroundColor: '#FF7E33', border: '0', fontSize: '0.7rem', fontFamily: 'DOCKER' }} className="col-2  br-30" >BUY ON CLICK</Button>
                                                    <Button style={{ aspectRatio: '7/1.5', backgroundColor: '#152F4E', border: '0', borderRadius: '30px 0 0 30px', marginRight: '2px' }} data-index="amazon" className="col-2 ms-2 btn-amazon-all" ></Button>
                                                    {/* <Button style={{ aspectRatio: '7/1.5', backgroundColor: '#152F4E', borderRadius: '0 30px 30px 0', border: '0' }} data-index="amazon" className="col-1" ><span className='open-sans ' style={{ fontSize: '0.8rem' }} >▼</span></Button> */}
                                { /* <Dropdown className='col-1' style={{ aspectRatio: '7/1.5', backgroundColor: '#152F4E', borderRadius: '0 30px 30px 0', border: '0' }}  >
                                                        <Dropdown.Toggle style={{ backgroundColor: '#152F4E', border: '0' }} id="dropdown-basic">

                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ backgroundColor: '#152F4E', border: '0', borderRadius: '30px 0px 30px 30px ' }} >
                                                            <Button onClick={this.clickCounter} data-index="amazon" className="btn-amazon btn-amazon-com" />
                                                            <Button onClick={this.clickCounter} data-index="amazon" className="btn-amazon btn-amazon-de" />
                                                            <Button onClick={this.clickCounter} data-index="amazon" className="btn-amazon btn-amazon-uk" />


                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </div>

                                            ))

                                        */}

                                {/*     </div> */}


                                <Accordion bsPrefix='x'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <button className="mb-3 btn br-30"
                                                    style={{width: '300px', border: '2px solid #152F4E'}}>
                                                <img alt='' src={gear} width="30"/>
                                                <span className='open-sans'>Battery compatibility</span>
                                                <span className='open-sans '
                                                      style={{fontSize: '0.8rem'}}>▼</span>
                                            </button>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="list-group text-start my-3" style={{border: '0'}}>
                                                {

                                                    this.state.productCard[0].ShortCharacter.split(";").map((CharacterItem, index) => (
                                                        <>
                                                            <li className="list-group-item"
                                                                style={{border: '0', background: '#F8F8F8'}}>
                                                                <img alt='' style={{paddingRight: '2%'}}
                                                                     src={elips}/>{CharacterItem}
                                                                <button className=" mx-2" style={{
                                                                    color: "#FFFFFF",
                                                                    border: "0",
                                                                    borderRadius: "20px",
                                                                    background: this.state.productCard[0].dieColor.split(";")[index]
                                                                }}>
                                                                            <span className="mx-2" style={{}}>

                                                                                {this.state.productCard[0].die.split(";")[index]}
                                                                            </span>
                                                                </button>
                                                            </li>
                                                        </>

                                                    ))

                                                }
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>


                            </div>
                            <div className="row justify-content-left align-left  text-start">
                                <div className="col-6">
                                    <button data-index="buy" className="btn btn-proxeet-w19-h4" type="button"
                                            onClick={this.buyClick} style={{color: 'white'}}>
                                        <span style={{fontSize: '22px'}} className='ttt'>Buy</span></button>
                                </div>
                                <div className="col-3">
                                    {/*<div className="input-group mb-3">
                                        <button className="btn btn-outline-secondary" data-index={'-'} onClick={this.shopCardFieldHeandler} type="button" style={{ borderRadius: '30px 0 0 30px', height: '60px' }}>-</button>
                                        <input type="number" className="form-control" onChange={this.shopCardFieldChangeHeandler} value={this.state.shopCardField} placeholder="0" aria-label="Example text with two button addons" />
                                        <button className="btn btn-outline-secondary" data-index={'+'} onClick={this.shopCardFieldHeandler} type="button" style={{ borderRadius: '0 30px 30px 0', height: '60px' }}>+</button>
                                                </div>*/}
                                </div>


                            </div>
                            <div className="row text-start">
                                { this.state.productCard[0].amazonLink !== '' ?
                                <div className="col-6 mt-3">
                                    <a href={this.state.productCard[0].amazonLink}>
                                        <Button onClick={this.clickCounter} data-index="amazon"
                                                className="btn-amazon btn-amazon-com"/>
                                    </a>
                                </div>
                                    :
                                    <></>
                                }
                            </div>

                            <div className="row text-start">
                                        { this.state.productCard[0].alibabaLink !== '' ?
                                            <div className="col-6 mt-3">
                                                <a href={this.state.productCard[0].alibabaLink}>
                                                    <Button onClick={this.clickCounter} data-index="amazon"
                                                    className="btn-amazon btn-alibaba"/>
                                                </a>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                    <div className="row text-start">
                                        { this.state.productCard[0].ebayLink !== '' ?
                                        <div className="col-6 mt-3">
                                            <a href={this.state.productCard[0].ebayLink}>
                                                <Button onClick={this.clickCounter}
                                                        data-index="amazon"
                                                className="btn-amazon btn-ebay"/>
                                            </a>
                                        </div>
                                        :
                                        <></>
                                        }
                                    </div>

                </div>
                </div>
            {this.state.youTubeLink !== null &&
                <div className="row justify-content-center align-middle  text-center">
                <div className='col-12 my-4'
                dangerouslySetInnerHTML={{__html: `${this.state.productCard[0].youTubeLink}`}}>


            </div>
    </div>

    }
    </div>
    </>
    }
    </>
    )
        ;
    }
}


export default withGetScreen(ProductsCard);