import React from 'react';
import logo from '../img/logo-basic.png';


export default class Offer extends React.Component {

    constructor(props) {
        super(props);




    }

    state = {


    }

    render() {

        return (
            <>

<div className="container-fluid">

                    <div className="row justify-content-center align-middle  text-center " style={{ position: 'relative' }}> 
                    
                    <img className='img-fluid my-5' style={{ width: '220px' }} src={logo} alt='' />
                    
                    <span className='proxeet-pc-header'>GENERAL SUPPLY TERMS AND CONDITIONS</span>

                    <p className='text-end'>Last revision on July 25th, 2023</p>

                    <h5 className='my-3'>1. INTRODUCTION</h5>

                    
    
       
       <div className='row justify-content-center align-middle border oferta'>
            <p className='col-1 mx-0 text-center p-4'>1.1</p> 
            <p className='col-10 mx-0 text-start p-4'> These General Supply Terms and Conditions (the <span style={{ fontWeight: 'bold' }}>“Terms”</span>) govern the procedure of packaging and selling certain products (the “Products”) by Proxeet Limited (a limited liability company duly incorporated and conducting its activities under the laws of the Republic of Cyprus under the registration number ΗΕ 437925 and having its registered office at 61 Neofytou Nikolaidi, Yannis Court, 2nd floor, office 201, 8011, Paphos, Cyprus) (<span style={{ fontWeight: 'bold' }}>“Proxeet”</span>) to anyone wishing to purchase the Products (the <span style={{ fontWeight: 'bold' }}>“Buyer”</span>).</p> 
       </div>
       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 mx-0 text-center p-4'>1.2</p> 
            <p className='col-10 mx-0 text-start p-4'>Any Buyer wishing to purchase from Proxeet accepts the Terms by ordering the Products through Proxeet’s website (<a href='https://proxeet.com/'>https://proxeet.com/</a>) (the <span style={{ fontWeight: 'bold' }}>“Website”</span>).</p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 mx-0 text-center p-4'>1.3</p>
       <p className='col-10 mx-0 text-start p-4'>The Terms and all matters arising out of or relating to the Terms, are governed by, and construed in accordance with, the Laws of the Cyprus Republic.</p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 mx-0 text-center p-4'>1.4</p>
       <p className='col-10 mx-0 text-start p-4'>Any dispute, controversy or claim arising out of or relating to the Terms, or the breach, termination or invalidity thereof, are settled by arbitration in accordance with the Cyprus Center for Alternative Dispute Resolution Rules (A.D.R. Cyprus Center Ltd) which rules are deemed to be incorporated by reference into this clause. The seat, or legal place, of arbitration shall be the Republic of Cyprus. The governing law of the Terms is the substantive law of the republic of Cyprus.</p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 mx-0 text-center p-4'>1.5</p>
       <p className='col-10 mx-0 text-start p-4'>The Products and the prices depicted on the Website do not constitute an unilateral offer, but a mere invitation to treat.</p>
       </div>
       

       
    
   

    <h5 className='my-3'>2. THE PRODUCTS SALE AND DELIVERY</h5>
    
    <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.1
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer wishing to Purchase the Products must follow the below procedure:
       </p>
       </div>

    <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>2.1.1
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer orders the Products via the Website, e-mail, or over the phone (the “Order”).
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.1.2
       </p>
       <p className='col-10 text-start p-4 pb-0'>Having received the Buyer’s Order, Proxeet issues a pro-forma invoice (the “Proforma”) to the Buyer. The Proforma will specify the Products ordered by the Buyer, their quantity, their price including delivery costs and VAT.
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.1.3
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer fully (100%) pays the Proforma by transferring the amount specified in the Proforma to Proxeet’s banking account specified in the Proforma.
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.1.4
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer may also pay the Proforma by card, when applicable.
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.1.5
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer fully (100%) pays the Proforma within five (5) calendar days from the date on which the Buyer receives the Proforma.
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.1.6
       </p>
       <p className='col-10 text-start p-4 pb-0'>As soon as the Buyer’s payment is credited into Proxeet’s banking account, but not later than five (5) calendar days from the Buyer’s payment credit date, Proxeet places the Products along with commercial invoice(s) and other applicable documentation at the disposal of the Buyer at Proxeet’s facility in Limassol, Cyprus [EXW – Ex Works (Incoterms 2020)] (the “Facility”).
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.1.7
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer must take delivery of the Goods as soon as they have been placed at the disposal of the Buyer at Proxeet’s facility in Limassol, Cyprus, but not later than five (5) calendar days from the date of the Goods placement at the Buyer’s disposal.
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.1.8
       </p>
       <p className='col-10 text-start p-4 pb-0'>Delivery services can be arranged upon request and should be paid separately by the Buyer.

       </p>
       </div>



    <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.2
       </p>
       <p className='col-10 text-start p-4 pb-0'>Proxeet bears all risks of loss or damage to the Products until they have been placed at the disposal of the Buyer at the Facility.
       </p>
    </div>
    <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.3
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer bears all risks of loss or damage to the Products from the time they have been placed at the disposal of the Buyer at the Facility.
       </p>
    </div>
    <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.4
       </p>
       <p className='col-10 text-start p-4 pb-0'>It is exclusively up to the Buyer to contract or arrange at its own cost for the carriage of the Products from the Facility, where applicable.
       </p>
    </div>
    <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.5
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer must inspect the Products received and either accept or, only if any Products do not comply with the Proforma and the description available on the Website, reject the Products.
       </p>
    </div>
    <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.6
       </p>
       <p className='col-10 text-start p-4 pb-0'>With respect to any allegedly defected Products, the Buyer must:
       </p>
    </div>



    <div className='row justify-content-center align-middle border oferta'>
       
       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>2.6.1
       </p>
       <p className='col-10 text-start p-4 pb-0'>notify Proxeet, in writing, of any alleged claim or defect within five (5) calendar days of receiving the Products; and
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.6.2
       </p>
       <p className='col-10 text-start p-4 pb-0'>deliver, at its expense and risk of loss, such allegedly defected Products to the Facility for inspection and testing by Proxeet.
       </p>
       </div>
    </div>



    <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.7
       </p>
       <p className='col-10 text-start p-4 pb-0'>If Proxeet’s inspection and testing reveals, to Proxeet’s reasonable satisfaction, that the Products are defected and any such defect has not been caused or contributed to by any of the factors described in the provisions of Article 3.2 of the Terms, then Proxeet reimburses the Buyer for return costs and:
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>2.7.1
       </p>
       <p className='col-10 text-start p-4 pb-0'>repair or replace such defected Products; and
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.7.2
       </p>
       <p className='col-10 text-start p-4 pb-0'>deliver to the Buyer, at Proxeet’s expense, the repaired or replaced Products or, only at Proxeet’s option, Proxeet may refund to the Buyer such amount paid by the Buyer to Proxeet for such defected Products returned by the Buyer to Proxeet.
       </p>
       </div>
       


<div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.8
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Buyer has no right to return for repair, replacement, credit or refund any Products except as set forth in the Terms.
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.9
       </p>
       <p className='col-10 text-start p-4 pb-0'>All defects and nonconformities that are not so specified by the Buyer as described above, will be deemed waived by the Buyer, such Products should be deemed to have been accepted by the Buyer, and no attempted revocation of acceptance will be effective.
       </p>
       </div>


      <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>2.10
       </p>
       <p className='col-10 text-start p-4 pb-0'>Proxeet may, without any liability or penalty, and without constituting a waiver of any of the Proxeet’s rights or remedies under the Terms, cancel any accepted Order if
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>2.10.1
       </p>
       <p className='col-10 text-start p-4 pb-0'> Proxeet determines that the Buyer is in violation of its payment obligations under the Terms,
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>2.10.2
       </p>
       <p className='col-10 text-start p-4 pb-0'> the Buyer has otherwise breached any of the Terms.
       </p>
       </div>
       

       
   

            <h5 className='my-3'>3. LIMITED PRODUCT WARRANTY</h5>

      <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>3.1
       </p>
       <p className='col-10 text-start p-4 pb-0'>Proxeet warrants that the Products will be free from any defects in materials or workmanship for a period of two (2) years from the date when the Buyer accepts the Products (the “Warranty”), subject to the exclusions and limitations set out below.
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>3.2
       </p>
       <p className='col-10 text-start p-4 pb-0'>The Warranty does not apply to:
       </p>
       </div>


       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>3.2.1
       </p>
       <p className='col-10 text-start p-4 pb-0'>any Products that have been subjected to abuse, misuse, neglect, negligence, accident, improper testing, improper installation, improper storage, improper handling, abnormal physical stress, abnormal environmental conditions or use contrary to any instructions issued by Proxeet;
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>3.2.2
       </p>
       <p className='col-10 text-start p-4 pb-0'>any Products that have been reconstructed, repaired or altered by any persons other than Proxeet or its authorized representative; and
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end p-4'>3.2.3
       </p>
       <p className='col-10 text-start p-4 pb-0'>any Products that has been used with any products manufactured by any third party, or a product that has not been previously approved in writing by Proxeet.
       </p>
       </div>






            <h5 className='my-3'>4. FORCE MAJEURE</h5>

      <div className='row justify-content-center align-middle border oferta'>
       <p className='col-1 text-center p-4'>4.1
       </p>
       <p className='col-10 text-start p-4 pb-0'>Proxeet is not liable or responsible to the Buyer, nor can be deemed to have defaulted under or breached these Terms, for any failure or delay in fulfilling or performing the Terms, if such failure or delay is caused by or results from acts beyond Proxeet’s control, including:
       </p>
       </div>


       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>4.1.1
       </p>
       <p className='col-10 text-start p-4 pb-0'>acts of nature;
       </p>
       </div>

       
       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>4.1.2
       </p>
       <p className='col-10 text-start p-4 pb-0'>flood, fire, earthquake or explosion;
       </p>
       </div>

       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>4.1.3
       </p>
       <p className='col-10 text-start p-4 pb-0'>war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest;
       </p>
       </div>

       
       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>4.1.4
       </p>
       <p className='col-10 text-start p-4 pb-0'>actions, embargoes or blockades in effect on or after the date of this Agreement;
       </p>
       </div>

       
       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>4.1.5
       </p>
       <p className='col-10 text-start p-4 pb-0'>national or regional emergency (including pandemics);
       </p>
       </div>

       
       <div className='row justify-content-center align-middle border oferta'>
       <p className='col-2 text-end  p-4'>4.1.6
       </p>
       <p className='col-10 text-start p-4 pb-0'>strikes, labor stoppages or slowdowns or other industrial disturbances.
       </p>
       </div>

       




<p className='text-start my-5'>End of General Supply Terms and Conditions</p>


<p className='text-start my-1'>25 100 999</p>
<p className='text-start my-1'>info@proxeet.com</p>
<p className='text-start my-1'>Cyprus, 8011 Paphos, 61 Neofytou Nikolaidi, Yiannis court, office 201</p>

        

                    </div> 
                    </div>
 
            </>
        );
    }
}