export function emailFirstTest(email) {

    if ( email.includes("@") && email.lastIndexOf("@") === email.indexOf("@") && email.length > 1) {

        let emailData = email.split("@");



        let firstEmailPartRegExp = new RegExp(/[a-zA-Z0-9.]+/);
        let secondEmailPartRegExp = new RegExp(/[a-zA-Z0-9.-]+/);

        let firstPart = false;
        let secondPart = false;

        console.log(emailData[0]);
        console.log(emailData[1]);

        if (emailData[0].length > 0) {

            firstPart = firstEmailPartRegExp.exec(emailData[0])[0] === emailData[0];
            console.log("first part of email: " + firstPart);

        }

        if (emailData[1].length > 0) {

            secondPart = (secondEmailPartRegExp.exec(emailData[1])[0] === emailData[1]) && emailData[1].includes(".");
            console.log("second part of email: " + secondPart);

        }


        return firstPart && secondPart;
    }
    else {

        return false;
    }

}