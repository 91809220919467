import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Slider from "react-slick";
//import Card from 'react-bootstrap/Card';
import { DarkBluePrevArrow, DarkBlueNextArrow } from './arrow';
import { withGetScreen } from 'react-getscreen';
import axios from 'axios';

class ProductsTags extends React.Component {

    constructor(props) {
        super(props);

        this.jsonGet();

    }



    state = {


        tags: {},
    }

    jsonGet = async () => {

        let tagsData = new FormData();
        tagsData.append('table', 'productsTags');



        const tag = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: tagsData });

        const tagsResp = await tag.data;


        this.setState({ tags: tagsResp });



    }

    setProductList = (e) => {

        this.props.setProductList(e.target.getAttribute("data-index"));

    }

    render() {


        const settingsMob = {



            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: <DarkBlueNextArrow />,
            prevArrow: <DarkBluePrevArrow />,
        };


        if (this.props.isMobile()) return (

            <>

                <style type="text/css">
                    {`
                                
                                .btn-custom {
                                 
                                    background-color: #FFFFFF;
                                    color: #152F4E;
                                    
                                    
        
                                }
        
                                
                                
                                .btn-custom:hover { 
                                    
                                    background-color: #FF7E33; 
                                    border: 1px solid  #FF7E33 !important;
                                    color: #FFF;
                                   
                                    
                                }
        
                                
        
                                
                            `}
                </style>
                <div className="container-fluid">


                    <div className="row justify-content-center align-middle align-items-center text-center">
                        <div className="col-10  text-center my-4">
                            {Object.keys(this.state.tags).length !== 0 &&


                                <>

                                    <Slider className='row'  {...settingsMob}>
                                        {
                                            Object.keys(this.state.tags).map((someId) => (

                                                <div className='col-3'>
                                                    <Button
                                                        onClick={this.setProductList}
                                                        data-index={this.state.tags[someId].tags}
                                                        className='btn-custom border rounded-4 px-3 mx-3'
                                                        variant="outline-light"
                                                    >
                                                        {this.state.tags[someId].tags}
                                                    </Button>
                                                </div>


                                            ))
                                        }
                                    </Slider>
                                </>
                            }

                        </div>
                    </div>

                </div>
            </>
        );


        if (this.props.isTablet()) return (


            <>


                <style type="text/css">
                    {`
                                
                                .btn-custom {
                                 
                                    background-color: #FFFFFF;
                                    color: #152F4E;

                                    
                                    
        
                                }
        
                             
                                
                                
                                .btn-custom:hover { 
                                    
                                    background-color: #FF7E33; 
                                    border: 1px solid  #FF7E33 !important;
                                    color: #FFF;
                                   
                                    
                                }
        
                                
        
                                
                            `}
                </style>
                <div className="container-fluid">


                    <div className="row justify-content-center align-middle align-items-center text-center">
                        <div className="col-10  text-center mt-4">
                            {Object.keys(this.state.tags).length !== 0 &&


                                <>
                                    <Slider className='row'  {...settingsMob}>

                                        {
                                            Object.keys(this.state.tags).map((someId) => (

                                                <div className='col-3'>
                                                    <Button
                                                        onClick={this.setProductList}
                                                        data-index={this.state.tags[someId].tags}
                                                        className='btn-custom border rounded-4 px-3 mx-3'
                                                        variant="outline-light"
                                                    >
                                                        {this.state.tags[someId].tags}
                                                    </Button>
                                                </div>


                                            ))
                                        }

                                    </Slider>
                                </>
                            }
                        </div>
                    </div>

                </div>



            </>




        );

        return (
            <>

                <style type="text/css">
                    {`
                        
                        .btn-custom {
                         
                            background-color: #FFFFFF;
                            color: #152F4E;
                           
                            
                            

                        }

                        

                        
                        
                        .btn-custom:hover { 
                            
                            background-color: #FF7E33; 
                            border: 1px solid  #FF7E33 !important;
                            color: #FFF;
                           
                            
                        }

                        

                        
                    `}
                </style>


                <div className="row justify-content-center align-middle  text-center my-3" style={{ background: '' }}>
                    <div className='col-10'>
                        {Object.keys(this.state.tags).length !== 0 &&


                            <>
                                <ButtonGroup className='mt-4' aria-label="Basic example">
                                    {
                                        Object.keys(this.state.tags).map((someId) => (


                                            <Button
                                                onClick={this.setProductList}
                                                data-index={this.state.tags[someId].tags}
                                                className='btn-custom border rounded-4 px-3 mx-3'
                                                variant="outline-light"
                                            >

                                                {this.state.tags[someId].tags}

                                            </Button>

                                        ))
                                    }
                                </ButtonGroup>
                            </>
                        }
                    </div>
                </div>

            </>
        );
    }
}

export default withGetScreen(ProductsTags);
