import React from 'react';
import { Chip } from './chip';

import { withGetScreen } from 'react-getscreen';
import { Link } from 'react-router-dom';


class AboutCompany extends React.Component {

    constructor(props) {
        super(props);


    }

    state = {

    }


    render() {

        if (this.props.isMobile()) return (

            <>
                <div className="container-fluid">

                    <div className="row justify-content-center text-center mt-4">
                        <span className="col-10 proxeet-pc-header"><span style={{ lineHeight: '1.5', fontSize: '1.9rem' }} >PEACE OF MIND<br />WHEN YOU'RE<br />OFF THE GRID<br /></span></span>
                    </div>


                    <div className="row justify-content-center text-center mt-3">
                        <Link to='/about'> <button
                            className="btn btn-proxeet-mob"
                            onClick={this.props.callAbout}>ABOUT COMPANY</button></Link>
                    </div>
                </div>





            </>

        );


        if (this.props.isTablet()) return (

            <>

                <div className='container-fluid position-relative' >
                    {/*  <div className="row justify-content-center align-middle  text-md-center">
                        <div>
                            
                            <Chip l={5} t={60} z={999} w={50} rotate={75} />
                        </div>
                    </div>*/}

                    <div className="row justify-content-center text-center my-4">
                        <span className="col-10 proxeet-pc-header"><span style={{ lineHeight: '1.2', fontSize: '8vw' }} >PEACE OF MIND<br />WHEN YOU'RE<br />OFF THE GRID<br /></span></span>
                    </div>


                    <div className="row justify-content-center text-center">
                        <Link to='/about'><button

                            className='btn-proxeet btn'
                            onClick={this.props.callAbout}
                        >ABOUT COMPANY</button></Link>
                    </div>
                </div>




            </>



        );


        return (
            <>

                <div className="container-fluid mt-5 d-none d-md-block d-xl-none" style={{ position: 'relative' }} >

                    <Chip l={'-10'} t={'5'} w={'60'} rotate={'50'} />
                    <div className=''>
                        <br /><br />

                    </div>

                    <div className="row justify-content-center text-center">
                        <span className="col-11 proxeet-pc-header"><span className='' style={{ lineHeight: '1', fontSize: '50px' }} >"Relentless pursuit <br />of excellence" - the driving force of our team</span></span>
                    </div>


                    <div className="row justify-content-center text-center mt-4">
                        <Link to='/about'>
                            <button
                                style={{ width: '14rem', height: '5rem', backgroundColor: '#FF7E33', color: '#FFF', fontSize: '1.3rem' }}
                                className="apply-btn btn ttt col-4 mt-5 br-50"
                                onClick={this.props.callAbout}
                            >
                                ABOUT COMPANY
                            </button>
                        </Link>
                    </div>
                </div>


                <div className="container-fluid mt-5 d-none d-xl-block" style={{ position: 'relative' }} >

                    <Chip l={'-10'} t={'5'} w={'60'} rotate={'50'} />
                    <div className=''>
                        <br /><br />

                    </div>

                    <div className="row justify-content-center text-center">
                        <span className="col-8 proxeet-pc-header"><span className='' style={{ lineHeight: '1', fontSize: '60px' }} >"Relentless pursuit <br />of excellence" - the driving force of our team</span></span>
                    </div>


                    <div className="row justify-content-center text-center mt-4">
                        <Link to='/about'>  <button
                            style={{ color: '#FFF', width: '14rem', height: '4rem', backgroundColor: '#FF7E33' }}
                            className="btn  mt-5 br-30"
                            onClick={this.props.callAbout}
                        ><span className='ttt'>ABOUT COMPANY</span></button></Link>
                    </div>
                </div>
            </>
        );
    }
}

export default withGetScreen(AboutCompany);