import React, { Fragment } from 'react';
import axios from 'axios';
import { Chip } from './chip';
import Question from './question';
import crab from '../img/chop-orange.svg';
import com from '../img/comingSon.png';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { withGetScreen } from 'react-getscreen';
import { ProductSearch } from './productSearch';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';



class Products extends React.Component {

    constructor(props) {
        super(props);



        //this.props.callProducts();
        this.jsonGet();



    }

    componentWillReceiveProps(nextProps) {



        this.makeSearchList();

    }


    componentDidUpdate(prevProps) {

        if (this.props.productSearchString !== prevProps.productSearchString) {

            this.makeSearchList();

        }

    }


    state = {

        ProductList: {},
        SearchResult: {},
        basicModal: false,


    }

    ProductCardActive = (e) => {

        this.props.callProductCard(e.target.getAttribute("data-index"));

    }

    jsonGet = async () => {

        const formData = new FormData();

        formData.append('table', 'products');


        const api_call = await axios.post(process.env.REACT_APP_PROXEET_FULL_API, formData);


        const data = await api_call.data;

        this.setState({ ProductList: data });

    }

    makeSearchList = () => {



        let searchFormValue = this.props.productSearchString;

        const ProductList = this.state.ProductList;


        let item_arr = [];
        const ObjectForProps = {};


        for (var element in ProductList) {

            item_arr.push(ProductList[element].name);
            item_arr.push(ProductList[element].description);

        };



        const f = item_arr.filter(function (e) {

            return e.toLowerCase().includes(searchFormValue.toLowerCase());

        });


        f.forEach(e => {

            for (let key in ProductList) {


                if (ProductList[key].name.includes(e) || ProductList[key].description.includes(e)) {

                    ObjectForProps[key] = ProductList[key];
                }
            }

        })

        if (searchFormValue !== '') {

            this.setState({ SearchResult: ObjectForProps });

        }
        else {

            this.setState({ SearchResult: {} });

        }

    }

    render() {



        if (this.props.isMobile()) return (

            <>

                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} modal={true} mod={'Thank you for your interest in Combo PoE Injector'} sendedText={'Your waiting list request has been successfully submitted'} />
                    </Modal.Body>

                </Modal>


                {this.props.cardCount === 'all' ?
                    <>

                        <div className="container-fluid">
                            <div className="row justify-content-center align-middle  text-center">
                                <span className='proxeet-mob-header' style={{ position: 'relative' }} >Products</span>
                            </div>

                            {
                                <div className="row justify-content-center align-middle my-3  text-center">

                                    <ProductSearch str={this.props.productSearchString} setProductList={this.props.setProductList} />

                                </div>
                            }


                            <div className="row justify-content-start align-middle  text-center my-4">
                                {
                                    this.props.productSearchString === undefined || this.props.productSearchString === null || this.props.productSearchString === '' ?


                                        Object.keys(this.state.ProductList).map((someId, index) => (


                                            <Fragment key={someId}>

                                                <Link className='col-6' onClick={() => { window.scrollTo(0, 0); }} to={this.state.ProductList[someId].link}>
                                                    <Card className="border my-3 border-1 shadow br-30">
                                                        <Card.Img variant="top" src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName} style={{ borderRadius: '30px 30px 0 0' }} />
                                                        <Card.Body>
                                                            <Card.Title
                                                                style={{ color: "#152F4E", fontSize: '0.9rem' }}
                                                                className='text-center mx-0 mb-2 proxeet-mob-header'
                                                                dangerouslySetInnerHTML={{ __html: this.state.ProductList[someId].name }}
                                                            />


                                                            <p
                                                                className='text-center my-2'
                                                                style={{ fontSize: '10px', color: '#00477A' }}
                                                                dangerouslySetInnerHTML={{ __html: this.state.ProductList[someId].description }}
                                                            />
                                                        </Card.Body>
                                                    </Card>
                                                </Link>



                                            </Fragment>
                                        ))

                                        :
                                        Object.keys(this.state.SearchResult).map((someId, index) => (


                                            <Link className='col-6' onClick={() => { window.scrollTo(0, 0); }} to={this.state.ProductList[someId].link}>
                                                <Card className="border my-3 border-1 shadow br-30">
                                                    <Card.Img variant="top" src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName} style={{ borderRadius: '30px 30px 0 0' }} />
                                                    <Card.Body className='mx-4'>
                                                        <Card.Title style={{ color: '#152F4E' }} ><span className='text-start mx-0 mb-2 proxeet-mob-header' style={{ color: "#152F4E", fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.ProductList[someId].name }} /></Card.Title>
                                                        <p className='text-start my-2' style={{ fontSize: '10px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.ProductList[someId].description }} />
                                                    </Card.Body>
                                                </Card>
                                            </Link>




                                        ))


                                }
                            </div>
                        </div>

                    </>
                    :
                    <>

                        <div className="container-fluid" style={{ position: 'relative' }} >

                            {<Chip l={'70'} t={'25'} w={'20'} rotate={'40'} />}

                            {<Chip l={'-16'} t={'95'} w={'20'} rotate={'10'} />}

                            <div className="row justify-content-center align-middle  text-center my-4">
                                <span className="col-12 text-center proxeet-mob-header">OUR PRODUCTS</span>
                            </div>

                            {
                                Object.keys(this.state.ProductList).slice(0, 2).map((someId) => (


                                    someId % 2

                                        ?

                                        <div key={someId.toString()} className="row justify-content-center align-middle  text-center">
                                            <div className="col-12  text-center">
                                                <div className='row justify-content-center align-middle  text-center'>

                                                    <div className='col-12'>
                                                        <div className='' style={{ margin: '0 auto', padding: '0', width: '250px', height: '250px', position: 'relative' }}>
                                                            <div className='' style={{ position: 'absolute', left: '65%', top: '0px', zIndex: '5000' }}>
                                                                {/*someId === 2 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{ borderRadius: '7px', background: '#FFFFFF' }} alt="" className='col-md-12 img-fluid' src={iblock}></img></button>

                        */}

                                                                {someId === 3 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{}} alt="" className='col-md-12 img-fluid' src={com}></img></button>

                                                                }

                                                            </div>
                                                            <img alt='' className="" style={{ margin: '0', padding: '0', width: '250px', height: '250px', borderRadius: '0 70px 0 0' }} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName}></img>
                                                            <div className='' style={{ position: 'absolute', left: '-35px', top: '200px', zIndex: '5000' }}>
                                                                <button style={{ backgroundColor: '#FFFFFF', width: '70px', height: '70px', borderRadius: '20px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn col-md-4 "><img style={{ width: '50px', height: '50px' }} alt="" className='img-fluid' src={crab}></img></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 mt-4' style={{}}>
                                                        <span className='proxeet-mob-header'>{this.state.ProductList[someId].name}</span>
                                                        <p>{this.state.ProductList[someId].description}</p>

                                                        <Link to={this.state.ProductList[someId].link}><button data-index={someId.toString()} onClick={this.ProductCardActive} className="btn btn-proxeet-mob-blue  mb-4 br-30"><span data-index={someId.toString()} onClick={this.ProductCardActive} className='ttt'>LEARN MORE</span></button></Link>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        :

                                        <div key={someId.toString()} className="row justify-content-center align-middle  text-center">
                                            <div className="col-12  text-center">
                                                <div className='row justify-content-center align-middle  text-center'>

                                                    <div className='col-12 mt-4'>
                                                        <div className='' style={{ margin: '0 auto', padding: '0', width: '250px', height: '250px', position: 'relative' }}>
                                                            <img alt='' className="" style={{ margin: '0', padding: '0', width: '250px', height: '250px', borderRadius: '70px 0 0 0' }} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName}></img>
                                                            <div className='' style={{ position: 'absolute', left: '67%', top: '0px', zIndex: '5000' }}>
                                                                {/*someId === 2 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{ borderRadius: '7px', background: '#FFFFFF' }} alt="" className='col-md-12 img-fluid' src={iblock}></img></button>

                                                    */}

                                                                {someId === 3 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{}} alt="" className='col-md-12 img-fluid' src={com}></img></button>

                                                                }

                                                            </div>
                                                            <div className='' style={{ position: 'absolute', right: '-35px', top: '200px', zIndex: '5000' }}>
                                                                <button style={{ backgroundColor: '#FFFFFF', width: '70px', height: '70px', borderRadius: '20px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn col-md-4 "><img style={{ width: '50px', height: '50px' }} alt="" className='img-fluid' src={crab}></img></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 mt-4' style={{}}>
                                                        <span className='proxeet-mob-header'>{this.state.ProductList[someId].name}</span>
                                                        <p>{this.state.ProductList[someId].description}</p>
                                                        <Link to={this.state.ProductList[someId].link}><button data-index={someId.toString()} onClick={this.ProductCardActive} className="btn mb-4 btn-proxeet-mob-blue"><span data-index={someId.toString()} onClick={this.ProductCardActive} className='ttt'>LEARN MORE</span></button></Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                ))



                            }

                            <div className="row justify-content-center align-middle  text-center">
                                {
                                    this.props.cardCount !== 'all' &&

                                    <Link to='/products'> <button


                                        onClick={this.props.callProducts}
                                        className="btn btn-proxeet-mob">ALL PRODUCTS</button></Link>
                                }
                            </div>




                        </div>
                    </>
                }

            </>
        );


        if (this.props.isTablet()) return (

            <>
                <Modal
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} modal={true} mod={'Thank you for your interest in Combo PoE Injector'} sendedText={'Your waiting list request has been successfully submitted'} />
                    </Modal.Body>

                </Modal>
                {this.props.cardCount === 'all' ?
                    <>

                        <div style={{ marginTop: '10%', marginBottom: '10%' }} className="container-fluid">
                            <div className="row justify-content-center align-middle  text-center">
                                <span className='proxeet-mob-header' style={{ position: 'relative' }} >Products</span>
                            </div>

                            {
                                <div className="row justify-content-center align-middle my-4  text-center">

                                    <ProductSearch str={this.props.productSearchString} setProductList={this.props.setProductList} />

                                </div>
                            }


                            <div className="row justify-content-start align-middle  text-center my-4">
                                {
                                    this.props.productSearchString === undefined || this.props.productSearchString === null || this.props.productSearchString === '' ?


                                        Object.keys(this.state.ProductList).map((someId, index) => (

                                            <Fragment key={someId}>


                                                <Link className='col-6' onClick={() => { window.scrollTo(0, 0); }} to={this.state.ProductList[someId].link}>
                                                    <Card className="border my-3 border-1 shadow" style={{ borderRadius: '30px 30px 30px 30px' }}>
                                                        <Card.Img variant="top" src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName} style={{ borderRadius: '30px 30px 0 0' }} />
                                                        <Card.Body>
                                                            <Card.Title style={{ color: '#152F4E' }} ><span className='text-start mx-0 mb-2 proxeet-pc-header' style={{ color: "#152F4E", fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.ProductList[someId].name }} /></Card.Title>
                                                            <p className='text-start my-2' style={{ fontSize: '10px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.ProductList[someId].description }} />
                                                        </Card.Body>
                                                    </Card>
                                                </Link>




                                            </Fragment>
                                        ))

                                        :
                                        Object.keys(this.state.SearchResult).map((someId, index) => (

                                            <>


                                                <Link className='col-6' onClick={() => { window.scrollTo(0, 0); }} to={this.state.ProductList[someId].link}>
                                                    <Card className="border my-3 border-1 shadow" style={{ borderRadius: '30px 30px 30px 30px' }}>
                                                        <Card.Img variant="top" src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName} style={{ borderRadius: '30px 30px 0 0' }} />
                                                        <Card.Body className='mx-4'>
                                                            <Card.Title style={{ color: '#152F4E' }} ><span className='text-start mx-0 mb-2 proxeet-pc-header' style={{ color: "#152F4E", fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.ProductList[someId].name }} /></Card.Title>
                                                            <p className='text-start my-2' style={{ fontSize: '10px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.ProductList[someId].description }} />
                                                        </Card.Body>
                                                    </Card>
                                                </Link>


                                            </>
                                        ))


                                }
                            </div>
                        </div>

                    </>
                    :
                    <>

                        <div className="container-fluid" style={{ position: 'relative' }} >

                            {<Chip l={'70'} t={'25'} w={'20'} rotate={'40'} />}

                            {<Chip l={'-16'} t={'95'} w={'20'} rotate={'10'} />}

                            <div className="row justify-content-center align-middle  text-center my-4">

                                <span className="col-12 text-center proxeet-pc-header">OUR PRODUCTS</span>
                            </div>

                            {
                                Object.keys(this.state.ProductList).slice(0, 2).map((someId) => (


                                    someId % 2

                                        ?

                                        <div key={someId.toString()} className="row justify-content-center align-middle  text-center">
                                            <div className="col-12  text-center">
                                                <div className='row justify-content-center align-middle  text-center'>

                                                    <div className='col-12 mt-4'>
                                                        <div className='' style={{ margin: '0 auto', padding: '0', width: '250px', height: '250px', position: 'relative' }}>
                                                            <div className='' style={{ position: 'absolute', left: '65%', top: '0px', zIndex: '5000' }}>
                                                                {/*someId === 2 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{ borderRadius: '7px', background: '#FFFFFF' }} alt="" className='col-md-12 img-fluid' src={iblock}></img></button>

                        */}

                                                                {someId === 3 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{}} alt="" className='col-md-12 img-fluid' src={com}></img></button>

                                                                }

                                                            </div>
                                                            <img alt='' className="" style={{ margin: '0', padding: '0', width: '250px', height: '250px', borderRadius: '0 70px 0 0' }} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName}></img>
                                                            <div className='' style={{ position: 'absolute', left: '-35px', top: '200px', zIndex: '5000' }}>
                                                                <button style={{ backgroundColor: '#FFFFFF', width: '70px', height: '70px', borderRadius: '20px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn border-0"><img style={{ width: '50px', height: '50px' }} alt="" className='img-fluid' src={crab}></img></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 mt-4' style={{}}>
                                                        <span className='proxeet-pc-header'>{this.state.ProductList[someId].name}</span>
                                                        <p>{this.state.ProductList[someId].description}</p>
                                                        <Link to={this.state.ProductList[someId].link}><button style={{ width: '24vh', height: '7vh', backgroundColor: '#152F4E' }} data-index={someId.toString()} onClick={this.ProductCardActive} className="btn-proxeet-mob-blue btn  mb-4 border-0 br-30"><span data-index={someId.toString()} onClick={this.ProductCardActive} className='ttt'>LEARN MORE</span></button></Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        :

                                        <div key={someId.toString()} className="row justify-content-center align-middle  text-center">
                                            <div className="col-12  text-center">
                                                <div className='row justify-content-center align-middle  text-center'>

                                                    <div className='col-12 mt-4'>
                                                        <div className='' style={{ margin: '0 auto', padding: '0', width: '250px', height: '250px', position: 'relative' }}>
                                                            <img alt='' className="" style={{ margin: '0', padding: '0', width: '250px', height: '250px', borderRadius: '70px 0 0 0' }} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName}></img>
                                                            <div className='' style={{ position: 'absolute', left: '67%', top: '0px', zIndex: '5000' }}>
                                                                {/*someId === 2 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{ borderRadius: '7px', background: '#FFFFFF' }} alt="" className='col-md-12 img-fluid' src={iblock}></img></button>

                                                    */}

                                                                {someId === 3 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{}} alt="" className='col-md-12 img-fluid' src={com}></img></button>

                                                                }

                                                            </div>
                                                            <div className='' style={{ position: 'absolute', left: '-35px', top: '200px', zIndex: '5000' }}>
                                                                <button style={{ backgroundColor: '#FFFFFF', width: '70px', height: '70px', borderRadius: '20px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn col-md-4 border-0"><img style={{ width: '50px', height: '50px' }} alt="" className='img-fluid' src={crab}></img></button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 mt-4' style={{}}>
                                                        <span className='proxeet-pc-header'>{this.state.ProductList[someId].name}</span>
                                                        <p>{this.state.ProductList[someId].description}</p>
                                                        <Link to={this.state.ProductList[someId].link}><button style={{ width: '24vh', height: '7vh', backgroundColor: '#152F4E' }} data-index={someId.toString()} onClick={this.ProductCardActive} className="btn-proxeet-mob-blue btn mb-4 border-0 br-30"><span data-index={someId.toString()} onClick={this.ProductCardActive} className='ttt'>LEARN MORE</span></button></Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                ))



                            }

                            <div className="row justify-content-center align-middle  text-center">
                                {
                                    this.props.cardCount !== 'all' &&

                                    <Link to='/products'> <button

                                        style={{ backgroundColor: '#FF7E33', border: 'none', height: '60px', borderRadius: '30px' }}
                                        onClick={this.props.callProducts}
                                        className="btn-proxeet btn col-8 ">
                                        <span className='ttt'>ALL PRODUCTS</span>

                                    </button></Link>
                                }
                            </div>




                        </div>
                    </>
                }


            </>

        );

        return (
            <>

                <Modal style={{ overflowY: 'auto' }}
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body>
                        <Question noChip={true} modal={true} mod={'Thank you for your interest in Combo PoE Injector'} sendedText={'Your waiting list request has been successfully submitted'} />
                    </Modal.Body>

                </Modal>

                <div className="container-fluid mt-5 d-none d-md-block d-xl-none" style={{ position: 'relative' }}>


                    <div className="row justify-content-center align-middle  text-center">
                        <span className="col-10 col-sm-10 col-md-8 text-md-center text-center proxeet-pc-header" style={{ position: 'relative' }} >{this.props.cardCount === 'all' ? 'PRODUCTS' : 'OUR PRODUCTS'}</span>
                    </div>
                    {

                        this.props.cardCount === 'all' ?

                            <>

                                {
                                    <div className="row justify-content-center align-middle  text-center my-4">

                                        <ProductSearch str={this.props.productSearchString} setProductList={this.props.setProductList} />

                                    </div>
                                }

                                {
                                    this.props.productSearchString === undefined || this.props.productSearchString === null || this.props.productSearchString === '' ?

                                        <>

                                            <div className="row justify-content-center align-middle text-center" style={{ position: 'relative' }} >


                                                <Chip l={'18'} t={'-30'} w={'20'} rotate={'80'} />


                                                <Chip l={'-12'} t={'40'} w={'40'} rotate={'40'} />

                                                <Chip l={'80'} t={'30'} w={'45'} rotate={'30'} />


                                                <div className="col-12">
                                                    <CardGroup className="row justify-content-center align-middle text-center">
                                                        {
                                                            Object.keys(this.state.ProductList).map((someId) => (
                                                                <>



                                                                    <Link key={someId + 'card'} to={this.state.ProductList[someId].link} className='col-3 mt-4' >
                                                                        <Card data-index={someId.toString()} onClick={this.ProductCardActive} className=" m-1 border border-1 btn br-30" style={{ padding: '0' }}>
                                                                            <Card.Img data-index={someId.toString()} variant="top" src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgRect} style={{ borderRadius: '30px 30px 0 0' }} />
                                                                            <div className='' style={{ position: 'absolute', left: '70%', top: '0px', zIndex: '5000' }}>


                                                                                {someId === 3 && <button style={{ width: '80px', height: '80px', borderRadius: '0px' }} className=" btn  col-md-4 "><img alt="" className='col-md-12 img-fluid' src={com}></img></button>

                                                                                }

                                                                            </div>
                                                                            <Card.Body data-index={someId.toString()} className='col-12 text-start'>
                                                                                <Card.Title data-index={someId.toString()} className='mx-2' style={{ color: '#152F4E' }} ><span data-index={someId.toString()} style={{ fontSize: '1.3rem' }} className=' ttt'>{this.state.ProductList[someId].name}</span></Card.Title>
                                                                                <Card.Text data-index={someId.toString()} className='mx-2'><span data-index={someId.toString()} style={{ fontSize: '0.9rem' }}>{this.state.ProductList[someId].description}</span></Card.Text>
                                                                            </Card.Body>

                                                                        </Card>
                                                                    </Link>






                                                                </>))
                                                        }




                                                    </CardGroup>
                                                </div>
                                            </div>

                                        </>

                                        :
                                        <>
                                            <div className="row justify-content-center align-middle text-center" style={{ position: 'relative' }} >

                                                <Chip l={'18'} t={'-30'} w={'20'} rotate={'80'} />


                                                <Chip l={'-12'} t={'40'} w={'40'} rotate={'40'} />

                                                <Chip l={'80'} t={'30'} w={'45'} rotate={'30'} />

                                                <div className="col-12">
                                                    <CardGroup className="row justify-content-center align-middle text-center">
                                                        {

                                                            Object.keys(this.state.SearchResult).map((someId) => (



                                                                <Link key={someId + 'card_1'} to={this.state.ProductList[someId].link} className='col-3 mt-4' >
                                                                    <Card data-index={someId.toString()} onClick={this.ProductCardActive} className=" m-1 border border-1 btn br-30" style={{ padding: '0' }}>
                                                                        <Card.Img data-index={someId.toString()} variant="top" src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.SearchResult[someId].imgRect} style={{ borderRadius: '30px 30px 0 0' }} />

                                                                        <Card.Body data-index={someId.toString()} className='col-12  text-start'>
                                                                            <Card.Title data-index={someId.toString()} className='mx-4' style={{ color: '#152F4E' }} ><span data-index={someId.toString()} style={{ fontSize: '1.8vw' }} className=' ttt'>{this.state.SearchResult[someId].name}</span></Card.Title>
                                                                            <Card.Text data-index={someId.toString()} className='mx-4'><span data-index={someId.toString()} style={{ fontSize: '1.2vw' }}>{this.state.SearchResult[someId].description}</span></Card.Text>
                                                                        </Card.Body>

                                                                    </Card>
                                                                </Link>




                                                            ))
                                                        }
                                                    </CardGroup>
                                                </div>
                                            </div>
                                        </>
                                }

                                <div className='mt-5'></div>
                                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />

                            </>

                            :




                            Object.keys(this.state.ProductList).slice(0, 2).map((someId) => (
                                !(someId % 2)

                                    ?
                                    <Fragment key={someId}>

                                        <Chip l={'0'} t={'0'} w={'55'} rotate={'40'} />

                                        <Chip l={'80'} t={'38'} w={'55'} rotate={'30'} />

                                        <div key={someId.toString()} className="row justify-content-center align-middle  text-center">
                                            <div className="col-11  text-center">
                                                <div className='row'>
                                                    <div className='col-6' style={{ paddingTop: '15%' }}>
                                                        <span className='proxeet-pc-header'>{this.state.ProductList[someId].name}</span>
                                                        <p>{this.state.ProductList[someId].description}</p>
                                                        <Link to={this.state.ProductList[someId].link}> <button style={{ backgroundColor: '#152F4E' }} data-index={someId.toString()} onClick={this.ProductCardActive} className="btn btn-proxeet-blue  col-md-4  border-0 br-30"><span data-index={someId.toString()} onClick={this.ProductCardActive} className='ttt'>LEARN MORE</span></button> </Link>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='m-0 p-0 position-relative'>
                                                            <img alt='' className='img-fluid' style={{ margin: '0', padding: '0', borderRadius: '100px 0 0 0' }} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName}></img>
                                                            <div
                                                                className='d-none d-md-block d-lg-none'
                                                                style={{ position: 'absolute', right: '-5%', bottom: '-5%', zIndex: '5000' }}
                                                            >
                                                                <button
                                                                    style={{
                                                                        backgroundColor: '#FFFFFF',
                                                                        width: '100px',
                                                                        height: '100px',
                                                                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
                                                                    }}
                                                                    className="apply-btn btn col-md-4 br-20"
                                                                >
                                                                    <img
                                                                        style={{ width: '70px', height: '70px' }}
                                                                        alt=""
                                                                        className='col-md-12'
                                                                        src={crab} />
                                                                </button>
                                                            </div>

                                                            <div className='d-none d-lg-block' style={{ position: 'absolute', right: '-20px', top: '85%', zIndex: '5000' }}>
                                                                <button style={{ backgroundColor: '#FFFFFF', width: '100px', height: '100px', borderRadius: '20px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn col-md-4 border-0"><img style={{ width: '70px', height: '70px' }} alt="" className='col-md-12' src={crab}></img></button>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    </Fragment>
                                    :

                                    <div key={someId.toString()} className="row justify-content-center align-middle  text-center my-4">
                                        <div className="col-11  text-center">
                                            <div className='row'>
                                                <div className='col-6' style={{}}>
                                                    <div className='' style={{ margin: '0', padding: '0', position: 'relative' }}>
                                                        <img alt='' className='img-fluid' style={{ margin: '0', padding: '0', borderRadius: '0 100px 0 0' }} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName}></img>

                                                        <div className='d-none d-md-block d-lg-none position-absolute' style={{ left: '-5%', bottom: '-5%', zIndex: '5000' }}>
                                                            <button style={{ backgroundColor: '#FFFFFF', width: '100px', height: '100px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn col-md-4 br-20"><img style={{ width: '70px', height: '70px' }} alt="" className='col-md-12' src={crab}></img></button>
                                                        </div>
                                                        <div className='d-none d-lg-block position-absolute' style={{ left: '-5%', bottom: '-5%', zIndex: '5000' }}>
                                                            <button style={{ backgroundColor: '#FFFFFF', width: '100px', height: '100px', borderRadius: '20px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn col-md-4 "><img style={{ width: '70px', height: '70px' }} alt="" className='col-md-12' src={crab}></img></button>
                                                        </div>


                                                        <div className='' style={{ position: 'absolute', left: '-5%', bottom: '-5%', zIndex: '5000' }}>

                                                            {/* <button style={{ width: '120px', height: '120px', borderRadius: '0px' }} className=" btn  col-md-4 "><img style={{ borderRadius: '7px', background: '#FFFFFF', width: '100px', height: '100px' }} alt="" className='col-md-12' src={iblock}></img></button>*/}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-6' style={{ paddingTop: '15%' }}>
                                                    <span className='proxeet-pc-header'>{this.state.ProductList[someId].name}</span>
                                                    <p>{this.state.ProductList[someId].description}</p>
                                                    <Link to={this.state.ProductList[someId].link}> <button style={{ backgroundColor: '#152F4E' }} data-index={someId.toString()} onClick={this.ProductCardActive} className="btn btn-proxeet-blue col-md-4  border-0 br-30"><span data-index={someId.toString()} onClick={this.ProductCardActive} className='ttt'>LEARN MORE</span></button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                            ))




                    }


                    <div className="row justify-content-center align-middle  text-md-center">


                        {this.props.cardCount !== 'all' && <Link to='/products'>  <button

                            style={{ width: '14rem', height: '5rem', marginTop: '5%', fontSize: '1.3rem', marginBottom: '0%', backgroundColor: '#FF7E33' }}
                            onClick={this.props.callProducts}
                            className="btn-proxeet btn col-4 br-30">
                            <span className='ttt'>ALL PRODUCTS</span>

                        </button></Link>}
                    </div>




                </div >


                <div className="container-fluid mt-5 d-none d-xl-block" style={{ position: 'relative' }}>


                    <div className="row justify-content-center align-middle  text-center">
                        <span className="col-10 col-sm-10 col-md-8 text-md-center text-center proxeet-pc-header" style={{ position: 'relative' }} >{this.props.cardCount === 'all' ? 'PRODUCTS' : 'OUR PRODUCTS'}</span>
                    </div>
                    {

                        this.props.cardCount === 'all' ?

                            <>

                                {
                                    <div className="row justify-content-center align-middle  text-center my-4">

                                        <ProductSearch str={this.props.productSearchString} setProductList={this.props.setProductList} />

                                    </div>
                                }

                                {
                                    this.props.productSearchString === undefined || this.props.productSearchString === null || this.props.productSearchString === '' ?

                                        <>

                                            <div className="row justify-content-center align-middle text-center" style={{ position: 'relative' }} >


                                                <Chip l={'18'} t={'-30'} w={'20'} rotate={'80'} />


                                                <Chip l={'-12'} t={'40'} w={'40'} rotate={'40'} />

                                                <Chip l={'80'} t={'30'} w={'45'} rotate={'30'} />


                                                <div className="col-12">
                                                    <CardGroup className="row justify-content-center align-middle text-center">
                                                        {
                                                            Object.keys(this.state.ProductList).map((someId) => (


                                                                <Link key={someId} to={this.state.ProductList[someId].link} className='col-3 mt-4' >
                                                                    <Card data-index={someId.toString()} onClick={this.ProductCardActive} className=" m-1 border border-1 btn br-30 p-0">
                                                                        <Card.Img data-index={someId.toString()} variant="top" src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgRect} style={{ borderRadius: '30px 30px 0 0' }} />

                                                                        <Card.Body data-index={someId.toString()} className='col-12  text-start'>
                                                                            <Card.Title
                                                                                data-index={someId.toString()}
                                                                                className='mx-4 ttt'

                                                                            >
                                                                                {this.state.ProductList[someId].name}

                                                                            </Card.Title>
                                                                            <Card.Text
                                                                                data-index={someId.toString()}
                                                                                className='mx-4'
                                                                                style={{ fontSize: '0.9rem' }}
                                                                            >
                                                                                {this.state.ProductList[someId].description}

                                                                            </Card.Text>
                                                                        </Card.Body>

                                                                    </Card>
                                                                </Link>
                                                            ))
                                                        }




                                                    </CardGroup>
                                                </div>
                                            </div>

                                        </>

                                        :
                                        <>
                                            <div className="row justify-content-center align-middle text-center" style={{ position: 'relative' }} >

                                                <Chip l={'18'} t={'-30'} w={'20'} rotate={'80'} />


                                                <Chip l={'-12'} t={'40'} w={'40'} rotate={'40'} />

                                                <Chip l={'80'} t={'30'} w={'45'} rotate={'30'} />

                                                <div className="col-12">
                                                    <CardGroup className="row justify-content-center align-middle text-center">
                                                        {

                                                            Object.keys(this.state.SearchResult).map((someId) => (
                                                                <Link key={someId} to={this.state.ProductList[someId].link} className='col-3 mt-4' >
                                                                    <Card data-index={someId.toString()} onClick={this.ProductCardActive} className=" m-1 border border-1 btn br-30" style={{ padding: '0' }}>
                                                                        <Card.Img data-index={someId.toString()} variant="top" src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.SearchResult[someId].imgRect} style={{ borderRadius: '30px 30px 0 0' }} />

                                                                        <Card.Body data-index={someId.toString()} className='col-12  text-start'>
                                                                            <Card.Title
                                                                                data-index={someId.toString()}
                                                                                className='mx-4 ttt' style={{ color: '#152F4E' }}
                                                                            >
                                                                                {this.state.SearchResult[someId].name}

                                                                            </Card.Title>
                                                                            <Card.Text
                                                                                data-index={someId.toString()}
                                                                                className='mx-4'
                                                                                style={{ fontSize: '0.9rem' }}
                                                                            >
                                                                                {this.state.SearchResult[someId].description}

                                                                            </Card.Text>
                                                                        </Card.Body>

                                                                    </Card>
                                                                </Link>
                                                            ))
                                                        }
                                                    </CardGroup>
                                                </div>
                                            </div>
                                        </>
                                }

                                <div className='mt-5'></div>
                                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />

                            </>

                            :




                            Object.keys(this.state.ProductList).slice(0, 2).map((someId) => (
                                !(someId % 2)

                                    ?
                                    <Fragment key={someId}>

                                        <Chip l={'0'} t={'0'} w={'55'} rotate={'40'} />

                                        <Chip l={'80'} t={'38'} w={'55'} rotate={'30'} />



                                        <div className='row justify-content-center align-middle  text-center'>
                                            <div className='col-3 d-flex align-items-center  justify-content-center'>
                                                <div>
                                                    <span className='proxeet-pc-header'>{this.state.ProductList[someId].name}</span>
                                                    <p>{this.state.ProductList[someId].description}</p>
                                                    <Link to={this.state.ProductList[someId].link}>
                                                        <button style={{ backgroundColor: '#152F4E' }} data-index={someId.toString()} onClick={this.ProductCardActive} className="btn btn-proxeet border-0 br-30">
                                                            <span data-index={someId.toString()} onClick={this.ProductCardActive} className='ttt'>LEARN MORE</span>
                                                        </button>
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className='col-3  justify-content-center align-middle'>
                                                <div className='position-relative d-inline-block'>
                                                    <img alt='' className='img-fluid' style={{ borderRadius: '100px 0 0 0' }} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName} />
                                                    <div className='position-absolute bottom-0 end-0' style={{ transform: 'translateY(30%) translateX(30%)', zIndex: '5000' }}>
                                                        <button style={{ backgroundColor: '#FFFFFF', width: '4rem', height: '4rem', borderRadius: '20px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn p-2"><img alt="" className='img-fluid' src={crab}></img></button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>





                                    </Fragment>
                                    :



                                    <div key={someId} className='row justify-content-center align-middle  text-center'>
                                        <div className='col-3'>
                                            <div className='position-relative d-inline-block'>
                                                <img alt='' className="img-fluid" style={{ borderRadius: '0 100px 0 0' }} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + this.state.ProductList[someId].imgName}></img>
                                                <div className='position-absolute bottom-0 start-0' style={{ transform: 'translateY(30%) translateX(-30%)', zIndex: '5000' }}>
                                                    <button style={{ backgroundColor: '#FFFFFF', width: '4rem', height: '4rem', borderRadius: '20px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="btn p-2"><img alt="" className=' img-fluid' src={crab}></img></button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3 d-flex align-items-center justify-content-center'>
                                            <div>
                                                <span className='proxeet-pc-header'>{this.state.ProductList[someId].name}</span>
                                                <p>{this.state.ProductList[someId].description}</p>
                                                <Link to={this.state.ProductList[someId].link}>
                                                    <button style={{ backgroundColor: '#152F4E' }} data-index={someId.toString()} onClick={this.ProductCardActive} className="btn btn-proxeet br-30">
                                                        <span data-index={someId.toString()} onClick={this.ProductCardActive} className='ttt'>LEARN MORE</span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>






                            ))




                    }


                    <div className="row justify-content-center align-middle  text-md-center">


                        {this.props.cardCount !== 'all' && <Link to='/products'>  <button

                            style={{ width: '14rem', height: '4rem', marginTop: '5%', marginBottom: '0%', backgroundColor: '#FF7E33' }}
                            onClick={this.props.callProducts}
                            className="btn-proxeet btn border-0 br-30">
                            <span className='ttt'>ALL PRODUCTS</span>

                        </button></Link>}
                    </div>




                </div >
            </>
        );
    }
}


export default withGetScreen(Products);