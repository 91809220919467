import React from 'react';
import axios from 'axios';
import NewsTags from './news/newsTags';
import plus from '../img/plus.png';
import cross from '../img/krest.png';
import {Chip} from './chip';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';


const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                '.Mui-expanded & > .collapsIconWrapper': {
                    display: 'none',
                },
                '.expandIconWrapper': {
                    display: 'none',
                },
                '.Mui-expanded & > .expandIconWrapper': {
                    display: 'block',
                },
            }}
        >
            <div className="expandIconWrapper" style={{border: '1px sold red'}}>
                <img
                    style={{width: '3rem', height: '3rem'}}
                    src={cross}
                    alt={''}
                />
            </div>
            <div className="collapsIconWrapper" style={{border: '1px sold red'}}>
                <img
                    style={{width: '3rem', height: '3rem'}}
                    alt={''}
                    src={plus}
                />
            </div>
        </Box>
    )
}

export class Faq extends React.Component {

    constructor(props) {
        super(props);

        this.props.callSupport();
        this.jsonGet();

    }

    state = {

        id: [],
        faqList: {},
        title: 'Frequently Asked Questions',
        subTitle: 'Browse help topics & questions',


    }


    jsonGet = async () => {

        let faqData = new FormData();
        faqData.append('table', 'faq');

        const faq = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_FULL_API,
            data: faqData
        });

        const data = await faq.data;

        this.setState({faqList: data});


    }

    setAccordImg = (e) => {

        let index = e.target.getAttribute("data-index");
        if (index > 0) {
            let setIndex = !this.state.id[index - 1];


            let id_arr = [];
            this.state.id.forEach(() => {
                id_arr.push(true);
            });


            id_arr[index - 1] = setIndex;
            this.setState({id: id_arr});
        }
    }


    render() {


        <style>{`
        
        .accordion-button-proxeet {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
                font-size: 1rem;
                color: var(--bs-accordion-btn-color);
                text-align: left;
                background-color: red !important;
                border: 1px solid red !important;
                border-radius: 0;
                overflow-anchor: none;
                transition: var(--bs-accordion-transition);
                background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
            }

            
        
        
        
        
        `}</style>


        return (
            <>
                <div className='container-fluid position-relative mt-4 mt-xl-3'>

                    <div className='d-none d-md-block' style={{zIndex: '555'}}>

                    </div>

                    <div className='d-block d-md-none' style={{zIndex: '555'}}>
                        <Chip l={'85'} t={'13'} w={'10'} rotate={'40'}/>
                    </div>

                    <div className="d-md-none row justify-content-center text-md-center">
                        <span className="col-10 col-sm-10 col-md-10 text-center proxeet-tab-header" style={{
                            marginTop: '3%',
                            marginBottom: '1%',
                            position: 'relative'
                        }}>{this.state.title}</span>
                    </div>

                    <div className="d-none d-md-block row justify-content-center text-md-center">
                        <span className="col-10 col-sm-10 col-md-10 text-center proxeet-pc-header" style={{
                            marginTop: '3%',
                            marginBottom: '1%',
                            position: 'relative'
                        }}>{this.state.title}</span>
                    </div>

                    <div className="row justify-content-center text-md-center">

                        <NewsTags/>

                        <div className="d-md-none row justify-content-center text-start my-4">
                            <span className="col-12 col-md-10  text-start  position-relative proxeet-tab-header"
                                  style={{color: '#95BBDB'}}>{this.state.subTitle}</span>
                        </div>

                        <div className="d-none d-md-block row justify-content-center text-start my-4">
                            <span className="col-12 col-md-10  text-start  position-relative proxeet-pc-header"
                                  style={{fontSize: '2.1rem', color: '#95BBDB'}}>{this.state.subTitle}</span>
                        </div>

                        <div className="row justify-content-center text-start my-4">
                            {Object.keys(this.state.faqList).length !== 0 &&
                                <div className='col-11 col-md-9  my-3'>
                                    {
                                        Object.values(this.state.faqList).map((someId, index) => (

                                            <Accordion key={"Accordion_" + index}>
                                                <AccordionSummary
                                                    expandIcon={<CustomExpandIcon/>}
                                                    className=''
                                                    id="panel1a-header"
                                                >
                                                    <Typography
                                                        className='my-3'><strong>{someId.header}</strong></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className='text-start '
                                                                style={{fontFamily: 'Open Sans', color: '#152F4E'}}>
                                                        {someId.description}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>

                                        ))

                                    }
                                </div>
                            }
                        </div>


                    </div>
                </div>
            </>
        );
    }
}

