import React from 'react';
import crab from '../img/chop-orange.svg';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import f_blue from '../img/f.svg';
import t_blue from '../img/socialIcons/x_blue.svg';
import tg from '../img/socialIcons/tg_blue.svg';
import shareBtn from '../img/socialIcons/share.svg';
import linkedin from '../img/socialIcons/linkedin_blue.svg'


export class Share extends React.Component {

    constructor(props) {
        super(props);



    }

    state = {

        copied: false,

        telegramShareLink: 'https://telegram.me/share/url?url=',
        facebookShareLink: 'https://www.facebook.com/sharer.php?u=',
        xShareLink: 'https://twitter.com/intent/tweet?text=',
        linkedinShareLink: 'https://www.linkedin.com/sharing/share-offsite/?url='

    }

    render() {

        return (
            <>

                    <ToastContainer className="p-3 position-fixed"  position={'bottom-end'} style={{  zIndex: 1 }} >

                    <Toast  show={this.state.copied }    onClose={() => {this.setState({ copied: false });}}  delay={5000} autohide >
                    <Toast.Header>
                        <img src={crab} style={{ height: '20px', width: '20px'}} className="rounded me-2" alt="" />
                        <strong className="me-auto">Proxeet</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>Link is copied to clipboard</Toast.Body>
                    </Toast>

                    </ToastContainer>


                <div className="row justify-content-start align-start  text-start mb-3">

                    <CopyToClipboard text={this.props.url}
                                     onCopy={() => this.setState({copied: true})}>
                        <button
                            className='col-3 col-xl-1'
                            style={{border: '0', background: '#FFF'}}
                        >
                            <img alt='' className='mx-2' src={shareBtn} style={{width: '', height: '50px'}}></img>
                        </button>
                    </CopyToClipboard>


                    <button
                        className='col-3 col-xl-1'
                        style={{border: '0', background: '#FFF'}}
                        onClick={() => {
                            window.open(this.state.xShareLink + this.props.url, "sharer", "status=0,toolbar=0,width=650,height=500")
                        }}
                    ><img alt='' className='mx-2' src={t_blue} style={{width: '', height: '50px'}}></img></button>


                    <button
                        className='col-3 col-xl-1'
                        style={{border: '0', background: '#FFF'}}
                        onClick={() => {
                            window.open(this.state.facebookShareLink + this.props.url, "sharer", "status=0,toolbar=0,width=650,height=500")
                        }}
                    ><img alt='' className='mx-2' src={f_blue} style={{width: '', height: '50px'}}></img></button>


                    <button
                        className='col-3 col-xl-1'
                        style={{border: '0', background: '#FFF'}}
                        onClick={() => {
                            window.open(this.state.telegramShareLink + this.props.url, "sharer", "status=0,toolbar=0,width=650,height=500")
                        }}
                    ><img alt='' className='mx-2' src={tg} style={{width: '', height: '50px'}}></img></button>

                    <button
                        className='col-3 col-xl-1'
                        style={{border: '0', background: '#FFF'}}
                        onClick={() => {
                            window.open(this.state.linkedinShareLink + this.props.url, "sharer", "status=0,toolbar=0,width=650,height=500")
                        }}
                    ><img alt='' className='mx-2' src={linkedin} style={{width: '', height: '50px'}}></img></button>


                </div>


            </>
        );
    }
}