import React from 'react';
import Slider from "react-slick";
//import Card from 'react-bootstrap/Card';
//import { BlueNextArrow, BluePrevArrow } from './arrow';
import { withGetScreen } from 'react-getscreen';

import img1 from '../img/ruller1.svg';
import img2 from '../img/ruller2.svg';
import img3 from '../img/ruller3.svg';
import img4 from '../img/ruller4.svg';
import img5 from '../img/ruller5.svg';
import img6 from '../img/ruller6.svg';
import img7 from '../img/ruller7.svg';

class Ruler extends React.Component {

    constructor(props) {
        super(props);

        if (this.props.lineColor !== '') {
            this.state.lineColor = this.props.lineColor;
        }

    }



    state = {

        names: ['ISP','Power Solutions','CCTV','Easy of use','Network','Cost-efficiency','Reliability' ],
        images: [img1,img2,img3,img4,img5,img6,img7 ],
        lineColor: '#95BBDB',

    }



    render() {



        const settingsMob = {



            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 2,

        };





        if (this.props.isMobile()) return (

            <>
                <div className="container-fluid mt-4">
                    <div className="row justify-content-center align-middle align-items-center text-center" style={{ background: this.state.lineColor }} >
                        <div className="col-11  text-center">
                            <Slider className='row' style={{ margin: '0', padding: '0' }} {...settingsMob}>
                               
                            {
                                    Object.keys(this.state.names).map((someId,index) => ( 
                                    
                                    <div className="col-2 mt-3">
                                    <div className="row justify-content-center align-middle align-items-center text-center" >
                                        <img alt='' style={{ width: '90px', height: '70px' }} src={this.state.images[index]} />
                                    </div>
                                    <div className="row justify-content-center align-middle align-items-center text-center" >
                                        <p style={{ color: 'white', fontSize: '10px' }} className="col text-center">{this.state.names[index]}</p>
                                    </div>
                                </div>
                                    
                                    ))
                            }


                              

                            </Slider >
                        </div >
                    </div >
                </div>
            </>
        );




        if (this.props.isTablet()) return (


            <>
                 <div className="container-fluid">
                    <div className="row justify-content-center align-middle align-items-center text-center" style={{ background: this.state.lineColor }} >
                        <div className="col-11  text-center">
                            <Slider className='row' style={{ margin: '0', padding: '0' }} {...settingsMob}>
                               
                            {
                                    Object.keys(this.state.names).map((someId,index) => ( 
                                    
                                    <div className="col-2 mt-3">
                                    <div className="row justify-content-center align-middle align-items-center text-center" >
                                        <img alt='' style={{ width: '90px', height: '70px' }} src={this.state.images[index]} />
                                    </div>
                                    <div className="row justify-content-center align-middle align-items-center text-center" >
                                        <p style={{ color: 'white', fontSize: '10px' }} className="col text-center">{this.state.names[index]}</p>
                                    </div>
                                </div>
                                    
                                    ))
                            }


                              

                            </Slider >
                        </div >
                    </div >
                </div>
            </>


        );

        return (
            <>
             <div className="container-fluid mt-3 mb-5 d-none d-md-block d-xl-none">
                    <div className="row justify-content-center align-middle  text-center" style={{  border: `1px solid ${this.state.lineColor}`, paddingTop: '2%', paddingBottom: '1%', background: this.state.lineColor }}>

                    {
                                    Object.keys(this.state.names).map((someId,index) => ( 
                                    <>
                                    <div className="col-1 mx-3">
                                        <img alt='' className='img-fluid' src={this.state.images[index]} />
                                    </div>
                                    </>
                                    ))
                    }
                    </div>
                    <div className="row justify-content-center align-middle  text-center" style={{ border: `1px solid ${this.state.lineColor}`, paddingTop: '1%', paddingBottom: '2%', background: this.state.lineColor }}>
                     {
                                    Object.keys(this.state.names).map((someId,index) => ( 
                                    <>
                                    <div className="col-1  mx-3 text-wrap text-center p-0"   style={{ color: 'white' }}>{this.state.names[index]}
                                    
                                       
                                    
                                    </div>
                                    </>
                                    ))
                    }
                    </div >

                </div >
                <div className="container-fluid mt-3 mb-5 d-none d-xl-block">
                    <div className="row justify-content-center align-middle  text-center" style={{ paddingTop: '2%', paddingBottom: '2%', background: this.state.lineColor }}>

                    {
                                    Object.keys(this.state.names).map((someId,index) => ( 
                                    <>
                                    <div className="col-1  mx-4 ">
                                        <img alt='' style={{ width: '120px', height: '120px' }} src={this.state.images[index]} />
                                        <p style={{ color: 'white', paddingTop: '2%', fontSize: '14px' }} className="col text-center">{this.state.names[index]}</p>
                                    </div>
                                    </>
                                    ))
                    }

                    

                    </div >

                </div >
            </>
        );
    }
}


export default withGetScreen(Ruler);