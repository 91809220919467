import React from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Chip} from './chip';
import plug from '../img/aboutCompany.jpg';


import {withGetScreen} from 'react-getscreen';

class AboutProxeet extends React.Component {

    constructor(props) {
        super(props);

        this.props.callAbout();


        this.jsonGet();

    }


    state = {


        text: [],
        currentText: '',
        active: 1,


    }


    jsonGet = async () => {


        let aboutData = new FormData();
        aboutData.append('table', 'aboutProxeet');


        const news = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_FULL_API,
            data: aboutData
        });

        const data = await news.data;

        let text_arr = [];

        text_arr.push(data[0].text1);
        text_arr.push(data[0].text2);


        this.setState({text: text_arr});
        this.setState({currentText: data[0].text1});

    }

    textHandler = (e) => {

        let index = Number(e.target.getAttribute("data-index-number"));
        console.log("item in about proxeet set: "+index);
        console.log(this.state.text[index - 1]);

        this.setState({currentText: this.state.text[index - 1]});
        this.setState({active: Number(index)});


    }

    render() {


        if (this.props.isMobile()) return (

            <>
                <div className="container-fluid" style={{marginBottom: '15%', position: 'relative'}}>

                    {<Chip l={'75'} t={'35'} w={'20'} rotate={'30'}/>}

                    <Container>
                        <Nav className='row justify-content-center align-middle  text-center my-3' style={{}}>
                            <Nav.Link className='col-3 open-sans font-weight-bold' style={{
                                fontWeight: this.state.active === 1 ? 'bold' : '',
                                color: '#152F4E',
                                fontSize: '12px',
                                borderBottom: '1px solid #d3d3d3'
                            }} data-index-number={1} onClick={this.textHandler}>Mission</Nav.Link>
                            <Nav.Link className='col-3 open-sans' style={{
                                fontWeight: this.state.active === 2 ? 'bold' : '',
                                color: '#152F4E',
                                fontSize: '12px',
                                borderBottom: '1px solid #d3d3d3'
                            }} data-index-number={2} onClick={this.textHandler}>Values</Nav.Link>
                        </Nav>
                    </Container>


                    <div className="row justify-content-center align-middle  text-center">
                        <div className='col-12 p-1 my-1'>

                            <span className='ttt2 ' style={{fontSize: '28px'}}>ABOUT PRO</span>
                            <span className='chip' style={{fontSize: '28px', color: '#FF7E33'}}>X </span>
                            <span className='ttt2 ' style={{fontSize: '28px'}}>EET</span>

                        </div>


                        <div className='col-12 my-1'>
                            <p className='text-start' dangerouslySetInnerHTML={{__html: this.state.currentText}}/>
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <img
                            alt=''
                            className='col-12'
                            style={{margin: '0', padding: '0'}}
                            src={plug}
                        />
                    </div>
                </div>
            </>
        );

        if (this.props.isTablet()) return (


            <>
                <div className="container-fluid" style={{marginBottom: '15%', position: 'relative'}}>

                    {<Chip l={'75'} t={'35'} w={'20'} rotate={'30'}/>}

                    <Container>
                        <Nav className='row justify-content-center align-middle  text-center my-3' style={{}}>
                            <Nav.Link className='col-3 open-sans font-weight-bold' style={{
                                fontWeight: this.state.active === 1 ? 'bold' : '',
                                color: '#152F4E',
                                fontSize: '12px',
                                borderBottom: '1px solid #d3d3d3'
                            }} data-index-number={1} onClick={this.textHandler}>Mission</Nav.Link>
                            <Nav.Link className='col-3 open-sans' style={{
                                fontWeight: this.state.active === 2 ? 'bold' : '',
                                color: '#152F4E',
                                fontSize: '12px',
                                borderBottom: '1px solid #d3d3d3'
                            }} data-index-number={2} onClick={this.textHandler}>Values</Nav.Link>
                            {/* <Nav.Link className='col-3 open-sans' style={{ color: '#152F4E', fontSize: '12px' }} data-index='3' onClick={this.textHendler} >Benefits</Nav.Link>*/}
                        </Nav>
                    </Container>


                    <div className="row justify-content-center align-middle  text-center">
                        <div className='col-12 p-1 my-1' style={{height: '10vh'}}>

                            <span className='ttt2 ' style={{fontSize: '28px'}}>ABOUT PRO</span>
                            <span className='chip' style={{fontSize: '28px', color: '#FF7E33'}}>X </span>
                            <span className='ttt2 ' style={{fontSize: '28px'}}>EET</span>

                        </div>


                        <div className='col-12 my-1'>
                            <p className='text-start' dangerouslySetInnerHTML={{__html: this.state.currentText}}/>
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <img alt='' className='col-12' style={{margin: '0', padding: '0'}} src={plug}></img>
                    </div>
                </div>
            </>

        );


        return (
            <>
                <div className="container-fluid">


                    <div className="row justify-content-end align-middle  text-center">
                        <Navbar expand="md" className='col-12 col-xl-7 mx-5 mt-3 '>
                            <Container>
                                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                <Navbar.Collapse id="basic-navbar-nav"
                                                 style={{background: '#FFFFFF', borderBottom: '0px solid #d3d3d3'}}
                                                 className="text-end justify-content-end">
                                    <Nav>
                                        <Nav.Link className='mx-2'><p
                                            style={{fontWeight: this.state.active === 1 ? 'bold' : ''}}
                                            data-index-number={1} onClick={this.textHandler}>Mission</p></Nav.Link>
                                        <Nav.Link className='mx-2'><p
                                            style={{fontWeight: this.state.active === 2 ? 'bold' : ''}}
                                            data-index-number={2} onClick={this.textHandler}>Values</p></Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                    <div className="row justify-content-end align-middle  text-center mx-5 mb-4">
                        <div className='col-md-4 col-xl-2' style={{borderBottom: '1px solid #d5d5d5'}}></div>
                    </div>


                    <div className='row justify-content-start align-middle  text-center position-relative'>


                        <Chip l={'87'} t={'70'} w={'40'} rotate={'50'}/>

                        <div className='col-12 col-xl-5 p-3 my-3'>

                            <span className='ttt2 ' style={{fontSize: '48px'}}>ABOUT PRO</span>
                            <span className='chip' style={{fontSize: '48px', color: '#FF7E33'}}>X </span>
                            <span className='ttt2 ' style={{fontSize: '48px'}}>EET</span>

                        </div>


                        <div className='col-11 col-xl-6 mx-4 my-3 text-start text-xl-start'>

                            <p dangerouslySetInnerHTML={{__html: this.state.currentText}}/>

                        </div>

                    </div>

                    <div className="row justify-content-center align-middle  text-md-center">
                        <img alt='' style={{margin: '0', padding: '0'}} src={plug}></img>
                    </div>
                </div>
            </>
        );
    }
}


export default withGetScreen(AboutProxeet);