import React from 'react';
import crab from '../img/chop-orange.svg';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SocialButton } from './SocialButton';
import { Chip } from './chip';

export class Contacts extends React.Component {

    constructor(props) {
        super(props);

        this.props.callContacts();

    }

    state = {


        proxeetAddress: 'Proxeet Ltd, Neofytou Nikolaidi, 61, Paphos, Cyprus',
        copied: false,
        mal: '\u0456&#x202F;n&#x202F;f&#x202F;o&#x202F;&#64&#x202F;p&#x202F;r&#x202F;o&#x202F;x&#x202F;e&#x202F;e&#x202F;t&#46;<![if !IE]>&#x202F;c&#x202F;<![endif]>o&#x202F;m',
        e: 'info',
        ma: 'proxeet.com',
    }

    render() {

        return (
            <>

                <ToastContainer className="p-3 position-fixed" position={'bottom-end'} style={{ zIndex: 1 }} >

                    <Toast show={this.state.copied} onClose={() => { this.setState({ copied: false }); }} delay={5000} autohide >
                        <Toast.Header>
                            <img src={crab} style={{ height: '20px', width: '20px' }} className="rounded me-2" alt="" />
                            <strong className="me-auto">Proxeet</strong>
                            <small></small>
                        </Toast.Header>
                        <Toast.Body>Proxeet address is copied to clipboard</Toast.Body>
                    </Toast>

                </ToastContainer>

                <div className="container-fluid mt-5">
                    <div className="row justify-content-center align-middle  text-center" style={{ position: 'relative', padding: '0', margin: '0' }} >
                        <div className='d-none d-md-block'>
                            <Chip l={'5'} t={'20'} w={'15'} rotate={'60'} />
                            <Chip l={'7'} t={'180'} w={'35'} rotate={'45'} />
                            <Chip l={'87'} t={'40'} w={'45'} rotate={'320'} />
                        </div>
                        <span className="col-10 col-md-8 proxeet-mob-header" style={{ fontSize: '2rem', position: 'relative' }} >Contacts</span>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <CopyToClipboard text={this.state.proxeetAddress}
                            onCopy={() => this.setState({ copied: true })}>
                            <p className="col-10 col-md-8 mt-4" style={{ position: 'relative' }} ><span style={{ color: '#95BBDB' }} >OFFICE:</span><br></br>Proxeet Ltd, Neofytou Nikolaidi, 61, Paphos, Cyprus<br></br><br></br></p>
                        </CopyToClipboard>
                    </div>


                    <div className="row justify-content-center align-middle  text-center mb-5">
                        <span style={{ color: '#95BBDB' }} >CONTACT:</span><br></br>   <a href={"tel:" + this.props.proxeetPhone}> <span style={{ color: '#00477A' }}>Phone: {this.props.proxeetPhone}</span> </a>
                    </div>

                    <div className="row justify-content-center align-middle  text-center mb-5">
                        <span style={{ color: '#95BBDB' }} >Email:</span><br></br><a href={'mailto:' + this.state.e + '@' + this.state.ma}><span style={{ color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.mal }} /> </a>
                    </div>



                    {<SocialButton color={'white'} />}






                    <div className="row justify-content-center align-middle  text-md-center mt-5">
                        <iframe title='map' className="col-11 col-md-8"
                                style={{ height: '60vh', border: "0", borderRadius: '50px' }}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3277.32637301815!2d32.42851127646723!3d34.77255817289431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e7068940b47107%3A0xa05709db3b0bd58e!2zzp3Otc6_z4bPjc-Ezr_PhSDOnc65zrrOv867zrHOr860zrcgNjEsIFBhcGhvcywg0JrQuNC_0YA!5e0!3m2!1sru!2s!4v1681324042183!5m2!1sru!2s"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">

                        </iframe>
                    </div>
                </div>
            </>
        );
    }
}