import React from 'react';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import gear from '../img/orangeGear.svg';
import elips from '../img/ellipse.svg';
import chipWhite from '../img/chip-white.svg';
import { Chip } from './chip';
import { withGetScreen } from 'react-getscreen';
import Accordion from 'react-bootstrap/Accordion';

class Manual extends React.Component {

    constructor(props) {
        super(props);


        this.props.callSupport();

        this.jsonGet();

    }




    state = {

        productID: 1,
        ProductName: '',
        productCard: {},
        dropList: {},
    }


    componentWillUpdate(nextProps, nextState) {

        /*   if (this.state.productID !== nextState.productID) {
  
              this.ProductCardGet();
  
          } */


    }

    jsonGet = async () => {

        const formData = new FormData();

        formData.append('table', 'ProductCard');
        formData.append('rows', 'id,ProductName');

        const api_call = await axios.post(process.env.REACT_APP_PROXEET_FULL_API, formData);

        const data = await api_call.data;

        this.setState({ dropList: data });

        this.setState({ ProductName: data[0].ProductName });


        this.ProductCardGet(1);

    }

    ProductCardGet = async (id) => {

        const formData = new FormData();

        formData.append('id', id);
        formData.append('table', 'ProductCard');


        const api_call = await axios.post(process.env.REACT_APP_PROXEET_FULL_API, formData);

        const data = await api_call.data;

        this.setState({ productCard: data });



    }

    productSet = (e) => {


        let value = e.target.getAttribute("data-index");
        this.ProductCardGet(value);
        let name = e.target.getAttribute("data-index-name");
        this.setState({ ProductName: name });
        this.setState({ productID: value });



    }

    render() {


        if (this.props.isMobile()) return (

            <>
                <div className='container-fluid mt-4 position-relative'>

                    {<Chip l={'82'} t={'20'} w={'15'} rotate={'45'} />}

                    {<Chip l={'85'} t={'90'} w={'15'} rotate={'45'} />}



                    <div className='row justify-content-center  text-center'>
                        <div className='col-12 my-4'><span className='proxeet-pc-header'>SELECT A PRODUCT</span></div>
                    </div>


                    <div className='row justify-content-center'>
                        <div className='col-12 text-center'>
                            <Dropdown >
                                <Dropdown.Toggle className='col-12 text-center' id="dropdown-basic" style={{ fontSize: '1.2rem', height: '3rem', fontWeight: 'bold', border: '0', background: '#95BBDB' }}>
                                    {this.state.ProductName}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-100'>
                                    {
                                        Object.keys(this.state.dropList).map((item) => (
                                            <Dropdown.Item
                                                onClick={this.productSet}
                                                className='d-flex align-items-center'
                                                style={{ fontWeight: 'bold', height: '3rem' }}

                                                data-index-name={this.state.dropList[item].ProductName}
                                                data-index={this.state.dropList[item].id} >
                                                {this.state.dropList[item].ProductName}
                                            </Dropdown.Item>
                                        ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>


                    </div>


                    <div className="row justify-content-center align-middle  text-md-center pt-4">
                        {Object.keys(this.state.productCard).length !== 0 &&
                            <img alt='' onClick={''}
                                src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/md/' + 1 + '.png'} />
                        }
                    </div>

                    <div className="row justify-content-center align-middle  text-center my-4">
                        <div className='col-12'><span className='proxeet-pc-header' style={{ fontSize: '22px' }} dangerouslySetInnerHTML={{ __html: this.state.ProductName }} /> </div>

                    </div>

                    <div className="row justify-content-center">
                        <div className='col-11' style={{ background: '#F8F8F8' }}>
                            <ul className="list-group text-start" style={{ border: '0' }}>
                                {Object.keys(this.state.productCard).length !== 0 &&

                                    this.state.productCard[0].spec.split(";").map((CharacterItem) => (

                                        <li className="list-group-item"
                                            style={{ color: '#152F4E', border: '0', background: '#F8F8F8' }}><img alt='' style={{ paddingRight: '2%' }} src={elips} />{CharacterItem}</li>


                                    ))

                                }
                            </ul>
                        </div>
                    </div>


                    <div className="row justify-content-center text-center my-3">

                        <Accordion bsPrefix='x' className="my-4">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <button className="btn mx-2" style={{
                                        width: '300px',
                                        border: '2px solid #152F4E',
                                        borderRadius: '30px 30px 30px 30px'
                                    }}>
                                        <img alt='' src={gear} width="30"/>
                                        <span className='open-sans'>Battery compatibility</span>
                                        <span className='open-sans ' style={{fontSize: '0.8rem'}}>▼</span>
                                    </button>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul className="list-group text-start my-3" style={{ border: '0' }}>
                                        {Object.keys(this.state.productCard).length !== 0 &&

                                            this.state.productCard[0].ShortCharacter.split(";").map((CharacterItem, index) => (
                                                <li className="list-group-item" style={{ fontSize: '1rem', color: '#152F4E', border: '0', background: '#F8F8F8' }}>
                                                    <img alt='' style={{ paddingRight: '2%' }} src={elips} />
                                                    {CharacterItem}
                                                    <button className=" mx-2"
                                                        style={{ color: "#FFFFFF", border: "0", borderRadius: "20px", background: this.state.productCard[0].dieColor.split(";")[index] }} >
                                                        <span className="mx-2" style={{ color: '#FFF' }} >
                                                            {this.state.productCard[0].die.split(";")[index]}
                                                        </span>
                                                    </button>
                                                </li>

                                            ))

                                        }
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>



                    </div>
                    <div className="row justify-content-center mb-4">
                        {Object.keys(this.state.productCard).length !== 0 &&
                            <ButtonGroup className='col-12' vertical>
                                <a className='col-7' href={"manual/" + this.state.productCard[0].manualLink + ".pdf"} target="_blank" rel="noreferrer">
                                    <Button className='col-12 mx-4 my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />User Manual</Button></a>
                                <a className='col-7' href={"pdf/" + this.state.productCard[0].pdfLink + ".pdf"} target="_blank" rel="noreferrer">
                                    <Button className='col-12 mx-4 my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />Datasheet</Button></a>

                            </ButtonGroup>
                        }
                    </div>

                </div >

            </>

        );


        if (this.props.isTablet()) return (

            <>
                <div className='container-fluid mb-4' style={{ position: 'relative' }}>

                    {<Chip l={'82'} t={'20'} w={'15'} rotate={'45'} />}

                    {<Chip l={'85'} t={'90'} w={'15'} rotate={'45'} />}



                    <div className='row justify-content-center  text-center'>
                        <div className='col-12 my-4'><span className='proxeet-pc-header'>SELECT A PRODUCT</span></div>
                    </div>


                    <div className='row justify-content-center'>

                        <Dropdown className='row justify-content-center'>
                            <Dropdown.Toggle className='text-center ttt col-10' id="dropdown-basic" style={{ fontWeight: 'bold', fontSize: '1.2rem', height: '3rem', border: '0', backgroundColor: '#95BBDB' }}>
                                {this.state.ProductName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='w-100' style={{ border: '1px solid #95BBDB' }}>
                                {
                                    Object.keys(this.state.dropList).map((item) => (
                                        <Dropdown.Item
                                            className='d-flex align-items-center'
                                            style={{ fontWeight: 'bold', height: '3rem' }}
                                            onClick={this.productSet}
                                            data-index-name={this.state.dropList[item].ProductName}
                                            data-index={this.state.dropList[item].id} >
                                            {this.state.dropList[item].ProductName}
                                        </Dropdown.Item>))
                                }
                            </Dropdown.Menu>
                        </Dropdown>



                    </div>


                    <div className="row justify-content-center align-middle text-md-center pt-4">
                        {Object.keys(this.state.productCard).length !== 0 &&
                            <img alt='' onClick={''}
                                src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/md/' + 1 + '.png'} />
                        }
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        {/* <div className='col-12'><span className='proxeet-pc-header' style={{ fontSize: '22px', paddingTop: '5%' }} dangerouslySetInnerHTML={{ __html: this.state.ProductName }} /> </div> */}
                        {/*<div className='col-6'><a href={this.state.pdfLink} target="_blank" rel="noreferrer"><img alt='' style={{ paddingTop: '3%' }} src={pdf} ></img><br/><span style={{ fontSize: '12px', color: '#00477a'  }} className='ttt' >Download Datasheet</span></a></div>*/}
                    </div>

                    <div className="row justify-content-center">
                        <div className='col-11' style={{ background: '#F8F8F8' }}>
                            <ul className="list-group text-start border-0">

                                {Object.keys(this.state.productCard).length !== 0 &&

                                    this.state.productCard[0].spec.split(";").map((CharacterItem) => (

                                        <li className="list-group-item"
                                            style={{ color: '#152F4E', border: '0', background: '#F8F8F8' }}>
                                            <img alt='' style={{ paddingRight: '2%' }} src={elips} />
                                            {CharacterItem}
                                        </li>

                                    ))

                                }
                            </ul>
                        </div>
                    </div>


                    <div className="row justify-content-center text-center">

                        <Accordion bsPrefix='x' className="my-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <button className="btn mx-2" style={{
                                        width: '300px',
                                        border: '2px solid #152F4E',
                                        borderRadius: '30px 30px 30px 30px'
                                    }}>
                                        <img alt='' src={gear} width="30"/>
                                        <span className='open-sans'>Battery compatibility</span>
                                        <span className='open-sans ' style={{fontSize: '0.8rem'}}>▼</span>
                                    </button>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul className="list-group text-start my-3" style={{ border: '0' }}>
                                        {Object.keys(this.state.productCard).length !== 0 &&

                                            this.state.productCard[0].ShortCharacter.split(";").map((CharacterItem, index) => (

                                                <li className="list-group-item" style={{ color: '#152F4E', border: '0', background: '#F8F8F8' }}>
                                                    <img alt='' style={{ paddingRight: '2%' }} src={elips} />
                                                    {CharacterItem}
                                                    <button className=" mx-2"
                                                        style={{ color: "#FFFFFF", border: "0", borderRadius: "20px", background: this.state.productCard[0].dieColor.split(";")[index] }} >
                                                        <span className="mx-2" style={{ color: '#FFF' }} >
                                                            {this.state.productCard[0].die.split(";")[index]}
                                                        </span>
                                                    </button>
                                                </li>


                                            ))

                                        }
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>



                    </div>
                    <div className="row justify-content-center">
                        {Object.keys(this.state.productCard).length !== 0 &&
                            <ButtonGroup className='col-12' vertical>
                                <a className='col-7' href={"manual/" + this.state.productCard[0].manualLink + ".pdf"} target="_blank" rel="noreferrer">
                                    <Button className='col-12 mx-4 my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />User Manual</Button></a>
                                <a className='col-7' href={"pdf/" + this.state.productCard[0].pdfLink + ".pdf"} target="_blank" rel="noreferrer">
                                    <Button className='col-12 mx-4 my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />Datasheet</Button></a>
                                {/*<Button className='my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />How to setup the Station mode</Button>
                        <Button className='my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />How to setup the Client+ AP mode</Button>
                        <Button className='my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />How to configure the AP mode</Button>
                        <Button className='my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#152F4E' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />Video Tutorial</Button>*/}
                            </ButtonGroup>
                        }
                    </div>

                </div>



            </>

        );


        return (


            <>

                <div className='container-fluid'>


                    <div className='row justify-content-center mt-3'>

                        <div className='col-5'>

                            <div className='row justify-content-center text-center'><div className='col-12 py-4'><span className='proxeet-pc-header'>SELECT A PRODUCT</span></div></div>

                            <div className='row justify-content-left'>
                                <div className='col-12 text-left py-3'>

                                    <Dropdown >
                                        <Dropdown.Toggle className='col-12 text-center ttt' id="dropdown-basic" style={{ fontWeight: 'bold', fontSize: '1.2rem', height: '3rem', border: '0px solid #152F4E', backgroundColor: '#95BBDB' }}>
                                            {this.state.ProductName}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='w-100' style={{ border: '1px solid #95BBDB' }}>
                                            {
                                                Object.keys(this.state.dropList).map((item) => (
                                                    <Dropdown.Item
                                                        className='d-flex align-items-center'
                                                        style={{ fontWeight: 'bold', height: '3rem' }}
                                                        onClick={this.productSet}
                                                        data-index-name={this.state.dropList[item].ProductName}
                                                        data-index={this.state.dropList[item].id} >
                                                        {this.state.dropList[item].ProductName}
                                                    </Dropdown.Item>))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                        </div>

                        <div className='col-10 col-xl-6 mx-2' style={{ position: 'relative' }} >


                            <Chip l={'85'} t={'65'} w={'20'} rotate={'30'} />



                            <div className="row justify-content-center align-middle  text-md-center">
                                <div className='col-6'>
                                    <div className="row justify-content-center align-middle  text-md-center pt-4">

                                        {Object.keys(this.state.productCard).length !== 0 &&
                                            <img alt='' onClick={''} src={process.env.REACT_APP_PRODUCTS_IMG_ON_SERVER + '/' + this.state.productCard[0].imgDir + '/md/' + 1 + '.png'} />

                                        }
                                    </div>
                                </div>
                                <div className='col-6 pt-4'>




                                    <div className="row justify-content-center align-middle  text-md-center">
                                        <div className='col-10'><span style={{ fontSize: '1.5rem', lineHeight: '2rem', paddingTop: '5%' }} className='proxeet-pc-header' dangerouslySetInnerHTML={{ __html: this.state.ProductName }} /> </div>

                                    </div>

                                    <div className="row justify-content-left">
                                        <div className='col-12' style={{ background: '#F8F8F8' }}>
                                            <ul className="list-group text-start border-0">

                                                {Object.keys(this.state.productCard).length !== 0 &&

                                                    this.state.productCard[0].spec.split(";").map((CharacterItem) => (

                                                        <li className="list-group-item" style={{ color: '#152F4E', border: '0', background: '#F8F8F8' }}>
                                                            <img alt='' style={{ paddingRight: '2%' }} src={elips} />{CharacterItem}</li>


                                                    ))

                                                }

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row justify-content-start align-middle  text-start">
                                        <div className="col-12 my-3">
                                            <Accordion bsPrefix='x'>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <button className="btn" style={{
                                                            width: '300px',
                                                            border: '2px solid #152F4E',
                                                            borderRadius: '30px 30px 30px 30px'
                                                        }}>
                                                            <img alt='' src={gear} width="30"/>
                                                            <span className='open-sans'>Battery compatibility</span>
                                                            <span className='open-sans '
                                                                  style={{fontSize: '0.8rem'}}>▼</span>
                                                        </button>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className="list-group text-start my-3" style={{ border: '0' }}>
                                                            {Object.keys(this.state.productCard).length !== 0 &&

                                                                this.state.productCard[0].ShortCharacter.split(";").map((CharacterItem, index) => (

                                                                    <li className="list-group-item" style={{ color: '#152F4E', border: '0', background: '#F8F8F8' }}>
                                                                        <img alt='' style={{ paddingRight: '2%' }} src={elips} />
                                                                        {CharacterItem}
                                                                        <button className=" mx-2" style={{ color: "#FFFFFF", border: "0", borderRadius: "20px", background: this.state.productCard[0].dieColor.split(";")[index] }} >
                                                                            <span className="mx-2" style={{ color: '#FFF' }} >
                                                                                {this.state.productCard[0].die.split(";")[index]}
                                                                            </span>
                                                                        </button>
                                                                    </li>


                                                                ))

                                                            }

                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                            </Accordion>

                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        {Object.keys(this.state.productCard).length !== 0 &&
                                            <ButtonGroup className='col-12' vertical>

                                                <a className='col-7' href={"manual/" + this.state.productCard[0].manualLink + ".pdf"} target="_blank" rel="noreferrer">
                                                    <Button className='col-12 mx-4 my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} >
                                                        <img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />User Manual</Button></a>
                                                <a className='col-7' href={"pdf/" + this.state.productCard[0].pdfLink + ".pdf"} target="_blank" rel="noreferrer">
                                                    <Button className='col-12 mx-4 my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} >
                                                        <img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />Datasheet</Button></a>
                                                {/*<Button className='my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />How to setup the Station mode</Button>
                                                    <Button className='my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />How to setup the Client+ AP mode</Button>
                                                    <Button className='my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#95BBDB' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />How to configure the AP mode</Button>
                                                    <Button className='my-2 text-start' style={{ borderRadius: '5px', border: 'none', backgroundColor: '#152F4E' }} ><img style={{ marginRight: '10px' }} src={chipWhite} alt="chip" width="30" />Video Tutorial</Button>*/}

                                            </ButtonGroup>
                                        }
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div >

                </div >
            </>
        );
    }
}


export default withGetScreen(Manual);
