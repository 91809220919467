import React from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withGetScreen } from 'react-getscreen';
import { Chip } from '../chip';

class NewsSearchResult extends React.Component {

    constructor(props) {
        super(props);

        this.jsonGet();

    }


    state = {

        news: {},
        maxId: '',
        SearchResult: {},

    }



    componentDidUpdate(prevProps, prevState) {

        if (this.props.newsSearchString !== prevProps.newsSearchString || this.state.news !== prevState.news) {

            this.makeSearchList();

        }




    }




    jsonGet = async () => {

        let newsData = new FormData();
        newsData.append('table', 'news');




        const news_get = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: newsData });

        const news_data = await news_get.data;

        this.setState({ news: news_data });


        // this.setState({ maxId: Object.keys(news_data).length });



    }

    makeSearchList = () => {



        let searchFormValue = this.props.newsSearchString;





        const SearchList = this.state.news;
        let item_arr = [];
        const ObjectForProps = {};


        for (let element in SearchList) {

            item_arr.push(SearchList[element].text);
            item_arr.push(SearchList[element].header);
            item_arr.push(SearchList[element].fullText);
            item_arr.push(SearchList[element].fullHeader);
            item_arr.push(SearchList[element].themes);

        }



        const f = item_arr.filter(function (e) {



            return e.toLowerCase().includes(searchFormValue.toLowerCase());


        });




        f.forEach(e => {

            for (let key in SearchList) {


                if (this.props.searchSrc === 'themes') {

                    if (SearchList[key].themes.includes(e)) {

                        ObjectForProps[key] = SearchList[key];
                    }



                }
                else if (this.props.searchSrc === 'base') {


                    if (SearchList[key].text.includes(e) || SearchList[key].header.includes(e)) {

                        ObjectForProps[key] = SearchList[key];
                    }

                }
            }

        })

        if (searchFormValue !== '') {

            this.setState({ SearchResult: ObjectForProps });
            this.setState({ maxId: Object.keys(ObjectForProps).length });


        }
        else {

            this.setState({ SearchResult: {} });

        }

    }



    render() {










        if (this.props.isMobile()) return (

            <>

                <div style={{ marginBottom: '10%' }} className="container-fluid mt-4">
                    <div className="row justify-content-center align-middle  text-center">
                        <span className='col-12 proxeet-pc-header' style={{ marginTop: '3%', marginBottom: '5%', position: 'relative' }} >NEWS SEARCH RESULT</span>
                    </div>


                    <div className="row justify-content-center align-middle  text-center" style={{ position: 'relative' }}>

                        {<Chip l={'75'} t={'65'} w={'25'} rotate={'30'} />}

                        <div className="row">

                            {
                                Object.keys(this.state.SearchResult).map((someId) => (
                                    <>

                                        <Link className='col-6' onClick={() => { window.scrollTo(0, 0); }} to={'/news/' + someId}>
                                            <Card className="my-3 border border-1 shadow" style={{ borderRadius: '30px 30px 30px 30px' }}>
                                                <Card.Img variant="top" src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName} style={{ borderRadius: '30px 30px 0 0' }} />
                                                <Card.Body className='mb-0 pb-0' >
                                                    <Card.Title style={{ color: '#152F4E' }} ><span className='text-start mb-0 proxeet-pc-header' style={{ color: "#152F4E", fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }} /></Card.Title>

                                                </Card.Body>
                                                <Card.Footer style={{ borderRadius: '0 0 30px 30px', background: '#FFF' }}>
                                                    <p className='text-start pt-0' style={{ fontSize: '10px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.news[someId].newsData }} />
                                                </Card.Footer>
                                            </Card>
                                        </Link>

                                    </>
                                ))
                            }


                        </div>
                    </div>


                </div>
            </>

        );

        if (this.props.isTablet()) return (

            <>
                <div style={{ marginBottom: '10%' }} className="container-fluid mt-4">
                    <div className="row justify-content-center align-middle  text-center">
                        <span className='col-12 proxeet-pc-header' style={{ marginTop: '3%', marginBottom: '5%', position: 'relative' }} >NEWS SEARCH RESULT</span>
                    </div>


                    <div className="row justify-content-center align-middle  text-center" style={{ position: 'relative' }}>

                        {<Chip l={'75'} t={'65'} w={'25'} rotate={'30'} />}

                        <div className="row">

                            {
                                Object.keys(this.state.SearchResult).map((someId) => (
                                    <>

                                        <Link className='col-6' onClick={() => { window.scrollTo(0, 0); }} to={'/news/' + someId}>
                                            <Card className="my-3 border border-1 shadow" style={{ borderRadius: '30px 30px 30px 30px' }}>
                                                <Card.Img variant="top" src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName} style={{ borderRadius: '30px 30px 0 0' }} />
                                                <Card.Body className='mb-0 pb-0' >
                                                    <Card.Title style={{ color: '#152F4E' }} ><span className='text-start mb-0 proxeet-pc-header' style={{ color: "#152F4E", fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }} /></Card.Title>

                                                </Card.Body>
                                                <Card.Footer style={{ borderRadius: '0 0 30px 30px', background: '#FFF' }}>
                                                    <p className='text-start pt-0' style={{ fontSize: '10px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.news[someId].newsData }} />
                                                </Card.Footer>
                                            </Card>
                                        </Link>

                                    </>
                                ))
                            }


                        </div>
                    </div>


                </div>


            </>



        );

        return (
            <>
                <div style={{ marginTop: '0%', marginBottom: '10%' }} className="container-fluid">
                    <div className="row justify-content-center align-middle  text-start">
                        <span className='col-9 proxeet-pc-header' style={{ marginTop: '3%', marginBottom: '5%', position: 'relative' }} >NEWS SEARCH RESULT</span>
                    </div>

                    <div className='row container-fluid'>
                        <Chip l={'80'} t={'85'} w={'60'} rotate={'40'} />
                    </div>

                    <div className="row justify-content-center align-middle  text-md-center">

                        <div className="col-10" style={{ position: 'relative', padding: '0', marginTop: '5%' }} >
                            <Chip l={'-15'} t={'30'} w={'25'} rotate={'40'} />

                            <Chip l={'-8'} t={'100'} w={'15'} rotate={'60'} />
                            {/* <Link className='col-4' onClick={() => { window.scrollTo(0, 0); }} to={'/news/' + this.state.SearchResult[someId].id}> */}







                            <CardGroup>
                                {
                                    Object.keys(this.state.SearchResult).slice(0, 3).map((someId) => {




                                        return (
                                            <Card
                                                href={'/news/' + this.state.SearchResult[someId].id}
                                                as="a"
                                                className="col-4 m-4 border border-1 shadow br-30">
                                                <Card.Img
                                                    variant="top"
                                                    src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                                    style={{ borderRadius: '30px 30px 0 0' }}
                                                />
                                                <Card.Body className='mx-4'>
                                                    <Card.Title
                                                        className='ttt'
                                                        style={{ color: '#152F4E' }}
                                                        dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }}
                                                    >
                                                    </Card.Title>
                                                </Card.Body>
                                                <Card.Footer
                                                    style={{
                                                        background: '#FFF',
                                                        border: '0',
                                                        borderRadius: '0 0 30px 30px ',
                                                    }}
                                                >
                                                    <small className="text-muted">
                                                        <h5
                                                            className='text-start'
                                                            style={{ fontSize: '0.9rem', color: '#95BBDB' }}
                                                            dangerouslySetInnerHTML={{ __html: this.state.news[someId].date }}
                                                        />
                                                    </small>
                                                </Card.Footer>
                                            </Card>
                                        );
                                    })
                                }

                            </CardGroup>
                            <CardGroup>
                                {this.state.maxId >= 7 &&
                                    Object.keys(this.state.SearchResult).slice(4, 7).map((someId) => {




                                        return (
                                            <Card
                                                href={'/news/' + this.state.SearchResult[someId].id}
                                                as="a"
                                                className="col-4 m-4 border border-1 shadow br-30">
                                                <Card.Img
                                                    variant="top"
                                                    src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                                    style={{ borderRadius: '30px 30px 0 0' }}
                                                />
                                                <Card.Body className='mx-4'>
                                                    <Card.Title
                                                        className='ttt'
                                                        style={{ color: '#152F4E' }}
                                                        dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }}
                                                    >
                                                    </Card.Title>
                                                </Card.Body>
                                                <Card.Footer
                                                    style={{
                                                        background: '#FFF',
                                                        border: '0',
                                                        borderRadius: '0 0 30px 30px ',
                                                    }}
                                                >
                                                    <small className="text-muted">
                                                        <h5
                                                            className='text-start'
                                                            style={{ fontSize: '0.9rem', color: '#95BBDB' }}
                                                            dangerouslySetInnerHTML={{ __html: this.state.news[someId].date }}
                                                        />
                                                    </small>
                                                </Card.Footer>
                                            </Card>
                                        );
                                    })
                                }
                            </CardGroup>
                            <CardGroup>
                                {this.state.maxId > 11 &&
                                    Object.keys(this.state.SearchResult).slice(8, 11).map((someId) => {




                                        return (
                                            <Card
                                                href={'/news/' + this.state.SearchResult[someId].id}
                                                as="a"
                                                className="col-4 m-4 border border-1 shadow br-30">
                                                <Card.Img
                                                    variant="top"
                                                    src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                                    style={{ borderRadius: '30px 30px 0 0' }}
                                                />
                                                <Card.Body className='mx-4'>
                                                    <Card.Title
                                                        className='ttt'
                                                        style={{ color: '#152F4E' }}
                                                        dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }}
                                                    >
                                                    </Card.Title>
                                                </Card.Body>
                                                <Card.Footer
                                                    style={{
                                                        background: '#FFF',
                                                        border: '0',
                                                        borderRadius: '0 0 30px 30px ',
                                                    }}
                                                >
                                                    <small className="text-muted">
                                                        <h5
                                                            className='text-start'
                                                            style={{ fontSize: '0.9rem', color: '#95BBDB' }}
                                                            dangerouslySetInnerHTML={{ __html: this.state.news[someId].date }}
                                                        />
                                                    </small>
                                                </Card.Footer>
                                            </Card>
                                        );
                                    })
                                }
                            </CardGroup>
                        </div>
                    </div>




                </div >
            </>
        );
    }
}



export default withGetScreen(NewsSearchResult);
