import React from 'react';

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Image from 'react-bootstrap/Image';

import call from '../img/call.svg';
import mail from '../img/mail.svg';
import chat from '../img/chat.svg';
import { Chip } from './chip';
import { withGetScreen } from 'react-getscreen';


class SupportSupport extends React.Component {

    constructor(props) {
        super(props);

        this.props.callSupport();

        //this.jsonGet();

    }



    state = {}

    jsonGet = async () => { }

    render() {


        if (this.props.isTablet()) return (


            <>
                <div className="container-fluid">
                    <div className="row justify-content-center text-md-center">
                        <span className="col-10 col-sm-10 col-md-6 text-center my-4 proxeet-pc-header" style={{ position: 'relative' }} >SUPPORT</span>
                    </div>

                    <div className="row justify-content-center text-md-center">
                        <div className="row justify-content-center text-center">
                            <h3 className="col-12  text-center ttt" style={{ color: '#95BBDB', position: 'relative' }} >You can contact us via:</h3>
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle" >
                        <CardGroup>
                            <Card style={{ border: 'none' }} >

                                <Card.Body className='row text-center '>
                                    <span className='ttt p-3' style={{ fontSize: '24px', color: '#FF7E33' }}><Image className='col-1' style={{ marginRight: '20px', height: '60px' }} src={chat} />CHAT</span>
                                </Card.Body>
                                <Card.Footer className='text-center' style={{ border: 'none', backgroundColor: '#FFFFFF' }} >
                                    <button disabled  data-index={''} onClick={() => { }} className=" btn btn-proxeet ">WRITE TO CHAT</button>
                                </Card.Footer>
                            </Card>
                            <Card style={{ border: 'none' }} >

                                <Card.Body className='row text-center'>

                                    <span className='ttt p-3' style={{ fontSize: '24px', color: '#FF7E33' }}><Image className='col-1 ' style={{ marginRight: '20px', height: '60px' }} src={call} />BY PHONE</span>

                                </Card.Body>
                                <Card.Footer className='text-center' style={{ border: 'none', backgroundColor: '#FFFFFF' }} >
                                <a href={"tel:"+this.props.proxeetPhone}>
                                    <button data-index={''} onClick={() => { }} className="btn btn-proxeet">CALL</button></a>
                                </Card.Footer>
                            </Card>
                            <Card style={{ border: 'none' }} >

                                <Card.Body className='row text-center'>
                                    <span className='ttt p-3' style={{ fontSize: '24px', color: '#FF7E33' }}><Image className='col-1' style={{ marginRight: '20px', height: '60px' }} src={mail} />BY MAIL</span>
                                </Card.Body>
                                <Card.Footer className='text-center' style={{ border: 'none', backgroundColor: '#FFFFFF' }} >

                                <a href = "mailto: info@proxeet.com">
                                    <button data-index={''} onClick={() => { }} className="btn btn-proxeet">WRITE TO MAIL</button></a>
                                </Card.Footer>

                            </Card>
                        </CardGroup>


                    </div>
                </div>

            </>);


        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-center text-md-center" style={{ position: 'relative' }} >

                        <div className='d-none d-md-block' style={{ zIndex: '555' }}>
                            <Chip l={'0'} t={'150'} w={'12'} rotate={'60'} />
                            <Chip l={'90'} t={'65'} w={'30'} rotate={'40'} />
                        </div>

                        <div className='d-block d-md-none' style={{ zIndex: '555' }}>
                            <Chip l={'75'} t={'650'} w={'30'} rotate={'40'} />
                        </div>


                        <span className="col-10 col-sm-10 col-md-6 text-center my-4 proxeet-pc-header" style={{ position: 'relative' }} >SUPPORT</span>
                    </div>

                    <div className="row justify-content-center text-md-center">
                        <div className="row justify-content-center text-center">
                            <h3 className="col-12  text-center ttt" style={{ color: '#95BBDB', position: 'relative' }} >You can contact us via:</h3>
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle" >


                        <CardGroup className='mb-4'>
                            <Card style={{ border: 'none' }} >

                                <Card.Body className='row text-center '>
                                    <span className='ttt p-3' style={{ fontSize: '24px', color: '#FF7E33' }}><Image className='col-1' style={{ marginRight: '20px', height: '60px' }} src={chat} />CHAT</span>
                                </Card.Body>
                                <Card.Footer className='text-center' style={{ border: 'none', backgroundColor: '#FFFFFF' }} >
                                     <button disabled data-index={''} onClick={() => { }} className="btn btn-proxeet">WRITE TO CHAT</button>
                                </Card.Footer>
                            </Card>
                            <Card style={{ border: 'none' }} >

                                <Card.Body className='row text-center'>

                                    <span className='ttt p-3' style={{ fontSize: '24px', color: '#FF7E33' }}><Image className='col-1 ' style={{ marginRight: '20px', height: '60px' }} src={call} />BY PHONE</span>

                                </Card.Body>
                                <Card.Footer className='text-center' style={{ border: 'none', backgroundColor: '#FFFFFF' }} >
                                <a href={"tel:"+this.props.proxeetPhone}><button data-index={''} onClick={() => { }} className="btn btn-proxeet">CALL</button></a>
                                </Card.Footer>
                            </Card>
                            <Card style={{ border: 'none' }} >

                                <Card.Body className='row text-center'>
                                    <span className='ttt p-3' style={{ fontSize: '24px', color: '#FF7E33' }}><Image className='col-1' style={{ marginRight: '20px', height: '60px' }} src={mail} />BY MAIL</span>
                                </Card.Body>
                                <Card.Footer className='text-center' style={{ border: 'none', backgroundColor: '#FFFFFF' }} >

                                <a href = "mailto: info@proxeet.com"><button  data-index={''} onClick={() => { }} className="btn btn-proxeet">WRITE TO MAIL</button></a>
                                </Card.Footer>

                            </Card>
                        </CardGroup>


                    </div>
                </div>
            </>
        );
    }
}


export default withGetScreen(SupportSupport);
