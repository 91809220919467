import React from 'react';
import man from '../img/baner/inakb.png';
//import desk from '../img/Enhanced-NR.png';
import { withGetScreen } from 'react-getscreen';

import v1 from '../img/baner/1.svg';
import v2 from '../img/baner/2.svg';
import v3 from '../img/baner/3.svg';
import v4 from '../img/baner/4.svg';
import v5 from '../img/baner/5.svg';
import r1 from '../img/baner/r1.png';

import big from '../img/baner/rr.png';
import big2 from '../img/baner/rastr-2.png';


class BackgroundPhoto extends React.Component {

    constructor(props) {
        super(props);


    }



    state = {

            leftHeader: 'Power on the Go:</br> IBlock Essential Released',
            rightHeader: 'Ergonomic hand strap with</br> reflective stripe - a simple but</br> standout solution to work with</br> ease and efficiency ',
            leftText: 'PORTABLE PASSIVE POE intelligently connects mobile devices to your router or extenders, whichever provides the best connection',
            rightText: 'The hand strap offers enhanced mobility, increased efficiency, improved</br> safety, comfortable handling and versatile application'


    }



    render() {



        if (this.props.isMobile()) return (

            <>
                <div className="container-fluid">

                    <div className="row justify-content-center align-middle  text-center">

                        {this.props.orientation === 'left' ?

                            <>
                              <div className="row d-block d-md-none " style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="col-6 text-start text-wrap" style={{  position: 'absolute', top: '25%', left: '13%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.leftHeader }}  />
                                    <p className='col-6 text-start text-wrap' style={{  position: 'absolute', bottom: '22%', left: '13%', lineHeight: '1.8vw', fontSize: '1.8vw' }}   dangerouslySetInnerHTML={{ __html: this.state.leftText }}  /> 
                                    <div className='col-12 ' style={{ height: '200px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '0%', top: '0px', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', right: '10.7%', bottom: '0px', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '21%', top: '0', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
        
                                        <img src={v4} className="img-fluid" style={{ height: '55%', position: 'absolute', left: '-3%', bottom: '6%', zIndex: '5000', transform: 'rotate(90deg)' }} alt="" />
                                        <img src={v5} className="img-fluid" style={{ height: '45%', position: 'absolute', left: '0', bottom: '0',zIndex: '5000', transform: 'rotate(90deg)' }} alt="" />
                                        <img src={man} className="img-fluid" style={{ height: '80%', position: 'absolute', right: '5%', top: '10%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>

                               

                            </>

                            :

                            <>                            
                                <div className="row d-block d-md-none " style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="text-start" style={{  position: 'absolute', top: '25%', left: '33%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.rightHeader }}  />
                                    <p className='text-start' style={{  position: 'absolute', bottom: '22%', left: '33%', lineHeight: '1.8vw', fontSize: '1.8vw' }}   dangerouslySetInnerHTML={{ __html: this.state.rightText }}  /> 
                                    <div className='col-12 ' style={{ height: '200px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '0%', top: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', left: '11%', bottom: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '21%', top: '0', zIndex: '5000' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ height: '55%', position: 'absolute', right: '0.1%', bottom: '0', zIndex: '5000'}} alt="" />
                                        <img src={v5} className="img-fluid" style={{ height: '45%', position: 'absolute', right: '0', bottom: '0',zIndex: '5000' }} alt="" />
                                        <img src={r1} className="img-fluid" style={{ height: '80%', position: 'absolute', left: '-4%', top: '10%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>
                            </>


                        }
                    </div>

                </div>


            </>
        );

        if (this.props.isTablet()) return (


            <>


                <div className="container-fluid d-block">

                    <div className="row justify-content-center align-middle  text-center">

                        {this.props.orientation === 'left' ?

                            <>
                                
                                <div className="row d-block d-sm-none " style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="col-6 text-start text-wrap" style={{  position: 'absolute', top: '25%', left: '13%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.leftHeader }}  />
                                    <p className='col-6 text-start text-wrap' style={{  position: 'absolute', bottom: '22%', left: '13%', lineHeight: '1.8vw', fontSize: '1.8vw' }}   dangerouslySetInnerHTML={{ __html: this.state.leftText }}  /> 
                                    <div className='col-12 ' style={{ height: '200px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '0%', top: '0px', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', right: '9%', bottom: '0px', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '18%', top: '0', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
        
                                        <img src={v4} className="img-fluid" style={{ height: '55%', position: 'absolute', left: '-3%', bottom: '6%', zIndex: '5000', transform: 'rotate(90deg)' }} alt="" />
                                        <img src={v5} className="img-fluid" style={{ height: '45%', position: 'absolute', left: '0', bottom: '0',zIndex: '5000', transform: 'rotate(90deg)' }} alt="" />
                                        <img src={man} className="img-fluid" style={{ height: '80%', position: 'absolute', right: '2%', top: '5%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>

                                <div className="row d-none d-sm-block d-md-none" style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="col-7 text-start text-wrap" style={{  position: 'absolute', top: '25%', left: '13%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.leftHeader }}  />
                                    <p className='col-7 text-start text-wrap' style={{  position: 'absolute', bottom: '18%', left: '13%', lineHeight: '1.8vw', fontSize: '1.8vw' }}   dangerouslySetInnerHTML={{ __html: this.state.leftText }}  /> 
                                    <div className='col-12 ' style={{ height: '200px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '0%', top: '0px', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', right: '7%', bottom: '0px', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '14%', top: '0', zIndex: '5000', transform: 'scale(-1, 1)'  }} alt="" />
        
                                        <img src={v4} className="img-fluid" style={{ height: '55%', position: 'absolute', left: '-2%', bottom: '6%', zIndex: '5000', transform: 'rotate(90deg)' }} alt="" />
                                        <img src={v5} className="img-fluid" style={{ height: '45%', position: 'absolute', left: '0', bottom: '0',zIndex: '5000', transform: 'rotate(90deg)' }} alt="" />
                                        <img src={man} className="img-fluid" style={{ height: '80%', position: 'absolute', right: '2%', top: '5%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>



                            </>

                            :

                            <>
                                

                                <div className="row d-block d-sm-none  " style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="text-start" style={{  position: 'absolute', top: '25%', left: '31%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.rightHeader }}  />
                                    <p className='text-start' style={{  position: 'absolute', bottom: '16%', left: '31%', lineHeight: '1.8vw', fontSize: '1.8vw' }}   dangerouslySetInnerHTML={{ __html: this.state.rightText }}  /> 
                                    <div className='col-12 ' style={{ height: '200px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '0%', top: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', left: '9%', bottom: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '17.5%', top: '0', zIndex: '5000' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ height: '55%', position: 'absolute', right: '0.1%', bottom: '0', zIndex: '5000'}} alt="" />
                                        <img src={v5} className="img-fluid" style={{ height: '45%', position: 'absolute', right: '0', bottom: '0',zIndex: '5000' }} alt="" />
                                        <img src={r1} className="img-fluid" style={{ height: '80%', position: 'absolute', left: '-2%', top: '10%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>

                                <div className="row d-none d-sm-block d-md-none" style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="text-start" style={{  position: 'absolute', top: '18%', left: '31%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.rightHeader }}  />
                                    <p className='text-start' style={{  position: 'absolute', bottom: '12%', left: '31%', lineHeight: '1.8vw', fontSize: '1.8vw' }}   dangerouslySetInnerHTML={{ __html: this.state.rightText }}  /> 
                                    <div className='col-12 ' style={{ height: '200px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '0%', top: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', left: '7%', bottom: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '14%', top: '0', zIndex: '5000' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ height: '55%', position: 'absolute', right: '0.1%', bottom: '0', zIndex: '5000'}} alt="" />
                                        <img src={v5} className="img-fluid" style={{ height: '45%', position: 'absolute', right: '0', bottom: '0',zIndex: '5000' }} alt="" />
                                        <img src={r1} className="img-fluid" style={{ height: '80%', position: 'absolute', left: '-2%', top: '10%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>



                            </>


                        }
                    </div>

                </div>







            </>);

        return (
            <>
                <div className="container-fluid">

                    <div className="row justify-content-center align-middle  text-md-center " style={{ marginTop: '5%'}} >

                        {this.props.orientation === 'left' ?

                            <>
                            
                           <div className="row d-none d-md-block d-lg-none " style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="col-6 text-start text-wrap" style={{  position: 'absolute', top: '25%', left: '15%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.leftHeader }}  />
                                    <p className='col-5 text-start text-wrap' style={{  position: 'absolute', bottom: '25%', left: '15%', fontSize: '1.2vw' }}   dangerouslySetInnerHTML={{ __html: this.state.leftText }}  /> 
                                    <div className='col-12 ' style={{ height: '400px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '0%', top: '0px', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', right: '11%', bottom: '0px', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '21%', top: '0', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ position: 'absolute', left: '0.1%', bottom: '0', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v5} className="img-fluid" style={{ position: 'absolute', left: '0', bottom: '0',zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={man} className="img-fluid" style={{ height: '80%', position: 'absolute', left: '40%', top: '10%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>

                                <div className="row d-none d-lg-block d-xl-none " style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="col-6 text-start text-wrap" style={{  position: 'absolute', top: '15%', left: '16%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.leftHeader }}  />
                                    <p className='col-5 text-start text-wrap' style={{  position: 'absolute', bottom: '18%', left: '16%', fontSize: '1.2vw' }}   dangerouslySetInnerHTML={{ __html: this.state.leftText }}  /> 
                                    <div className='col-12 ' style={{ height: '400px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '0%', top: '0px', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', right: '7%', bottom: '0px', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '14%', top: '0', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ position: 'absolute', left: '0.1%', bottom: '0', zIndex: '5000', transform: 'scale(-1, 1)'}} alt="" />
                                        <img src={v5} className="img-fluid" style={{ position: 'absolute', left: '0', bottom: '0',zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={man} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '43%', top: '0%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>
                                

                                <div className="row d-none d-xl-block d-xxl-none" style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className='col-6 text-start text-wrap' style={{  position: 'absolute', top: '15%', left: '15%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.leftHeader }}  />
                                    <p className='col-6 text-start text-wrap' style={{  position: 'absolute', bottom: '10%', left: '15%', fontSize: '1.2vw' }}   dangerouslySetInnerHTML={{ __html: this.state.leftText }}  /> 
                                    <div className='col-12 ' style={{ height: '400px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '0%', top: '0px', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', right: '6.4%', bottom: '0px', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '13%', top: '0', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ position: 'absolute', left: '0.1%', bottom: '0', zIndex: '5000', transform: 'scale(-1, 1)'}} alt="" />
                                        <img src={v5} className="img-fluid" style={{ position: 'absolute', left: '0', bottom: '0',zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        <img src={man} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '50%', top: '0%',zIndex: '5000' }} alt="" />
                                    </div>
                                </div>

                                <div className="row d-none d-xxl-block" style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="col-5 text-start text-wrap" style={{  position: 'absolute', top: '15%', left: '15%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.leftHeader }}  />
                                    <p className='col-5 text-start text-wrap ' style={{  position: 'absolute', bottom: '20%', left: '15%',lineHeight: '1.8vw' , fontSize: '1.2vw' }}   dangerouslySetInnerHTML={{ __html: this.state.leftText }}  /> 
                                    <div className='col-12 ' style={{ height: '40vw'}}>

                                        <img src={big} className="img-fluid" style={{ height: '100%', position: 'absolute', right: '0%', top: '0px', zIndex: '5000', transform: 'scale(-1, 1)' }} alt="" />
                                        
                                        <img src={big2} className="img-fluid" style={{ height: '50%', position: 'absolute', left: '0.1%', bottom: '0', zIndex: '5000', transform: 'scale(-1, 1)'}} alt="" />
                                        
                                        <img src={man} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '33%', top: '0%',zIndex: '5000', transform: 'rotate(-5deg)'  }} alt="" />
                                    </div>
                                </div>

                            </>

                            :

                            <>

                                <div className="row d-none d-md-block d-lg-none " style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="text-start" style={{  position: 'absolute', top: '25%', left: '35%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.rightHeader }}  />
                                    <p className='text-start' style={{  position: 'absolute', bottom: '20%', left: '35%', fontSize: '1.2vw' }}   dangerouslySetInnerHTML={{ __html: this.state.rightText }}  /> 
                                    <div className='col-12 ' style={{ height: '400px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '0%', top: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', left: '11%', bottom: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '21%', top: '0', zIndex: '5000' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ position: 'absolute', right: '0.1%', bottom: '0', zIndex: '5000'}} alt="" />
                                        <img src={v5} className="img-fluid" style={{ position: 'absolute', right: '0', bottom: '0',zIndex: '5000' }} alt="" />
                                        <img src={r1} className="img-fluid" style={{ height: '80%', position: 'absolute', left: '-5%', top: '10%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>

                                <div className="row d-none d-lg-block d-xl-none " style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="text-start" style={{  position: 'absolute', top: '15%', left: '35%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.rightHeader }}  />
                                    <p className='text-start' style={{  position: 'absolute', bottom: '10%', left: '35%', fontSize: '1.2vw' }}   dangerouslySetInnerHTML={{ __html: this.state.rightText }}  /> 
                                    <div className='col-12 ' style={{ height: '400px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '0%', top: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', left: '8.5%', bottom: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '16%', top: '0', zIndex: '5000' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ position: 'absolute', right: '0.1%', bottom: '0', zIndex: '5000'}} alt="" />
                                        <img src={v5} className="img-fluid" style={{ position: 'absolute', right: '0', bottom: '0',zIndex: '5000' }} alt="" />
                                        <img src={r1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '-5%', top: '0%',zIndex: '5000', transform: 'rotate(0deg)' }} alt="" />
                                    </div>
                                </div>
                                

                                <div className="row d-none d-xl-block d-xxl-none" style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="text-start" style={{  position: 'absolute', top: '15%', left: '30%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.rightHeader }}  />
                                    <p className='text-start' style={{  position: 'absolute', bottom: '10%', left: '30%', fontSize: '1.2vw' }}   dangerouslySetInnerHTML={{ __html: this.state.rightText }}  /> 
                                    <div className='col-12 ' style={{ height: '400px'}}>
                                        <img src={v1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '0%', top: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v2} className="img-fluid" style={{ height: '52.5%', position: 'absolute', left: '6.2%', bottom: '0px', zIndex: '5000' }} alt="" />
                                        <img src={v3} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '12%', top: '0', zIndex: '5000' }} alt="" />
                                        <img src={v4} className="img-fluid" style={{ position: 'absolute', right: '0.1%', bottom: '0', zIndex: '5000'}} alt="" />
                                        <img src={v5} className="img-fluid" style={{ position: 'absolute', right: '0', bottom: '0',zIndex: '5000' }} alt="" />
                                        <img src={r1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '0', top: '0%',zIndex: '5000' }} alt="" />
                                    </div>
                                </div>

                                <div className="row d-none d-xxl-block" style={{ padding: '0', background: '#F6F6F6', position: 'relative' }} >
                                    <h2 className="text-start" style={{  position: 'absolute', top: '15%', left: '35%', color: '#95BBDB', fontSize: '3vw' }}  dangerouslySetInnerHTML={{ __html: this.state.rightHeader }}  />
                                    <p className='text-start' style={{  position: 'absolute', bottom: '20%', left: '35%',lineHeight: '1.8vw' , fontSize: '1.2vw' }}   dangerouslySetInnerHTML={{ __html: this.state.rightText }}  /> 
                                    <div className='col-12 ' style={{ height: '40vw'}}>
                                        <img src={big} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '0%', top: '0px', zIndex: '5000' }} alt="" />
                                        
                                        <img src={big2} className="img-fluid" style={{ height: '50%', position: 'absolute', right: '0.1%', bottom: '0', zIndex: '5000'}} alt="" />
                                        {/*<img src={v5} className="img-fluid" style={{ position: 'absolute', right: '0', bottom: '0',zIndex: '5000' }} alt="" />*/}
                                        <img src={r1} className="img-fluid" style={{ height: '100%', position: 'absolute', left: '-3%', top: '0%',zIndex: '5000', transform: 'rotate(-5deg)'  }} alt="" />
                                    </div>
                                </div>
                                
                                

                              



                            </>


                        }
                    </div>

                </div>
            </>
        );
    }
}

export default withGetScreen(BackgroundPhoto);