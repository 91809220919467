import React from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { Link } from 'react-router-dom';
import crab from '../img/chop-orange.svg';
import axios from 'axios';
import { withGetScreen } from 'react-getscreen';
import { Chip } from './chip';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';

import Question from './question';

class ManualSearchResult extends React.Component {

    constructor(props) {
        super(props);

        // this.props.callNews();
        this.jsonGet();

    }

    componentDidUpdate(prevProps) {

        if (this.props.manualSearchString !== prevProps.manualSearchString) {

            this.makeSearchList();

        }

    }



    state = {

        manuals: {},
        maxId: '',
        SearchResult: {},
        noResults: 'Your search returned no matches. Please check the spelling of your search terms, try other keywords or contact us via the contact form.'

    }


    jsonGet = async () => {

        let getManuals = new FormData();
        getManuals.append('table', 'manuals');
        const manuals = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: getManuals });


        const manual_data = await manuals.data;

        this.setState({ manuals: manual_data });

        this.setState({ maxId: Object.keys(manual_data).length });


    }

    makeSearchList = () => {

        let searchFormValue = this.props.manualSearchString;
        const SearchList = this.state.manuals;
        let item_arr = [];
        const ObjectForProps = {};


        for (let element in SearchList) {

            item_arr.push(SearchList[element].manualContent);
            item_arr.push(SearchList[element].id);
            item_arr.push(SearchList[element].file);

        }

        const f = item_arr.filter(function (e) {

            return e.toLowerCase().includes(searchFormValue.toLowerCase());

        });


        f.forEach(e => {

            for (let key in SearchList) {


                if (SearchList[key].manualContent.includes(e)) {

                    ObjectForProps[key] = SearchList[key];
                }
            }

        })

        if (searchFormValue !== '') {

            this.setState({ SearchResult: ObjectForProps });

        }
        else {

            this.setState({ SearchResult: {} });

        }

    }



    render() {


        /*
                const settingsMob = {
        
        
        
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SamplePrevArrow />,
                };
        
        */





        if (this.props.isMobile()) return (

            <>
                <Modal style={{ overflowY: 'auto' }}
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body >
                        <Question noChip={true} mod={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>

                </Modal>


                {this.props.manualSearchString !== '' &&

                    <div className="container-fluid my-4">


                        <div className="row justify-content-center align-middle  text-center">
                            <span className='col-12 proxeet-pc-header' style={{ position: 'relative' }} >MANUAL SEARCH RESULT</span>
                        </div>

                        <div className='row container-fluid'>
                            {/* <Chip l={'80'} t={'85'} w={'60'} rotate={'40'} />*/}
                        </div>

                        <div className="row justify-content-center align-middle  text-md-center">

                            <CardGroup>
                                {
                                    Object.keys(this.state.SearchResult).map((someId) => (

                                        <>

                                            <Link className='col-6 m-4' onClick={() => { window.scrollTo(0, 0); }} to={'/manuals/' + this.state.manuals[someId].file}>
                                                <Card className="border mx-4 my-3 border-1 shadow" style={{ borderRadius: '30px 30px 30px 30px' }}>
                                                    <Card.Img variant="top" className='p-4' src={crab} style={{ borderRadius: '30px 30px 0 0' }} />
                                                    <Card.Body className='mx-4'>
                                                        <Card.Title style={{ color: '#152F4E' }} ><span className='text-center mx-0 mb-2 proxeet-pc-header' style={{ color: "#152F4E", fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.manuals[someId].file }} /></Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        </>
                                    ))
                                }
                            </CardGroup>

                        </div>

                        <div className="row justify-content-center align-middle  text-center">
                            <h4 className='col-12' style={{ position: 'relative' }} >{this.state.noResults}</h4>
                        </div>
                        <div className="row justify-content-center align-middle  text-center">
                            <div className="col-12 my-3">
                                <button onClick={() => this.setState({ basicModal: !this.state.basicModal })} className="btn" type="button" style={{ width: '32vh', height: '7vh', border: '0px', color: 'white', backgroundColor: '#FF7E33', borderRadius: '30px 30px 30px 30px' }}><span style={{ fontSize: '20px' }} className='ttt'>connect with us</span></button>
                            </div>
                        </div>


                    </div>
                }


            </>

        );


        if (this.props.isTablet()) return (

            <>
                <Modal style={{ overflowY: 'auto' }}
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />

                    </Modal.Header>
                    <Modal.Body >
                        <Question noChip={true} mod={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>

                </Modal>


                {this.props.manualSearchString !== '' &&

                    <div className="container-fluid my-4">


                        <div className="row justify-content-center align-middle  text-center">
                            <span className='col-12 proxeet-pc-header' style={{ position: 'relative' }} >MANUAL SEARCH RESULT</span>
                        </div>

                        <div className='row container-fluid'>
                            {/* <Chip l={'80'} t={'85'} w={'60'} rotate={'40'} />*/}
                        </div>

                        <div className="row justify-content-center align-middle  text-md-center">

                            <CardGroup>
                                {
                                    Object.keys(this.state.SearchResult).map((someId) => (

                                        <>

                                            <Link className='col-6 m-4' onClick={() => { window.scrollTo(0, 0); }} to={'/manuals/' + this.state.manuals[someId].file}>
                                                <Card className="border mx-4 my-3 border-1 shadow" style={{ borderRadius: '30px 30px 30px 30px' }}>
                                                    <Card.Img variant="top" className='p-4' src={crab} style={{ borderRadius: '30px 30px 0 0' }} />
                                                    <Card.Body className='mx-4'>
                                                        <Card.Title style={{ color: '#152F4E' }} ><span className='text-center mx-0 mb-2 proxeet-pc-header' style={{ color: "#152F4E", fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.manuals[someId].file }} /></Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        </>
                                    ))
                                }
                            </CardGroup>

                        </div>

                        <div className="row justify-content-center align-middle  text-center">
                            <h4 className='col-12' style={{ position: 'relative' }} >{this.state.noResults}</h4>
                        </div>
                        <div className="row justify-content-center align-middle  text-center">
                            <div className="col-12 my-3">
                                <button onClick={() => this.setState({ basicModal: !this.state.basicModal })} className="btn" type="button" style={{ width: '32vh', height: '7vh', border: '0px', color: 'white', backgroundColor: '#FF7E33', borderRadius: '30px 30px 30px 30px' }}><span style={{ fontSize: '20px' }} className='ttt'>connect with us</span></button>
                            </div>
                        </div>


                    </div>
                }

            </>



        );


        return (
            <>
                <Modal style={{ overflowY: 'auto' }} show={this.state.basicModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

                    <Modal.Header style={{ border: '0' }}>
                        <CloseButton onClick={() => this.setState({ basicModal: !this.state.basicModal })} />
                    </Modal.Header>

                    <Modal.Body >
                        <Question noChip={true} mod={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
                    </Modal.Body>

                </Modal>


                {this.props.manualSearchString !== '' &&

                    <div style={{ marginTop: '0%', marginBottom: '10%' }} className="container-fluid">
                        <div className="row justify-content-center align-middle  text-center">
                            <span className='col-9 proxeet-pc-header' style={{ marginTop: '3%', marginBottom: '5%', position: 'relative' }} >MANUAL SEARCH RESULT</span>
                        </div>

                        <div className='row container-fluid'>
                            <Chip l={'80'} t={'85'} w={'60'} rotate={'40'} />
                        </div>

                        <div className="row justify-content-center align-middle  text-center">


                            {Object.keys(this.state.SearchResult).length !== 0

                                ?

                                <div className='col-12' style={{ position: 'relative' }} >


                                    <div className='row justify-content-center align-middle  text-center'>

                                        {
                                            Object.keys(this.state.SearchResult).map((someId) => (

                                                <>
                                                    <h5 className='text-start col-11 my-1 mx-4' >Next document contains text: {'"' + this.props.manualSearchString + '"'}</h5>
                                                    <p className='text-start col-11 my-1  mx-4'>{this.state.manuals[someId].file}</p>
                                                    <Link
                                                        key={someId}
                                                        className='col-11 mx-4 text-start'
                                                        onClick={() => { window.scrollTo(0, 0); }}
                                                        to={'/manuals/' + this.state.manuals[someId].file}
                                                    >
                                                        {'https://proxeet.com/manuals/' + this.state.manuals[someId].file}
                                                    </Link>
                                                </>

                                            ))
                                        }

                                    </div>
                                </div>

                                :

                                <>
                                    <div className="row justify-content-center align-middle  text-center">
                                        <span className='col-11 proxeet-pc-header' style={{ fontSize: '1.8rem', position: 'relative' }} >{this.state.noResults}</span>
                                    </div>

                                    <div className="row justify-content-center align-middle  text-center">
                                        <div className="col-12 my-3">
                                            <button onClick={() => this.setState({ basicModal: !this.state.basicModal })} className="btn br-30" type="button" style={{ width: '15rem', height: '4rem', border: '0px', color: 'white', backgroundColor: '#FF7E33' }}><span style={{ fontSize: '20px' }} className='ttt'>connect with us</span></button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>


                }
            </>
        );
    }
}



export default withGetScreen(ManualSearchResult);
