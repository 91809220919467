import React from 'react';
import Card from 'react-bootstrap/Card';
import borderNew from '../img/bdr.png';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import {Chip} from './chip';
import {withGetScreen} from 'react-getscreen';
import starImg from '../img/star.svg'
import CloseButton from 'react-bootstrap/CloseButton';
import Slider from "react-slick";
import {SampleNextArrow, SamplePrevArrow} from './arrow';
import {Link} from "react-router-dom";


class Stars extends React.Component {

    constructor(props) {
        super(props);


        this.starItems();

    }

    indents = [];

    starItems = () => {

        for (let i = 0; i < this.props.stars; i++) {

            this.indents.push(<img alt='' key={'star_' + i} className='mx-1'
                                   style={{width: `${this.props.size}+px`, height: `${this.props.size}px`}}
                                   src={starImg}/>);

        }

    }


    render() {

        return (<>
            {this.indents}
        </>);

    }

}


class StarsMob extends React.Component {

    constructor(props) {
        super(props);


        this.starItems();

    }

    indents = [];

    starItems = () => {

        for (let i = 0; i < this.props.stars; i++) {


            this.indents.push(<img key={'id_' + i} alt='' className=''
                                   style={{margin: '0', padding: '0', width: '25px', height: '25px'}} src={starImg}/>);

        }

    }


    render() {

        return (<>
            {this.indents}
        </>);

    }

}


class LatestRewies extends React.Component {

    constructor(props) {
        super(props);


        this.jsonGet();
        this.getAmazonReviews();

    }

    state = {

        componentHeader: 'Latest reviews',
        componentText: `Reviews from `,

        // rewies: {},
        amazonReviews: {},

        basicModal: false,
        curientRewies: 1,

        amazon: '',


        commentImage: {}


    }


    jsonGet = async () => {

        // let reviewsData = new FormData();
        // reviewsData.append('table', 'rewies');
        // //newsData.append('rows', 'id');
        //
        // const reviews = await axios.request({
        //     method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: reviewsData
        // });
        //
        //
        // const reviewsResp = await reviews.data;
        //
        // this.setState({rewies: reviewsResp});


        let marketPlacesData = new FormData();
        marketPlacesData.append('table', 'marketplace');

        const marketplaces = await axios.request({
            method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: marketPlacesData
        });

        const marketplacesResp = await marketplaces.data;

        this.setState({amazon: marketplacesResp[0].marketplaceLink});


    }


    getAmazonReviews = async () => {

        let amazonReviewsData = new FormData();
        amazonReviewsData.append('table', 'amazonReviews');


        const amazonReviews = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_FULL_API,
            data: amazonReviewsData
        });


        const amazonReviewsResp = await amazonReviews.data;

        this.setState({amazonReviews: amazonReviewsResp});

    }


    getCommentImage = async (index) => {


        let commentImgData = new FormData();
        commentImgData.append('id', index);
        commentImgData.append('type', 'amazonReviews');


        const commentImage = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_GET_POLIMORF_IMG,
            data: commentImgData
        });


        const commentImgResp = await commentImage.data;

        this.setState({commentImage: commentImgResp});
    }

    rewiesOpen = (e) => {

        let index = Number(e.target.getAttribute("data-index")) - 1;

        this.getCommentImage(Number(e.target.getAttribute("data-index")));

        this.setState({curientRewies: Number(index)});
        this.setState({basicModal: !this.state.basicModal});

    }

    rewiesOpenMob = (e) => {

        this.getCommentImage(Number(e));
        let index = Number(e) - 1; //Number(e.target.getAttribute("data-index")) - 1;

        this.setState({curientRewies: Number(index)});
        this.setState({basicModal: !this.state.basicModal});

    }


    render() {

        const settings = {


            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            nextArrow: <SampleNextArrow/>,
            prevArrow: <SamplePrevArrow/>,
        };


        if (this.props.isMobile()) return (

            <>
                {Object.keys(this.state.amazonReviews).length !== 0 && <Modal
                    style={{overflowY: 'auto'}}
                    show={this.state.basicModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header style={{border: '0'}}>
                        <CloseButton onClick={() => this.setState({basicModal: !this.state.basicModal})}/>

                    </Modal.Header>
                    <Modal.Body>
                        <Card key={1} className='col-11 m-4 br-30' style={{border: '2px solid #FF7E33'}}>
                            <img alt='' src={borderNew} style={{
                                width: 'max-content',
                                height: 'auto',
                                position: 'relative',
                                top: '-7px',
                                left: '25%',
                                zIndex: '200'
                            }}/>

                            <Card.Body>


                                <div
                                    className="row justify-content-center align-middle align-items-center  text-md-center"
                                    style={{border: '0px solid blue'}}
                                >
                                    <div className='col-3'>
                                        <img alt='' className=' img-fluid'
                                             src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[this.state.curientRewies].imgName}/>
                                    </div>
                                    <div className='col-9'>
                                        <div
                                            className="row justify-content-center align-middle  text-start"
                                            style={{border: '0px solid green'}}
                                        >
                                                <span className='col-11 ttt fs-3 proxeet-blue'
                                                      style={{border: '0px solid green'}}
                                                >

                                                    {this.state.amazonReviews[this.state.curientRewies].name}
                                                </span>
                                            <div className={'row justify-content-center'}>
                                                <div className='col-12'
                                                     style={{border: '0px solid red'}}
                                                >

                                                    <Stars
                                                        stars={(this.state.amazonReviews[this.state.curientRewies].stars)}/>
                                                </div>
                                                <div className='col-12 mt-1'
                                                     style={{border: '0px solid green'}}
                                                >
                                                        <span className={'proxeet-blue fw-bold'}>
                                                            {this.state.amazonReviews[this.state.curientRewies].shortText}
                                                        </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row justify-content-center align-middle align-items-center  text-md-center mt-3"
                                    style={{border: '0px solid blue'}}
                                >
                                    <div className={'col-11 text-center'}>
                                            <span className={'px-1 open-sans proxeet-light-blue'}>
                                              {this.state.amazonReviews[this.state.curientRewies].getDate}
                                            </span>
                                    </div>
                                </div>
                                <div
                                    className="row justify-content-center align-middle align-items-center  text-md-center mt-3"
                                    style={{border: '0px solid blue'}}
                                >
                                    <div className={'col-6 text-start ttt'}>
                                            <span
                                                className={'px-3 '}
                                                style={{
                                                    fontSize: '0.45rem'
                                                }}
                                            >
                                                {'purchased device'}
                                            </span>
                                    </div>
                                    <div className={'col-6 text-start'}>
                                            <span
                                                style={{
                                                    fontSize: '0.5rem'
                                                }}
                                            >
                                               <Link
                                                   className={''}
                                                   style={{
                                                       fontSize: '0.8rem'
                                                   }}
                                                   to={this.state.amazonReviews[this.state.curientRewies].link}>
                                                {this.state.amazonReviews[this.state.curientRewies].type}
                                               </Link>
                                            </span>
                                    </div>
                                </div>
                                <div
                                    className="row justify-content-center align-middle align-items-center  text-md-center"
                                    style={{border: '0px solid blue'}}
                                >
                                    <div className={'col-6 text-start'}>
                                            <span
                                                className={'px-3 ttt'}
                                                style={{
                                                    fontSize: '0.5rem'
                                                }}

                                            >
                                                {'type battery'}
                                            </span>
                                    </div>
                                    <div className={'col-6 text-start'}>
                                            <span
                                                className={''}
                                                style={{
                                                    fontSize: '0.7rem'
                                                }}
                                            >

                                                    {this.state.amazonReviews[this.state.curientRewies].batteryType}
                                            </span>
                                    </div>
                                </div>
                                <Card.Text className='d-inline-block text-start text-wrap my-2'
                                           style={{border: '0px solid red'}}>
                                    {/*{this.state.rewies[this.state.curientRewies].text}*/}
                                    <p
                                        className={''}

                                        dangerouslySetInnerHTML={{
                                            __html: this.state.amazonReviews[this.state.curientRewies].text
                                        }}
                                    />


                                </Card.Text>
                                <div
                                    className="row justify-content-center align-middle align-items-center  text-md-center"
                                    style={{border: '0px solid blue'}}
                                >
                                    {Object.keys(this.state.commentImage).length !== 0 &&
                                        <>
                                            {Object.keys(this.state.commentImage).map((someId) => (


                                                <div
                                                    key={someId}
                                                    style={{border: '0px solid blue'}}
                                                    className={'col-4'}>
                                                    <img
                                                        className={'img-fluid'}
                                                        src={process.env.REACT_APP_POLIMORF_IMAGE_DIR_ON_SERVER + this.state.commentImage[someId].url}
                                                    />

                                                </div>
                                            ))

                                            }
                                        </>
                                    }
                                </div>
                            </Card.Body>

                            {/*<Card.Body>*/}

                            {/*    <Card.Text className='d-inline-block text-start text-wrap'>*/}
                            {/*        {this.state.amazonReviews[this.state.curientRewies].text}*/}
                            {/*    </Card.Text>*/}
                            {/*    <div*/}
                            {/*        className="row justify-content-center align-middle align-items-center  text-md-center">*/}
                            {/*        <div className='col-3 '>*/}
                            {/*            <img alt='' className=' img-fluid'*/}
                            {/*                 src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[this.state.curientRewies].imgName}/>*/}
                            {/*        </div>*/}
                            {/*        <div className='col-9'>*/}
                            {/*            <div className="row justify-content-center align-middle  text-start">*/}
                            {/*                    <span className='col-10 ttt proxeet-blue'>*/}
                            {/*                        {this.state.amazonReviews[this.state.curientRewies].name}*/}
                            {/*                    </span>*/}
                            {/*                <div className='col-12 px-1'>*/}
                            {/*                    <Stars*/}
                            {/*                        stars={(this.state.amazonReviews[this.state.curientRewies].stars)}/>*/}

                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Card.Body>*/}
                        </Card>
                    </Modal.Body>

                </Modal>}

                <div className='container-fluid position-relative'>
                    {<Chip l={'-3'} t={'15'} w={'20'} rotate={'50'}/>}

                    <div className="row justify-content-center align-middle  text-center">
                        <span
                            className="col-10 col-sm-10 col-md-8 proxeet-mob-header"
                            dangerouslySetInnerHTML={{__html: this.state.componentHeader}}
                        />
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <p className="col-10 col-sm-10 col-md-8 mt-2"
                           style={{marginTop: '0%', marginBottom: '5%', position: 'relative'}}>
                            {this.state.componentText}
                            <Link className={'open-sans proxeet-light-blue fs-4'}
                                  to={this.state.amazon}>amazon</Link>
                        </p>
                    </div>
                    {/* Dynamic data block */}
                    <div className="row  justify-content-center align-middle text-md-center">


                        <div className="row justify-content-center">
                            <div className="col-10  text-center">
                                {Object.keys(this.state.amazonReviews).length !== 0 &&

                                    <Slider className='row'  {...settings}>


                                        {Object.keys(this.state.amazonReviews).map((someId) => (
                                            <div key={someId}>
                                                <Card key={this.state.amazonReviews[someId].id}
                                                      className='text-center m-4 px-4'
                                                      style={{border: '2px solid #FF7E33', borderRadius: '30px'}}>
                                                    <img alt='' src={borderNew} style={{
                                                        width: 'max-content',
                                                        height: 'auto',
                                                        position: 'relative',
                                                        top: '-7px',
                                                        left: '52%',
                                                        zIndex: '200'
                                                    }}/>


                                                    <div
                                                        className="row justify-content-center align-middle align-items-center  text-center">
                                                        <p className='col-12 text-start mt-4 mx-0'> {this.state.amazonReviews[someId].text.slice(0, 60) + '...'}</p>

                                                    </div>
                                                    <div className='row'>
                                                        <button
                                                            onClick={e => this.rewiesOpenMob(this.state.amazonReviews[someId].id)}

                                                            /* data-index={this.state.rewies[someId].id} */
                                                            className='text-center btn'
                                                            style={{color: '#FF7E33', fontWeight: 'bold'}}
                                                        >
                                                            Read full comment
                                                        </button>
                                                    </div>


                                                    <div
                                                        className="row justify-content-center align-middle align-items-center  text-md-center">
                                                        <div className='col-3 mb-5'>
                                                            <img alt='' className=' img-fluid'
                                                                 src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[someId].imgName}/>
                                                        </div>
                                                        <div className='col-9'>
                                                            <div
                                                                className="row justify-content-center align-middle  text-center">

                                                                    <span className='col-12 ttt px-1 mx-4' style={{
                                                                        color: '#152F4E', fontSize: '16px'
                                                                    }}>{this.state.amazonReviews[someId].name}</span>


                                                            </div>
                                                            <div
                                                                className="row justify-content-center align-middle  text-center mb-4"
                                                                style={{height: '40px'}}>

                                                                <StarsMob key={this.state.amazonReviews[someId].id}
                                                                          className=''
                                                                          stars={this.state.amazonReviews[someId].stars}/>


                                                            </div>

                                                        </div>
                                                    </div>

                                                </Card>
                                            </div>))}


                                    </Slider>}
                            </div>
                        </div>
                    </div>
                </div>
            </>);


        if (this.props.isTablet()) return (


            <>
                {Object.keys(this.state.amazonReviews).length !== 0 &&
                    <Modal
                        style={{overflowY: 'auto'}}
                        show={this.state.basicModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header style={{border: '0'}}>
                            <CloseButton onClick={() => this.setState({basicModal: !this.state.basicModal})}/>
                        </Modal.Header>
                        <Card.Body
                            style={{
                                padding: '1rem'
                            }}
                        >


                            <div
                                className="row justify-content-center align-middle align-items-center  text-md-center"
                                style={{border: '0px solid blue'}}
                            >
                                <div
                                    className='col-3 '
                                    style={{
                                        border: '0px solid red'
                                    }}
                                >
                                    <img alt='' className=' img-fluid'
                                         src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[this.state.curientRewies].imgName}/>
                                </div>
                                <div className='col-9'>
                                    <div
                                        className="row justify-content-center align-middle  text-start"
                                        style={{border: '0px solid green'}}
                                    >
                                                <span className='col-11 ttt fs-3 proxeet-blue'
                                                      style={{border: '0px solid green'}}
                                                >

                                                    {this.state.amazonReviews[this.state.curientRewies].name}
                                                </span>
                                        <div className={'row justify-content-center'}>
                                            <div className='col-6 '
                                                 style={{border: '0px solid red'}}
                                            >

                                                <Stars
                                                    stars={(this.state.amazonReviews[this.state.curientRewies].stars)}/>
                                            </div>
                                            <div className='col-6 mt-1'
                                                 style={{border: '0px solid green'}}
                                            >
                                                        <span className={'proxeet-blue fw-bold'}>
                                                            {this.state.amazonReviews[this.state.curientRewies].shortText}
                                                        </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div
                                className="row justify-content-center align-middle align-items-center  text-md-center mt-3"
                                style={{border: '0px solid blue'}}
                            >
                                <div className={'col-10 text-center'}>
                                            <span className={'px-3 open-sans proxeet-light-blue'}>
                                              {this.state.amazonReviews[this.state.curientRewies].getDate}
                                            </span>
                                </div>
                            </div>
                            <div
                                className="row justify-content-center align-middle align-items-center  text-md-center mt-3"
                                style={{border: '0px solid blue'}}
                            >
                                <div className={'col-6 text-start'}>
                                            <span className={'px-3 ttt'}>
                                                {'purchased device'}
                                            </span>
                                </div>
                                <div className={'col-6 text-start'}>
                                            <span>
                                               <Link
                                                   className={'ttt'}
                                                   to={this.state.amazonReviews[this.state.curientRewies].link}>
                                                {this.state.amazonReviews[this.state.curientRewies].type}
                                               </Link>
                                            </span>
                                </div>
                            </div>
                            <div
                                className="row justify-content-center align-middle align-items-center  text-md-center"
                                style={{border: '0px solid blue'}}
                            >
                                <div className={'col-6 text-start'}>
                                            <span className={'px-3 ttt'}>
                                                {'type battery'}
                                            </span>
                                </div>
                                <div className={'col-6 text-start'}>
                                            <span className={'ttt'}>

                                                    {this.state.amazonReviews[this.state.curientRewies].batteryType}
                                            </span>
                                </div>
                            </div>
                            <Card.Text className='d-inline-block text-start text-wrap my-2'
                                       style={{border: '0px solid red'}}>
                                {/*{this.state.rewies[this.state.curientRewies].text}*/}
                                <p
                                    className={''}

                                    dangerouslySetInnerHTML={{
                                        __html: this.state.amazonReviews[this.state.curientRewies].text
                                    }}
                                />
                                <div
                                    className="row justify-content-center align-middle align-items-center  text-md-center"
                                    style={{border: '0px solid blue'}}
                                >
                                    {Object.keys(this.state.commentImage).length !== 0 &&
                                        <>
                                            {Object.keys(this.state.commentImage).map((someId) => (


                                                <div
                                                    key={someId}
                                                    style={{border: '0px solid blue'}}
                                                    className={'col-4'}>
                                                    <img
                                                        className={'img-fluid'}
                                                        src={process.env.REACT_APP_POLIMORF_IMAGE_DIR_ON_SERVER + this.state.commentImage[someId].url}
                                                    />

                                                </div>
                                            ))

                                            }
                                        </>
                                    }
                                </div>


                            </Card.Text>
                        </Card.Body>
                        {/*<Modal.Body>*/}
                        {/*    <Card key={1} className='col-11 m-4'*/}
                        {/*          style={{border: '2px solid #FF7E33', borderRadius: '30px'}}>*/}
                        {/*        <img alt='' src={borderNew} style={{*/}
                        {/*            width: 'max-content',*/}
                        {/*            height: 'auto',*/}
                        {/*            position: 'relative',*/}
                        {/*            top: '-7px',*/}
                        {/*            left: '25%',*/}
                        {/*            zIndex: '200'*/}
                        {/*        }}/>*/}

                        {/*        <Card.Body>*/}

                        {/*            <Card.Text className='d-inline-block text-start text-wrap ' style={{}}>*/}
                        {/*                {this.state.amazonReviews[this.state.curientRewies].text}*/}
                        {/*            </Card.Text>*/}
                        {/*            <div*/}
                        {/*                className="row justify-content-center align-middle align-items-center  text-md-center">*/}
                        {/*                <div className='col-3 '>*/}
                        {/*                    <img alt='' className=' img-fluid'*/}
                        {/*                         src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[this.state.curientRewies].imgName}/>*/}
                        {/*                </div>*/}
                        {/*                <div className='col-9'>*/}
                        {/*                    <div className="row justify-content-center align-middle  text-start">*/}
                        {/*                            <span className='col-10 ttt ' style={{*/}
                        {/*                                color: '#152F4E', fontSize: '18px'*/}
                        {/*                            }}> {this.state.amazonReviews[this.state.curientRewies].name}</span>*/}
                        {/*                        <div className='col-12 px-1'>*/}
                        {/*                            <Stars*/}
                        {/*                                stars={(this.state.amazonReviews[this.state.curientRewies].stars)}/>*/}

                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </Card.Body>*/}
                        {/*    </Card>*/}
                        {/*</Modal.Body>*/}

                    </Modal>}

                <div className="container-fluid" style={{position: 'relative'}}>
                    {<Chip l={'-3'} t={'15'} w={'20'} rotate={'50'}/>}

                    <div className="row justify-content-center align-middle  text-center">
                        <span className="col-10 col-sm-10 col-md-8 proxeet-pc-header"
                              style={{marginTop: '10%', marginBottom: '1%', position: 'relative'}}
                              dangerouslySetInnerHTML={{__html: this.state.componentHeader}}/>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <p className="col-10 col-sm-10 col-md-8"
                           style={{marginTop: '0%', marginBottom: '5%', position: 'relative'}}>
                            {this.state.componentText}
                            <Link className={'open-sans proxeet-light-blue fs-4'}
                                  to={this.state.amazon}>amazon</Link>
                        </p>
                    </div>
                    {/* Dynamic data block */}
                    <div className="row  justify-content-center align-middle text-md-center">


                        <div className="row justify-content-center">
                            <div className="col-10  text-center">
                                {Object.keys(this.state.amazonReviews).length !== 0 &&
                                    <Slider className='row'  {...settings}>


                                        {Object.keys(this.state.amazonReviews).map((someId) => (<div>
                                            <Card key={someId - 1} className='text-center m-4 px-4'
                                                  style={{border: '2px solid #FF7E33', borderRadius: '30px'}}>
                                                <img alt='' src={borderNew} style={{
                                                    width: 'max-content',
                                                    height: 'auto',
                                                    position: 'relative',
                                                    top: '-7px',
                                                    left: '52%',
                                                    zIndex: '200'
                                                }}></img>


                                                <div
                                                    className="row justify-content-center align-middle align-items-center  text-center">
                                                    <p
                                                        className='col-12 text-start mt-4 mx-0'
                                                        style={{cursor: 'pointer'}}
                                                    >

                                                        {this.state.amazonReviews[someId].text.slice(0, 80) + '...'}
                                                    </p>

                                                </div>
                                                <div className='row'>
                                                    <p onClick={e => this.rewiesOpenMob(this.state.amazonReviews[someId].id)}
                                                       className='text-center btn' style={{
                                                        border: '0', color: '#FF7E33', fontWeight: 'bold'
                                                    }}>Read full comment</p>
                                                </div>


                                                <div
                                                    className="row justify-content-center align-middle align-items-center  text-md-center">
                                                    <div className='col-3 mb-5'>
                                                        <img alt='' className=' img-fluid'
                                                             src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[someId].imgName}/>
                                                    </div>
                                                    <div className='col-9'>
                                                        <div
                                                            className="row justify-content-center align-middle  text-center">

                                                                    <span className='col-12 ttt px-1 mx-4' style={{
                                                                        color: '#152F4E', fontSize: '16px'
                                                                    }}>{this.state.amazonReviews[someId].name}</span>


                                                        </div>
                                                        <div
                                                            className="row justify-content-center align-middle  text-center mb-4"
                                                            style={{height: '40px'}}>

                                                            <StarsMob className=''
                                                                      stars={this.state.amazonReviews[someId].stars}/>


                                                        </div>

                                                    </div>
                                                </div>

                                            </Card>
                                        </div>))}


                                    </Slider>}
                            </div>
                        </div>
                    </div>
                </div>


            </>

        );

        return (
            <>
                {
                    Object.keys(this.state.amazonReviews).length !== 0 &&
                    <Modal
                        style={{
                            overflowY: 'auto',
                            margin: '3rem'
                        }}
                        show={this.state.basicModal}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header style={{border: '0px solid red'}}>
                            <CloseButton onClick={() => this.setState({basicModal: !this.state.basicModal})}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Card key={1} className='col-11 m-4'
                                  style={{border: '2px solid #FF7E33', borderRadius: '30px'}}>
                                <img alt='' src={borderNew} style={{
                                    width: 'max-content',
                                    height: 'auto',
                                    position: 'relative',
                                    top: '-7px',
                                    left: '25%',
                                    zIndex: '200'
                                }}/>

                                <Card.Body>


                                    <div
                                        className="row justify-content-center align-middle align-items-center  text-md-center"
                                        style={{border: '0px solid blue'}}
                                    >
                                        <div className='col-3'>
                                            <img alt='' className=' img-fluid'
                                                 src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[this.state.curientRewies].imgName}/>
                                        </div>
                                        <div className='col-9'>
                                            <div
                                                className="row justify-content-center align-middle  text-start"
                                                style={{border: '0px solid green'}}
                                            >
                                                <span className='col-11 ttt fs-3 proxeet-blue'
                                                      style={{border: '0px solid green'}}
                                                >

                                                    {this.state.amazonReviews[this.state.curientRewies].name}
                                                </span>
                                                <div className={'row justify-content-center'}>
                                                    <div className='col-6 '
                                                         style={{border: '0px solid red'}}
                                                    >

                                                        <Stars
                                                            stars={(this.state.amazonReviews[this.state.curientRewies].stars)}/>
                                                    </div>
                                                    <div className='col-6 mt-1'
                                                         style={{border: '0px solid green'}}
                                                    >
                                                        <span className={'proxeet-blue fw-bold'}>
                                                            {this.state.amazonReviews[this.state.curientRewies].shortText}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="row justify-content-center align-middle align-items-center  text-md-center mt-3"
                                        style={{border: '0px solid blue'}}
                                    >
                                        <div className={'col-10 text-center'}>
                                            <span className={'px-3 open-sans proxeet-light-blue'}>
                                              {this.state.amazonReviews[this.state.curientRewies].getDate}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row justify-content-center align-middle align-items-center  text-md-center mt-3"
                                        style={{border: '0px solid blue'}}
                                    >
                                        <div className={'col-6 text-start'}>
                                            <span className={'px-3 ttt'}>
                                                {'purchased device'}
                                            </span>
                                        </div>
                                        <div className={'col-6 text-start'}>
                                            <span>
                                               <Link
                                                   className={'ttt'}
                                                   to={this.state.amazonReviews[this.state.curientRewies].link}>
                                                {this.state.amazonReviews[this.state.curientRewies].type}
                                               </Link>
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="row justify-content-center align-middle align-items-center  text-md-center"
                                        style={{border: '0px solid blue'}}
                                    >
                                        <div className={'col-6 text-start'}>
                                            <span className={'px-3 ttt'}>
                                                {'type battery'}
                                            </span>
                                        </div>
                                        <div className={'col-6 text-start'}>
                                            <span className={'ttt'}>

                                                    {this.state.amazonReviews[this.state.curientRewies].batteryType}
                                            </span>
                                        </div>
                                    </div>
                                    <Card.Text className='d-inline-block text-start text-wrap my-2'
                                               style={{border: '0px solid red'}}>
                                        {/*{this.state.rewies[this.state.curientRewies].text}*/}
                                        <p
                                            className={''}

                                            dangerouslySetInnerHTML={{
                                                __html: this.state.amazonReviews[this.state.curientRewies].text
                                            }}
                                        />
                                        <div
                                            className="row justify-content-center align-middle align-items-center  text-md-center"
                                            style={{border: '0px solid blue'}}
                                        >
                                            {Object.keys(this.state.commentImage).length !== 0 &&
                                                <>
                                                    {Object.keys(this.state.commentImage).map((someId) => (


                                                        <div
                                                            key={someId}
                                                            style={{border: '0px solid blue'}}
                                                            className={'col-4'}>
                                                            <img
                                                                className={'img-fluid'}
                                                                src={process.env.REACT_APP_POLIMORF_IMAGE_DIR_ON_SERVER + this.state.commentImage[someId].url}
                                                            />

                                                        </div>
                                                    ))

                                                    }
                                                </>
                                            }
                                        </div>


                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Modal.Body>

                    </Modal>
                }

                <div className="container-fluid my-4 d-none d-xl-block">


                    <div className="container" style={{position: 'relative'}}>
                        <Chip l={'-5'} t={'5'} w={'60'} rotate={'40'}/>
                        <Chip l={'75'} t={'50'} w={'30'} rotate={'55'}/>

                        <div className="row justify-content-center align-middle  text-center">
                            <span className="col-10 col-sm-10 col-md-8 proxeet-pc-header position-relative"
                                  dangerouslySetInnerHTML={{__html: this.state.componentHeader}}/>
                        </div>

                        <div className="row justify-content-center align-middle  text-center mt-3">
                            <div className="col-10 col-sm-10 col-md-8 position-relative ">
                                <span className={'open-sans  proxeet-light-blue fs-4'}

                                >
                                    {this.state.componentText}
                                    <Link className={'open-sans proxeet-light-blue fs-4'}
                                          to={this.state.amazon}>amazon</Link>
                                </span>
                            </div>

                        </div>


                        {Object.keys(this.state.amazonReviews).length !== 0 &&
                            <div className="row gx-5 justify-content-center align-middle text-md-center">


                                {this.props.showAllRewies === true ? <>

                                    {

                                        Object.keys(this.state.amazonReviews).map((someId) => (

                                            <Card key={someId} className='col-4 m-4'
                                                  style={{border: '2px solid #FF7E33', borderRadius: '30px'}}>
                                                <img alt='' src={borderNew} style={{
                                                    width: 'max-content',
                                                    height: 'auto',
                                                    position: 'relative',
                                                    top: '-7px',
                                                    left: '25%',
                                                    zIndex: '200'
                                                }}></img>

                                                <Card.Body>

                                                    <Card.Text className='text-start'>
                                                        {this.state.amazonReviews[someId].text}
                                                    </Card.Text>
                                                    <div
                                                        className="row justify-content-center align-middle align-items-center  text-md-center">
                                                        <div className='col-3 '>
                                                            <img alt='' className=' img-fluid'
                                                                 src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[someId].imgName}/>
                                                        </div>
                                                        <div className='col-9'>
                                                            <div
                                                                className="row justify-content-center align-middle  text-start">
                                                                        <span className='col-10 ttt' style={{
                                                                            color: '#152F4E', fontSize: '22px'
                                                                        }}> {this.state.amazonReviews[someId].name}</span>
                                                                <div className='col-10 px-1'>
                                                                    <Stars
                                                                        stars={this.state.amazonReviews[someId].stars}/>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>

                                        ))}
                                </> : <>

                                    <div
                                        className="row justify-content-center align-middle  text-md-center d-flex  flex-wrap ">
                                        {/*{Object.keys(this.state.amazonReviews).slice(0, 4).map((someId) => (*/}
                                        {Object.keys(this.state.amazonReviews).map((someId) => (

                                            <Card key={someId} className='col-4 m-4' style={{
                                                border: '2px solid #FF7E33', borderRadius: '30px'
                                            }}>
                                                <img alt='' src={borderNew} style={{
                                                    width: 'max-content',
                                                    height: 'auto',
                                                    position: 'relative',
                                                    top: '-7px',
                                                    left: '25%',
                                                    zIndex: '200'
                                                }}/>

                                                <Card.Body>

                                                    <Card.Text data-index={this.state.amazonReviews[someId].id}
                                                               onClick={this.rewiesOpen}
                                                               className='d-block text-start text-wrap  text-truncate'
                                                               style={{
                                                                   marginBottom: '0',
                                                                   paddingBottom: '0',
                                                                   cursor: 'pointer',
                                                               }}
                                                    >
                                                        {this.state.amazonReviews[someId].text.slice(0, 100) + '...'}

                                                    </Card.Text>
                                                    <div
                                                        className="row justify-content-center align-middle align-items-center  text-center">
                                                        <p data-index={this.state.amazonReviews[someId].id}
                                                           onClick={this.rewiesOpen}
                                                           className='text-center btn proxeet-orange fw-bold'
                                                        >Read full comment</p>
                                                    </div>
                                                    <div
                                                        className="row justify-content-center align-middle align-items-center  text-md-center">
                                                        <div className='col-3 '>
                                                            <img alt='' className=' img-fluid'
                                                                 src={process.env.REACT_APP_REWIES_IMG_ON_SERVER + this.state.amazonReviews[someId].imgName}/>
                                                        </div>
                                                        <div className='col-9'>
                                                            <div
                                                                className="row justify-content-center align-middle  text-start">
                                                                            <span className='col-10 ttt proxeet-blue'
                                                                                  dangerouslySetInnerHTML={{__html: this.state.amazonReviews[someId].name}}/>
                                                                <div className='col-10 px-1'>
                                                                    <Stars size={'30'} key={someId}
                                                                           stars={this.state.amazonReviews[someId].stars}/>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>

                                        ))}

                                    </div>

                                </>

                                }


                            </div>

                        }


                    </div>
                </div>

            </>
        )
            ;
    }
}

export default withGetScreen(LatestRewies);