import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import axios from 'axios';
import ScrollToTop from "react-scroll-to-top";


import SiteMenu from './Components/sitemenu';
import TopBlock from './Components/top_block.jsx';
import About from './Components/about';
import Welcome from './Components/wellcome';
import Products from './Components/products';
import AboutCompany from './Components/about_company.jsx';
import ProductApp from './Components/product_app';
import Partners from './Components/partners';
import LatestReviews from './Components/latest_rewies';
import Question from './Components/question';
import Footer from './Components/footer';
import ProductsCard from './Components/productCard';

import DeviceInfo from './Components/DeviceInfo';
import BackgroundPhoto from './Components/backgroundPhoto';
import DeviceDescription from './Components/DeviceDescription';
import InfoCard from './Components/infoCard';

import NewsRoom from './Components/news/newsRoom';

import Ruler from './Components/ruler';
import Subscribe from './Components/subscribe';
import MoreNews from './Components/news/moreNews';
import LastNews from './Components/news/lastNews';
import NewsSearchResult from './Components/news/newsSearchResult';
import NewsTags from './Components/news/newsTags';
import ProductsTags from './Components/productsTegs';
import AboutProxeet from './Components/aboutProxeet';
import WhoIsProxeet from './Components/whoIsProxeet';
import Support from './Components/support';
import SupportBtn from './Components/supportBtn';
import HowToBuy from './Components/howToBuy';
import StaticSlider from './Components/staticSlider';
import Manual from './Components/manual';

import NewsSlider from './Components/NewsSlider';

import ManualSearchResult from './Components/manualSearchResult';

import NewsPage from './Components/news/news';
import NoMatch from './Components/NoMatch';
import SupportSupport from './Components/supportSupport';
import { Faq } from './Components/faq';
import { Video } from './Components/video';
import { Contacts } from './Components/contacts';


import {  Routes, Route, Outlet } from "react-router-dom";


import './App.scss';
import arrow_up from './img/btn/arrow up.svg';



export class App extends React.Component {

  constructor(props) {

    super(props);

    this.state = {


      productCardCounter: 0,

      proxeetPhone: '',

      menuItemActive: '',


      productSearchString: '',
      manualSearchString: '',

      newsLinkList: [],

      NewsRoom: 0,
      NewsTags: 0,
      LastNews: 0,
      MoreNews: 0,
      Subscribe: 0,

      partners: 1,
      reviews: 0,
      products: 0,
      news: 0,

      showAllReviews: false,

      emailSent: 'false',
      menuColor: '',

      productsEnable: '',
      newsEnable: '',

      cord: '',
      newsSearchString: '',
      newsSearchStringThemes: ''

    };

    this.jsonGet();

    this.callContacts = this.callContacts.bind(this);

    this.callProductCard = this.callProductCard.bind(this);

    this.call = this.call.bind(this);

  }

  componentDidUpdate = () => {


    window.scrollTo(this.state.cord, this.state.cord);

  };


  jsonGet = async () => {

    let getComponent = new FormData();
    getComponent.append('table', 'components');
    const components = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: getComponent });

    const data = await components.data;

    this.setState({ news: Number(data[0].news) });
    this.setState({ reviews: Number(data[0].reviews) });
    //this.setState({ partners: Number(data[0].partners) });
    this.setState({ products: Number(data[0].products) });
    this.setState({ productsEnable: Number(data[0].products) });
    this.setState({ newsEnable: Number(data[0].news) });
    this.setState({ proxeetPhone: data[0].phone });





    let newsData = new FormData();
    newsData.append('table', 'news');
    newsData.append('rows', 'id');


    const news = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: newsData });

    const newsLinks = await news.data;

    let idList = [];

    newsLinks.forEach((element) => {

      idList.push(element.id);

    });

    this.setState({ newsLinkList: idList });

  }


  setProductList = (value) => {

    this.setState({ productSearchString: value });

  }



  callContacts = () => {

    this.setMenuActive('contacts');
    this.allOff();

    this.setState({ menuColor: 'blue' });

    this.setState({ contactPage: 1 });
    window.scrollTo(0, 0);




  }

  setMenuActive = (item) => {

    this.setState({ menuItemActive: item });





  }





  allOff = () => {

    this.setState({ showAllReviews: false });
    this.setState({ mainPage: 0 });
    this.setState({ aboutPage: 0 });
    this.setState({ supportPage: 0 });
    this.setState({ faqPage: 0 });
    this.setState({ howToBuyPage: 0 });
    this.setState({ contactPage: 0 });
    this.setState({ newsPage: 0 });
    this.setState({ productCardPage: 0 });
    this.setState({ productPage: 0 });
    this.setState({ reviewsPage: 0 });
    this.setState({ businessPage: 0 });

    this.setState({ singleNewPage: 0 });
    this.setState({ manualPage: 0 });
    this.setState({ supportSupportPage: 0 });

    

    this.setState({ productCardCounter: 0 });

  }

  callBusiness = () => {

    this.setMenuActive('');
    this.allOff();
    this.setState({ menuColor: 'white' });
    this.setState({ businessPage: 1 });
    window.scrollTo(0, 0);

  }

  callSingleNewPage = () => {

    this.setMenuActive('');
    this.allOff();
    this.setState({ menuColor: 'blue' });
    this.setState({ singleNewPage: 1 });
    window.scrollTo(0, 0);

  }


  callManualPage = () => {

    this.setMenuActive('');
    this.allOff();
    this.setState({ menuColor: 'white' });

    this.setState({ manualPage: 1 });

    window.scrollTo(0, 0);


  }

  callSupportSupportPage = () => {

    this.setMenuActive('support');
    this.allOff();

    this.setState({ menuColor: 'blue' });
    this.setState({ supportSupportPage: 1 });


    window.scrollTo(0, 0);

  }

  callProducts = () => {



    this.setMenuActive('products');
    this.setState({ menuColor: 'blue' });

    this.allOff();
    this.setState({ productCardCounter: 'all' });
    this.setState({ productPage: 1 });


    window.scrollTo(0, 0);


  }





  callProductCard = (id) => {


    this.setMenuActive('');
    this.allOff();

    this.setState({ menuColor: 'blue' });

    this.setState({ ProductCardId: id });
    this.setState({ productCardPage: 1 });


    window.scrollTo(0, 0);



  }

  callNews = () => {

    this.setMenuActive('news');

    this.allOff();

    this.setState({ menuColor: 'blue' });

    this.setState({ newsPage: 1 });
    window.scrollTo(0, 0);

  }

  callAbout = () => {

    this.setMenuActive('about');

    this.allOff();
    this.setState({ menuColor: 'white' });

    this.setState({ aboutPage: 1 });
    window.scrollTo(0, 0);


  }

  callSupport = () => {


    this.setMenuActive('support');
    this.allOff();
    this.setState({ menuColor: 'white' });



    this.setState({ supportPage: 1 });
    window.scrollTo(0, 0);

  }

  callFaq = () => {

    this.setMenuActive('support');
    this.allOff();
    this.setState({ menuColor: 'blue' });

    this.setState({ faqPage: 1 });

    window.scrollTo(0, 0);


  }

  callHowToBuy = () => {

    this.setMenuActive('howToBuy');

    this.allOff();
    this.setState({ menuColor: 'white' });

    this.setState({ howToBuyPage: 1 });
    window.scrollTo(0, 0);

  }


  call = () => {

    this.allOff();
    this.setMenuActive('');

    this.setState({ menuColor: '' });
    this.setState({ mainPage: 1 });

  }



  goTop = () => {

    this.setState({ cord: 0 });

  }


  newsSearchStringSet = (searchString) => {

    this.setState({ newsSearchString: searchString });

  }

  newsSearchStringThemesSet = (searchString) => {

    this.setState({ manualSearchString: '' });
    this.setState({ newsSearchStringThemes: searchString });

  }

  manualSearchStringSet = (searchString) => {

    this.setState({ newsSearchStringThemes: '' });
    this.setState({ manualSearchString: searchString });

  }


  render() {

    return (
      <>
        <Routes>
          <Route path="/" element={

            <>
              <SiteMenu
                menuColor={this.state.menuColor}
                productsStatus={this.state.productsEnable}
                call={this.call}
                callNews={this.callNews}
                callAbout={this.callAbout}
                callProducts={this.callProducts}
                callContacts={this.callContacts}
                callSupport={this.callSupport}
                callHowToBuy={this.callHowToBuy}

                menuItemActive={this.state.menuItemActive}
                menuItemSet={this.setMenuActive}

              />
              <ScrollToTop style={{ padding: '0', background: 'rgba(0,0,0,0)', borderRadius: '50%', width: '50px', bottom: '140px' }} component={<img alt={"none"} className='img-fluid' style={{}} src={arrow_up}></img>} smooth />
              <Outlet />
            </>


          }>
            <Route index element={

              <>
                {<TopBlock callProductCard={this.callProductCard} />}



                {<About />}
                {<Welcome />}
                {this.state.products === 1 && <Products
                  callProductCard={this.callProductCard}
                  cardCount={this.state.productCardCounter}
                  callProducts={this.callProducts}
                  productSearchString={this.state.productSearchString}
                  setProductList={this.setProductList}
                />}
                {<AboutCompany callAbout={this.callAbout} />}
                {<ProductApp />}
                {this.state.partners === 1 && <Partners />}
                {this.state.reviews === 1 && <LatestReviews showAllRewies={false} /> }
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
                {this.state.news === 1 && <NewsSlider />}



              </>







            } />

            <Route path="about" element={<>

              <>
                <StaticSlider format={'big'} header={'BETTER TOGETHER'} text={'Work faster. Improve your quality and efficiency'} btnName={''} />
                <AboutProxeet callAbout={this.callAbout} />
                <WhoIsProxeet />
                {<Ruler lineColor={'#00477A'} />}
                {this.state.news === 1 && <NewsSlider />}
                {/*<Question modal={false} mod={''} />*/}
              </>




            </>} />

            <Route path="news" element={


              <>
                <NewsRoom newsSearchStringThemesSet={this.newsSearchStringThemesSet} newsSearchStringSet={this.newsSearchStringSet} />
                <NewsTags newsSearchStringSet={this.newsSearchStringSet} />
                {(
                  (this.state.newsSearchString === '') && (this.state.newsSearchStringThemes === '')
                ) ?
                  <LastNews callNews={this.callNews} callSingleNewPage={this.callSingleNewPage} />

                  : <>
                    {
                      this.state.newsSearchString === '' ?

                        <NewsSearchResult searchSrc={'themes'} newsSearchString={this.state.newsSearchStringThemes} />

                        :

                        <NewsSearchResult searchSrc={'base'} newsSearchString={this.state.newsSearchString} />


                    }
                  </>

                }

                <MoreNews callSingleNewPage={this.callSingleNewPage} newsSearchStringThemesSet={this.newsSearchStringThemesSet} />
                <Subscribe isSent={this.state.emailSent} full={'true'} />
              </>

            } />


            <Route path="contacts" element={

              <>
                <Contacts callContacts={this.callContacts} proxeetPhone={this.state.proxeetPhone} />
                <div className='mt-5'></div>
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
              </>

            } />
            <Route path="business" element={

              <>
                <StaticSlider header={'Why choose Proxeet'} text={'Bring your service to the Next-Next-Level'} btnName={''} />
                <br />
                {/*<AboutProxeet callAbout={() => { }} />*/}
                {/*<ProductApp extended={'full'} />*/}
                {this.state.partners === 1 && <Partners />}

                <Question modal={false} mod={'Please contact us for business inquiries'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />

              </>

            } />



            {this.state.newsLinkList.map((someId) => (

              <Route key={someId + '_path'} path={'/news/' + (someId)} element={

                <>
                  <NewsPage callNews={this.callNews} newsNum={someId} url={'https://proxeet.com/news/' + (someId)} />
                  <MoreNews />
                </>

              } />))

            }











            <Route path="rewies" element={
              <>
                {
                  this.state.rewies === 1 &&
                  <LatestReviews
                    showAllRewies={true}

                  />
                }
              </>
            } />

            <Route path="howToBuy" element={

              <>
                <StaticSlider header={'How to buy?'} text={'Below is a list of distributors and marketplaces'} btnName={''} />
                <HowToBuy callHowToBuy={this.callHowToBuy} />
                <Question
                  modal={false}
                  mod={'Questions? We are here to assist you'}
                  subHeader={'Leave your details and our Sales team will contact you within 1 working day'}
                  sendedText={'Thank you! Your message sent successfully!'} />
              </>


            } />



            {  /* SUPPORT */}


            <Route path="support" element={
              <>
                <StaticSlider manualSearchStringSet={this.manualSearchStringSet} search={true} header={'Welcome to Proxeet Support'} text={'Search for documentation or product information'} btnName={''} />
                {<ManualSearchResult manualSearchString={this.state.manualSearchString} />}
                <Support callSupport={this.callSupport} />
                <SupportBtn callFaq={this.callFaq} callManualPage={this.callManualPage} callsupportSupportPage={this.callSupportSupportPage} />
                {/*<NewsTags />*/}
              </>
            } />

            <Route path="support/support" element={
              <>
                <SupportSupport callSupport={this.callSupportSupportPage} />
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
              </>
            } />
            <Route path="manuals" element={

              <>
                <StaticSlider header={'MANUAL'} text={'This section contains product documentation as well as brief instructions and video tutorials on various topics '} btnName={''} />
                <Manual callSupport={this.callSupport} productID={'1'} />

              </>



            } />


            <Route path="support/faq" element={<Faq callSupport={this.callFaq} />} />

            {  /* SUPPORT */}



            <Route path="products" element={


              <>
                <ProductsTags setProductList={this.setProductList} />
                {
                  this.state.products === 1 && <Products


                    callProductCard={this.callProductCard}
                    cardCount={'all'}
                    callProducts={this.callProducts}
                    productSearchString={this.state.productSearchString}
                    setProductList={this.setProductList}
                  />
                }
              </>




            } />


            <Route path='products/passiveInjector' element={
              <>

                {<ProductsCard callProducts={this.callProducts} productID={'6'} />}
                {<Video productID={this.state.ProductCardId} />}
                {<DeviceInfo />}
                {<BackgroundPhoto orientation={'left'} />}
                {<ProductApp extended={'passive'} />}
                {<BackgroundPhoto />}

                {<DeviceDescription />}
                {<Ruler lineColor={'#95BBDB'} />}
                {<InfoCard />}
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />

              </>
            } />

            <Route path='products/activeInjector' element={
              <>

                {<ProductsCard callProducts={this.callProducts} productID={'4'} />}
                {<Video productID={this.state.ProductCardId} />}
                {<DeviceInfo />}
                {<BackgroundPhoto orientation={'left'} />}
                {<ProductApp extended={'active'} />}
                {<BackgroundPhoto />}

                {<DeviceDescription />}
                {<Ruler lineColor={'#95BBDB'} />}
                {<InfoCard />}
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />
              </>
            } />

            <Route path='/products/comboInjector' element={
              <>

                {<ProductsCard callProducts={this.callProducts} productID={'2'} />}
                {<Video productID={this.state.ProductCardId} />}
                {<DeviceInfo />}
                {<BackgroundPhoto orientation={'left'} />}
                {<ProductApp />}
                {<BackgroundPhoto />}

                {<DeviceDescription />}
                {<Ruler lineColor={'#95BBDB'} />}
                {<InfoCard />}
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />

              </>
            } />



            <Route path='/products/comboTypeCInjector' element={
              <>

                {<ProductsCard callProducts={this.callProducts} productID={'1'} />}
                {<Video productID={this.state.ProductCardId} />}
                {<DeviceInfo />}
                {<BackgroundPhoto orientation={'left'} />}
                {<ProductApp />}
                {<BackgroundPhoto />}

                {<DeviceDescription />}
                {<Ruler lineColor={'#95BBDB'} />}
                {<InfoCard />}
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />

              </>
            } />

            <Route path='/products/activeTypeCInjector' element={
              <>

                {<ProductsCard callProducts={this.callProducts} productID={'3'} />}
                {<Video productID={this.state.ProductCardId} />}
                {<DeviceInfo />}
                {<BackgroundPhoto orientation={'left'} />}
                {<ProductApp />}
                {<BackgroundPhoto />}

                {<DeviceDescription />}
                {<Ruler lineColor={'#95BBDB'} />}
                {<InfoCard />}
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />

              </>
            } />

            <Route path='/products/passiveTypeCInjector' element={
              <>

                {<ProductsCard callProducts={this.callProducts} productID={'5'} />}
                {<Video productID={this.state.ProductCardId} />}
                {<DeviceInfo />}
                {<BackgroundPhoto orientation={'left'} />}
                {<ProductApp />}
                {<BackgroundPhoto />}

                {<DeviceDescription />}
                {<Ruler lineColor={'#95BBDB'} />}
                {<InfoCard />}
                <Question modal={false} mod={'Questions? We are here to assist you'} subHeader={'Leave you details and we will contact you shortly'} sendedText={'Thank you! Your message sent successfully!'} />

              </>
            } />







{/*       */}



            <Route path="/404" element={<NoMatch />} status={404} />

            <Route path="*" element={<NoMatch />} status={404} />
          </Route>
        </Routes>



        <Footer productsStatus={this.state.productsEnable} newsStatus={this.state.newsEnable}

          proxeetPhone={this.state.proxeetPhone}

          goTop={this.goTop}

          call={this.call}
          callNews={this.callNews}
          callAbout={this.callAbout}
          callProducts={this.callProducts}
          callContacts={this.callContacts}
          callSupport={this.callSupport}
          callHowToBuy={this.callHowToBuy}
          callFaq={this.callFaq}
          callBuisnes={this.callBusiness}



        />


      </>




    );

  }
}

export default App;
