import React from 'react';
//import card1 from '../img/card1.png';
//import card2 from '../img/card2.png';
//import card3 from '../img/card3.png';
import bat from '../img/bat.png';
//import { Chip } from './chip';
//import border_img from '../img/bdr.png';
import { withGetScreen } from 'react-getscreen';

class InfoCard extends React.Component {

    constructor(props) {
        super(props);



    }

    state = {

        header: 'Supported power tools batteries',

    }


    render() {


        if (this.props.isMobile()) return (

            <>

            <div className="container-fluid mt-5">



            <div className="row justify-content-center my-4">

                <div className=" col-12 text-center">
                    <span className='proxeet-pc-header' dangerouslySetInnerHTML={{ __html: this.state.header }} />
                </div>

                <div className=" col-10 text-center" >
                    <img alt='' className='img-fluid' src={bat} />
                </div>




            </div>
            </div>
            </>
        );
/*
        if (this.props.isTablet()) return (




            <>

                <div className="container-fluid d-block d-sm-none">



                    <div className="row justify-content-center my-3">



                        <div className=" col-11 text-center m-3" style={{ backgroundColor: '#FFFFFF', border: '2px solid #FF7E33', borderRadius: '30px 30px 30px 30px' }}  >



                            <img src={border_img} style={{ position: 'relative', top: '-14px', left: '10%', zIndex: '200' }}></img>


                            <h2 className='my-4' >PERFORMANCE PROCESSOR</h2>
                            <p className='my-4'>PORTABLE PASSIVE POE intelligently connects mobile devices to your<br /> router or extenders, whichever provides the best connection</p>


                            <div className="row justify-content-center">

                                <div className=" col-4 text-center" >
                                    <img style={{ width: '100px', height: '90px' }} src={card1} />
                                </div>
                                <div className=" col-8 text-start" >
                                    <p className='text-wrap'>Оптоволокно — самая мощная в мире потребительская интернет-технология</p>
                                </div>

                            </div>

                            <div className="row">

                                <div className=" col-4 text-center" >
                                    <img style={{ width: '100px', height: '90px' }} src={card2} />
                                </div>
                                <div className=" col-8 text-start" >
                                    <p className='text-wrap'>Оптоволокно — самая мощная в мире потребительская интернет-технология</p>
                                </div>

                            </div>

                            <div className="row justify-content-center align-middle  mb-4">


                                <div className=" col-4 text-center" style={{ margin: '' }} >
                                    <img style={{ width: '100px', height: '90px' }} src={card3} />
                                </div>
                                <div className=" col-8 text-start" >
                                    <p style={{ fontSize: '12px' }} className='text-wrap'>Оптоволокно — самая мощная в мире потребительская интернет-технология</p>
                                </div>
                            </div>



                        </div>



                    </div>
                </div>





            </>);
*/
        return (

            <>
                <div className="container-fluid">



                    <div className="row justify-content-center my-4">

                        <div className=" col-12 text-center">
                            <span className='proxeet-pc-header' dangerouslySetInnerHTML={{ __html: this.state.header }} />


                        </div>

                        <div className=" col-10 text-center" >
                            <img alt='' className='img-fluid' src={bat} />
                        </div>

                 


                    </div>
                </div>
            </>


        );
    }
}


export default withGetScreen(InfoCard);