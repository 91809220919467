import React from 'react';
import axios from 'axios';
import {Chip} from './chip';
import {withGetScreen} from 'react-getscreen';
import Distributors from "./partners";

class HowToBuy extends React.Component {

    constructor(props) {
        super(props);

        this.jsonGet();
        this.props.callHowToBuy();


    }


    state = {

        selectedTab: 'distributors',
        header: 'Proxeet Distributors around the world',

        marketplace: {},
        t: true,

    }


    jsonGet = async () => {


        let getMarketplace = new FormData();
        getMarketplace.append('table', 'marketplace');
        const components = await axios.request({
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_PROXEET_FULL_API,
            data: getMarketplace
        });

        const data = await components.data;
        this.setState({marketplace: data});

    }


    tabHeandler = (e) => {


        let curientTabSelected = e.target.getAttribute("data-index");

        if (curientTabSelected === 'marketplaces' && this.state.t === false) {

            this.setState({t: !this.state.t});

        } else if (curientTabSelected === 'distributors' && this.state.t === true) {

            this.setState({t: !this.state.t});


        }


    }

    render() {

        let activeTab = {

            borderRadius: '30px 30px 0 0',
            width: '100%',
            height: '4rem',
            color: '#00477A',
            fontSize: '30px',

        };

        let passiveTab = {

            borderRadius: '30px 30px 0 0',
            background: '#FF7E33',
            width: '100%',
            height: '4rem',
            color: '#FFFFFF',
            fontSize: '30px',


        };


        let activeTabMob = {

            borderRadius: '20px 20px 0 0',
            width: '100%',
            height: '3rem',
            color: '#00477A',
            fontSize: '12px',

        };

        let passiveTabMob = {

            borderRadius: '20px 20px 0 0',
            background: '#FF7E33',
            width: '100%',
            height: '3rem',
            color: '#FFFFFF',
            fontSize: '12px',


        };


        if (this.props.isMobile()) return (

            <>
                <div className="container-fluid">


                    <div className="row justify-content-center align-middle  text-center">
                        <span className='proxeet-mob-header'
                              style={{marginTop: '6%', position: "relative",}}>{this.state.header}</span>
                    </div>


                    {this.state.t === false &&
                        <div className="row justify-content-center align-middle  text-center "
                             style={{marginTop: '6%'}}>
                            <iframe title='map' className="col-11 col-md-8"
                                    style={{height: '60vh', border: "0", borderRadius: '50px'}}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3277.32637301815!2d32.42851127646723!3d34.77255817289431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e7068940b47107%3A0xa05709db3b0bd58e!2zzp3Otc6_z4bPjc-Ezr_PhSDOnc65zrrOv867zrHOr860zrcgNjEsIFBhcGhvcywg0JrQuNC_0YA!5e0!3m2!1sru!2s!4v1681324042183!5m2!1sru!2s"
                                    allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    }


                    <div className="row justify-content-center align-middle  text-center" style={{
                        marginTop: '5%',
                        marginBottom: '0%',
                        paddingTop: '3%',
                        paddingBottom: '3%',
                        background: this.state.lineColor
                    }}>

                        <div className="col-11 col-lg-9">

                            <ul className="nav nav-tabs">
                                <li className="nav-item col-6 ">
                                    <button data-index='marketplaces' onClick={this.tabHeandler}
                                            className="nav-link ttt active pt-2 " aria-current="page"
                                            style={this.state.t === true ? passiveTabMob : activeTabMob}>Marketplaces
                                    </button>
                                </li>
                                <li className="nav-item col-6">
                                    <button data-index='distributors' onClick={this.tabHeandler}
                                            className="nav-link ttt pt-2 "
                                            style={this.state.t === false ? passiveTabMob : activeTabMob}>Distributors
                                    </button>
                                </li>


                            </ul>
                            <div className={'proxeet-gray'}>
                                {Object.keys(this.state.marketplace).length !== 0 &&

                                    <>


                                        {
                                            this.state.t === true
                                                ?
                                                <>
                                                    {
                                                        Object.keys(this.state.marketplace).map((someId) => (

                                                            <a className='my-3' key={someId}
                                                               href={this.state.marketplace[someId].marketplaceLink}>
                                                                <img className='col-8 my-2 btn'
                                                                     src={process.env.REACT_APP_MARKETPLACES_IMG_ON_SERVER + this.state.marketplace[someId].img}/>
                                                            </a>


                                                        ))
                                                    }

                                                </>
                                                :
                                                <div className='row justify-content-center'>
                                                    <div className={'col-12 my-3'}>
                                                        <Distributors/>
                                                    </div>

                                                </div>
                                        }

                                    </>
                                }

                            </div>


                        </div>


                    </div>

                </div>

            </>
        );


        if (this.props.isTablet()) return (

            <>
                <div className="container-fluid">


                    <div className="row justify-content-center align-middle  text-center">
                        <span className='proxeet-tab-header'
                              style={{marginTop: '6%', position: "relative",}}>{this.state.header}</span>
                    </div>


                    {this.state.t === false &&
                        <div className="row justify-content-center align-middle  text-center "
                             style={{marginTop: '6%'}}>
                            <iframe title='map' className="col-11 col-md-8"
                                    style={{height: '60vh', border: "0", borderRadius: '50px'}}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3277.32637301815!2d32.42851127646723!3d34.77255817289431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e7068940b47107%3A0xa05709db3b0bd58e!2zzp3Otc6_z4bPjc-Ezr_PhSDOnc65zrrOv867zrHOr860zrcgNjEsIFBhcGhvcywg0JrQuNC_0YA!5e0!3m2!1sru!2s!4v1681324042183!5m2!1sru!2s"
                                    allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    }


                    <div className="row justify-content-center align-middle  text-center" style={{
                        marginTop: '5%',
                        marginBottom: '0%',
                        paddingTop: '3%',
                        paddingBottom: '3%',
                        background: this.state.lineColor
                    }}>

                        <div className="col-11 col-lg-9">

                            <ul className="nav nav-tabs">
                                <li className="nav-item col-6 ">
                                    <button data-index='marketplaces' onClick={this.tabHeandler}
                                            className="nav-link ttt active pt-2 " aria-current="page"
                                            style={this.state.t === true ? passiveTabMob : activeTabMob}>Marketplaces
                                    </button>
                                </li>
                                <li className="nav-item col-6">
                                    <button data-index='distributors' onClick={this.tabHeandler}
                                            className="nav-link ttt pt-2 "
                                            style={this.state.t === false ? passiveTabMob : activeTabMob}>Distributors
                                    </button>
                                </li>


                            </ul>
                            <div style={{background: '#F7F7F7'}}>
                                {Object.keys(this.state.marketplace).length !== 0 &&

                                    <>


                                        {
                                            this.state.t === true
                                                ?
                                                <>
                                                    {
                                                        Object.keys(this.state.marketplace).map((someId) => (

                                                            <a className='my-3' key={someId}
                                                               href={this.state.marketplace[someId].marketplaceLink}>
                                                                <img className='col-4 my-2 btn'
                                                                     src={process.env.REACT_APP_MARKETPLACES_IMG_ON_SERVER + this.state.marketplace[someId].img}/>
                                                            </a>


                                                        ))
                                                    }
                                                </>
                                                :
                                                <div className={'row justify-content-center'}>
                                                    <div className={'col-10'}>
                                                        <Distributors/>
                                                    </div>

                                                </div>
                                        }
                                    </>
                                }

                            </div>


                        </div>


                    </div>

                </div>


            </>

        );


        return (
            <>
                <div className="container-fluid mt-5" style={{position: 'relative'}}>


                    <Chip l={'0'} t={'-15'} w={'20'} rotate={'50'}/>
                    <Chip l={'-3'} t={'40'} w={'10'} rotate={'60'}/>


                    <Chip l={'87'} t={'30'} w={'25'} rotate={'50'}/>


                    <div className="row justify-content-center align-middle  text-center">
                        <span className='proxeet-pc-header' style={{position: "relative",}}>{this.state.header}</span>
                    </div>


                    {this.state.t === false &&
                        <div className="row justify-content-center align-middle  text-center ">
                            <iframe title='map' className="col-11 col-md-8 mt-4"
                                    style={{height: '60vh', border: "0", borderRadius: '50px'}}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3277.32637301815!2d32.42851127646723!3d34.77255817289431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e7068940b47107%3A0xa05709db3b0bd58e!2zzp3Otc6_z4bPjc-Ezr_PhSDOnc65zrrOv867zrHOr860zrcgNjEsIFBhcGhvcywg0JrQuNC_0YA!5e0!3m2!1sru!2s!4v1681324042183!5m2!1sru!2s"
                                    allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    }
                    <div className="row justify-content-center align-middle  text-center mt-4" style={{
                        marginBottom: '0%',
                        paddingTop: '3%',
                        paddingBottom: '3%',
                        background: this.state.lineColor
                    }}>

                        <div className="col-11 col-lg-9">

                            <ul className="nav nav-tabs">
                                <li className="nav-item col-6 ">
                                    <button
                                        data-index='marketplaces'
                                        onClick={this.tabHeandler}
                                        className="nav-link ttt active pt-1 "
                                        aria-current="page"
                                        style={this.state.t === true ? passiveTab : activeTab}
                                    >
                                        Marketplaces
                                    </button>
                                </li>
                                <li className="nav-item col-6">
                                    <button
                                        data-index='distributors'
                                        onClick={this.tabHeandler}
                                        className="nav-link ttt pt-1"
                                        style={this.state.t === false ? passiveTab : activeTab}
                                    >
                                        Distributors
                                    </button>
                                </li>


                            </ul>
                            <div style={{background: '#F7F7F7',  borderRadius: '0 0 30px 30px'}}>
                                {Object.keys(this.state.marketplace).length !== 0 &&

                                    <>


                                        {
                                            this.state.t === true
                                                ?
                                                <div className='row justify-content-center align-middle  text-center'>

                                                    {
                                                        Object.keys(this.state.marketplace).map((someId) => (

                                                            <a className='col-3 my-3' key={someId}
                                                               href={this.state.marketplace[someId].marketplaceLink}>
                                                                <img className='img-fluid my-2 btn'
                                                                     src={process.env.REACT_APP_MARKETPLACES_IMG_ON_SERVER + this.state.marketplace[someId].img}/>
                                                            </a>


                                                        ))
                                                    }


                                                    {/*<p className='py-4' >Marketplaces</p>*/}
                                                </div>
                                                :
                                                <div>
                                                    <Distributors/>
                                                </div>
                                        }

                                    </>
                                }

                            </div>


                        </div>


                    </div>

                </div>
            </>
        );
    }
}

export default withGetScreen(HowToBuy);