import React from 'react';
import axios from 'axios';




export class Video extends React.Component {

    constructor(props) {
        super(props);


        this.videoPlayer = React.createRef();

        this.jsonGet();

    }

    state = {

        TextOnVideo: false,
        video: {}
    }

    componentDidUpdate(prevProps, prevState) {

        window.addEventListener('scroll', this.Play);

        if (this.state.video !== prevState.video) {

            // this.Play();    

        }




    }

    /*
    handlePlayVideo = () => {
        this.videoPlayer.current.play();
    }

    handlePauseVideo = () => {
        this.videoPlayer.current.pause();
    }

    handleTextOnVideo = () => {

        this.setState({ TextOnVideo: !this.state.TextOnVideo });
    }

    */


    jsonGet = async () => {

        let getVideo = new FormData();
        getVideo.append('table', 'video');
        const components = await axios.request({ method: 'post', maxBodyLength: Infinity, url: process.env.REACT_APP_PROXEET_FULL_API, data: getVideo });

        const data = await components.data;




        this.setState({ video: data });


    }


    Play = () => {


        let playConst = (w = window.screen.width, h = window.screen.height) => {

            if (h > w) {


                return w / 2;

            }
            else {


                return 100;
            }


        }


        if ((this.videoPlayer.current.getBoundingClientRect().y < playConst()) && (this.videoPlayer.current.getBoundingClientRect().y > -this.videoPlayer.current.getBoundingClientRect().height)) {
            this.videoPlayer.current.play();
        } else {
            this.videoPlayer.current.pause();
        }

    };



    render() {



        return (
            <>
                <div className="container-fluid mt-4 m-0 p-0" >
                    {/*<div className="row text-start">
                        <div className="col-8">
                            <button className="btn" type="button" onClick={this.handlePlayVideo} style={{ color: 'white', backgroundColor: '#152F4E', borderRadius: '30px 30px 30px 30px' }}>Play</button>
                        </div>
                    </div>

                    <div className="row text-start">
                        <div className="col-8">
                            <button className="btn" type="button" onClick={this.handlePauseVideo} style={{ color: 'white', backgroundColor: '#152F4E', borderRadius: '30px 30px 30px 30px' }}>Pause</button>
                        </div>
                    </div>

                    <div className="row text-start">
                        <div className="col-8">
                            <button className="btn" type="button" onClick={this.handleTextOnVideo} style={{ color: 'white', backgroundColor: '#152F4E', borderRadius: '30px 30px 30px 30px' }}>text</button>
                        </div>
                    </div>
                    */}
                    <div className="row">

                        <div className="container" >
                            {
                                Object.keys(this.state.video).map((someId) => (

                                    <video key={someId} ref={this.videoPlayer} playsinline onFocus={this.Play} onBlur={this.Pause} muted={true} controls={true} >
                                        {/* ffmpeg2theora proxeet.mkv -o o.ogg */}
                                        <source src={process.env.REACT_APP_VIDEO_DIR + this.state.video[someId].videoName.toString() + '.ogg'} type='video/ogg; codecs="theora, vorbis"' />
                                        {/*ffmpeg -i proxeet.mkv -c:v libx264 -c:a aac 1.mp4*/}
                                        {/*<source src={process.env.REACT_APP_VIDEO_DIR + this.state.video[someId].videoName.toString()+'.mp4'} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />*/}
                                        {/*  ffmpeg -i proxeet.mkv  proxeet.webm */}
                                        <source src={process.env.REACT_APP_VIDEO_DIR + this.state.video[someId].videoName.toString() + '.webm'} type='video/webm; codecs="vp8, vorbis"' />

                                        {<source src={process.env.REACT_APP_VIDEO_DIR + this.state.video[someId].videoName.toString() + '.mp4'} type='video/mp4;' />}
                                    </video>



                                ))
                            }






                        </div>
                    </div>


                </div>
            </>
        );
    }
}   