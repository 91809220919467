import React from 'react';


import cardImg1 from '../img/cardImg1.svg';
import cardImg2 from '../img/cardImg2.svg';
import cardImg3 from '../img/cardImg3.svg';
import cardImg4 from '../img/cardImg4.svg';


import { Chip } from './chip';

import { withGetScreen } from 'react-getscreen';



class DeviceInfo extends React.Component {

    constructor(props) {
        super(props);



    }



    state = {

        header: 'PORTABLE PASSIVE POE INJECTOR: Power your network ANYWHERE with UNMATCHED versatility',
        text: 'Our portable passive PoE Injector, coupled with a compatible battery from a power tool delivers seamless power solutions for remote and challenging environments, process of construction, maintenance, repair and diagnostics of communication networks and ensuring uninterrupted connectivity',

        box1: 'Versatile Power Solution',
        description1: 'Injector is equipped with a port for connecting a battery from a power tool. This combination offers unmatched versatility in powering network devices. This innovative solution allows users to power PoE-enabled devices in remote or challenging environments where traditional power sources may be unavailable or impractical. The compatibility with power tool batteries ensures a readily available power supply, making it ideal for any installations, outdoor setups or temporary deployments',
        box2: 'Simplified Installation',
        description2: 'The combination of a portable passive PoE injector and a power tool battery streamlines the installation process. Unlike traditional wired power setups that require access to electrical outlets or the use of complex wiring configurations, this solution eliminates the need for additional wiring and infrastructure. The simplicity of deployment translates to significant time and cost savings, allowing network professionals to complete installations quickly and efficiently. Additionally, the “plug-and-play” functionality of the portable PoE injector ensures a hassle-free setup, reducing the technical expertise required for power provisioning',
        box3: 'Cost-Efficiency',
        description3: 'Using a portable passive PoE injector can result in cost savings. By leveraging existing Ethernet infrastructure, you eliminate the need for additional electrical wiring, power outlets, and associated installation costs. This makes it a cost-effective solution for expanding your network or deploying devices in areas where electrical wiring is challenging or costly. Additionally, the flexibility and portability of the injector enable you to repurpose it for different locations or devices, reducing the need for multiple power sources',
        box4: 'Reliability',
        description4: 'Portable passive PoE injectors are built with durable components and have built-in surge protection, ensuring stable power delivery to connected devices. This reliability is crucial in maintaining uninterrupted network connectivity and powering critical devices, such as security cameras, antennas, access points or other devices. With a portable passive PoE injector, you can trust that your devices will receive consistent power, minimizing the risk of downtime or disruption',


    }



    render() {

        if (this.props.isMobile()) return (

            <>
                <div className="container-fluid" style={{ marginTop: '10%', marginBottom: '10%', position: 'relative' }} >

                    <Chip l={'80'} t={'-7'} w={'15'} rotate={'35'} />


                    <Chip l={'-13'} t={'93'} w={'20'} rotate={'20'} />

                    <div className="row justify-content-center align-middle  text-center">
                        <span className="col-12 my-3 proxeet-mob-header" style={{ fontSize: '22px', position: 'relative' }} dangerouslySetInnerHTML={{ __html: this.state.header }} />
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <p className="col-12 my-3" style={{ marginTop: '0%', marginBottom: '3%', position: 'relative' }} dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>


                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12" >
                            <img src={cardImg1} className="my-3" alt="" />
                            <h2 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.box1 }} />
                            <p className="text-start" style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: this.state.description1 }} />
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12" >
                            <img src={cardImg2} className="my-3" alt="" />
                            <h2 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.box2 }} />
                            <p className="text-start" style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: this.state.description2 }} />
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12" >
                            <img src={cardImg3} className="my-3" alt="" />
                            <h2 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.box3 }} />
                            <p className="text-start" style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: this.state.description3 }} />
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12" >
                            <img src={cardImg4} className="my-3" alt="" />
                            <h2 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.box4 }} />
                            <p className="text-start" style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: this.state.description4 }} />
                        </div>
                    </div>

                </div>


            </>
        );

        if (this.props.isTablet()) return (




            <>
                <div className="container-fluid" style={{ marginTop: '10%', marginBottom: '10%', position: 'relative' }} >

                    <Chip l={'80'} t={'-7'} w={'15'} rotate={'35'} />


                    <Chip l={'-13'} t={'93'} w={'20'} rotate={'20'} />

                    <div className="row justify-content-center align-middle  text-center">
                        <span className="col-12 my-3 proxeet-pc-header" style={{ fontSize: '22px', position: 'relative' }} dangerouslySetInnerHTML={{ __html: this.state.header }} />
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <p className="col-12 my-3" style={{ marginTop: '0%', marginBottom: '3%', position: 'relative' }} dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>


                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12" >
                            <img src={cardImg1} className="my-3" alt="" />
                            <h2 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.box1 }} />
                            <p className="text-start" style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: this.state.description1 }} />
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12" >
                            <img src={cardImg2} className="my-3" alt="" />
                            <h2 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.box2 }} />
                            <p className="text-start" style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: this.state.description2 }} />
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12" >
                            <img src={cardImg3} className="my-3" alt="" />
                            <h2 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.box3 }} />
                            <p className="text-start" style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: this.state.description3 }} />
                        </div>
                    </div>

                    <div className="row justify-content-center align-middle  text-center">
                        <div className="col-12" >
                            <img src={cardImg4} className="my-3" alt="" />
                            <h2 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.box4 }} />
                            <p className="text-start" style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: this.state.description4 }} />
                        </div>
                    </div>

                </div>


            </>);

        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-center align-middle  text-md-center">
                        <span className="col-10 col-sm-10 col-xl-8 proxeet-pc-header" style={{ fontSize: '2.5rem', marginTop: '10%', marginBottom: '1%', position: 'relative' }} dangerouslySetInnerHTML={{ __html: this.state.header }} />
                    </div>

                    <div className="row justify-content-center align-middle  text-md-center">
                        <p className="col-10 col-sm-10 col-xl-8" style={{ marginTop: '0%', marginBottom: '3%', position: 'relative' }} dangerouslySetInnerHTML={{ __html: this.state.text }} />

                    </div>


                    <div className="row justify-content-center align-middle  text-md-center">

                        <div className="col-md-5 col-xl-3" >

                            <img src={cardImg4} className="my-3" alt="" />
                            <h2 dangerouslySetInnerHTML={{ __html: this.state.box1 }} />
                            <p className="text-start" dangerouslySetInnerHTML={{ __html: this.state.description1 }} />


                        </div>

                        <div className="col-md-5 col-xl-3" >

                            <img src={cardImg3} className="my-3" alt="" />
                            <h2 dangerouslySetInnerHTML={{ __html: this.state.box2 }} />
                            <p className="text-start" dangerouslySetInnerHTML={{ __html: this.state.description2 }} />


                        </div>

                    </div>

                    <div className="row justify-content-center align-middle  text-md-center">

                        <div className="col-md-5 col-xl-3" >

                            <img src={cardImg2} className="my-3" alt="" />
                            <h2 dangerouslySetInnerHTML={{ __html: this.state.box3 }} />
                            <p className="text-start" dangerouslySetInnerHTML={{ __html: this.state.description3 }} />


                        </div>
                        <div className="col-md-5 col-xl-3" >

                            <img src={cardImg1} className="my-3" alt="" />
                            <h2 dangerouslySetInnerHTML={{ __html: this.state.box4 }} />
                            <p className="text-start" dangerouslySetInnerHTML={{ __html: this.state.description4 }} />


                        </div>

                    </div>

                    <div className="row justify-content-center align-middle  text-md-center">

                    </div>

                </div>
            </>
        );
    }
}


export default withGetScreen(DeviceInfo);