import React from 'react';
import Card from 'react-bootstrap/Card';
import logo from '../img/chop-orange.png';
import b1 from '../img/1b.svg';
import b2 from '../img/2b.svg';
import b3 from '../img/3b.svg';
import b4 from '../img/4b.svg';
import { withGetScreen } from 'react-getscreen';

class Welcome extends React.Component {

    constructor(props) {
        super(props);



    }

    state = {

        header: 'Welcome to proxeet',
        text: 'Explore how we can help you and your business',

        box1: 'Enhanced performance for WISP<span style="font-size: 0.85rem">s</span>',
        box2: 'Cost savings and improved efficiency',
        box3: 'Innovative solutions & development',
        box4: 'Expert<br/> support'

    }


    render() {

        if (this.props.isMobile()) return (

            <>

                <div className="container-fluid mt-3">

                    <div className='row  justify-content-center' >
                        <span className='text-center proxeet-pc-header' style={{ fontSize: '1.5rem' }} dangerouslySetInnerHTML={{ __html: this.state.header }} />
                        <span style={{ fontSize: '16px', color: '#00477a' }} className='text-center' dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>

                    <div className='row  justify-content-center mt-4' >

                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-3 my-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b1} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box1 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                        <Card style={{ width: '16rem', border: '2px solid #FF7E33', borderRadius: '30px' }} className='d-flex justify-content-center align-items-center text-center mx-3 my-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b2} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span style={{ fontSize: '20px', color: '#00477A' }} className='ttt' dangerouslySetInnerHTML={{ __html: this.state.box2 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                        <Card style={{ width: '16rem', border: '2px solid #FF7E33', borderRadius: '30px' }} className='d-flex justify-content-center align-items-center text-center mx-3 my-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b3} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box3 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>

                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-3 my-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b4} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box4 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </div>
                </div>


            </>

        );


        if (this.props.isTablet()) return (


            <>


                <div className="container-fluid mt-4">

                    <div className='row  justify-content-center' >
                        <span className='text-center proxeet-pc-header' dangerouslySetInnerHTML={{ __html: this.state.header }} />
                        <span style={{ fontSize: '16px', color: '#00477a' }} className='text-center mt-2 mb-4' dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>


                    <div className='row  justify-content-center mt-4' >

                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-3 my-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b1} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box1 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                        <Card style={{ width: '16rem', border: '2px solid #FF7E33', borderRadius: '30px' }} className='d-flex justify-content-center align-items-center text-center mx-3 my-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b2} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span style={{ fontSize: '20px', color: '#00477A' }} className='ttt' dangerouslySetInnerHTML={{ __html: this.state.box2 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                        <Card style={{ width: '16rem', border: '2px solid #FF7E33', borderRadius: '30px' }} className='d-flex justify-content-center align-items-center text-center mx-3 my-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b3} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box3 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>

                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-3 my-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b4} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='my-4'>

                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box4 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </div>



                </div>




            </>

        );

        return (

            <>
                <div className="container-fluid mt-5 d-none d-md-block d-xl-none">

                    <div className='row  justify-content-center' >
                        <span className='text-center proxeet-pc-header' dangerouslySetInnerHTML={{ __html: this.state.header }} />
                        <span style={{ fontSize: '16px', color: '#00477a' }} className='text-center mt-2 mb-4' dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>


                    <div className='row  justify-content-center mt-4' >
                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-4 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b1} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box1 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                        <Card style={{ width: '16rem', border: '2px solid #FF7E33', borderRadius: '30px' }} className='d-flex justify-content-center align-items-center text-center mx-4  border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b2} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span style={{ fontSize: '20px', color: '#00477A' }} className='ttt' dangerouslySetInnerHTML={{ __html: this.state.box2 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className='row  justify-content-center mt-4' >
                        <Card style={{ width: '16rem', border: '2px solid #FF7E33', borderRadius: '30px' }} className='d-flex justify-content-center align-items-center text-center mx-4 mt-5 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b3} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box3 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>

                        <Card style={{ width: '16rem', border: '2px solid #FF7E33', borderRadius: '30px' }} className='d-flex justify-content-center align-items-center text-center mx-4 mt-5 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b4} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box4 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="container-fluid mt-5 d-none d-xl-block">

                    <div className='row  justify-content-center' >
                        <span className='text-center proxeet-pc-header' dangerouslySetInnerHTML={{ __html: this.state.header }} />
                        <span style={{ fontSize: '16px', color: '#00477a' }} className='text-center mt-2 mb-4' dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </div>

                    <div className='row  justify-content-center mt-4' >

                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b1} />
                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box1 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b2} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span style={{ fontSize: '20px', color: '#00477A' }} className='ttt' dangerouslySetInnerHTML={{ __html: this.state.box2 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b3} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box3 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>

                        <Card style={{ width: '16rem' }} className='d-flex justify-content-center align-items-center text-center mx-3 border-0' >
                            <img alt='' className='img-fluid position-absolute' src={b4} />

                            <Card.Body>
                                <Card.Img className='mt-3' variant="top" style={{ width: '50px', height: '50px' }} src={logo} />
                                <Card.Text className='mt-3'>
                                    <span className='ttt' style={{ fontSize: '20px', color: '#00477A' }} dangerouslySetInnerHTML={{ __html: this.state.box4 }} />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </>


        );
    }
}

export default withGetScreen(Welcome);
