import React, { Component } from "react";
import Slider from "react-slick";
import axios from 'axios';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import '../carousel.css';
import logoWhite from '../img/logo-white.png';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { SampleNextArrow, SamplePrevArrow, BigNextArrow, BigPrevArrow } from './arrow';
import { withGetScreen } from 'react-getscreen';
import { Chip } from "./chip";


class NewsSlider extends Component {


  constructor(props) {
    super(props);

    this.jsonGet();

  }

  state = {


    news: {}

  }

  jsonGet = async () => {

    let getNews = new FormData();
    getNews.append('table', 'news');
    getNews.append('rows', 'id,header,text,imgName');
    const components =
        await axios.request({
          method: 'post',
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_PROXEET_FULL_API, data: getNews
        });

    const data = await components.data;

    this.setState({ news: data });


  }

  render() {



    const settingsMob = {



      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };





    const settings = {
      className: "slider",
      dots: false,
      infinite: true,
      centerMode: true,
      slidesToShow: 2.3,
      slidesToScroll: 1,
      variableWidth: false,
      nextArrow: <BigNextArrow />,
      prevArrow: <BigPrevArrow />,
    };


    if (this.props.isMobile()) return (

      <>

        <div className='container-fluid position-relative mt-4' >

          {<Chip l={'70'} t={'17'} w={'15'} rotate={'70'} />}

          {<Chip l={'-20'} t={'80'} w={'20'} rotate={'30'} />}


          <div className="row justify-content-center align-middle  text-center">

            <span className="proxeet-mob-header">Stay updated with our NEWS and EVENTS</span>
          </div>

          <div className="row justify-content-center align-middle align-items-center text-center mt-4">
            <div className="col-10  text-center">
              {
                Object.keys(this.state.news).length !== 0 &&

                <Slider className='row'  {...settingsMob}>
                  {
                    Object.keys(this.state.news).reverse().map((someId) => (

                      <div key={someId.toString()} className="col-3"  >
                        <Link onClick={() => { window.scrollTo(0, 0); }} to={'/news/' + this.state.news[someId].id}>
                          <Card className="mx-3 border-0">
                            <Card.Title className="text-start" style={{ backgroundColor: '#95BBDB', borderRadius: '30px 0 30px 0' }} >
                              <img

                                className=" "
                                style={{ borderRadius: '30px 0 30px 0' }}
                                src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                width="100%"
                                height="250px"
                                alt="logo"
                              />
                              <div className='' style={{ position: 'absolute', left: '15px', top: '15px', zIndex: '5000' }}>
                                <button style={{ backgroundColor: '#152F4E', width: '120px', height: '45px', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="btn"><img alt="" className='img-fluid' src={logoWhite}></img></button>
                              </div>
                            </Card.Title>
                            <Card.Body className="text-start ml-0">
                              <Card.Title className="text-start"><span style={{ color: "#152F4E" }} className="text-start ttt" dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }} /></Card.Title>
                            </Card.Body>
                          </Card>
                        </Link>
                      </div>
                    ))
                  }
                </Slider>
              }
            </div>
          </div>

        </div>
      </>




    );

    if (this.props.isTablet()) return (


      <>
        <div className="container-fluid" style={{ position: 'relative' }}>

          {<Chip l={'70'} t={'17'} w={'15'} rotate={'70'} />}

          {<Chip l={'-20'} t={'80'} w={'20'} rotate={'30'} />}


          <div className="row justify-content-center align-middle  text-center">

            <span className="proxeet-tab-header" style={{ marginTop: '10%', marginBottom: '5%', position: 'relative' }} >Stay updated with our NEWS and EVENTS</span>
          </div>

          <div className="row justify-content-center align-middle align-items-center text-center">
            <div className="col-10  text-center">
              {Object.keys(this.state.news).length !== 0 &&
                <Slider className='row'  {...settingsMob}>
                  {
                    Object.keys(this.state.news).reverse().map((someId) => (

                      <div key={someId.toString()} className="col-3"  >
                        <Link onClick={() => { window.scrollTo(0, 0); }} to={'/news/' + this.state.news[someId].id}>
                          <Card className="mx-3 border-0">
                            <Card.Title className="text-start" style={{ backgroundColor: '#95BBDB', borderRadius: '30px 0 30px 0' }} >
                              <img
                                className=""
                                style={{ borderRadius: '30px 0 30px 0' }}
                                src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                                width="100%"
                                height="250px"
                                alt="logo"
                              />
                              <div className='' style={{ position: 'absolute', left: '15px', top: '15px', zIndex: '5000' }}>
                                <button style={{ backgroundColor: '#152F4E', width: '120px', height: '45px', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="btn border-0 col-md-4 "><img style={{ width: '100px', height: '25px' }} alt="" className='col-md-12' src={logoWhite}></img></button>
                              </div>
                            </Card.Title>
                            <Card.Body className="text-start ml-0">
                              <Card.Title className="text-start">
                                <span style={{ color: "#152F4E" }} className="text-start ttt" dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }} />
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Link>
                      </div>



                    ))
                  }
                </Slider>
              }
            </div>
          </div>

        </div>




      </>

    );

    return (
      <>
        <div className="container-fluid mt-4 d-none d-md-block d-xl-none" style={{ position: 'relative' }}>
          <Chip l={'-12'} t={'75'} w={'40'} rotate={'-30'} />
          <Chip l={'87'} t={'36'} w={'15'} rotate={'0'} />
          <div className="row justify-content-center align-middle  text-center">
            <span className="proxeet-pc-header" style={{ position: 'relative' }} >Stay updated with our NEWS and EVENTS</span>
          </div>

          <div className="row justify-content-center align-middle align-items-center text-center mt-4">
            <div className="col-10  text-center">
              {Object.keys(this.state.news).length !== 0 &&

                <Slider className='row slider'  {...settings}>
                  {

                    Object.keys(this.state.news).reverse().map((someId) => (




                      <Link key={someId} onClick={() => { window.scrollTo(0, 0); }} to={'/news/' + this.state.news[someId].id} style={{ margin: '0', padding: '0' }} >
                        <Card style={{ border: '0', width: '200px' }} >
                          <Card.Title className="text-start" style={{ backgroundColor: '#95BBDB', borderRadius: '30px 0 30px 0' }} >
                            <img

                              className=" "
                              style={{ borderRadius: '30px 0 30px 0' }}
                              src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                              width="100%"
                              height="120px"
                              alt="logo"
                            />
                            <div className='' style={{ position: 'absolute', left: '15px', top: '15px', zIndex: '5000' }}>
                              <button style={{ backgroundColor: '#152F4E', width: '80px', height: '30px', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn btn-success col-md-4 ">
                                <img style={{ width: '60px', height: '20px' }} alt="" className='col-md-12' src={logoWhite} />
                              </button>
                            </div>
                          </Card.Title>



                          <Card.Body className="text-start">
                            <Card.Title className="text-start">
                              <span style={{ fontSize: '12px', color: "#152F4E" }} className="text-start ttt" dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }} />
                            </Card.Title>

                          </Card.Body>
                        </Card>
                      </Link>



                    ))
                  }
                </Slider>
              }
            </div>
          </div>

        </div>

        <div className="container-fluid mt-4 d-none d-xl-block" style={{ position: 'relative' }}>
          <Chip l={'-12'} t={'75'} w={'40'} rotate={'-30'} />
          <Chip l={'87'} t={'36'} w={'15'} rotate={'0'} />
          <div className="row justify-content-center align-middle  text-center">
            <span className="proxeet-pc-header" style={{ position: 'relative' }} >Stay updated with our NEWS and EVENTS</span>
          </div>

          <div className="row justify-content-center align-middle align-items-center text-center mt-4">
            <div className="col-10  text-center">
              {Object.keys(this.state.news).length !== 0 &&
                <Slider className='row slider'  {...settings}>
                  {
                    Object.keys(this.state.news).reverse().map((someId) => (



                      <Link key={someId.toString()} onClick={() => { window.scrollTo(0, 0); }} to={'/news/' + this.state.news[someId].id} style={{ margin: '0', padding: '0' }} >
                        <Card className="border-0" style={{ width: '400px' }} >
                          <Card.Title className="text-start" style={{ backgroundColor: '#95BBDB', borderRadius: '30px 0 30px 0' }} >
                            <img
                              className=" "
                              style={{ borderRadius: '30px 0 30px 0' }}
                              src={process.env.REACT_APP_NEWS_IMG_ON_SERVER + this.state.news[someId].imgName}
                              width="100%"
                              height="266px"
                              alt="logo"
                            />
                            <div className='' style={{ position: 'absolute', left: '15px', top: '15px', zIndex: '5000' }}>
                              <button style={{ backgroundColor: '#152F4E', width: '120px', height: '45px', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} className="apply-btn btn btn-success col-md-4 ">
                                <img style={{ width: '100px', height: '25px' }} alt="" className='col-md-12' src={logoWhite} />
                              </button>
                            </div>
                          </Card.Title>
                          <Card.Body className="text-start">
                            <Card.Title className="text-start">
                              <span style={{ color: "#152F4E" }} className="text-start ttt" dangerouslySetInnerHTML={{ __html: this.state.news[someId].header }} /></Card.Title>
                          </Card.Body>
                        </Card>
                      </Link>



                    ))
                  }
                </Slider>
              }
            </div>
          </div>

        </div>

      </>);
  }
}

export default withGetScreen(NewsSlider);